import {ColumnConfig} from '../ColumnConfig';
import {AttachmentProjectColumnSet} from './AttachmentProjectColumnSet';
import {AttachmentProjectImageColumnSet} from './AttachmentProjectImageColumnSet';
import {ConstructionScheduleTaskColumnSet} from './ConstructionScheduleTaskColumnSet';
import {NameableDropdownItemColumnSet} from './NameableDropdownItemColumnSet';
import {PdfPlanFolderColumnSet} from './PdfPlanFolderColumnSet';
import {PdfPlanMarkerProtocolEntryColumnSet} from './PdfPlanMarkerProtocolEntryColumnSet';
import {PdfPlanPageColumnSet} from './PdfPlanPageColumnSet';
import {PdfPreviewColumnSet} from './PdfPreviewColumnSet';
import {PdfProtocolSettingColumnSet} from './PdfProtocolSettingColumnSet';
import {ProjectCompanyColumnSet} from './ProjectCompanyColumnSet';
import {ProjectCraftColumnSet} from './ProjectCraftColumnSet';
import {ProjectCustomReportTypeColumnSet} from './ProjectCustomReportTypeColumnSet';
import {ProjectProfileColumnSet} from './ProjectProfileColumnSet';
import {ProjectProtocolEntryTypeColumnSet} from './ProjectProtocolEntryTypeColumnSet';
import {ProjectProtocolLocationColumnSet} from './ProjectProtocolLocationColumnSet';
import {ProjectProtocolTypeColumnSet} from './ProjectProtocolTypeColumnSet';
import {ProtocolColumnSet} from './ProtocolColumnSet';
import {ProtocolEntryChatColumnSet} from './ProtocolEntryChatColumnSet';
import {ReportActivityColumnSet} from './ReportActivityColumnSet';
import {ReportEmployeeColumnSet} from './ReportEmployeeColumnSet';
import {ReportMachineColumnSet} from './ReportMachineColumnSet';
import {ReportMaterialsColumnSet} from './ReportMaterialsColumnSet';
import {ReportSupplierColumnSet} from './ReportSupplierColumnSet';
import {ReportWeekColumnSet} from './ReportWeekColumnSet';
import {UserConnectionInviteColumnSet} from './UserConnectionInviteColumnSet';
import {UserOfflineProjectColumnSet} from './UserOfflineProjectColumnSet';
import {PdfPlanColumnSet} from './PdfPlanColumnSet';
import {ShareVersionsEmailSettingsTemplateColumnSet} from './ShareVersionsEmailSettingsTemplateColumnSet';
import {ProjectCalendarColumnSet} from './CalendarColumnSet';
import {UserDeviceOfflineProjectColumnSet} from './UserDeviceOfflineProjectColumnSet';
import {AttachmentProjectBannerColumnSet} from './AttachmentProjectBannerColumnSet';

export const ProjectColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'number'},
  {name: 'name'},
  {name: 'description', def: null},
  {name: 'status', def: null},
  {name: 'contractee'},
  {name: 'currency'},
  {name: 'language'},
  {name: 'address_site', prop: 'addressSite', def: null},
  {name: 'address_id', prop: 'addressId', def: null},
  {name: 'address_contractee', prop: 'addressContractee', def: null},
  {name: 'start_at', prop: 'startAt', cast: 'Date', def: null},
  {name: 'complete_at', prop: 'completeAt', cast: 'Date', def: null},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'created_at', prop: 'createdAt', cnd: true, cast: 'Timestamp'},
  {name: 'project_image', prop: 'projectImage', def: null},
  {name: 'tax_rate', prop: 'taxRate', def: 0},
  {name: 'attachmentProjects', fk: 'projectId', mappedBy: AttachmentProjectColumnSet, transient: true},
  {name: 'attachmentProjectImages', fk: 'projectId', mappedBy: AttachmentProjectImageColumnSet, transient: true},
  {name: 'constructionScheduleTasks', fk: 'projectId', mappedBy: ConstructionScheduleTaskColumnSet, transient: true},
  {name: 'projectProtocolNameableDropdownItems', fk: 'projectId', mappedBy: NameableDropdownItemColumnSet, transient: true},
  {name: 'pdfPlanFolders', fk: 'projectId', mappedBy: PdfPlanFolderColumnSet, transient: true},
  {name: 'pdfPlans', fk: 'projectId', mappedBy: PdfPlanColumnSet, transient: true},
  {name: 'pdfPlanMarkerProtocolEntries', fk: 'projectId', mappedBy: PdfPlanMarkerProtocolEntryColumnSet, transient: true},
  {name: 'pdfPlanPages', fk: 'projectId', mappedBy: PdfPlanPageColumnSet, transient: true},
  {name: 'pdfPreviews', fk: 'projectId', mappedBy: PdfPreviewColumnSet, transient: true},
  {name: 'pdfProtocolSettings', fk: 'projectId', mappedBy: PdfProtocolSettingColumnSet, transient: true},
  {name: 'projectCompanies', fk: 'projectId', mappedBy: ProjectCompanyColumnSet, transient: true},
  {name: 'projectCrafts', fk: 'projectId', mappedBy: ProjectCraftColumnSet, transient: true},
  {name: 'projectCustomReportTypes', fk: 'projectId', mappedBy: ProjectCustomReportTypeColumnSet, transient: true},
  {name: 'projectProfiles', fk: 'projectId', mappedBy: ProjectProfileColumnSet, transient: true},
  {name: 'projectProtocolEntryTypes', fk: 'projectId', mappedBy: ProjectProtocolEntryTypeColumnSet, transient: true},
  {name: 'projectProtocolLocations', fk: 'projectId', mappedBy: ProjectProtocolLocationColumnSet, transient: true},
  {name: 'projectProtocolTypes', fk: 'projectId', mappedBy: ProjectProtocolTypeColumnSet, transient: true},
  {name: 'protocolEntryChats', fk: 'projectId', mappedBy: ProtocolEntryChatColumnSet, transient: true},
  {name: 'protocols', fk: 'projectId', mappedBy: ProtocolColumnSet, transient: true},
  {name: 'reportActivities', fk: 'projectId', mappedBy: ReportActivityColumnSet, transient: true},
  {name: 'reportEmployees', fk: 'projectId', mappedBy: ReportEmployeeColumnSet, transient: true},
  {name: 'reportMachines', fk: 'projectId', mappedBy: ReportMachineColumnSet, transient: true},
  {name: 'reportMaterials', fk: 'projectId', mappedBy: ReportMaterialsColumnSet, transient: true},
  {name: 'reportSuppliers', fk: 'projectId', mappedBy: ReportSupplierColumnSet, transient: true},
  {name: 'reportWeeks', fk: 'projectId', mappedBy: ReportWeekColumnSet, transient: true},
  {name: 'userConnectionInvites', fk: 'projectId', mappedBy: UserConnectionInviteColumnSet, transient: true},
  {name: 'userOfflineProjects', fk: 'projectId', mappedBy: UserOfflineProjectColumnSet, transient: true},
  {name: 'userDeviceOfflineProjects', fk: 'projectId', mappedBy: UserDeviceOfflineProjectColumnSet, transient: true},
  {name: 'shareVersionsEmailSettingsTemplates', fk: 'projectId', mappedBy: ShareVersionsEmailSettingsTemplateColumnSet, transient: true},
  {name: 'calendars', fk: 'projectId', mappedBy: ProjectCalendarColumnSet, transient: true},
  {name: 'attachmentProjectBanners', fk: 'projectId', mappedBy: AttachmentProjectBannerColumnSet, transient: true},
];
