import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {WorkflowType} from 'src/app/model/send-protocol';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {UserService} from 'src/app/services/user/user.service';
import {LicenseType, Participant, Protocol, Report} from 'submodules/baumaster-v2-common';
import {NextMeetingComponent} from '../next-meeting/next-meeting.component';
import {NextMeetingForm, IndividualNextMeetingForm} from '../next-meeting/next-meeting.interface';
import {PdfProtocolSignatureForm} from '../pdf-protocol-signatures/pdf-protocol-signatures.interface';

@Component({
  selector: 'app-pdf-advanced-settings',
  templateUrl: './pdf-advanced-settings.component.html',
  styleUrls: ['./pdf-advanced-settings.component.scss'],
})
export class PdfAdvancedSettingsComponent {

  readonly WorkflowTypeEnum = WorkflowType;

  @Input()
  showOnlyTemplateOptions = false;
  @Input()
  workflowType: WorkflowType;
  @Input()
  form: UntypedFormGroup;

  @Input()
  protocol?: Protocol;
  @Input()
  report?: Report;

  @Input()
  participants: Participant[] = [];

  @Input()
  nextMeeting?: NextMeetingForm;
  @Output()
  nextMeetingChange = new EventEmitter<NextMeetingForm>();

  @Input()
  individualNextMeetings: IndividualNextMeetingForm[] = [];
  @Output()
  individualNextMeetingsChange = new EventEmitter<IndividualNextMeetingForm[]>();

  @Input()
  showSignatures?: boolean;
  @Output()
  showSignaturesChange = new EventEmitter<boolean>();
  @Input()
  showReportSignatures?: boolean;
  @Output()
  showReportSignaturesChange = new EventEmitter<boolean>();

  @Input()
  pdfProtocolSignatures: PdfProtocolSignatureForm[] = [];
  @Output()
  pdfProtocolSignaturesChange = new EventEmitter<PdfProtocolSignatureForm[]>();

  @ViewChild(NextMeetingComponent, {
    static: false
  }) nextMeetingComponent: NextMeetingComponent;

  public readonly isSignatureEnabled$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER, LicenseType.LIGHT]).pipe(
    switchMap((isEnabled) => isEnabled ? of(true) : this.userService.currentUser$.pipe(
      map((user) => user.assignedReportRights)
    ))
  );

  constructor(private featureEnabledService: FeatureEnabledService, private userService: UserService) { }

  toggleIndividualProtocol() {
    const individualProtocol = this.form.get('individualProtocol').value;
    this.form.get('individualProtocol').setValue(!individualProtocol, { emitEvent: false });
    setTimeout(() => this.form.get('individualProtocol').markAsDirty());
  }
}
