import {ColumnConfig} from '../ColumnConfig';
import {ActivityColumnSet} from './ActivityColumnSet';
import {EmployeeColumnSet} from './EmployeeColumnSet';
import {EquipmentColumnSet} from './EquipmentColumnSet';
import {IndividualNextMeetingColumnSet} from './IndividualNextMeetingColumnSet';
import {MaterialColumnSet} from './MaterialColumnSet';
import {ParticipantColumnSet} from './ParticipantColumnSet';
import {ProfileCraftColumnSet} from './ProfileCraftColumnSet';
import {ProjectProfileColumnSet} from './ProjectProfileColumnSet';
import {ProtocolEntryColumnSet} from './ProtocolEntryColumnSet';
import {ReportActivityColumnSet} from './ReportActivityColumnSet';
import {ReportColumnSet} from './ReportColumnSet';
import {ReportEmployeeColumnSet} from './ReportEmployeeColumnSet';
import {ReportMachineColumnSet} from './ReportMachineColumnSet';
import {ReportMaterialsColumnSet} from './ReportMaterialsColumnSet';
import {ReportSupplierColumnSet} from './ReportSupplierColumnSet';
import {StaffColumnSet} from './StaffColumnSet';
import {UserConnectionInviteColumnSet} from './UserConnectionInviteColumnSet';
import {UserInviteColumnSet} from './UserInviteColumnSet';
import {UserPublicColumnSet} from './UserPublicColumnSet';
import {AttachmentProtocolSignatureColumnSet} from './AttachmentProtocolSignatureColumnSet';
import {PdfPlanVersionDistRecipientColumnSet} from './PdfPlanVersionDistRecipientColumnSet';

export const ProfileColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'note', def: null},
  {name: 'profession', def: null},
  {name: 'address_id', prop: 'addressId', def: null},
  {name: 'client_id', prop: 'clientId', cnd: true},
  {name: 'company_id', prop: 'companyId'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
  {name: 'is_active', prop: 'isActive', def: true},
  {name: 'attached_to_user_id', prop: 'attachedToUserId', def: null},
  {name: 'dsgvo_show_email', prop: 'dsgvoShowEmail', def: false},
  {name: 'dsgvo_show_tel', prop: 'dsgvoShowTel', def: false},
  {name: 'participants', fk: 'profileId', mappedBy: ParticipantColumnSet, transient: true},
  {name: 'activities', fk: 'modifiedById', mappedBy: ActivityColumnSet, transient: true},
  {name: 'equipments', fk: 'modifiedById', mappedBy: EquipmentColumnSet, transient: true},
  {name: 'materials', fk: 'modifiedById', mappedBy: MaterialColumnSet, transient: true},
  {name: 'profileCrafts', fk: 'profileId', mappedBy: ProfileCraftColumnSet, transient: true},
  {name: 'projectProfiles', fk: 'profileId', mappedBy: ProjectProfileColumnSet, transient: true},
  {name: 'protocolEntries', fk: 'internalAssign', mappedBy: ProtocolEntryColumnSet, transient: true},
  {name: 'reportActivities', fk: 'importById', mappedBy: ReportActivityColumnSet, transient: true},
  {name: 'reportEmployees', fk: 'importById', mappedBy: ReportEmployeeColumnSet, transient: true},
  {name: 'reportMachines', fk: 'importById', mappedBy: ReportMachineColumnSet, transient: true},
  {name: 'reportMaterials', fk: 'importById', mappedBy: ReportMaterialsColumnSet, transient: true},
  {name: 'reportSuppliers', fk: 'importById', mappedBy: ReportSupplierColumnSet, transient: true},
  {name: 'reports', fk: 'editorId', mappedBy: ReportColumnSet, transient: true},
  {name: 'reports', fk: 'employerId', mappedBy: ReportColumnSet, transient: true},
  {name: 'userConnectionInvites', fk: 'profileId', mappedBy: UserConnectionInviteColumnSet, transient: true},
  {name: 'userInvites', fk: 'profileId', mappedBy: UserInviteColumnSet, transient: true},
  {name: 'userPublics', fk: 'profileId', mappedBy: UserPublicColumnSet, transient: true},
  {name: 'staffs', fk: 'modifiedById', mappedBy: StaffColumnSet, transient: true},
  {name: 'employees', fk: 'modifiedById', mappedBy: EmployeeColumnSet, transient: true},
  {name: 'employees', fk: 'employerId', mappedBy: EmployeeColumnSet, transient: true},
  {name: 'individualNextMeetings', fk: 'profileId', mappedBy: IndividualNextMeetingColumnSet, transient: true},
  {name: 'attachmentProtocolSignatures', fk: 'profileId', mappedBy: AttachmentProtocolSignatureColumnSet, transient: true},
  {name: 'pdfPlanVersionDistRecipients', fk: 'profileId', mappedBy: PdfPlanVersionDistRecipientColumnSet, transient: true},
];
