import {Client, ClientStatusEnum} from 'submodules/baumaster-v2-common';
import {Nullish} from '../model/nullish';

export const isViewerOnlyClient = (client: Nullish<Pick<Client, 'status'>>) => [
  ClientStatusEnum.EXPIRED_TEST_PHASE,
  ClientStatusEnum.CANCELLED,
  ClientStatusEnum.PAYMENT_OVERDUE,
  ClientStatusEnum.DELETED
].includes(client?.status);

export const isPaymentOverdueClient = (client: Nullish<Pick<Client, 'status'>>) => [
  ClientStatusEnum.PAYMENT_OVERDUE
].includes(client?.status);
