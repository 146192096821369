import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IdType, NotificationConfigRecipient, User} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import {IntegrityResolverService} from '../integrity/integrity-resolver.service';
import {ProjectAvailabilityExpirationService} from '../project/project-availability-expiration.service';
import {StorageService} from '../storage.service';
import {UserService} from '../user/user.service';
import {AbstractProjectAwareDataService} from './abstract-project-aware-data.service';
import {ProjectDataService} from './project-data.service';

const REST_ENDPOINT_URI = 'api/data/notificationConfigRecipients';
const VERSION_INTRODUCED = '2.28.0'; // todo: Change before release

@Injectable({
  providedIn: 'root'
})
export class NotificationConfigRecipientDataService extends AbstractProjectAwareDataService<NotificationConfigRecipient>{

  constructor(http: HttpClient, storage: StorageService,
              authenticationService: AuthenticationService,
              userService: UserService,
              protected projectDataService: ProjectDataService,
              protected projectAvailabilityExpirationService: ProjectAvailabilityExpirationService,
              loggingService: LoggingService, integrityResolverService: IntegrityResolverService) {
    super(StorageKeyEnum.NOTIFICATION_CONFIG_RECIPIENT, REST_ENDPOINT_URI, [], http, storage, authenticationService, userService, projectDataService, loggingService,
          projectAvailabilityExpirationService, integrityResolverService, VERSION_INTRODUCED);
  }

  public async deleteByProfileId(profileId: IdType, projectId: IdType) {
    return super.delete((recipients) => recipients.filter((recipient) => recipient.profileId === profileId), projectId);
  }

  protected checkHasCurrentUserPermission(currentUser: User): boolean {
    return true;
  }
}
