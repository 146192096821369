import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PluginListenerHandle} from '@capacitor/core';
import {
  PermissionStatus,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import {Platform} from '@ionic/angular';
import {BehaviorSubject, EMPTY, Subscription, debounceTime, distinctUntilChanged, filter, fromEventPattern, retry, switchMap, timer} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthenticationService} from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class FcmPushTokenManagerService {

  private subscription: Subscription | undefined;
  private permissionRequestResultSubject = new BehaviorSubject<PermissionStatus|undefined>(undefined);
  permissionRequestResult$ = this.permissionRequestResultSubject.asObservable();

  get permissionRequestResult(): PermissionStatus|undefined { return this.permissionRequestResultSubject.value; }
  set permissionRequestResult(permissionRequestResult: PermissionStatus|undefined) { this.permissionRequestResultSubject.next(permissionRequestResult); }

  constructor(private platform: Platform, private authenticationService: AuthenticationService, private httpClient: HttpClient) {}

  isPushSupported() {
    return this.platform.is('capacitor');
  }

  private getToken$() {
    if (!this.isPushSupported()) {
      return EMPTY;
    }

    return fromEventPattern((handler) => {
      return PushNotifications.addListener('registration', handler);
    }, (_handler, signalPromise: Promise<PluginListenerHandle>) => {
      signalPromise.then((handle) => handle.remove());
    }).pipe(
      switchMap((token: Token) => this.authenticationService.data.pipe(
        filter((data) => data && data.impersonated !== true), // Never send FCM token when impersonated
        distinctUntilChanged((a, b) => a.userId === b.userId),
        debounceTime(0),
        switchMap(() => this.httpClient.post(`${environment.serverUrl}fcmPushTokens/`, {token: token.value})),
        retry({
          delay: (_error, retryCount) => {
            return timer((2 ** Math.min(retryCount, 10)) * 50 * (Math.random() + 1));
          },
          resetOnSuccess: true,
        })
      ))
    );
  }

  async cachedPermissionsOrRequest() {
    if (!this.permissionRequestResult) {
      this.permissionRequestResult = await PushNotifications.requestPermissions();
    }

    return this.permissionRequestResult;
  }

  async startTokenManagement({forced = false}: Partial<{forced: boolean}> = {}) {
    if (this.subscription) {
      return;
    }
    const result = await (forced ? this.forcePermissionsRequest() : this.cachedPermissionsOrRequest());
    if (result.receive === 'granted') {
      PushNotifications.register();
      this.subscription = this.getToken$().subscribe();
    } // TODO(tdom): Do we want to show some alert to the user?
  }

  async forcePermissionsRequest() {
    this.permissionRequestResult = await PushNotifications.requestPermissions();

    return this.permissionRequestResult;
  }

  stopTokenManagement() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }
}
