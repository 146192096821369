import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {PdfPlanMarkerComponent} from 'src/app/components/common/pdf-plan-marker/pdf-plan-marker.component';
import {ImageCanvasComponent} from 'src/app/components/common/image-canvas/image-canvas.component';
import {PdfPlanTreeViewComponent} from 'src/app/components/common/pdf-plan-tree-view/pdf-plan-tree-view.component';
import {LicenseModule} from '../license/license.module';
import {UiModule} from '../ui/ui.module';
import {RxLet} from '@rx-angular/template/let';
import {OfflineInfoComponent} from '../../../components/common/offline-info/offline-info.component';

@NgModule({
  declarations: [
    PdfPlanMarkerComponent,
    ImageCanvasComponent,
    PdfPlanTreeViewComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    FontAwesomeModule,
    PipesModule,
    LicenseModule,
    UiModule,
    RxLet,
    OfflineInfoComponent
  ],
  exports: [
    PdfPlanMarkerComponent,
    ImageCanvasComponent,
    PdfPlanTreeViewComponent
  ]
})
export class PlanMarkerModule { }
