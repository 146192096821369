import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CraftDataService} from '../../../services/data/craft-data.service';
import {Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {Address, Company, CompanyCraft, Craft, IdType, Profile} from 'submodules/baumaster-v2-common';
import {ProfileDataService} from '../../../services/data/profile-data.service';
import {AddressDataService} from '../../../services/data/address-data.service';
import {CompanyDataService} from '../../../services/data/company-data.service';
import {CompanyCraftDataService} from '../../../services/data/company-craft-data.service';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import _ from 'lodash';
import {CraftService} from 'src/app/services/craft/craft.service';
import {EMAIL_REG_EXP_PATTERN, PHONE_NUMBER_PATTERN} from '../../../shared/constants';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {TranslateService} from '@ngx-translate/core';
import {UsernameUpdateComponent} from '../../the-settings/username-update/username-update.component';
import {ModalController} from '@ionic/angular';
import {UserService} from 'src/app/services/user/user.service';
import {SelectableService} from '../../../services/common/selectable.service';
import {LoggingService} from '../../../services/common/logging.service';
import {SelectableChangeType} from '../../common/selectable/selectable.component';
import {combineLatestAsync} from 'src/app/utils/async-utils';

const LOG_SOURCE = 'ContactEmployeeFormComponent';

@Component({
  selector: 'app-contact-person-form',
  templateUrl: './contact-employee-form.component.html',
  styleUrls: ['./contact-employee-form.component.scss'],
})

export class ContactEmployeeFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() form: UntypedFormGroup;
  @Output() formChange = new EventEmitter<UntypedFormGroup>();

  @Input() addressId: string;

  @Input() prePopulateMainFieldWithText?: string;

  @Input() formDirty: boolean;
  @Output() formDirtyChange = new EventEmitter<boolean>();

  @Input()
  readonly = false;

  @Input()
  public company: Company;

  public readonly emailRegExpPattern = EMAIL_REG_EXP_PATTERN;

  public address: Address;
  public profile: Profile;
  public username: string | undefined;
  public isCurrentUser: boolean | undefined;
  public companyCrafts: Array<CompanyCraft>;
  public craftData: Observable<Array<Craft>>;
  public craftsWithinCompany$: Observable<Array<Craft>>;
  private craftsWithinCompanyInitializedWith: [string | undefined, string | undefined] | undefined;
  public profileCrafts: Array<Craft> = [];
  public contactEmployeeForm: UntypedFormGroup = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    companyName: ['', Validators.required],
    crafts: [''],
    role: [''],
    mobile: [''],
    phone: [''],
    email: [''],
    fax: [''],
    note: [''],
    dsgvoShowTel: [false],
    dsgvoShowEmail: [false]
  });

  public projectCompanies: Observable<Array<Company>>;

  private craftsForProfileSubscription: Subscription | undefined;
  private formDataSubscription: Subscription | undefined;
  private valueChangesSubscription: Subscription | undefined;
  public readonly phoneNumberPattern = PHONE_NUMBER_PATTERN;


  constructor(private formBuilder: UntypedFormBuilder,
              private craftDataService: CraftDataService,
              private profileDataService: ProfileDataService,
              private addressDataService: AddressDataService,
              private companyDataService: CompanyDataService,
              private companyCraftDataService: CompanyCraftDataService,
              private projectCompanyDataService: ProjectCompanyDataService,
              private craftService: CraftService,
              private featureEnabledService: FeatureEnabledService,
              private translateService: TranslateService,
              private modalController: ModalController,
              private userService: UserService,
              private selectableService: SelectableService,
              private loggingService: LoggingService) {
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.addressId && !_.isEmpty(this.addressId)) {
        this.initFormContactData();
    }
    if (changes.company && this.company) {
      this.contactEmployeeForm.get('companyName').setValue(this.company);
      this.companyChanged({ value: this.company, values: [this.company], component: null });
    }
    if (changes.prePopulateMainFieldWithText && changes.prePopulateMainFieldWithText.currentValue && changes.prePopulateMainFieldWithText.firstChange) {
      this.contactEmployeeForm.controls.lastName.setValue(this.prePopulateMainFieldWithText);
      this.contactEmployeeForm.controls.lastName.markAsDirty();
    }
  }

  async ngOnInit(): Promise<void> {
    this.projectCompanies = this.companyDataService.activeDataForOwnClient$;
    this.craftData = this.craftDataService.dataForOwnClient$;
  }

  companyChanged(event: SelectableChangeType<Company>) {
    const value = event.value;
    if (!value) {
      this.loggingService.warn(LOG_SOURCE, 'companyChanged called without a value even though it is a mandatory field');
      return;
    }
    this.initCompanyCraft(value.id);
    this.contactEmployeeForm.get('role').reset();
    this.contactEmployeeForm.get('crafts').reset();
  }

  initCompanyCraft(companyId: IdType, profileId?: IdType) {
    const [initializedWithCompanyId, initializedWithProfileId] = this.craftsWithinCompanyInitializedWith ?? [];
    if (initializedWithCompanyId === companyId && initializedWithProfileId === profileId) {
      this.loggingService.debug(LOG_SOURCE, `initCompanyCraft omitted, as it was already initialized with companyId:${companyId} and profileId:${profileId}`);
      return;
    }
    this.loggingService.debug(LOG_SOURCE, `initCompanyCraft called with companyId:${companyId} and profileId:${profileId}`);
    this.craftsWithinCompanyInitializedWith = [companyId, profileId];
    this.craftsWithinCompany$ = (profileId ?
      this.craftService.getCraftsForCompanyAndProfileWithDeletedSuffix(companyId, profileId) :
      this.craftService.getCraftsForCompanyWithDeletedSuffix(companyId)).pipe(
        distinctUntilChanged((x, y) => _.isEqual(x, y))
      );
    if (!_.isEmpty(profileId)) {
      this.profileCraftsUnsubscribe();
      this.craftsForProfileSubscription = this.craftService.getCraftsForProfileWithDeletedSuffix(profileId).pipe(
        distinctUntilChanged((x, y) => _.isEqual(x, y))
      ).subscribe(profileCrafts => {
        this.profileCrafts = profileCrafts || [];
        const craftsControl = this.contactEmployeeForm.get('crafts');
        const value = craftsControl.value;
        if (!craftsControl.dirty && !_.isEqual(value, this.profileCrafts)) {
          craftsControl.setValue(this.profileCrafts, {onlySelf: true});
        }
      });
    }
  }

  craftsOnChange(event: { component: IonicSelectableComponent, value: Craft, values: Array<Craft> }) {
    this.profileCrafts = event.values.length !== 0 ? _.sortBy(event.values, 'name') : [];
    this.contactEmployeeForm.get('crafts').setValue(this.profileCrafts, {onlySelf: true});
  }

  private profileCraftsUnsubscribe() {
    this.craftsForProfileSubscription?.unsubscribe();
    this.craftsForProfileSubscription = undefined;
  }

  private formDataUnsubscribe() {
    this.formDataSubscription?.unsubscribe();
    this.formDataSubscription = undefined;
  }

  ngOnDestroy(): void {
    this.profileCraftsUnsubscribe();
    this.formDataUnsubscribe();
    this.valueChangesUnsubscribe();
  }

  private initFormContactData() {
    this.form?.markAsPristine();
    this.formDataSubscription = combineLatestAsync([
      this.profileDataService.dataForOwnClient$,
      this.addressDataService.dataForOwnClient$,
      this.companyDataService.dataForOwnClient$,
      this.companyCraftDataService.dataForOwnClient$,
      this.userService.currentUser$
    ])
    .pipe(
      map(([profiles, addresses, companies, companyCrafts, currentUser]) => {
        const address = addresses.find(addressData => addressData.id === this.addressId);
        const profile = profiles.find(profileData => profileData.addressId === address.id);
        const company = companies.find(companyData => companyData.id === profile.companyId);
        const companyCraftsByCompanyId = companyCrafts.filter(cc => cc.companyId === company.id);
        return {
          address,
          company,
          profile,
          companyCraftsByCompanyId,
          currentUser
        };
      })
    ).subscribe(data => {
      this.address = data.address;
      this.company = data.company;
      this.profile = data.profile;
      this.companyCrafts = data.companyCraftsByCompanyId;
      if (data.currentUser.profileId === data.profile.id) {
        this.username = data.currentUser.username;
        this.isCurrentUser = true;
      } else {
        this.username = undefined;
        this.isCurrentUser = false;
      }
      this.applyContactData();
    });
  }

  private applyContactData() {
    const firstNameControl = this.contactEmployeeForm.get('firstName');
    const lastNameControl = this.contactEmployeeForm.get('lastName');
    const mobileControl = this.contactEmployeeForm.get('mobile');
    const phoneControl = this.contactEmployeeForm.get('phone');
    const emailControl = this.contactEmployeeForm.get('email');
    const faxControl = this.contactEmployeeForm.get('fax');
    const companyNameControl = this.contactEmployeeForm.get('companyName');
    const noteControl = this.contactEmployeeForm.get('note');
    const roleControl = this.contactEmployeeForm.get('role');
    const dsgvoShowTelControl = this.contactEmployeeForm.get('dsgvoShowTel');
    const dsgvoShowEmailControl = this.contactEmployeeForm.get('dsgvoShowEmail');

    if (!firstNameControl.dirty) {
      firstNameControl.setValue(this.address.firstName, {onlySelf: true});
    }
    if (!lastNameControl.dirty) {
      lastNameControl.setValue(this.address.lastName, {onlySelf: true});
    }
    if (!mobileControl.dirty) {
      mobileControl.setValue(this.address.mobile, {onlySelf: true});
    }
    if (!phoneControl.dirty) {
      phoneControl.setValue(this.address.phone, {onlySelf: true});
    }
    if (!emailControl.dirty) {
      emailControl.setValue(this.address.email, {onlySelf: true});
    }
    if (!faxControl.dirty) {
      faxControl.setValue(this.address.fax, {onlySelf: true});
    }
    if (!companyNameControl.dirty) {
      companyNameControl.setValue(this.company, {onlySelf: true});
    }
    if (!noteControl.dirty) {
      noteControl.setValue(this.profile.note, {onlySelf: true});
    }
    if (!roleControl.dirty) {
      roleControl.setValue(this.profile.profession, {onlySelf: true});
    }
    if (!dsgvoShowTelControl.dirty) {
      dsgvoShowTelControl.setValue(this.profile.dsgvoShowTel, {onlySelf: true});
    }
    if (!dsgvoShowEmailControl.dirty) {
      dsgvoShowEmailControl.setValue(this.profile.dsgvoShowEmail, {onlySelf: true});
    }
    this.initCompanyCraft(this.company.id, this.profile.id);
  }

  ngAfterViewInit() {
    this.checkFormIsDirty();
    this.form = this.contactEmployeeForm;
  }

  checkFormIsDirty() {
    this.valueChangesUnsubscribe();
    this.valueChangesSubscription = this.contactEmployeeForm.valueChanges
    .subscribe((formValues) => {
      this.updateForm();
      this.updateFormDirty(this.contactEmployeeForm.dirty);
    });
  }

  private updateFormDirty(formDirty: boolean) {
    this.formDirty = formDirty;
    this.formDirtyChange.emit(formDirty);
  }

  private updateForm() {
    this.form = this.contactEmployeeForm;
    this.formChange.emit(this.contactEmployeeForm);
  }

  private valueChangesUnsubscribe() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
      this.valueChangesSubscription = undefined;
    }
  }

  public async changeUsername() {
    const modal = await this.modalController.create({
      component: UsernameUpdateComponent
    });
    return await modal.present();
  }

  createNewCompanyFunction = (text?: string): Promise<Company|undefined> => {
    return this.selectableService.createNewCompany(text);
  };

  assignCompanyToProjectFunction = (item: Company, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignNameableDropdownToProject(item, assign);
  };

  assignCraftToProjectFunction = (item: Craft, assign: boolean): Promise<boolean> => {
    return this.selectableService.assignCraftToProject(item, assign);
  };
}
