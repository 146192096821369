import {Injectable} from '@angular/core';
import {Craft, IdType} from 'submodules/baumaster-v2-common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CraftDataService} from '../data/craft-data.service';
import {ProfileCraftDataService} from '../data/profile-craft-data.service';
import {CompanyCraftDataService} from '../data/company-craft-data.service';
import {combineLatestAsync, mapObjectsNameWithDeletedSuffix} from 'src/app/utils/async-utils';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CraftService {

  constructor(
    private craftDataService: CraftDataService,
    private profileCraftDataService: ProfileCraftDataService,
    private companyCraftDataService: CompanyCraftDataService,
    private translateService: TranslateService
  ) {
  }

  getCraftsForProfile(profileId: IdType): Observable<Craft[]> {
    return combineLatestAsync([this.profileCraftDataService.dataForOwnClient$, this.craftDataService.dataForOwnClient$]).pipe(
      map(([profileCrafts, crafts]) => {
        return crafts?.filter((craft) => {
          return profileCrafts?.find((profileCraft) => profileCraft.craftId === craft.id && profileCraft.profileId === profileId);
        });
      })
    );
  }

  getCraftsForProfileWithDeletedSuffix(profileId: IdType): Observable<Craft[]> {
    return this.getCraftsForProfile(profileId).pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  }

  getCraftsForCompany(companyId: IdType): Observable<Craft[]> {
    return combineLatestAsync([this.companyCraftDataService.dataForOwnClient$, this.craftDataService.dataForOwnClient$]).pipe(
      map(([companyCrafts, crafts]) => {
        return crafts.filter((craft) => companyCrafts.find((companyCraft) => companyCraft.craftId === craft.id && companyCraft.companyId === companyId));
      })
    );
  }

  getCraftsForCompanyWithDeletedSuffix(companyId: IdType): Observable<Craft[]> {
    return this.getCraftsForCompany(companyId).pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  }

  getCraftsForCompanyAndProfile(companyId: IdType, profileId: IdType): Observable<Craft[]> {
    return combineLatestAsync([
      this.getCraftsForCompany(companyId),
      this.getCraftsForProfile(profileId)
    ]).pipe(
      map(([companyCrafts, profileCrafts]) => {
        const activeInCompany = companyCrafts.filter((craft) => craft.isActive);

        return [...profileCrafts, ...activeInCompany.filter((craft) => !profileCrafts.some((profileCraft) => profileCraft.id === craft.id))];
      })
    );
  }

  getCraftsForCompanyAndProfileWithDeletedSuffix(companyId: IdType, profileId: IdType): Observable<Craft[]> {
    return this.getCraftsForCompanyAndProfile(companyId, profileId).pipe(mapObjectsNameWithDeletedSuffix(this.translateService));
  }
}
