<div class="tabs">
  <ion-toolbar class="mobile-padding">
    <div class="center">
      <ion-button *ngFor="let tabMenu of tabMenus; let arrIndex = index" fill="clear"  (click)="navigateTo(tabMenu)" [class.active]="tabMenu?.url === selectedMenu?.url || tabMenu?.url === router.url" [hidden]="tabMenu.disabled$ | async">
        <ion-grid>
          <ion-row>
            <ion-col>
              <fa-icon class="text-secondary" [icon]="tabMenu.icon"></fa-icon>
            </ion-col>
          </ion-row>
          <ion-row class="ion-hide-sm-down">
            <ion-col class="ion-text-center tab-menu-label"><span
                    class="text-secondary">{{ tabMenu.titleTab ? (tabMenu.titleTab | translate): (tabMenu.title | translate) }}</span></ion-col>
          </ion-row>
        </ion-grid>
      </ion-button>
    </div>
  </ion-toolbar>
</div>
