import {Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {
  Attachment,
  AttachmentBimMarkerScreenshot,
  AttachmentProtocolEntry,
  BimMarker,
  BimVersion,
  IdType,
  LicenseType,
  MIME_TYPE_EXTENSION_WHITELIST,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPageMarking,
  PdfPlanPageMarkingBase,
  PdfPlanVersion,
  Project,
  Protocol,
  ProtocolEntry,
  ProtocolEntryStatus,
  ProtocolEntryType
} from 'submodules/baumaster-v2-common';
import {AlertController, LoadingController, ModalController, Platform, PopoverController} from '@ionic/angular';
import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {Observable, of, Subscription} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import _ from 'lodash';
import {LoggingService} from '../../../services/common/logging.service';
import {v4 as uuidv4} from 'uuid';
import {PhotoService} from '../../../services/photo/photo.service';
import {convertErrorToMessage, ErrorWithUserMessage, UniqueConstraintError} from '../../../shared/errors';
import {IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES, LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY} from '../../../shared/constants';
import {AttachmentEntryDataService} from '../../../services/data/attachment-entry-data.service';
import {AttachmentBlob} from '../../../model/attachments';
import {Router} from '@angular/router';
import {convertFileViaBinaryStringToFile, isImage, isImageBlob, isQuotaExceededError} from '../../../utils/attachment-utils';
import {SystemEventService} from '../../../services/event/system-event.service';
import {PdfPlanTreeViewComponent} from '../../common/pdf-plan-tree-view/pdf-plan-tree-view.component';
import {ModifiedMarkerData, PdfPlanMarkerComponent, PdfPlanMarkerComponentMode} from '../../common/pdf-plan-marker/pdf-plan-marker.component';
import {PdfPlanMarkerProtocolEntryDataService} from 'src/app/services/data/pdf-plan-marker-protocol-entry-data.service';
import {AudioRecordingComponent} from '../../common/audio-recording/audio-recording.component';
import {FileEntry} from '@awesome-cordova-plugins/file/ngx';
import {FormDirty, ProtocolEntryFormComponent} from '../protocol-entry-form/protocol-entry-form.component';
import {convertDateTimeToISOString, trimTimeFromDate} from '../../../utils/date-utils';
import {MarkerData} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/fabricPdfPlanMarker';
import {observableToPromise} from '../../../utils/async-utils';
import {AttachmentService} from '../../../services/attachment/attachment.service';
import {ProjectService} from '../../../services/project/project.service';
import {SketchComponent} from '../../common/sketch/sketch.component';
import {costStringToNumber} from 'src/app/utils/protocol-entry-utils';
import {getProtocolEntryPagePath} from 'src/app/utils/router-utils';
import {MediaService} from 'src/app/services/media/media.service';
import {ProtocolEntryCompanyService} from 'src/app/services/protocol/protocol-entry-company.service';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {PdfPlanPageMarkingDataService} from '../../../services/data/pdf-plan-page-marking-data.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {Nullish} from '../../../model/nullish';
import {UserService} from 'src/app/services/user/user.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {LastUsedPlanVersionService} from 'src/app/services/entry/last-used-plan-version.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {ProtocolOpenEntryDataService} from 'src/app/services/data/protocol-open-entry-data.service';
import {EntryCreationMode} from 'src/app/model/entry-creation-mode';
import {BimPlanTreeViewComponent} from '../../autodesk/bim-plan-tree-view/bim-plan-tree-view.component';
import {BimViewerModalService} from '../../../services/project-room/bim-viewer-modal.service';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {BimMarkerCreation, BimPlanTreeViewComponentData, BimViewerComponentData, BimViewerComponentReturnType, isBimMarkerCreation, toBimMarker} from '../../../model/bim-plan-with-deletable';
import {OverlayEventDetail} from '@ionic/core';
import {BimMarkerDataService} from '../../../services/data/bim-marker-data.service';
import {AttachmentBimMarkerScreenshotDataService} from '../../../services/data/attachment-bim-marker-screenshot-data.service';
import {PopoverService} from 'src/app/services/ui/popover.service';

const LOG_SOURCE = 'ProtocolEntryCreateComponent';

@Component({
  selector: 'app-protocol-entry-create',
  templateUrl: './protocol-entry-create.component.html',
  styleUrls: ['./protocol-entry-create.component.scss'],
  providers: [PageDidEnterLifecycleService],
})
export class ProtocolEntryCreateComponent implements OnInit, OnChanges, OnDestroy {
  private readonly convertForExternalSourcesNeeded = this.platform.is('android');

  @Input() protocolId: IdType;
  @Input() createdInProtocolId: IdType | undefined;
  @Input() expressView = true;
  @Input() parentEntryId: IdType | undefined;
  @Input() parentIsCarriedOver: boolean | undefined;
  @Input() navigateOnSuccess = true;
  @Input() onlyActionableEntryTypes = false;
  @Input() defaultEntryType: Nullish<ProtocolEntryType>;
  @Input() typeRequired = false;
  @Input() isTask?: boolean = false;
  @Input() startWithWorkflow?: EntryCreationMode;

  @ViewChild('protocolEntryFormComponent') protocolEntryFormComponent: ProtocolEntryFormComponent;

  public protocolEntryFormDirty: FormDirty = {dirty: false};
  public protocolEntryForm: UntypedFormGroup|undefined;
  public copyData = false;
  public isRequiredValueMet = false;

  public attachmentsObservable = new Observable<Array<AttachmentBlob>>();
  public protocol: Observable<Protocol | null>;
  public protocolEntriesSubscription: Subscription | undefined;
  public protocolSubEntriesSubscription: Subscription | undefined;
  public parentProtocolEntriesData: Array<ProtocolEntry>;
  public protocolSubEntriesData: Array<ProtocolEntry> | undefined;
  public selectedProject: Project | undefined;
  public selectedAdditionalFieldsId: IdType | null = null;
  public attachments = new Array<AttachmentBlob>();
  public readonly takingPhotosSupported: boolean;
  public readonly audioRecordingSupported: boolean;
  public readonly acceptedMimeTypesForUpload: string;
  public loading: boolean | undefined;

  private modal: HTMLIonModalElement;
  public pdfPlanMarkers: MarkerData[] = [];
  public modifiedMarkings: ModifiedMarkerData | undefined;
  public modifiedSketching: Nullish<PdfPlanPageMarkingBase | PdfPlanPageMarking>;
  public selectedPdfPlanVersion: PdfPlanVersion;

  private selectedBimVersion: BimVersion|undefined;
  public isBimMarkerApplied = false;
  private bimMarkers: Array<BimMarker|BimMarkerCreation>|undefined;

  public audioAttachments: IdType[] = [];
  public pictureAttachments: IdType[] = [];
  public blankPictureAttachments: IdType[] = [];
  public uploadedAttachments: IdType[] = [];
  public projectRoomAttachments: IdType[] = [];

  private taskAmount = 0;
  public isInStartWithWorkflow = false;
  private photoSeriesUsed = false;
  private photoSeriesAmount = 0;

  constructor(private modalController: ModalController,
              private protocolDataService: ProtocolDataService,
              private toastService: ToastService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private attachmentEntryDataService: AttachmentEntryDataService,
              private projectDataService: ProjectDataService,
              private projectService: ProjectService,
              private alertCtrl: AlertController,
              private popoverCtr: PopoverController,
              private translateService: TranslateService,
              private loggingService: LoggingService,
              private photoService: PhotoService,
              public platform: Platform,
              private systemEventService: SystemEventService,
              private pdfPlanMarkerProtocolEntryDataService: PdfPlanMarkerProtocolEntryDataService,
              private pdfPlanPageMarkingDataService: PdfPlanPageMarkingDataService,
              private router: Router,
              private loadingController: LoadingController,
              private attachmentService: AttachmentService,
              private mediaService: MediaService,
              private protocolEntryCompanyService: ProtocolEntryCompanyService,
              private pageDidEnterLifecycleService: PageDidEnterLifecycleService,
              private userService: UserService,
              private posthogService: PosthogService,
              private renderer: Renderer2,
              private lastUsedPlanVersionService: LastUsedPlanVersionService,
              private protocolService: ProtocolService,
              private protocolOpenEntryDataService: ProtocolOpenEntryDataService,
              private bimViewerModalService: BimViewerModalService,
              private featureEnabledService: FeatureEnabledService,
              private bimMarkerDataService: BimMarkerDataService,
              private attachmentBimMarkerScreenshotDataService: AttachmentBimMarkerScreenshotDataService,
              private popoverService: PopoverService) {
    this.loggingService.debug(LOG_SOURCE, 'constructor called.');
    this.acceptedMimeTypesForUpload = MIME_TYPE_EXTENSION_WHITELIST.join(',');
    this.takingPhotosSupported = this.photoService.isCameraSupported();
    this.audioRecordingSupported = this.mediaService.isRecordingSupported();
  }

  async ngOnInit() {
    if (this.startWithWorkflow === 'plan') {
      this.hideComponent();
    }
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
    this.protocol = await this.protocolDataService.getById(this.protocolId);
    this.protocolEntriesSubscription = this.protocolEntryDataService.getByProtocolId(this.protocolId).subscribe(protocolEntries => {
      this.parentProtocolEntriesData = protocolEntries.filter((entry) => !entry.parentId);
    });
    if (this.parentEntryId) {
      this.protocolSubEntriesSubscription = this.protocolEntryDataService.getSubEntriesByParentEntryId(this.parentEntryId).subscribe(subEntries =>
        this.protocolSubEntriesData = subEntries);
    }
    this.selectedProject = await this.projectDataService.getCurrentProject();

    await this.updateModalView();
    this.setCanDismiss();

    let markerCreated = false;
    if (this.startWithWorkflow === 'plan') {
      this.selectedPdfPlanVersion = await this.lastUsedPlanVersionService.getRememberedPlanVersion();
      markerCreated = await this.addPdfPlanMarker(this.selectedPdfPlanVersion ? true : false);
    }
    if (this.isInStartWithWorkflow) {
      if (markerCreated) {
        this.showComponent();
        setTimeout(async () => {
          await this.protocolEntryFormComponent.focusOnTitleInput();
        }, 300);
      } else {
        this.modal.dismiss();
      }
    }
  }

  hideComponent() {
    this.renderer.addClass(this.modal, 'd-none');
    this.isInStartWithWorkflow = true;
  }

  showComponent() {
    this.renderer.removeClass(this.modal, 'd-none');
    this.isInStartWithWorkflow = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loggingService.debug(LOG_SOURCE, 'ngOnChanges called.');
  }

  async ionViewWillEnter() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewWillEnter called.');
    if (!this.isInStartWithWorkflow && Capacitor.isPluginAvailable('Keyboard')) {
      this.loggingService.debug(LOG_SOURCE, 'Capacitor plugin Keyboard available.');
      await Keyboard.show();
    }
    this.clearAttachments();
  }

  async ionViewDidEnter() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidEnter called.');
    this.pageDidEnterLifecycleService.pageDidEnter();
  }

  ionViewWillLeave() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewWillLeave called.');
  }

  async ionViewDidLeave() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidLeave called.');
    if (Capacitor.isPluginAvailable('Keyboard')) {
      this.loggingService.debug(LOG_SOURCE, 'Capacitor plugin Keyboard available.');
      await Keyboard.hide();
    }
  }

  ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    if (this.protocolEntriesSubscription) {
      this.protocolEntriesSubscription.unsubscribe();
      this.protocolEntriesSubscription = null;
    }
    if (this.protocolSubEntriesSubscription) {
      this.protocolSubEntriesSubscription.unsubscribe();
      this.protocolSubEntriesSubscription = undefined;
    }
  }

  async checkCreationFormMet(formData: object) {
    this.isRequiredValueMet = false;
    if (!_.isEmpty(_.get(formData, 'title', '')) || this.attachments.length || this.pdfPlanMarkers.length || this.modifiedSketching) { // add the attachment later.
      this.isRequiredValueMet = true;
    }
  }

  private canDismiss = async (data?: any, role?: string) => {
    if (role === 'save') {
      return true;
    }

    if (!this.protocolEntryFormDirty.dirty) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss'
        }
      ]
    });

    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  async setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  async updateModalView() {
    if (this.expressView) {
      this.modal.cssClass = 'half-screen-modal';
    } else {
      this.modal.cssClass = 'full-screen-modal';
    }
  }

  async showFullScreen() {
    this.expressView = false;
    await this.updateModalView();
  }

  async takePicture() {
    await this.photoService.getAttachmentFromCamera(
      'protocol entry create',
      this.attachments?.length,
      LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY,
      async (attachmentBlob) => {
        const attachment = await this.addAttachmentBlob(attachmentBlob);
        this.pictureAttachments.push(attachment.id);
        return attachment;
      },
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
  }

  async takePictures() {
    const photoAmountBefore = this.pictureAttachments?.length ?? 0;
    await this.photoService.getAttachmentsFromCamera(
      'protocol entry create',
      this.attachments?.length,
      LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY,
      async (attachmentBlob) => {
        const attachment = await this.addAttachmentBlob(attachmentBlob);
        this.pictureAttachments.push(attachment.id);
        return attachment;
      },
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
    this.photoSeriesAmount += ((this.pictureAttachments?.length ?? 0) - photoAmountBefore);
    this.photoSeriesUsed = ((this.pictureAttachments?.length ?? 0) - photoAmountBefore) > 0;
  }

  uploadFileEvent = async (event: any) => {
    const files: FileList = event.target.files;
    const filesLength = files.length;
    if (filesLength > LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments?.length) {
      await this.showAlertForFileUpload();
      return;
    }
    let compressingLoading: HTMLIonLoadingElement|undefined;
    try {
      for (let i = 0; i < filesLength; i++) {
        let file = files.item(i);
        if (this.convertForExternalSourcesNeeded) {
          file = await convertFileViaBinaryStringToFile(file);
        }
        const filename = file.name;
        if (!(await this.photoService.ensureContentIsJpegOrPng(file, 'entry'))) {
          break;
        }
        if (file.size > IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES && isImageBlob(file)) {
          if (!compressingLoading) {
            compressingLoading = await this.loadingController.create({
              message: this.translateService.instant('imageCompressionInProgress')
            });
            await compressingLoading.present();
          }
          await this.createAttachment(await this.photoService.scaleImage(file), filename, filesLength, this.uploadedAttachments);
        } else {
          await this.createAttachment(file, filename, filesLength, this.uploadedAttachments);
        }
      }
    } finally {
      if (compressingLoading) {
        await compressingLoading.dismiss();
      }
    }

    event.target.value = '';
  };

  private async createAttachment(blob: Blob, filename: string, filesLength: number, uploadedOrProjectRoomAttachments: Array<IdType>, markings?: string|null) {
    const newAttachment: AttachmentBlob = await this.addAttachment(blob, filename, markings);
    uploadedOrProjectRoomAttachments.push(newAttachment.id);
    if (filesLength === 1 && isImage(newAttachment)) {
      await this.photoService.showToastMessageImageTakenEditMarkings(newAttachment, this.onTakenPhotoMarkingsChanged);
    }
  }

  private async showAlertForFileUpload() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('alert.reachedAttachmentMaxNumberLimit.header'),
      message: this.translateService.instant('alert.reachedAttachmentMaxNumberLimit.message_entry', {limit: LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY}),
      buttons: [this.translateService.instant('okay')]
    });
    await alert.present();
  }

  private async addAttachment(blob: Blob, fileName: string, markings?: string|null): Promise<AttachmentBlob> {
    const attachment = this.photoService.createAttachmentBlob(blob, fileName, markings);
    return this.addAttachmentBlob(attachment);
  }

  private async addAttachmentBlob(attachment: AttachmentBlob): Promise<AttachmentBlob> {
    this.attachments = this.attachments.concat(attachment); // do not use .push because the child component would not be "notified" about the change.
    this.protocolEntryFormDirty = {dirty: true};
    if (this.protocolEntryForm) {
      this.protocolEntryForm?.markAsDirty();
      await this.checkCreationFormMet(this.protocolEntryForm);
    }
    this.attachmentsObservable = of(this.attachments);
    return attachment;
  }

  private async copyAndAddAttachments(attachmentsToCopy: Array<Attachment>) {
    const loading = await this.loadingController.create({
      message: this.translateService.instant('attachment.addingInProgress')
    });
    try {
      await loading.present();
      const blobs = new Array<{ blob: Blob, fileName: string, markings?: string|null }>();
      for (const attachmentToCopy of attachmentsToCopy) {
        if (!attachmentToCopy.filePath) {
          throw new Error(`Unable to copy attachment with id ${attachmentToCopy.id} as we do not have the attachment information.`);
        }
        const blob = await this.photoService.downloadAttachment(attachmentToCopy, 'image');
        if (!blob) {
          throw new Error(`Unable to copy attachment with id ${attachmentToCopy.id} as we do not have the blob.`);
        }
        blobs.push({blob, fileName: attachmentToCopy.fileName, markings: attachmentToCopy.markings});
      }

      for (const blobFilename of blobs) {
        await this.createAttachment(blobFilename.blob, blobFilename.fileName, blobs.length, this.projectRoomAttachments, blobFilename.markings);
      }
    } finally {
      await loading.dismiss();
    }
  }

  async saveDismiss() {
    if (!this.protocolEntryForm?.valid) {
      await this.showValidationFormError(this.translateService.instant('form_error_validation_message'));
      return;
    }
    const id = uuidv4();
    const actionMessage = () => `Create protocol entry (id=${id}, mode=${
      this.copyData ? 'series' : 'single'
    })`;
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, actionMessage);
    try {
      const rawData = this.protocolEntryForm.getRawValue();
      logInstance.logCheckpoint(() => `(type=${
        !rawData.type ? '_empty_' : `${rawData.type.name} (${rawData.type.id})`
      }, actionable=${
        !!(rawData.type?.statusFieldActive)
      }, attachmentCount=${this.attachments.length}, protocolId=${this.protocolId}, markerPlanIds=${
        this.pdfPlanMarkers?.map((v) => v.pdfPlanPageId)
      }), markingPlanId=${
        this.modifiedSketching?.pdfPlanPageId
      }))`);
      this.loading = true;
      const protocolEntry = await this.createNewProtocolEntry(rawData, id);
      await this.createAttachments(protocolEntry);
      await this.createPdfPlanMarkers(protocolEntry);
      await this.createPdfPlanPageMarking(protocolEntry);
      await this.createBimMarkers(protocolEntry);
      await this.toastService.savingSuccess();
      this.taskAmount++;
      if (this.hasTaskAnyValueOrAttachment() && this.expressView) {
        this.capturePosthogEvent('express');
      } else if (this.hasTaskAnyValueOrAttachment() && !this.expressView) {
        this.capturePosthogEvent('fullModal');
      }

      if (this.copyData) {
        this.protocolEntryForm.get('title').reset();
        this.protocolEntryForm.get('text').reset();
        this.protocolEntryFormComponent?.editor?.resetContent('');
        await this.protocolEntryFormComponent.focusOnTitleInput();
        this.protocolEntryFormDirty = {dirty: false};
        this.isRequiredValueMet = false;
        this.clearAttachments();
        if (this.startWithWorkflow === 'plan') {
          const markerCreated = await this.addPdfPlanMarker(this.selectedPdfPlanVersion ? true : false);
          if (markerCreated) {
            setTimeout(async () => {
              await this.protocolEntryFormComponent.focusOnTitleInput();
            }, 300);
          } else {
            this.modal.dismiss();
          }
        }
      } else {
        if (this.navigateOnSuccess) {
          await this.router.navigate(getProtocolEntryPagePath(this.createdInProtocolId ?? this.protocolId, protocolEntry.id), {
            state: {newProtocolEntry: true, protocolListShowActive: true}
          });
        }
        this.capturePosthogEvent('taskAmount');

        if (this.startWithWorkflow) {
          this.posthogService.captureEvent(`[${this.isTask ? 'Tasks' : 'Entry'}] ${this.startWithWorkflow ?? 'text'} based workflow`, {});
          if (this.startWithWorkflow === 'plan') {
            if (this.isTask) {
              if (this.selectedPdfPlanVersion) {
                this.lastUsedPlanVersionService.setRememberedPlanVersion(this.selectedPdfPlanVersion.pdfPlanId, 'tasks');
              }
            } else {
              if (this.selectedPdfPlanVersion) {
                this.lastUsedPlanVersionService.setRememberedPlanVersion(this.selectedPdfPlanVersion.pdfPlanId, 'protocols');
              }
            }
          }
        }
        await this.modal.dismiss(protocolEntry, 'save');
      }

      logInstance.success();
    } catch (error) {
      logInstance.failure(error);
      let message: string | undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage;
      } else {
        message = error.message;
      }
      this.loggingService.error(LOG_SOURCE, `Error inserting ProtocolEntry. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent('ProtocolEntryCreateComponent - saveDismiss', error?.userMessage + '-' + error?.message);
      if (isQuotaExceededError(message)) {
        await this.attachmentService.showToastQuotaExceeded();
      } else {
        await this.toastService.errorWithMessageAndHeader('error_saving_message', message);
      }
    } finally {
      this.loading = false;
    }
  }

  private clearAttachments() {
    this.attachments = [];
    this.pdfPlanMarkers = [];
    this.modifiedSketching = undefined;
    this.modifiedMarkings = undefined;
    this.bimMarkers = [];
    this.selectedBimVersion = undefined;
    this.isBimMarkerApplied = false;
    this.audioAttachments = [];
    this.uploadedAttachments = [];
    this.projectRoomAttachments = [];
    this.pictureAttachments = [];
    this.blankPictureAttachments = [];
    this.attachmentsObservable = of(this.attachments);
  }

  async showValidationFormError(errorMessage) {
    await this.toastService.errorWithMessageAndHeader('form_error_validation_header', errorMessage);
  }

  async createNewProtocolEntry(rawData, id: IdType): Promise<ProtocolEntry> {
    if (!this.protocolId) {
      throw Error('Unable to create protocol with null or undefined protocolId');
    }
    const user = await observableToPromise(this.userService.currentUser$);
    const protocolEntry: ProtocolEntry = {
      allCompanies: rawData.company?.id === null,
      changedAt: convertDateTimeToISOString(new Date()),
      createdAt: convertDateTimeToISOString(new Date()),
      createdAtDb: null,
      id,
      number: this.getNextNumber(),
      status: ProtocolEntryStatus.OPEN,
      isContinuousInfo: true,
      nameableDropdownId: this.selectedAdditionalFieldsId,
      protocolId: this.protocolId,
      createdInProtocolId: this.createdInProtocolId,
      parentId: !this.parentEntryId ? null : this.parentEntryId,
      internalAssignmentId: rawData.internalAssignmentId?.id,
      priority: rawData.priority !== '' ? rawData.priority : null,
      title: rawData.title,
      text: rawData.text,
      companyId: _.isEmpty(rawData.company) ? null : rawData.company?.id,
      cost: !rawData.cost ? null : costStringToNumber(rawData.cost),
      craftId: rawData.craft?.id,
      locationId: rawData.location?.id,
      startDate: convertDateTimeToISOString(trimTimeFromDate(rawData.startDate)),
      todoUntil: convertDateTimeToISOString(trimTimeFromDate(rawData.todoUntil)),
      reminderAt: convertDateTimeToISOString(rawData.reminderAt !== '' ? rawData.reminderAt : null),
      typeId: rawData.type?.id,
      createdById: user.id
    };
    await this.protocolEntryDataService.insert(protocolEntry, this.selectedProject.id);

    if (this.createdInProtocolId && this.createdInProtocolId !== this.protocolId) {
      const openEntries = await this.protocolService.createOpenEntriesForContinuousProtocolsRange(
        await this.protocolService.getPreviousContinuousProtocolsBetween(this.protocolId, this.createdInProtocolId, {includeFrom: false, includeTo: false}),
        protocolEntry
      );
      if (openEntries?.length) {
        await this.protocolOpenEntryDataService.insert(openEntries, this.selectedProject.id);
      }
    }

    await this.protocolEntryCompanyService.saveObserverCompanies(protocolEntry.id, rawData.observerCompanies);

    return protocolEntry;
  }

  private async checkMaxNumberOfAttachments(files: FileList) {
    if (files.length > LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments?.length) {
      const alert = await this.alertCtrl.create({
        header: 'Max number of files reached',
        message: 'max 5, sie können noch hochladen',
        buttons: ['OK']
      });
      await alert.present();
      return;
    }
  }

  getNextNumber() {
    const entries = this.parentEntryId ? this.protocolSubEntriesData : this.parentProtocolEntriesData;
    if (!entries?.length) {
      return 1;
    }
    const numbers = _.sortBy(entries.map((protocolEntry) => protocolEntry.number));
    return numbers[numbers.length - 1] + 1;
  }

  dismissModal() {
    this.capturePosthogEvent('taskAmount');
    return this.modal.dismiss();
  }

  toggleCopyData() {
    this.copyData = !this.copyData;
  }

  private async createAttachments(protocolEntry: ProtocolEntry): Promise<void> {
    if (!this.attachments.length) {
      return;
    }
    const attachments = new Array<AttachmentProtocolEntry>();
    for (const attachmentBlob of this.attachments) {
      const attachment: AttachmentProtocolEntry = {
        id: attachmentBlob.id,
        hash: attachmentBlob.hash,
        projectId: this.selectedProject.id,
        protocolEntryId: protocolEntry.id,
        mimeType: attachmentBlob.mimeType,
        markings: attachmentBlob.markings,
        fileName: attachmentBlob.fileName,
        fileExt: attachmentBlob.fileExt,
        changedAt: attachmentBlob.changedAt,
        createdAt: attachmentBlob.createdAt,
        createdById: attachmentBlob.createdById
      };
      attachments.push(attachment);
    }
    const blobs = this.attachments.map((attachment) => attachment.blob);
    await this.attachmentEntryDataService.insert(attachments, this.selectedProject.id, {}, blobs);
  }

  onAdditionalFieldsChange(additionalFieldId) {
    this.selectedAdditionalFieldsId = additionalFieldId;
  }

  private onTakenPhotoMarkingsChanged = async (takenImageAttachment: AttachmentBlob, markings: Nullish<string>) => {
    try {
      const index = this.attachments.findIndex((attachment) => attachment.id === takenImageAttachment.id);
      if (index >= 0) {
        takenImageAttachment.markings = markings;
        this.attachments[index] = takenImageAttachment;
        this.attachmentsObservable = of(this.attachments);
      }
    } catch (error) {
      await this.systemEventService.logErrorEvent('ProtocolEntryCreateComponent - onTakenPhotoMarkingsChanged', error?.userMessage + '-' + error?.message);
    }

  };

  public onMarkingsChanged(attachment: AttachmentBlob, markings: string) {
    attachment.markings = markings;
  }

  public onAttachmentDeleted(deletedAttachement: AttachmentBlob) {
    _.remove(this.pictureAttachments, (id: IdType) => id === deletedAttachement.id);
    _.remove(this.blankPictureAttachments, (id: IdType) => id === deletedAttachement.id);
    _.remove(this.uploadedAttachments, (id: IdType) => id === deletedAttachement.id);
    _.remove(this.projectRoomAttachments, (id: IdType) => id === deletedAttachement.id);
    _.remove(this.audioAttachments, (id: IdType) => id === deletedAttachement.id);
    _.remove(this.attachments, (attachment: AttachmentBlob) => attachment.id === deletedAttachement.id);
  }

  async addPdfPlanMarker(planWasPreSelected = false) {
    if ((_.isEmpty(this.pdfPlanMarkers) && !this.modifiedSketching && !(this.startWithWorkflow === 'plan')) || _.isEmpty(this.selectedPdfPlanVersion)) {
      return await this.openPdfPlanHolderTreeFile();
    } else {
      return await this.openPdfPlanMarker(planWasPreSelected);
    }
  }

  async addBimMarker() {
    if (this.isBimMarkerApplied && this.selectedBimVersion) {
      const modal = await this.bimViewerModalService.openModal({
        returnType: BimViewerComponentReturnType.RETURN_CHANGES,
        selectedBimVersion: this.selectedBimVersion,
        selectedProtocolEntry: undefined,
        acrossProjects: false,
        markers: this.bimMarkers
      });
      const modalResult = await modal.onDidDismiss<BimViewerComponentData>();
      await this.handleBimViewerComponentResult(modalResult);
      return;
    } else {
      if (!(await this.featureEnabledService.isFeatureEnabled(true, false, [LicenseType.PROFESSIONAL]))) {
        this.toastService.infoWithMessageAndHeader('toast.licenseDisabled.header', 'toast.licenseDisabled.message');
        return;
      }
      const modal = await this.modalController.create({
        component: BimPlanTreeViewComponent,
        cssClass: 'omg-modal omg-boundary omg-in-modal-list',
        componentProps: {
          returnType: BimViewerComponentReturnType.RETURN_CHANGES,
          selectedProtocolEntry: undefined,
          acrossProjects: false
        }
      });
      await modal.present();
      const {role, data} = await modal.onDidDismiss<BimPlanTreeViewComponentData>();
      if (role && role !== 'cancel' && role !== 'backdrop') {
        await this.handleBimViewerComponentResult(await data.bimViewerDismiss);
      }
    }
  }

  private async handleBimViewerComponentResult(bimViewerResult: OverlayEventDetail<BimViewerComponentData>) {
    const {role, data} = bimViewerResult;
    if (!role || role === 'cancel' || role === 'backdrop') {
      return;
    }
    if (data?.bimMarkers?.length) {
      this.isBimMarkerApplied = true;
      this.bimMarkers = data.bimMarkers;
      this.selectedBimVersion = data.selectedBimVersion;
    } else {
      this.isBimMarkerApplied = false;
      this.bimMarkers = undefined;
      this.selectedBimVersion = undefined;
    }
  }

  async openPdfPlanHolderTreeFile() {
    const modal = await this.modalController.create({
      component: PdfPlanTreeViewComponent,
      cssClass: 'omg-modal omg-boundary omg-in-modal-list',
      componentProps: {}
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (typeof data === 'undefined' && this.isInStartWithWorkflow) {
      await this.modal.dismiss();
    }
    this.selectedPdfPlanVersion = data.pdfPlanVersion;
    const markerCreated = await this.openPdfPlanMarker();
    return markerCreated;
  }

  async openPdfPlanMarker(planWasPreSelected = false): Promise<boolean> {
    const modal = await this.modalController.create({
      component: PdfPlanMarkerComponent,
      cssClass: 'full-screen-modal-xxl',
      componentProps: {
        openMode: PdfPlanMarkerComponentMode.RETURN_CHANGES,
        pdfPlanVersion: this.selectedPdfPlanVersion,
        modifiedMarkings: this.modifiedMarkings,
        modifiedSketching: this.modifiedSketching,
        planWasPreSelected,
        planBasedWorkflow: this.startWithWorkflow === 'plan'
      }
    });
    await modal.present();
    if (planWasPreSelected) {
      this.posthogService.captureEvent('[PlanMarker] Plan was auto selected', {});
    }
    const { data } = await modal.onWillDismiss();
    if (typeof data !== 'undefined' && (!_.isEmpty(data.markers) || !_.isEmpty(data.modifiedMarkings?.new) || !_.isEmpty(data.modifiedMarkings?.updated) ||
      !_.isEmpty(data.modifiedMarkings?.deleted) || !_.isEmpty(data.modifiedSketching))) {
      this.selectedPdfPlanVersion = data.selectedPlanVersion;
      this.pdfPlanMarkers = data.markers;
      if ('modifiedMarkings' in data) {
        this.modifiedMarkings = data.modifiedMarkings;
      }
      if ('modifiedSketching' in data) {
        this.modifiedSketching = data.modifiedSketching;
      }
      this.protocolEntryFormDirty = {dirty: true};
      if (this.protocolEntryForm) {
        this.protocolEntryForm.markAsDirty();
        await this.checkCreationFormMet(this.protocolEntryForm);
      }
      return true;
    } else {
      if (this.isInStartWithWorkflow) {
        return false;
      }
    }
  }

  async createPdfPlanMarkers(protocolEntry: ProtocolEntry) {
    for (const marker of this.pdfPlanMarkers) {
      await this.insertNewMarker(marker, protocolEntry);
    }
  }

  async createPdfPlanPageMarking(protocolEntry: ProtocolEntry) {
    if (!this.modifiedSketching) {
      return;
    }
    const pdfPlanPageMarking: PdfPlanPageMarking = {
      id: uuidv4(),
      markings: this.modifiedSketching.markings,
      pdfPlanPageId: this.modifiedSketching.pdfPlanPageId,
      protocolEntryId: protocolEntry.id,
      name: this.modifiedSketching.name,
      createdAt: new Date().toISOString(),
      changedAt: new Date().toISOString()
    };
    await this.pdfPlanPageMarkingDataService.insert(pdfPlanPageMarking, this.selectedProject.id);
  }

  async createBimMarkers(protocolEntry: ProtocolEntry) {
    if (!this.isBimMarkerApplied) {
      return;
    }
    if (!this.bimMarkers?.length) {
      this.loggingService.warn(LOG_SOURCE, 'isBimMarkerApplied set to true but no bimMarkers applied.');
      return;
    }
    const bimMarkerCreations: Array<BimMarkerCreation> = this.bimMarkers.filter((bimMarker) => isBimMarkerCreation(bimMarker)) as Array<BimMarkerCreation>;
    if (!bimMarkerCreations.length) {
      this.loggingService.warn(LOG_SOURCE, `${this.bimMarkers?.length} bimMarkers set but none of them are bimMarkerCreation objects`);
      return;
    }
    const projectId = this.selectedProject.id;
    for (const bimMarkerCreation of bimMarkerCreations) {
      const bimMarker = toBimMarker(bimMarkerCreation);
      bimMarker.protocolEntryId = protocolEntry.id;
      if (!bimMarker.id) {
        bimMarker.id = uuidv4();
      }
      await this.bimMarkerDataService.insert({
        ...bimMarker,
      }, projectId);
      const attachment: AttachmentBimMarkerScreenshot = {
        ...bimMarkerCreation.attachment,
        bimMarkerId: bimMarker.id,
        projectId
      };
      await this.attachmentBimMarkerScreenshotDataService.insert(attachment, projectId, undefined, [bimMarkerCreation.blob]);
    }
  }

  async insertNewMarker(marker: MarkerData, protocolEntry: ProtocolEntry) {
    try {
        const newMarker: PdfPlanMarkerProtocolEntry = {
          protocolEntryId: protocolEntry.id,
          positionX: Math.floor(marker.x),
          positionY: Math.floor(marker.y),
          pdfPlanPageId: marker.pdfPlanPageId,
          projectId: this.selectedProject.id,
          changedAt: new Date(),

          activityId: null,
          equipmentId: null,
          materialId: null,
          id: uuidv4()
        };
        await this.pdfPlanMarkerProtocolEntryDataService.insert(newMarker, this.selectedProject.id);
    } catch (error) {
      if (error instanceof UniqueConstraintError) {
        await this.toastService.errorWithMessageAndHeader('error_saving_message', this.translateService.instant('protocolCreation.errors.duplicate_plan_markers'));
      } else {
        await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - insertNewMarker', error?.userMessage + '-' + error?.message);
        this.loggingService.error(LOG_SOURCE, 'insertNewMarker' + error?.message);
        await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
      }
    }
  }

  startAudioRecording = async () => {
    const modal = await this.modalController.create({
      component: AudioRecordingComponent,
      keyboardClose: false,
      backdropDismiss: true
    });
    modal.onDidDismiss().then(async (result) => {
      if (result.data) {
        const resultAfterRecording: { blobObject: Blob, fileEntry: FileEntry } = result.data;
        if (!_.isEmpty(resultAfterRecording)) {
          const audioAttachment = await this.addAttachment(resultAfterRecording.blobObject, resultAfterRecording.fileEntry.name);
          this.audioAttachments.push(audioAttachment?.id);
        }
      }
    });
    return await modal.present();
  };

  projectRoomAttachmentsSelector = async () => {
    if (LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments.length <= 0) {
      await this.showAlertForFileUpload();
      return;
    }

    const project = await observableToPromise(this.projectService.getById(this.selectedProject?.id));
    if (await this.attachmentService.showAlertIfOnlineOnlyProjectAndNoNetwork(project)) {
      return;
    }

    const selectedAttachments = await this.attachmentService.showProjectRoomAttachmentsSelector(LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments.length);
    if (selectedAttachments?.length) {
      await this.copyAndAddAttachments(selectedAttachments);
    }
  };

  openSketchingTool = async () => {
    if (LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments?.length === 0) {
      await this.showAlertForFileUpload();
      return;
    }
    const blob = this.photoService.createEmptyImage();
    const modal = await this.modalController.create({
      component: SketchComponent,
      backdropDismiss: false,
      cssClass: 'full-screen-sketch',
      componentProps: {
        attachmentUrl: URL.createObjectURL(blob),
        onMarkingsChanged: async (markings: Nullish<string>) => {
          const newAttachment = await this.addAttachment(blob, uuidv4() + '.jpg');
          this.blankPictureAttachments.push(newAttachment.id);
          if (newAttachment) {
            await this.onTakenPhotoMarkingsChanged(newAttachment, markings);
          }
        }
      }
    });
    await modal.present();
  };

  async openPopoverMenu(event) {
    const result = await this.popoverService.openActions(event, [
      {
        label: 'PlanMarker',
        role: 'planMarker',
        icon: ['fal', 'map-marker-alt']
      },
      {
        label: 'marker',
        role: 'bimMarker',
        icon: ['bau', 'bim']
      },
      {
        label: 'file',
        role: 'file',
        icon: ['fal', 'paperclip'],
        uploadFileHandler: this.uploadFileEvent
      },
      {
        label: 'attachmentFileProjectRoomPopover.popover',
        role: 'attach',
        icon: ['fal', 'cube'],
      },
      {
        label: 'drawing',
        role: 'drawing',
        icon: ['fal', 'signature'],
      },
      {
        label: 'Audio',
        role: 'audio',
        icon: ['fal', 'microphone'],
        disabled: !this.audioRecordingSupported
      }
    ]);

    if (result !== 'backdrop') {
      switch (result) {
        case 'drawing':
          this.openSketchingTool();
          break;
        case 'audio':
          this.startAudioRecording();
          break;
        case 'attach':
          this.projectRoomAttachmentsSelector();
          break;
        case 'planMarker':
          this.addPdfPlanMarker();
          break;
        case 'bimMarker':
          this.addBimMarker();
          break;
        default:
          throw new Error('Unsupported action: ' + result);
      }
    }
  }

  capturePosthogEvent(context: 'taskAmount' | 'express' | 'fullModal'): void {
    if (this.taskAmount > 0 && context === 'taskAmount') {
      if (this.parentEntryId) {
        this.posthogService.captureEvent(this.isTask ? 'Subtask created' : '[Protocols][CreateEntry] Subentry created', {
          seriesMode: this.taskAmount > 1 || this.copyData,
          amount: this.taskAmount
        });
      } else {
        this.posthogService.captureEvent(this.isTask ? 'Task created' : '[Protocols][CreateEntry] Entry created', {
          seriesMode: this.taskAmount > 1 || this.copyData,
          amount: this.taskAmount
        });
      }
    }
    if (context === 'express') {
      this.posthogService.captureEvent(this.isTask ? '[Tasks] Express view creation' : '[Protocols][CreateEntry] Express view', {
        title: !_.isNull(this.protocolEntryForm.value.title),
        type: !_.isNull(this.protocolEntryForm.value.type),
        company: !_.isNull(this.protocolEntryForm.value.company),
        location: !_.isNull(this.protocolEntryForm.value.location),
        todoUntil: !_.isNull(this.protocolEntryForm.value.todoUntil),
        planmarker: !_.isEmpty(this.pdfPlanMarkers),
        sketchingsAdded: !_.isNull(this.modifiedSketching),
        planMarkerAmount: this.pdfPlanMarkers?.length ?? 0,
        bimMarker: !_.isEmpty(this.bimMarkers),
        photoSingleMode: !_.isEmpty(this.pictureAttachments),
        photoSingleModeAmount: ((this.pictureAttachments?.length ?? 0) - this.photoSeriesAmount),
        attachments: !_.isEmpty(this.uploadedAttachments),
        attachmentsAmount: this.uploadedAttachments?.length ?? 0,
        attachmentsProjectRoom: !_.isEmpty(this.projectRoomAttachments),
        attachmentsProjectRoomAmount: this.projectRoomAttachments?.length ?? 0,
        drawing: !_.isEmpty(this.blankPictureAttachments),
        drawingAmount: this.blankPictureAttachments?.length ?? 0,
        audio: !_.isEmpty(this.audioAttachments),
        audioAmount: this.audioAttachments?.length ?? 0,
        photoSeriesUsed: this.photoSeriesUsed,
        photoSeriesAmount: this.photoSeriesAmount,
        createdViaSeriesMode: this.copyData || this.taskAmount > 1
      });
    }
    if (context === 'fullModal') {
      this.posthogService.captureEvent(this.isTask ? '[Tasks] Full Modal view creation' : '[Protocols][CreateEntry] FullModal view', {
        title: !_.isNull(this.protocolEntryForm.value.title),
        type: !_.isNull(this.protocolEntryForm.value.type),
        company: !_.isNull(this.protocolEntryForm.value.company),
        location: !_.isNull(this.protocolEntryForm.value.location),
        additionalField: !_.isNull(this.selectedAdditionalFieldsId),
        observer: !_.isEmpty(this.protocolEntryForm.value.observerCompanies),
        observerAmount: this.protocolEntryForm.value?.observerCompanies?.length ?? 0,
        todoUntil: !_.isNull(this.protocolEntryForm.value.todoUntil),
        priority: !_.isNull(this.protocolEntryForm.value.priority),
        cost: !_.isNull(this.protocolEntryForm.value.cost),
        craft: !_.isNull(this.protocolEntryForm.value.craft),
        responsible: !_.isNull(this.protocolEntryForm.value.internalAssignmentId),
        text: !_.isNull(this.protocolEntryForm.value.text),
        startDate: !_.isNull(this.protocolEntryForm.value.startDate),
        planmarker: !_.isEmpty(this.pdfPlanMarkers),
        sketchingsAdded: !_.isNull(this.modifiedSketching),
        planMarkerAmount: this.pdfPlanMarkers?.length ?? 0,
        bimMarker: !_.isEmpty(this.bimMarkers),
        photoSingleMode: !_.isEmpty(this.pictureAttachments),
        photoSingleModeAmount: ((this.pictureAttachments?.length ?? 0) - this.photoSeriesAmount),
        attachments: !_.isEmpty(this.uploadedAttachments),
        attachmentsAmount: this.uploadedAttachments?.length ?? 0,
        attachmentsProjectRoom: !_.isEmpty(this.projectRoomAttachments),
        attachmentsProjectRoomAmount: this.projectRoomAttachments?.length ?? 0,
        drawing: !_.isEmpty(this.blankPictureAttachments),
        drawingAmount: this.blankPictureAttachments?.length ?? 0,
        audio: !_.isEmpty(this.audioAttachments),
        audioAmount: this.audioAttachments?.length ?? 0,
        photoSeriesUsed: this.photoSeriesUsed,
        photoSeriesAmount: this.photoSeriesAmount,
        createdViaSeriesMode: this.copyData || this.taskAmount > 1
      });
    }
  }

  hasTaskAnyValueOrAttachment() {
    const formValues = this.protocolEntryForm.value;
    return !_.isNull(formValues.company) || !_.isNull(formValues.cost) || !_.isNull(formValues.craft) || !_.isNull(formValues.createdAt) || !_.isNull(formValues.internalAssignmentId) ||
      !_.isNull(formValues.location) || !_.isNull(formValues.number) || !_.isNull(formValues.observerCompanies) || !_.isNull(formValues.priority) || !_.isNull(formValues.startDate) ||
      !_.isNull(formValues.text) || !_.isNull(formValues.todoUntil) || !_.isEmpty(this.uploadedAttachments) || !_.isEmpty(this.audioAttachments) || !_.isEmpty(this.blankPictureAttachments) ||
      !_.isEmpty(this.pictureAttachments) || !_.isEmpty(this.projectRoomAttachments) || !_.isEmpty(this.pdfPlanMarkers);
  }
}
