import {Component, OnInit, ViewChild} from '@angular/core';
import {map} from 'rxjs/operators';
import {ProtocolListComponent} from 'src/app/components/protocol/protocol-list/protocol-list.component';
import {ProtocolFilterService} from 'src/app/services/protocol/protocol-filter.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';

@Component({
  selector: 'app-protocol-list-page',
  templateUrl: './protocol-list.page.html',
  styleUrls: ['./protocol-list.page.scss'],
})
export class ProtocolListPage implements OnInit {

  @ViewChild(ProtocolListComponent, {
    static: false
  }) protocolList: ProtocolListComponent;

  isVisible$ = this.deviceService.isAboveBreakpoint(Breakpoints.xxl).pipe(map((isHidden) => !isHidden));

  protocolTypeFilterObservable = this.protocolFilterService.selectedProtocolType$;

  constructor(
    private deviceService: DeviceService,
    private protocolFilterService: ProtocolFilterService,
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
  }
}
