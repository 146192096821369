import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import _ from 'lodash';
import {combineLatest, from, Observable, of, ReplaySubject} from 'rxjs';
import {filter, map, shareReplay, startWith, switchMap, take} from 'rxjs/operators';
import {LicenseType} from 'submodules/baumaster-v2-common';
import {ToastDurationInMs} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {FeatureEnabledService} from '../feature/feature-enabled.service';
import {SyncStatusService} from '../sync/sync-status.service';
import {SyncStrategy} from '../sync/sync-utils';
import {SyncService} from '../sync/sync.service';
import {DeviceService} from '../ui/device.service';
import {UserService} from '../user/user.service';
import {ToastService} from './toast.service';
import {environment} from '../../../environments/environment';
import {PopoverService} from '../ui/popover.service';
import {IonMenuService} from '../ui/ion-menu.service';
import {UserflowService} from '../userflow/userflow.service';
import {TranslateService} from '@ngx-translate/core';
import {NetworkStatusService} from './network-status.service';
import {AlertController} from '@ionic/angular';

export interface MenuGroup {
  title: string;
  icon?: string[];
  menus: Menu[];
  expandable: boolean;
  expandedDefaultValue?: boolean;
  className?: string;
}

export interface Menu {
  title: string;
  titleInProgress?: string;
  url?: string;
  externalLinkIcon?: boolean;
  actionFn?: () => void;
  actionInProgress$?: Observable<boolean>;
  icon: string[];
  showTab: boolean;
  titleTab?: string;
  disabled$?: Observable<boolean>;
  isNew?: boolean;
  menus?: MenuGroup;
  keepMenuOpenAfterClick?: boolean;
}

const GROUP_EXPANDED_DEFAULT_VALUE = true;
const NEW_FLAG_FOR_TASKS_UNTIL = new Date('2023-09-09');

const segmentMapper = (segment: string): string => {
  if (['company', 'contact-detail'].includes(segment)) {
    return 'contacts';
  }

  return segment;
};

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menuSubject = new ReplaySubject<Menu>(1);
  currentMenu$ = this.menuSubject.asObservable();

  mainMenus: Array<Menu|MenuGroup> = [
    {
      title: 'MENU.dashboard',
      url: '/dashboard',
      icon: ['fal', 'tachometer-fast'],
      showTab: true
    },
    {
      title: 'MENU.capture',
      expandable: true,
      menus: [
        {
          title: 'MENU.tasks',
          url: '/tasks',
          icon: ['fal', 'clipboard-check'],
          showTab: true,
          isNew: new Date().getTime() <= NEW_FLAG_FOR_TASKS_UNTIL.getTime() ? true : false
        },
        {
          title: 'MENU.protocols',
          titleTab: 'MENU.protocolOverview',
          url: '/protocols',
          icon: ['fal', 'clipboard'],
          showTab: true
        },
        {
          title: 'MENU.reports',
          url: '/reports',
          icon: ['fal', 'file-alt'],
          showTab: false,
          // Below code is valid, but incosistent with the rest of the app
          // Keeping it, because it will be needed for next release
          disabled$: combineLatest([
            this.featureEnabledService.isFeatureEnabled$(false, null, [LicenseType.VIEWER, LicenseType.LIGHT]).pipe(map((enabled) => !enabled)),
            this.featureEnabledService.isFeatureEnabled$(false, true, null).pipe(map((enabled) => !enabled)),
          ]).pipe(switchMap(([isDisabledLicense, isDisabledConnected]) => {
            if (isDisabledConnected) {
              return of(true);
            }

            if (isDisabledLicense) {
              return this.userService.currentUser$.pipe(map((user) => !user || (!user.assignedReportRights && [LicenseType.VIEWER, LicenseType.LIGHT].includes(user.role))));
            }

            if(environment.hideDailyConstructionRecord) {
              return of(true);
            }

            return of(false);
          })),
        },
        {
          title: 'MENU.constructionReports',
          url: '/constructionReports',
          icon: ['fal', 'building'],
          showTab: false,
          // Below code is valid, but incosistent with the rest of the app
          // Keeping it, because it will be needed for next release
          disabled$: combineLatest([
            this.featureEnabledService.isFeatureEnabled$(false, null, [LicenseType.VIEWER, LicenseType.LIGHT]).pipe(map((enabled) => !enabled)),
            this.featureEnabledService.isFeatureEnabled$(false, true, null).pipe(map((enabled) => !enabled)),
          ]).pipe(switchMap(([isDisabledLicense, isDisabledConnected]) => {
            if (isDisabledConnected) {
              return of(true);
            }

            if (isDisabledLicense) {
              return this.userService.currentUser$.pipe(map((user) => !user || (!user.assignedReportRights && [LicenseType.VIEWER, LicenseType.LIGHT].includes(user.role))));
            }

            return of(false);
          })),
        },
      ]
    },
    {
      title: 'MENU.maintain',
      expandable: true,
      menus: [
        {
          title: 'MENU.projects',
          url: '/projects',
          icon: ['fal', 'list'],
          showTab: false
        },
        {
          title: 'MENU.projectSettings',
          url: '/project-settings',
          icon: ['fal', 'sliders-v'],
          showTab: false,
          disabled$: this.featureEnabledService.isFeatureEnabled$(false, null, [LicenseType.VIEWER]).pipe(map((enabled) => !enabled)),
        },
        {
          title: 'MENU.contacts',
          url: '/contacts',
          icon: ['fal', 'user'],
          showTab: true,
        },
        {
          title: 'MENU.projectRoom',
          url: '/project-room',
          icon: ['fal', 'cube'],
          showTab: false
        },
      ]
    },
    {
      title: 'MENU.manage',
      expandable: true,
      menus: [
        {
          title: 'MENU.search',
          url: '/search',
          icon: ['fal', 'search'],
          showTab: false
        },
        {
          title: 'MENU.projectCosts',
          url: '/project-costs',
          icon: ['fal', 'file-invoice-dollar'],
          showTab: false,
          disabled$: this.featureEnabledService.isFeatureEnabled$(true, null, [LicenseType.PROFESSIONAL]).pipe(switchMap(async (enabled) => {
            return !enabled || await this.deviceService.isNativeMobile(); // Disabled when not proffesional or is a native app on mobile
          })),
        },
        {
          title: 'MENU.constructionSchedule',
          url: '/construction-schedule',
          icon: ['fal6', 'chart-gantt'],
          showTab: false,
          disabled$: from(this.deviceService.isNativeMobile()),
        }
      ]
    },
    {
      title: 'MENU.service',
      expandable: true,
      className: 'menu-service-group',
      menus: [
        {
          title: 'MENU.synchronizeData',
          titleInProgress: 'MENU.synchronizationInProgress',
          actionInProgress$: this.syncStatusService.dataSyncInProgressObservable.pipe(map((syncStatus) => !!syncStatus?.inProgress)),
          actionFn: () => this.syncDataActive(),
          icon: ['fal', 'sync'],
          showTab: false
        },
        {
          title: 'MENU.syncAttachmentsFromErrorQueue',
          titleInProgress: 'MENU.synchronizationInProgress',
          actionInProgress$: this.syncStatusService.attachmentSyncInProgressObservable.pipe(map((syncStatus) => !!syncStatus?.inProgress)),
          actionFn: () => this.uploadFilesFromErrorQueue(),
          disabled$: this.syncStatusService.numberOfAttachmentsInErrorUploadQueueObservable.pipe(map((value) => value <= 0)),
          icon: ['fal', 'cloud-upload'],
          showTab: false
        },
        {
          title: 'MENU.instructions',
          actionFn: () => this.openHelpPopover(),
          icon: ['fal', 'question-square'],
          showTab: false,
          keepMenuOpenAfterClick: true
        }
      ]
    }
  ];

  mainMenuGroups: Array<MenuGroup> = this.mainMenus.filter((mainMenu): mainMenu is MenuGroup => 'menus' in mainMenu);
  mainMenusFlatten: Array<Menu> = this.mainMenus.filter((mainMenu): mainMenu is Menu => !('menus' in mainMenu))
    .concat(_.flattenDeep(this.mainMenuGroups.map((mainMenuGroup) => mainMenuGroup.menus)));
  tabMenus = this.mainMenusFlatten.filter(mainMenu => mainMenu.showTab);

  private expandedGroupsByTitle = new Map<string, boolean>();

  isMenuHiddenOnScreen$ = this.deviceService.isAboveMediaQuery('(max-width: 1199px)');
  isMenuSmallOnScreen$ = this.deviceService.isAboveMediaQuery('(min-width: 1200px) and (max-width: 2093px)');
  isMenuBigOnScreen$ = this.deviceService.isAboveMediaQuery('(min-width: 2094px)');

  showSidebarMenuStatically$ = this.deviceService.isAboveMediaQuery('(min-width: 1200px)');

  enableIonMenu$ = this.authService.isAuthenticated$;

  allowExpandedMenu$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event: NavigationEnd) => event.urlAfterRedirects.startsWith('/dashboard')),
    shareReplay(1)
  );
  pageWithSidebarMenu$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith({ urlAfterRedirects: this.location.path() ?? '/' }),
    map((event: Pick<NavigationEnd, 'urlAfterRedirects'>) =>
      !event.urlAfterRedirects.startsWith('/resetPasswordConfirm')
      && !event.urlAfterRedirects.startsWith('/userInviteConfirm')
      && !event.urlAfterRedirects.startsWith('/userConnectionInviteConfirm')
      && !event.urlAfterRedirects.startsWith('/login')
    ),
    shareReplay(1)
  );

  constructor(
    private featureEnabledService: FeatureEnabledService,
    private userService: UserService,
    private deviceService: DeviceService,
    private router: Router,
    private location: Location,
    private syncService: SyncService,
    private syncStatusService: SyncStatusService,
    private toastService: ToastService,
    private authService: AuthenticationService,
    private popoverService: PopoverService,
    private ionMenuService: IonMenuService,
    private userflowService: UserflowService,
    private translateService: TranslateService,
    private networkStatusService: NetworkStatusService,
    private alertCtrl: AlertController
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1),
    ).subscribe((event: NavigationEnd) => {
      const [, firstSegment] = event.urlAfterRedirects.split('/');

      if (firstSegment) {
        const mappedSegment = segmentMapper(firstSegment);
        const menu = this.mainMenusFlatten.find((theMenu) => theMenu.url && theMenu.url === `/${mappedSegment}`);
        if (menu) {
          this.setMenu(menu);
        }
      }
    });
  }

  setMenu(menu: Menu) {
    this.menuSubject.next(menu);
  }

  async syncDataActive() {
    await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES, {showInfoToastMessages: true});
  }

  toggleGroupExpanded(menuGroup: MenuGroup): boolean {
    const newValue = !this.isGroupExpanded(menuGroup);
    this.setGroupExpanded(menuGroup, newValue);
    return newValue;
  }

  isGroupExpanded(menuGroup: MenuGroup): boolean {
    return this.expandedGroupsByTitle.get(menuGroup.title) ?? menuGroup.expandedDefaultValue ?? GROUP_EXPANDED_DEFAULT_VALUE;
  }

  setGroupExpanded(menuGroup: MenuGroup, expanded: boolean): void {
    this.expandedGroupsByTitle.set(menuGroup.title, expanded);
  }

  async uploadFilesFromErrorQueue() {
    const filesScheduled = await this.syncService.uploadFilesFromErrorQueue();
    if (filesScheduled === 0) {
      await this.toastService.infoWithMessage('sync_no_attachments_in_error_queue');
    } else {
      await this.toastService.toastWithTranslateParams('sync_attachments_moved_from_error_to_upload_queue', {count: filesScheduled}, ToastDurationInMs.INFO_WITH_MESSAGE);
    }
  }

  openHelpPage() {
    window.open(environment.helpPageUrl);
  }

  openAcademyPage() {
    window.open(environment.academyUrl);
  }

  navigateToTechnicalHelp() {
    this.router.navigate(['/technical-help']);
  }

  async openHelpPopover() {
    const result = await this.popoverService.openActions(event, [
      {
        role: 'academy',
        label: 'MENU.academy',
        icon: ['far', 'graduation-cap'],
        externalLink: true
      },
      {
        role: 'onlineHelp',
        label: 'MENU.manual',
        icon: ['far', 'question-square'],
        externalLink: true
      },
      {
        role: 'tutorial',
        label: 'MENU.tutorial',
        icon: ['far', 'shoe-prints']
      },
      {
        role: 'technicalHelp',
        label: 'MENU.technicalHelp',
        icon: ['far', 'code']
      }
    ]);

    if (result !== 'backdrop') {
      switch (result) {
        case 'academy':
          this.ionMenuService.ionMenu?.close();
          this.openAcademyPage();
          break;
        case 'onlineHelp':
          this.ionMenuService.ionMenu?.close();
          this.openHelpPage();
          break;
        case 'tutorial':
          this.ionMenuService.ionMenu?.close();
          this.startUserflow();
          break;
        case 'technicalHelp':
          this.ionMenuService.ionMenu?.close();
          this.navigateToTechnicalHelp();
          break;
        default:
          this.ionMenuService.ionMenu?.close();
          throw new Error('Unsupported action: ' + result);
      }
    }
  }

  public async startUserflow() {
    if (this.networkStatusService.offline) {
      const alert = await this.alertCtrl.create({
        header: this.translateService.instant('userflow.error.cannotStartNoInternet'),
        buttons: [this.translateService.instant('okay')]
      });
      await alert.present();
      return;
    }
    if (!this.userflowService.isUserflowEnabledForScreenSize()) {
      const alert = await this.alertCtrl.create({
        header: this.translateService.instant('userflow.error.notSupportForScreenSize'),
        buttons: [this.translateService.instant('okay')]
      });
      await alert.present();
      return;
    }
    await this.userflowService.startUserflowWithContent();
  }
}
