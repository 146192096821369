import {ColumnConfig} from '../ColumnConfig';

const RecipientInVersionDistUnique = ['profile_id', 'version_dist_id'];

// If this columnSet is changed keep in mind to also reflect the changes in PdfPlanVersionDistributionAccessColumnSet and it's respective SQL queries
export const PdfPlanVersionDistRecipientColumnSet: Array<ColumnConfig> = [
  {name: 'id', prop: 'id', cnd: true},
  {name: 'profile_id', prop: 'profileId', cnd: true, uniqueWith: [RecipientInVersionDistUnique]},
  {name: 'version_dist_id', prop: 'versionDistId', cnd: true, uniqueWith: [RecipientInVersionDistUnique]},
  {name: 'seen_at', prop: 'seenAt', cast: 'Timestamp', def: null},
  {name: 'created_at', prop: 'createdAt', cast: 'Timestamp'},
  {name: 'changed_at', prop: 'changedAt', cast: 'Timestamp'},
];
