import {AfterViewInit, Component, ContentChild, Directive, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {WorkflowType} from '../../../model/send-protocol';
import {TranslateService} from '@ngx-translate/core';
import {ThemeService} from '../../../services/ui/theme.service';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {map, switchMap} from 'rxjs/operators';
import {CustomPdfConfigurationDataService} from 'src/app/services/data/custom-pdf-configuration-data.service';
import {ReportTypeCode, UserEmailSignature} from 'submodules/baumaster-v2-common';
import {environment} from 'src/environments/environment';
import {UserEmailSignatureFormType} from 'src/app/model/email-signature-form-model';
import {haveObjectsEqualProperties} from 'src/app/utils/object-utils';
import {Nullish} from 'src/app/model/nullish';

@Directive({ selector: '[appPdfEmailSettingsBetweenTemplate]' })
export class PdfEmailSettingsBetweenTemplateDirective {}

@Component({
  selector: 'app-pdf-email-settings',
  templateUrl: './pdf-email-settings.component.html',
  styleUrls: ['./pdf-email-settings.component.scss']
})

export class PdfEmailSettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly senderEmailAddress = environment.senderEmailAddress;
  @Input() workflowType: WorkflowType = WorkflowType.Protocol;
  @Input() form: UntypedFormGroup;
  @Output() formChange = new EventEmitter<UntypedFormGroup>();
  @Input() formDirty: boolean;
  @Output() formDirtyChange = new EventEmitter<boolean>();
  @Output() resetForm = new EventEmitter<void>();
  public networkConnected$: Observable<boolean>;
  public emailSettingsForm: UntypedFormGroup;
  private valueChangesSubscription: Subscription | undefined;
  public readonly WorkflowTypeEnum = WorkflowType;

  @Input() showSaveTemplate = false;
  @Input() hideResetButton = false;
  @Input() recipientEmails: string[];
  @Input() showOnlyTemplateOptions = false;
  @Output() saveAsDefaultSettings = new EventEmitter<void>();
  @Output() saveSignatureAsDefaultSettings = new EventEmitter<void>();
  @Input() pendingSettingsSaving = false;
  @Input() userEmailSignature?: UserEmailSignature;
  @Input() reportType?: ReportTypeCode;

  collapsedRecipients = true;

  address$ = this.userProfileService.currentUserProfile$.pipe(switchMap((profile) => this.addressDataService.getById(profile.addressId)));
  showPostScriptum$ = this.customPdfConfigurationDataService.data.pipe(map((data) => !data?.[0]?.oemPdfs));

  @Output()
  attachmentUserEmailSignatureCreate = new EventEmitter<{blob: Blob; filename: string}>();

  @ContentChild(PdfEmailSettingsBetweenTemplateDirective, {
    read: TemplateRef,
    static: true,
  })
  betweenTemplate: Nullish<TemplateRef<Record<string, never>>>;

  get pdfDownloadButtonLabel() {
    if (this.workflowType === WorkflowType.GlobalSearch) {
      return 'sendProtocol.emailSettings.pdfGlobalSearchDownloadText';
    }
    if (this.workflowType === WorkflowType.Report) {
      if (this.reportType === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_DIARY) {
        return 'sendProtocol.emailSettings.pdfDownloadTextReportConstructionDiary';
      }
      if (this.reportType === ReportTypeCode.REPORT_TYPE_CONSTRUCTION_REPORT) {
        return 'sendProtocol.emailSettings.pdfDownloadTextReportConstructionReport';
      }
      if (this.reportType === ReportTypeCode.REPORT_TYPE_DIRECTED_REPORT) {
        return 'sendProtocol.emailSettings.pdfDownloadTextReportDirectedReport';
      }
    }
    return 'sendProtocol.emailSettings.pdfDownloadText';
  }

  readonly compareSignatures = (left: Nullish<UserEmailSignatureFormType>, right: Nullish<UserEmailSignatureFormType>) =>
    left && right && haveObjectsEqualProperties(left, right, ['includeCompanyLogo', 'size', 'text']);

  constructor(private networkStatusService: NetworkStatusService, public translateService: TranslateService, public themeService: ThemeService, private userProfileService: UserProfileService,
              private addressDataService: AddressDataService, private customPdfConfigurationDataService: CustomPdfConfigurationDataService) {
  }

  ngOnDestroy(): void {
    this.valueChangesUnsubscribe();
  }

  ngOnInit(): void {
    this.emailSettingsForm = this.form;
    this.networkConnected$ = this.networkStatusService.onlineOrUnknown$;
  }

  ngAfterViewInit() {
    this.checkFormIsDirty();
  }

  checkFormIsDirty() {
    this.valueChangesUnsubscribe();
    this.valueChangesSubscription = this.emailSettingsForm.valueChanges
      .subscribe((formValues) => {
        this.updateForm();
        this.updateFormDirty(this.emailSettingsForm.dirty);
      });
  }

  public restoreDefaultValues() {
    this.emailSettingsForm.markAsPristine();
    this.updateForm();
    this.updateFormDirty(false);
    this.resetForm.emit();
  }

  private updateFormDirty(formDirty: boolean) {
    this.formDirty = formDirty;
    this.formDirtyChange.emit(formDirty);
  }

  private updateForm() {
    this.form = this.emailSettingsForm;
    this.formChange.emit(this.emailSettingsForm);
  }

  private valueChangesUnsubscribe() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
      this.valueChangesSubscription = undefined;
    }
  }
}
