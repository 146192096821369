import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {AbstractFileAccessUtil, CacheStorageFileAccessUtil, FileAccessUtilClassName, FilesystemFileAccessUtil} from '../../utils/attachment-utils';
import {environment} from '../../../environments/environment';
import {ReplaySubject} from 'rxjs';
import {StorageService} from '../storage.service';
import {StorageKeyEnum} from '../../shared/constants';
import {observableToPromise} from '../../utils/async-utils';

const STORAGE_KEY = StorageKeyEnum.FILE_ACCESS_UTIL;

@Injectable({
  providedIn: 'root'
})
export class AttachmentSettingService {
  private readonly mediaUrl = environment.serverUrl + 'media';
  private readonly cacheStorageFileAccessUtil = new CacheStorageFileAccessUtil(this.mediaUrl);
  private readonly filesystemFileAccessUtil = new FilesystemFileAccessUtil(this.mediaUrl);
  private readonly fileAccessUtilSubject = new ReplaySubject<AbstractFileAccessUtil>(1);
  public readonly fileAccessUtil$ = this.fileAccessUtilSubject.asObservable();

  constructor(private platform: Platform, private storage: StorageService) {
    this.initFileAccessUtilSubject();
  }

  public getSupportedFileAccessUtils(): Array<AbstractFileAccessUtil> {
    if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
      return [this.cacheStorageFileAccessUtil, this.filesystemFileAccessUtil];
    }
    return [this.cacheStorageFileAccessUtil];
  }

  public getDefaultFileAccessUtilForDevice(): AbstractFileAccessUtil {
    if (this.platform.is('capacitor') && this.platform.is('ios')) {
      return this.filesystemFileAccessUtil;
    }
    return this.cacheStorageFileAccessUtil;
  }

  public getSupportedFileAccessUtilClassNames(): Array<FileAccessUtilClassName> {
    return this.getSupportedFileAccessUtils().map((fileAccessUtil) => fileAccessUtil.className);
  }

  public isSwitchingFileAccessUtilSupported(): boolean {
    return this.getSupportedFileAccessUtils().length > 1;
  }

  public isFileAccessUtilSupported(fileAccessUtilClassName: FileAccessUtilClassName): boolean {
    return this.getSupportedFileAccessUtilClassNames().some((value) => value === fileAccessUtilClassName);
  }

  public async getFileAccessUtil(): Promise<AbstractFileAccessUtil> {
    return await observableToPromise(this.fileAccessUtilSubject);
  }

  private async getFileAccessUtilFromStorageOrDefault(): Promise<AbstractFileAccessUtil> {
    const fileAccessUtilClassName: FileAccessUtilClassName|undefined|null = await this.storage.get(STORAGE_KEY);
    if (fileAccessUtilClassName) {
      return this.getFileAccessUtilByClassName(fileAccessUtilClassName);
    }
    return this.cacheStorageFileAccessUtil;
  }

  public async changeFileAccessUtil(newFileAccessUtilClassName: FileAccessUtilClassName): Promise<{oldFileAccessUtil: AbstractFileAccessUtil; newFileAccessUtil?: AbstractFileAccessUtil}> {
    const oldFileAccessUtil = await observableToPromise(this.fileAccessUtilSubject);
    if (oldFileAccessUtil.className === newFileAccessUtilClassName) {
      return {oldFileAccessUtil};
    }
    if (!this.isFileAccessUtilSupported(newFileAccessUtilClassName)) {
      throw new Error(`newFileAccessUtilClassName ${newFileAccessUtilClassName} is not supported on this device.`);
    }
    const newFileAccessUtil = this.getFileAccessUtilByClassName(newFileAccessUtilClassName);
    await this.storage.set(STORAGE_KEY, newFileAccessUtil.className);
    this.fileAccessUtilSubject.next(newFileAccessUtil);
    return {oldFileAccessUtil, newFileAccessUtil};
  }

  private getFileAccessUtilByClassName(fileAccessUtilClassName: FileAccessUtilClassName): AbstractFileAccessUtil {
    switch (fileAccessUtilClassName) {
      case 'CacheStorageFileAccessUtil': return this.cacheStorageFileAccessUtil;
      case 'FilesystemFileAccessUtil': return this.filesystemFileAccessUtil;
      default: return this.cacheStorageFileAccessUtil;
    }
  }

  private async initFileAccessUtilSubject() {
    const fileAccessUtil = await this.getFileAccessUtilFromStorageOrDefault();
    this.fileAccessUtilSubject.next(fileAccessUtil);
  }
}
