import {Injectable} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Device} from '@capacitor/device';
import {LoggingService} from './logging.service';
import {startWorker} from '../../utils/async-utils';
import {SyncStatusService} from '../sync/sync-status.service';
import {CACHE_STORAGE_SIZE_META_SIZE_FACTOR, CRITICAL_STORAGE_SPACE_LIMIT_IN_BYTES, WARNING_STORAGE_SPACE_LIMIT_IN_BYTES} from '../../shared/constants';
import {StorageService} from '../storage.service';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {distinctUntilChanged, filter, map, mergeMap, shareReplay} from 'rxjs/operators';
import {Observable, of, Subscription, timer} from 'rxjs';
import {AlertService} from '../ui/alert.service';
import {SystemEventService} from '../event/system-event.service';
import {PosthogService} from '../posthog/posthog.service';

const LOG_SOURCE = 'SystemInfoService';

export interface StorageQuota {
  quota: number;
  usage: number;
  fallbackImplementation?: boolean;
  usageDetails: {
    caches: number;
    indexedDB: number|undefined;
    serviceWorkerRegistrations: number|undefined;
  };
}

/**
 * StorageQuotaLevels.
 * The order of the enum values is import as it is being used for comparing and sorting.
 */
export enum StorageQuotaLevelEnum {
  OK,
  WARNING,
  CRITICAL,
  FULL
}

const STORAGE_QUOTA_LEVEL_CHECK_INTERVAL_IN_MS = 1 * 60 * 1000;
const STORAGE_QUOTA_LEVEL_NOTIFICATION_INTERVAL_IN_MS = 2 * 60 * 1000;

@Injectable({
  providedIn: 'root'
})
export class SystemInfoService {
  private calculateCacheStorageSizeFallbackPromise: Promise<number>|undefined;
  public storageQuota$ = this.syncStatusService.attachmentSyncInProgressObservable
    .pipe(filter((attachmentSyncInProgress) => !attachmentSyncInProgress.inProgress))
    .pipe(mergeMap(async (attachmentSyncInProgress) => await this.getStorageQuota()))
    .pipe(shareReplay(1));

  public storageQuotaLevel$: Observable<StorageQuotaLevelEnum | undefined> = !this.isStorageQuotaLevelWarningRelevantForDevice() ? of(undefined) :
    timer(0, STORAGE_QUOTA_LEVEL_CHECK_INTERVAL_IN_MS)
      .pipe(mergeMap(async () => await this.getStorageQuota(false)))
      .pipe(map((storageQuota) => this.getStorageQuotaLevel(storageQuota)))
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));

  private storageQuotaLevelSubscription: Subscription|undefined;
  private lastStorageQuotaLevel: {storageQuotaLevel: StorageQuotaLevelEnum, timestamp: number}|undefined;

  constructor(private alertController: AlertController, private translateService: TranslateService, private storage: StorageService, private loggingService: LoggingService,
              private syncStatusService: SyncStatusService, private attachmentSettingService: AttachmentSettingService, private alertService: AlertService,
              private systemEventService: SystemEventService, private platform: Platform, private posthogService: PosthogService) {
  }

  public async getStorageQuota(useFallback = true): Promise<StorageQuota | undefined> {
    const startTime = Date.now();
    let fallbackUsed = false;
    try {
      if (!navigator?.storage?.estimate) {
        if (useFallback) {
          fallbackUsed = true;
          return await this.calculateStorageQuotaFallback();
        }
        return undefined;
      } else {
        const storageQuota = await navigator.storage.estimate() as StorageQuota;
        const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
        if (useFallback && !fileAccessUtil.affectsStorageQuota) {
          fallbackUsed = true;
          const size = await this.calculateCacheStorageSizeFallback();
          storageQuota.usage = (storageQuota.usage || 0) + (size || 0);
        }
        return storageQuota;
      }
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `getStorageQuota(fallbackUsed=${fallbackUsed}) failed: ${error?.message}`);
      return undefined;
    } finally {
      this.loggingService.debug(LOG_SOURCE, `getStorageQuota(fallbackUsed=${fallbackUsed}) took ${Date.now() - startTime} ms.`);
    }
  }

  public getStorageQuotaLevel(storageQuota: StorageQuota|undefined): StorageQuotaLevelEnum|undefined {
    const startTime = Date.now();
    if (!storageQuota) {
      this.loggingService.debug(LOG_SOURCE, `getStorageQuotaLevel took ${Date.now() - startTime} ms and returned undefined.`);
      return undefined;
    }
    const bytesFree = (storageQuota.quota - storageQuota.usage);
    if (bytesFree <= 0) {
      this.loggingService.debug(LOG_SOURCE, `getStorageQuotaLevel took ${Date.now() - startTime} ms and returned "FULL" (bytesFree=${bytesFree}).`);
      return StorageQuotaLevelEnum.FULL;
    }
    if (bytesFree > WARNING_STORAGE_SPACE_LIMIT_IN_BYTES) {
      this.loggingService.debug(LOG_SOURCE, `getStorageQuotaLevel took ${Date.now() - startTime} ms and returned "OK" (bytesFree=${bytesFree}).`);
      return StorageQuotaLevelEnum.OK;
    }
    if (bytesFree > CRITICAL_STORAGE_SPACE_LIMIT_IN_BYTES) {
      this.loggingService.debug(LOG_SOURCE, `getStorageQuotaLevel took ${Date.now() - startTime} ms and returned "WARNING" (bytesFree=${bytesFree}).`);
      return StorageQuotaLevelEnum.WARNING;
    }
    if (bytesFree >= 1) {
      this.loggingService.debug(LOG_SOURCE, `getStorageQuotaLevel took ${Date.now() - startTime} ms and returned "CRITICAL" (bytesFree=${bytesFree}).`);
      return StorageQuotaLevelEnum.CRITICAL;
    }
    this.loggingService.debug(LOG_SOURCE, `getStorageQuotaLevel took ${Date.now() - startTime} ms and returned undefined (bytesFree=${bytesFree}).`);
  }

  public isStorageQuotaLevelWarningRelevantForDevice(): boolean {
    return this.isStorageIndexedDb();
  }

  private isStorageSqLite(): boolean {
    const storageDriver = this.storage.driver;
    this.loggingService.debug(LOG_SOURCE, `storageDriver="${storageDriver}"`);
    return storageDriver === 'cordovaSQLiteDriver';
  }

  private isStorageIndexedDb(): boolean {
    return !this.isStorageSqLite();
  }

  private async calculateStorageSize(): Promise<number> {
    this.loggingService.debug(LOG_SOURCE, 'calculateStorageSize started.');
    const startTime = new Date().getTime();
    try {
      let totalSize = 0;
      for (const key of await this.storage.keys()) {
        const value = await this.storage.get(key);
        totalSize += value ? JSON.stringify(value).length : 0;
      }
      return totalSize;
    } finally {
      this.loggingService.debug(LOG_SOURCE, `calculateStorageSize finished in ${new Date().getTime() - startTime} ms.`);
    }
  }

  private async calculateCacheStorageSizeFallback(): Promise<number|undefined> {
    if (this.calculateCacheStorageSizeFallbackPromise) {
      return this.calculateCacheStorageSizeFallbackPromise; // calculation already in progress. Do not call again but use this result.
    }
    try {
      const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
      if (typeof Worker !== 'undefined' && fileAccessUtil.webWorkerSupported) {
        // eslint-disable-next-line
        this.calculateCacheStorageSizeFallbackPromise = startWorker(new Worker(new URL('./total-size-of-all-caches-web.worker', import.meta.url), {type: 'module'}),
          {mediaUrl: fileAccessUtil.mediaUrl, fileAccessUtilClassName: fileAccessUtil.className});
      } else {
        this.calculateCacheStorageSizeFallbackPromise = fileAccessUtil.getTotalSizeOfAllLocations();
      }
      return await this.calculateCacheStorageSizeFallbackPromise;
    } finally {
      this.calculateCacheStorageSizeFallbackPromise = undefined;
    }
  }

  private async calculateStorageQuotaFallback(): Promise<StorageQuota|undefined> {
    this.loggingService.debug(LOG_SOURCE, `calculateStorageQuotaFallback called`);
    const startTime = new Date().getTime();
    try {
      const deviceInfo = await Device.getInfo();
      if (deviceInfo.diskFree === undefined) {
        return undefined;
      }
      let quota = deviceInfo.diskFree;
      if (deviceInfo.platform === 'ios' && deviceInfo.osVersion.startsWith('12')) {
        // Source: https://love2dev.com/blog/what-is-the-service-worker-cache-storage-limit/#:~:text=Up%20to%20this%20point%20Apple,storage%20limit%20to%20roughly%2050MB.
        quota = Math.min(500 * 1024 * 1024, deviceInfo.diskFree / 2); // 500MB or half the free space
      }
      const cacheStorageSize = Math.round((await this.calculateCacheStorageSizeFallback()) * CACHE_STORAGE_SIZE_META_SIZE_FACTOR);
      if (cacheStorageSize === undefined) {
        return undefined;
      }
      const indexedDBSize = this.isStorageSqLite() ? undefined : await this.calculateStorageSize();
      return {
        usage: cacheStorageSize + (indexedDBSize || 0),
        quota,
        fallbackImplementation: true,
        usageDetails: {
          caches: cacheStorageSize,
          indexedDB: indexedDBSize,
          serviceWorkerRegistrations: undefined
        }
      } as StorageQuota;
    } finally {
      this.loggingService.debug(LOG_SOURCE, `calculateStorageQuotaFallback finished in ${new Date().getTime() - startTime} ms`);
    }
  }

  public async calculateStorageSpaceLeft(useFallback = true): Promise<number|undefined> {
    const storageQuota = await this.getStorageQuota(useFallback);
    return storageQuota ? storageQuota.quota - storageQuota.usage : undefined;
  }

  public async isStorageSpaceLeftCritical(additionalRequiredStorageSpace?: number): Promise<boolean|undefined> {
    let storageSpaceLeft = await this.calculateStorageSpaceLeft();
    if (storageSpaceLeft === undefined) {
      return undefined;
    }
    if (additionalRequiredStorageSpace) {
      storageSpaceLeft -= additionalRequiredStorageSpace;
    }
    return storageSpaceLeft <= CRITICAL_STORAGE_SPACE_LIMIT_IN_BYTES;
  }

  public async showWarningIfStorageSpaceLeftCritical(): Promise<void> {
    const fileAccessUtil = await this.attachmentSettingService.getFileAccessUtil();
    if (fileAccessUtil.affectsStorageQuota && await this.isStorageSpaceLeftCritical()) {
      const alert = await this.alertController.create({
        header: this.translateService.instant('alert.storageSpaceLeftCritical.header'),
        message: this.translateService.instant('alert.storageSpaceLeftCritical.message'),
        buttons: [this.translateService.instant('okay')]
      });

      await alert.present();
    }
  }

  public startStorageQuotaLevelNotification() {
    this.loggingService.debug(LOG_SOURCE, 'startStorageQuotaLevelNotification');
    this.storageQuotaLevelSubscription?.unsubscribe();
    this.storageQuotaLevelSubscription = this.storageQuotaLevel$.subscribe(async (storageQuotaLevel) => {
      if (!storageQuotaLevel) {
        this.lastStorageQuotaLevel = undefined;
      } else {
        const lastStorageQuotaLevel = this.lastStorageQuotaLevel;
        this.lastStorageQuotaLevel = {storageQuotaLevel, timestamp: Date.now()};
        this.systemEventService.logEvent('SystemInfoService.startStorageQuotaLevelNotification.subscribe', `storageQuotaLevel changed to ${storageQuotaLevel}`);
        if (!lastStorageQuotaLevel || lastStorageQuotaLevel.storageQuotaLevel !== storageQuotaLevel) {
          if (storageQuotaLevel === StorageQuotaLevelEnum.WARNING) {
          this.posthogService.captureEvent('[Device][Storage]StorageWarningState', {});
          }
          if (storageQuotaLevel === StorageQuotaLevelEnum.CRITICAL) {
            this.posthogService.captureEvent('[Device][Storage]StorageCriticalState', {});
          }
          if (storageQuotaLevel === StorageQuotaLevelEnum.FULL) {
            this.posthogService.captureEvent('[Device][Storage]StorageFullState', {});
          }
        }
        if (storageQuotaLevel === StorageQuotaLevelEnum.WARNING &&
          (!lastStorageQuotaLevel ||
          (lastStorageQuotaLevel && lastStorageQuotaLevel.storageQuotaLevel < storageQuotaLevel || Date.now() - lastStorageQuotaLevel.timestamp >= STORAGE_QUOTA_LEVEL_NOTIFICATION_INTERVAL_IN_MS))) {
          const ret = await this.showStorageSpaceLeftWarningDialog();
          this.systemEventService.logEvent('SystemInfoService.startStorageQuotaLevelNotification.subscribe', `storageQuotaLevel ${storageQuotaLevel} confirmed by user with ${ret}`);
        }
        this.lastStorageQuotaLevel = {storageQuotaLevel, timestamp: Date.now()};
      }
    });
  }

  public stopStorageQuotaLevelNotification(): boolean {
    this.loggingService.debug(LOG_SOURCE, 'stopStorageQuotaLevelNotification');
    this.lastStorageQuotaLevel = undefined;
    if (!this.storageQuotaLevelSubscription) {
      return false;
    }
    this.storageQuotaLevelSubscription?.unsubscribe();
    return true;
  }

  public async showStorageSpaceLeftWarningDialog(): Promise<void> {
    // Using an alertController instead of alertService on purpose. Since we open a new tab when the user clicks the button and it has to be in the click event, otherwise the browser blocks popups.
    const alert = await this.alertController.create({
      header: this.translateService.instant('alert.storageSpaceLeftWarning.header'),
      message: this.translateService.instant('alert.storageSpaceLeftWarning.message'),
      buttons: [
        this.translateService.instant('okay'),
        {
          text: this.translateService.instant('alert.storageSpaceLeftCritical.buttonReadInstructions'),
          handler: () => this.redirectHowToFreeStorage()
        }
      ]
    });

    await alert.present();
  }

  public async showStorageSpaceLeftCriticalDialog(): Promise<void> {
    // Using an alertController instead of alertService on purpose. Since we open a new tab when the user clicks the button and it has to be in the click event, otherwise the browser blocks popups.
    const alert = await this.alertController.create({
      header: this.translateService.instant('alert.storageSpaceLeftCritical.header'),
      message: this.translateService.instant('alert.storageSpaceLeftCritical.message'),
      buttons: [
        this.translateService.instant('okay'),
        {
          text: this.translateService.instant('alert.storageSpaceLeftCritical.buttonReadInstructions'),
          handler: () => this.redirectHowToFreeStorage()
        }
      ]
    });
    await alert.present();
  }

  public redirectHowToFreeStorage() {
    window.open('https://helpdesk.bau-master.com/portal/de/kb/articles/ger%C3%A4tespeicher-freigeben-android', '_blank');
  }
}
