import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Device} from '@capacitor/device';
import {AuthenticationService} from '../auth/authentication.service';
import {Subscription} from 'rxjs';
import {FirebaseEventEnum, StorageKeyEnum} from '../../shared/constants';
import {LoggingService} from '../common/logging.service';
import {Platform} from '@ionic/angular';
import {StorageService} from '../storage.service';
import { FirebaseAnalyticsFacadeService } from './firebase-analytics-facade.service';
import {IdType} from 'submodules/baumaster-v2-common';

const LOG_SOURCE = 'AnalyticsService';

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService implements OnDestroy {
  private readonly systemSupportsAnalytics: boolean;
  private enabled = false;
  private authenticatedUserId: IdType|undefined ;
  private authenticationSubscription: Subscription;

  constructor(private firebaseAnalytics: FirebaseAnalyticsFacadeService, private router: Router, private storage: StorageService, private authenticationService: AuthenticationService,
              private loggingService: LoggingService, private platform: Platform) {
    this.systemSupportsAnalytics = this.platform.is('android') || this.platform.is('ios') || this.platform.is('pwa') || this.platform.is('mobileweb') || this.platform.is('desktop');
    this.systemSupportsAnalytics = this.systemSupportsAnalytics && this.firebaseAnalytics.isEnabled;
    this.authenticationSubscription = this.authenticationService.authenticatedUserId$.subscribe(async (authenticatedUserId) => {
      this.authenticatedUserId = authenticatedUserId;
      if (!authenticatedUserId) {
        await this.clearStorageData();
        await this.firebaseAnalytics.resetAnalyticsData();
        await this.init();
      } else {
        await this.init();
        await this.initUserId(authenticatedUserId);
      }
    });
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  private async init() {
    await this.initEnabled();
    await this.initDeviceInfo();
    await this.initCacheStorageSupportedInfo();
    await this.initProductionEnvironmentInfo();
  }

  private async initEnabled() {
    let enabled = true;
    if (!this.systemSupportsAnalytics) {
      enabled = false;
    } else if (!environment.analyticsEnabled) {
      this.loggingService.info(LOG_SOURCE, 'Analytics disabled for environment.');
      enabled = false;
    } else {
      const analyticsDisabled = !!(await this.storage.get(StorageKeyEnum.ANALYTICS_DISABLED));
      if (analyticsDisabled) {
        this.loggingService.info(LOG_SOURCE, 'Analytics disabled in system settings.');
        enabled = true;
      }
    }

    this.enabled = enabled;
    await this.firebaseAnalytics.setEnabled(this.enabled);
  }

  private async initDeviceInfo() {
    const deviceInfo = await Device.getInfo();
    await this.firebaseAnalytics.setUserProperty('device', deviceInfo.operatingSystem + ' ' + deviceInfo.osVersion);
    const diskFree = deviceInfo.diskFree;
    await this.firebaseAnalytics.setUserProperty('diskFree', diskFree ? diskFree.toString() : '');
    const diskTotal = deviceInfo.diskTotal;
    await this.firebaseAnalytics.setUserProperty('diskTotal', diskTotal ? diskTotal.toString() : '');
    await this.firebaseAnalytics.setUserProperty('version', environment.version);
  }

  private async initUserId(userId: string) {
    const analyticsUserInfoEnabledStorage: boolean | null | undefined = await this.storage.get(StorageKeyEnum.ANALYTICS_USER_INFO_ENABLED);
    const analyticsUserInfoEnabled: boolean = analyticsUserInfoEnabledStorage === null || analyticsUserInfoEnabledStorage === undefined ? true : analyticsUserInfoEnabledStorage;
    if (analyticsUserInfoEnabled) {
      this.loggingService.info(LOG_SOURCE, 'logging userId enabled.');
      await this.firebaseAnalytics.setUserId(userId);
    }
  }

  private async clearStorageData() {
    await this.storage.remove(StorageKeyEnum.ANALYTICS_DISABLED);
    await this.storage.remove(StorageKeyEnum.ANALYTICS_USER_INFO_ENABLED);
  }

  public async logEvent(logSource: string, message: string, additionalParams: Record<string, unknown> = {}) {
    if (!this.enabled) {
      return;
    }
    await this.firebaseAnalytics.logEvent(FirebaseEventEnum.APPLICATION_INFO, {
      ...additionalParams,
      logSource,
      ga_realtime: 1,
      page: this.router.url,
      message
    });
  }

  public async logErrorEvent(logSource: string, error: string|Error) {
    if (!this.enabled) {
      return;
    }
    const message = typeof error === 'string' ? error : error?.message;
    await this.firebaseAnalytics.logEvent(FirebaseEventEnum.APPLICATION_ERROR, {
      logSource,
      ga_realtime: 1,
      page: this.router.url,
      message
    });
  }

  public isEnabled(): boolean {
    return this.enabled;
  }

  public async isAnalyticsDisabledInStorage(): Promise<boolean|undefined> {
    const analyticsDisabled: boolean|null = await this.storage.get(StorageKeyEnum.ANALYTICS_DISABLED);
    return analyticsDisabled === null ? undefined : analyticsDisabled;
  }

  public async setAnalyticsDisabledInStorage(analyticsDisabled: boolean): Promise<void> {
    await this.storage.set(StorageKeyEnum.ANALYTICS_DISABLED, analyticsDisabled);
    await this.initEnabled();
  }

  public async isAnalyticsUserInfoEnabledInStorage(): Promise<boolean|undefined> {
    const userInfoEnabled: boolean|null = await this.storage.get(StorageKeyEnum.ANALYTICS_USER_INFO_ENABLED);
    return userInfoEnabled === null ? undefined : userInfoEnabled;
  }

  public async setAnalyticsUserInfoEnabledInStorage(userInfoEnabled: boolean): Promise<void> {
    await this.storage.set(StorageKeyEnum.ANALYTICS_USER_INFO_ENABLED, userInfoEnabled);
    if (userInfoEnabled) {
      await this.initUserId(this.authenticatedUserId);
    } else {
      await this.firebaseAnalytics.resetAnalyticsData();
      await this.init();
    }
  }

  public isSystemSupportsAnalytics(): boolean {
    return this.systemSupportsAnalytics;
  }

  private async initCacheStorageSupportedInfo() {
    const cacheStorageSupported = typeof caches !== 'undefined';
    await this.firebaseAnalytics.setUserProperty('cacheStorageSupported', cacheStorageSupported.toString());
  }

  private async initProductionEnvironmentInfo() {
    await this.firebaseAnalytics.setUserProperty('environment', environment.name);
  }
}
