import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonicModule} from '@ionic/angular';
import {MbscModule} from '@mobiscroll/angular-ivy';
import {TranslateModule} from '@ngx-translate/core';
import {SelectableInputModule} from '../selectable-input/selectable-input.module';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {AttachmentFullScreenViewerComponent} from 'src/app/components/common/attachment-full-screen-viewer/attachment-full-screen-viewer.component';
import {ProjectSelectorModule} from 'src/app/components/common/project-selector/project-selector.module';
import {ProtocolTypeSelectorComponent} from 'src/app/components/common/protocol-type-selector/protocol-type-selector.component';
import {ContactEmployeCreateComponent} from 'src/app/components/contacts/contact-employe-create/contact-employe-create.component';
import {ContactEmployeeFormComponent} from 'src/app/components/contacts/contact-employee-form/contact-employee-form.component';
import {IndividualNextMeetingFormComponent} from 'src/app/components/pdf/next-meeting/individual-next-meeting-form/individual-next-meeting-form.component';
import {IndividualNextMeetingsGroupsComponent} from 'src/app/components/pdf/next-meeting/individual-next-meetings-groups/individual-next-meetings-groups.component';
import {IndividualNextMeetingsComponent} from 'src/app/components/pdf/next-meeting/individual-next-meetings/individual-next-meetings.component';
import {NextMeetingFormComponent} from 'src/app/components/pdf/next-meeting/next-meeting-form/next-meeting-form.component';
import {NextMeetingComponent} from 'src/app/components/pdf/next-meeting/next-meeting.component';
import {PdfConfigurationComponent} from 'src/app/components/pdf/pdf-configuration/pdf-configuration.component';
import {PdfEmailSettingsBetweenTemplateDirective, PdfEmailSettingsComponent} from 'src/app/components/pdf/pdf-email-settings/pdf-email-settings.component';
import {PdfMailingListTableComponent} from 'src/app/components/pdf/pdf-mailing-list-table/pdf-mailing-list-table.component';
import {PdfMailingListComponent} from 'src/app/components/pdf/pdf-mailing-list/pdf-mailing-list.component';
import {PdfProtocolSettingsComponent} from 'src/app/components/pdf/pdf-protocol-settings/pdf-protocol-settings.component';
import {PdfWorkflowComponent} from 'src/app/components/pdf/pdf-workflow/pdf-workflow.component';
import {ProtocolAdditionalFieldsComponent} from 'src/app/components/protocol/protocol-additional-fields/protocol-additional-fields.component';
import {ProtocolCreateComponent} from 'src/app/components/protocol/protocol-create/protocol-create.component';
import {ProtocolDetailComponent} from 'src/app/components/protocol/protocol-detail/protocol-detail.component';
import {ProtocolEntriesComponent} from 'src/app/components/protocol/protocol-entries/protocol-entries.component';
import {ProtocolEntryAttachmentsComponent} from 'src/app/components/protocol/protocol-entry-attachments/protocol-entry-attachments.component';
import {ProtocolEntryChatCreateComponent} from 'src/app/components/protocol/protocol-entry-chat-create/protocol-entry-chat-create.component';
import {ProtocolEntryChatComponent} from 'src/app/components/protocol/protocol-entry-chat/protocol-entry-chat.component';
import {ProtocolEntryChatsComponent} from 'src/app/components/protocol/protocol-entry-chats/protocol-entry-chats.component';
import {ProtocolEntryCreateComponent} from 'src/app/components/protocol/protocol-entry-create/protocol-entry-create.component';
import {ProtocolEntryEditComponent} from 'src/app/components/protocol/protocol-entry-edit/protocol-entry-edit.component';
import {ProtocolEntryFormComponent} from 'src/app/components/protocol/protocol-entry-form/protocol-entry-form.component';
import {ProtocolEntryLightComponent} from 'src/app/components/protocol/protocol-entry-light/protocol-entry-light.component';
import {ProtocolEntryMassEditComponent} from 'src/app/components/protocol/protocol-entry-mass-edit/protocol-entry-mass-edit.component';
import {ProtocolEntryModalComponent} from 'src/app/components/protocol/protocol-entry-modal/protocol-entry-modal.component';
import {ProtocolEntryPriorityPopoverComponent} from 'src/app/components/protocol/protocol-entry-priority-popover/protocol-entry-priority-popover.component';
import {ProtocolEntryPriorityComponent} from 'src/app/components/protocol/protocol-entry-priority/protocol-entry-priority.component';
import {ProtocolEntryShortIdComponent} from 'src/app/components/protocol/protocol-entry-short-id/protocol-entry-short-id.component';
import {ProtocolEntrySlimCardComponent} from 'src/app/components/protocol/protocol-entry-slim-card/protocol-entry-slim-card.component';
import {ProtocolEntryStatusPopoverComponent} from 'src/app/components/protocol/protocol-entry-status-popover/protocol-entry-status-popover.component';
import {ProtocolEntryStatusComponent} from 'src/app/components/protocol/protocol-entry-status/protocol-entry-status.component';
import {ProtocolEntryComponent} from 'src/app/components/protocol/protocol-entry/protocol-entry.component';
import {ProtocolFormComponent} from 'src/app/components/protocol/protocol-form/protocol-form.component';
import {ProtocolListComponent} from 'src/app/components/protocol/protocol-list/protocol-list.component';
import {ProtocolShortNameNumberComponent} from 'src/app/components/protocol/protocol-short-name-number/protocol-short-name-number.component';
import {CurrencyDirective} from 'src/app/directives/currency.directive';
import {MainHeaderModule} from 'src/app/modules/main-header/main-header.module';
import {PipesModule} from 'src/app/pipes/pipes.module';
import {PlanMarkerModule} from 'src/app/shared/module/plan-marker/plan-marker.module';
import {AttachmentSliderComponent} from '../../../components/common/attachment-slider/attachment-slider.component';
import {CopyProjectComponent} from '../../../components/copy/copy-project/copy-project.component';
import {CopyProtocolEntryComponent} from '../../../components/copy/copy-protocol-entry/copy-protocol-entry.component';
import {CopyProtocolComponent} from '../../../components/copy/copy-protocol/copy-protocol.component';
import {MissingAttachmentsComponent} from '../../../components/pdf/missing-attachments/missing-attachments.component';
import {PdfViewerComponent} from '../../../components/pdf/pdf-viewer/pdf-viewer.component';
import {SignatureComponent} from '../../../components/pdf/signature/signature.component';
import {ProjectRoomAttachmentsListComponent} from '../../../components/project-room/project-room-attachments-list/project-room-attachments-list.component';
import {ProjectRoomAttachmentsSelectorComponent} from '../../../components/project-room/project-room-attachments-selector/project-room-attachments-selector.component';
import {ProtocolEntryEditGroupedMenuPopoverComponent} from '../../../components/protocol/protocol-entry-edit-grouped-menu-popover/protocol-entry-edit-grouped-menu-popover.component';
import {ProtocolEntryDetailsDirective} from '../../../directives/protocol/protocol-entry-details.directive';
import {DownloadModule} from '../download/download.module';
import {LicenseModule} from '../license/license.module';
import {UiModule} from '../ui/ui.module';
import {AudioRecordingComponent} from '../../../components/common/audio-recording/audio-recording.component';
import {PdfProtocolSignaturesComponent} from '../../../components/pdf/pdf-protocol-signatures/pdf-protocol-signatures.component';
import {PdfProtocolSignatureComponent} from '../../../components/pdf/pdf-protocol-signature/pdf-protocol-signature.component';
import {PdfProtocolSignaturesFooterComponent} from '../../../components/pdf/pdf-protocol-signatures-footer/pdf-protocol-signatures-footer.component';
import {PdfProtocolSignaturesDialogComponent} from '../../../components/pdf/pdf-protocol-signatures-dialog/pdf-protocol-signatures-dialog.component';
import {EmailSignatureModule} from '../email-signature/email-signature.module';
import {TooltipModule} from '../tooltip/tooltip.module';
import {PdfAdvancedSettingsComponent} from 'src/app/components/pdf/pdf-advanced-settings/pdf-advanced-settings.component';
import {AttachmentViewerModule} from '../attachment-viewer/attachment-viewer.module';
import {SharedSvgModule} from 'src/app/shared/module/shared-svg/shared-svg.module';
import {CommonSelectableModule} from '../selectable/selectable.module';
import {ManageCompanyOrderComponent} from 'src/app/components/company/manage-company-order/manage-company-order.component';
import {ProtocolEntryContinuousInfoPopoverComponent} from '../../../components/protocol/protocol-entry-continuous-info-popover/protocol-entry-continuous-info-popover.component';
import {RxLet} from '@rx-angular/template/let';
import {RxIf} from '@rx-angular/template/if';
import {DynamicSizeVirtualScrollStrategy, FixedSizeVirtualScrollStrategy, RxVirtualFor, RxVirtualScrollViewportComponent} from '@rx-angular/template/experimental/virtual-scrolling';
import {RxFor} from '@rx-angular/template/for';
import {ColorPickerButtonComponent} from 'src/app/components/ui/color-picker-button/color-picker-button.component';
import {ProtocolDetailEditComponent} from 'src/app/components/protocol/protocol-detail-edit/protocol-detail-edit.component';
import {ProtocolFormWeatherComponent} from 'src/app/components/protocol/protocol-form/protocol-form-weather/protocol-form-weather.component';
import {InfoPopoverClickDirective} from 'src/app/directives/common/info-popover-click.directive';
import {EntryCardListComponent, EntryListGroupHeaderTemplateDirective} from 'src/app/components/entry/entry-card-list/entry-card-list.component';
import {InfoPopoverComponent} from '../../../components/common/info-popover/info-popover.component';
import {OfflineInfoComponent} from '../../../components/common/offline-info/offline-info.component';

@NgModule({
  declarations: [
    CurrencyDirective,
    ProtocolEntryFormComponent,
    ProtocolEntryCreateComponent,
    ProtocolCreateComponent,
    ProtocolEntryComponent,
    ProtocolEntriesComponent,
    ProtocolEntryStatusComponent,
    ProtocolEntryShortIdComponent,
    ProtocolEntryPriorityPopoverComponent,
    ProtocolAdditionalFieldsComponent,
    ProtocolFormComponent,
    AttachmentSliderComponent,
    AudioRecordingComponent,
    ContactEmployeCreateComponent,
    ContactEmployeeFormComponent,
    PdfProtocolSettingsComponent,
    PdfWorkflowComponent,
    PdfMailingListTableComponent,
    PdfMailingListComponent,
    PdfConfigurationComponent,
    PdfEmailSettingsComponent,
    MissingAttachmentsComponent,
    NextMeetingComponent,
    PdfViewerComponent,
    SignatureComponent,
    ProjectRoomAttachmentsListComponent,
    ProjectRoomAttachmentsSelectorComponent,
    CopyProtocolEntryComponent,
    CopyProtocolComponent,
    CopyProjectComponent,
    ProtocolShortNameNumberComponent,
    ProtocolEntryEditGroupedMenuPopoverComponent,
    ProtocolEntryLightComponent,
    ProtocolEntryMassEditComponent,
    ProtocolListComponent,
    ProtocolTypeSelectorComponent,
    ProtocolDetailComponent,
    ProtocolEntryPriorityComponent,
    IndividualNextMeetingFormComponent,
    IndividualNextMeetingsComponent,
    NextMeetingFormComponent,
    IndividualNextMeetingsGroupsComponent,
    ProtocolEntrySlimCardComponent,
    ProtocolEntryDetailsDirective,
    ProtocolEntryModalComponent,
    ProtocolEntryEditComponent,
    ProtocolEntryChatComponent,
    ProtocolEntryChatCreateComponent,
    ProtocolEntryStatusPopoverComponent,
    ProtocolEntryChatsComponent,
    ProtocolEntryAttachmentsComponent,
    AttachmentFullScreenViewerComponent,
    PdfProtocolSignaturesComponent,
    PdfProtocolSignatureComponent,
    PdfProtocolSignaturesFooterComponent,
    PdfProtocolSignaturesDialogComponent,
    PdfAdvancedSettingsComponent,
    PdfEmailSettingsBetweenTemplateDirective,
    ProtocolEntryContinuousInfoPopoverComponent,
    ProtocolDetailEditComponent,
    ProtocolFormWeatherComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    MbscModule,
    TranslateModule,
    FontAwesomeModule,
    SelectableInputModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    UiModule,
    PlanMarkerModule,
    PdfViewerModule,
    DownloadModule,
    LicenseModule,
    RouterModule,
    ProjectSelectorModule,
    MainHeaderModule,
    EmailSignatureModule,
    TooltipModule,
    AttachmentViewerModule,
    SharedSvgModule,
    CommonSelectableModule,
    ManageCompanyOrderComponent,
    RxLet,
    RxIf,
    RxVirtualScrollViewportComponent,
    RxVirtualFor,
    RxFor,
    DynamicSizeVirtualScrollStrategy,
    FixedSizeVirtualScrollStrategy,
    InfoPopoverClickDirective,
    InfoPopoverComponent,
    InfoPopoverClickDirective,
    EntryCardListComponent,
    EntryListGroupHeaderTemplateDirective,
    ColorPickerButtonComponent,
    OfflineInfoComponent
  ],
  exports: [
    CurrencyDirective,
    ProtocolEntryComponent,
    ProtocolEntriesComponent,
    ProtocolEntryStatusComponent,
    ProtocolEntryShortIdComponent,
    ProtocolEntryFormComponent,
    ProtocolEntryCreateComponent,
    ProtocolCreateComponent,
    ProtocolEntryPriorityPopoverComponent,
    CommonModule,
    IonicModule,
    TranslateModule,
    MbscModule,
    ProtocolAdditionalFieldsComponent,
    ProtocolFormComponent,
    AttachmentSliderComponent,
    AttachmentViewerModule,
    AudioRecordingComponent,
    ContactEmployeCreateComponent,
    ContactEmployeeFormComponent,
    PdfProtocolSettingsComponent,
    PdfWorkflowComponent,
    PdfMailingListTableComponent,
    PdfMailingListComponent,
    PdfConfigurationComponent,
    PdfEmailSettingsComponent,
    MissingAttachmentsComponent,
    NextMeetingComponent,
    PdfViewerComponent,
    ProjectRoomAttachmentsListComponent,
    ProjectRoomAttachmentsSelectorComponent,
    PdfViewerComponent,
    CopyProtocolEntryComponent,
    CopyProtocolComponent,
    CopyProjectComponent,
    ProtocolShortNameNumberComponent,
    ProtocolEntryEditGroupedMenuPopoverComponent,
    ProtocolEntryLightComponent,
    ProtocolEntryMassEditComponent,
    ProtocolListComponent,
    ProtocolTypeSelectorComponent,
    ProtocolDetailComponent,
    ProtocolEntryPriorityComponent,
    IndividualNextMeetingFormComponent,
    IndividualNextMeetingsComponent,
    NextMeetingFormComponent,
    IndividualNextMeetingsGroupsComponent,
    ProtocolEntrySlimCardComponent,
    ProtocolEntryDetailsDirective,
    ProtocolEntryModalComponent,
    ProtocolEntryEditComponent,
    ProtocolEntryChatComponent,
    ProtocolEntryChatCreateComponent,
    ProtocolEntryStatusPopoverComponent,
    ProtocolEntryChatsComponent,
    ProtocolEntryAttachmentsComponent,
    AttachmentFullScreenViewerComponent,
    PdfProtocolSignaturesComponent,
    PdfProtocolSignatureComponent,
    PdfAdvancedSettingsComponent,
    PdfEmailSettingsBetweenTemplateDirective,
    SharedSvgModule,
    ProtocolEntryContinuousInfoPopoverComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // required for swiperjs
})
export class ProtocolModule { }
