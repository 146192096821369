import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {CompanySource} from 'src/app/model/contacts';
import {PdfPlanWithDeletable} from 'src/app/model/pdf-plan-with-deletable';
import {ContactService} from 'src/app/services/contact/contact.service';
import {PdfPlanVersionAccessDataService} from 'src/app/services/data/pdf-plan-version-access-data.service';
import _ from 'lodash';
import {PdfPlanVersionDataService} from 'src/app/services/data/pdf-plan-version-data.service';
import {PdfPlanVersion, PdfPlanVersionDistributionAccess} from 'submodules/baumaster-v2-common/';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {UserDataService} from 'src/app/services/data/user-data.service';
import {PdfPlanVersionDistributionAccessDataService} from 'src/app/services/data/pdf-plan-version-distribution-access-data.service';
import {CsvExportService} from 'src/app/services/common/csv-export.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {ModalController, Platform, IonicModule} from '@ionic/angular';
import {getCalendarWeek, getWeekRange} from 'src/app/utils/date-utils';
import {PdfPlanHistoryEmailComponent} from './pdf-plan-history-email/pdf-plan-history-email.component';
import moment from 'moment';
import {LanguageService} from 'src/app/services/i18n/language.service';
import {LoggingService} from 'src/app/services/common/logging.service';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {SelectableInputModule} from 'src/app/shared/module/selectable-input/selectable-input.module';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {getUserNameFromAddress} from 'src/app/utils/user-name-utils';
import {EllipsisTooltipDirective} from 'src/app/directives/common/ellipsis-tooltip.directive';
import {TooltipModule} from "../../../shared/module/tooltip/tooltip.module";

interface VersionAccessHistory {
  companyName: string;
  personName: string;
  sentAt: Array<Date | string | undefined> | undefined;
  downloadedAt: Date | string | undefined;
  downloadedVia: 'email' | 'app' | undefined;
  viewedAt: Date | string | undefined;
  connectedUser: boolean;
  email: string | undefined;
  profileId: string;
}

interface PdfPlanVersionDistributionAccessWithCreator extends PdfPlanVersionDistributionAccess {
  creatorName?: string;
}

type SortType = 'companyName' | 'personName' | 'sentAt' | 'downloadedAt' | 'viewedAt';

const LOG_SOURCE = 'PdfPlanHistoryComponent';

@Component({
  selector: 'app-pdf-plan-history',
  templateUrl: './pdf-plan-history.component.html',
  styleUrls: ['./pdf-plan-history.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FontAwesomeModule, FormsModule, TranslateModule, UiModule, SelectableInputModule, EllipsisTooltipDirective, TooltipModule]
})

export class PdfPlanHistoryComponent implements OnInit, OnDestroy {
  @Input()
  pdfPlan: PdfPlanWithDeletable;

  readonly trackByProfile = (versionAccessData: VersionAccessHistory) => versionAccessData?.profileId;

  mode: 'PROJECT_TEAM' | 'HISTORY' = 'PROJECT_TEAM';
  readonly selectedVersionSubject = new BehaviorSubject<PdfPlanVersion|undefined>(undefined);
  readonly selectedVersion$: Observable<PdfPlanVersion|undefined> = this.selectedVersionSubject.asObservable();

  modal: HTMLIonModalElement;
  title: string;
  searchText: string | null | undefined;
  selectedVersionName: string;
  isDesktop = true;
  sortType: SortType = 'companyName';
  sortMode: 'asc' | 'desc' = 'asc';

  private versionAccessData: Array<VersionAccessHistory>;
  private combineSubscription: Subscription | undefined;
  private language = 'en';
  private profile$ = this.userProfileService.currentUserOwnProfile$;
  private company$ = this.companyDataService.dataForOwnClient$.pipe(
    switchMap((companies) => this.profile$.pipe(
      map((profile) => profile ? companies?.find((company) => company.id === profile.companyId) : null)
    ))
  );

  public filteredCompanies: Array<CompanySource> = [];
  public planVersions$: Observable<Array<PdfPlanVersion>> | undefined;
  public filteredVersionAccessData: Array<VersionAccessHistory>;
  public distributionsGroupedByWeek: { key: number, value: PdfPlanVersionDistributionAccessWithCreator[] }[] = [];

  constructor(private pdfPlanVersionAccessDataService: PdfPlanVersionAccessDataService,
              private contactService: ContactService,
              private companyDataService: CompanyDataService,
              private userProfileService: UserProfileService,
              private pdfPlanVersionDataService: PdfPlanVersionDataService,
              private userDataService: UserDataService,
              private pdfPlanVersionDistributionAccessDataService: PdfPlanVersionDistributionAccessDataService,
              private csvExportService: CsvExportService,
              private posthogService: PosthogService,
              private platform: Platform,
              private modalController: ModalController,
              private languageService: LanguageService,
              private loggingService: LoggingService,
              private translateService: TranslateService) { }

  ngOnInit() {
    this.title = this.pdfPlan?.latestPdfPlanVersion?.name;
    this.isDesktop = this.platform.is('desktop');
    this.selectedVersionSubject.next(this.pdfPlan?.latestPdfPlanVersion);
    this.selectedVersionName = this.pdfPlan?.latestPdfPlanVersion.name;

    this.combineSubscription = combineLatestAsync([this.contactService.getSortedCompaniesActiveOnly(),
      this.selectedVersion$.pipe(
        filter((version) => version != null),
        switchMap((version) => this.pdfPlanVersionAccessDataService.getAllByPlanVersionId$(version.id))
      ), this.company$, this.userDataService.data,
      this.selectedVersion$.pipe(
        filter((version) => version != null),
        switchMap(version => this.pdfPlanVersionDistributionAccessDataService.getAllByPlanVersionId$(version.id))
      ), this.languageService.selectedLanguage])
    .subscribe(([companies, versionAccesses, userCompany, users, versionDistAccesses, language]) => {
      companies = companies.map((company) => ({...company, isUserCompany: company.id === userCompany?.id}));
      const filteredCompanies = companies.filter((company) => !!company.projectCompany).map((company) => ({
        ...company,
        employees: company.employees.filter((employee) => !!employee.projectProfile)
      }));
      this.language = language;
      const selectedVersionAccesses = versionAccesses;
      const selectedVersionDistAccesses: Array<PdfPlanVersionDistributionAccessWithCreator> = versionDistAccesses;
      const versionAccessData: Array<VersionAccessHistory> = [];
      for (const company of filteredCompanies) {
        for (const employee of company.employees) {
          if (employee?.user) {
            for (const distAccess of selectedVersionDistAccesses) {
              if (distAccess?.createdById) {
                if (employee.user.id === distAccess.createdById) {
                  distAccess.creatorName = (employee?.firstName ?? '') + ' ' + (employee?.lastName ?? '');
                }
              }
            };
          }
          let downloadedInApp: string | Date | undefined;
          let downloadedInMail: string | Date | undefined;
          const versionAccessHistory: VersionAccessHistory = {
            companyName: company?.name,
            personName: getUserNameFromAddress(this.translateService, employee, employee.profile?.isActive),
            sentAt: [],
            downloadedAt: '',
            downloadedVia: undefined,
            viewedAt: '',
            connectedUser: !_.isEmpty(employee.profile?.attachedToUserId),
            email: employee?.email,
            profileId: employee.profile.id
          };
          const filteredVersionDistAccesses = selectedVersionDistAccesses.filter(versionDistAccess => 
            versionDistAccess?.seenAt && versionDistAccess.profileId === employee.profile.id
          );
          if (filteredVersionDistAccesses.length > 0) {
            downloadedInMail = this.earliestCreatedAtInArray(filteredVersionDistAccesses);
          }
          versionAccessHistory.sentAt.push(...selectedVersionDistAccesses.filter(versionDistAccess => versionDistAccess.profileId === employee.profile.id).map(versionDist => versionDist.createdAt));
          const userId = users.find(user => user.profileId === employee.profile.id)?.id ?? employee.profile?.attachedToUserId;
          if (userId) {
            downloadedInApp = selectedVersionAccesses.find(versionAccess => versionAccess.userId === userId && versionAccess.type === 'download')?.createdAt;
            versionAccessHistory.viewedAt = selectedVersionAccesses.find(versionAccess => versionAccess.userId === userId && versionAccess.type === 'view')?.createdAt;
          }
          if (downloadedInApp && downloadedInMail) {
            versionAccessHistory.downloadedAt = new Date(downloadedInApp) < new Date(downloadedInMail) ? downloadedInApp : downloadedInMail;
            versionAccessHistory.downloadedVia = versionAccessHistory.downloadedAt === downloadedInApp ? 'app' : 'email';
          } else if (downloadedInApp && !downloadedInMail) {
            versionAccessHistory.downloadedAt = downloadedInApp;
            versionAccessHistory.downloadedVia = 'app';
          } else if (!downloadedInApp && downloadedInMail) {
            versionAccessHistory.downloadedAt = downloadedInMail;
            versionAccessHistory.downloadedVia = 'email';
          }
          versionAccessData.push(versionAccessHistory);
        }
      }
      const uniqDists = _.uniqBy(selectedVersionDistAccesses, 'distributionId');
      const distsGroupedByWeek = _.groupBy(uniqDists, dist => getCalendarWeek(new Date(dist.createdAt)));
      this.distributionsGroupedByWeek = Object.entries(distsGroupedByWeek).map(([key, value]) => ({ key: Number(key), value: _.orderBy(value, ['createdAt'], ['desc']) })).reverse();
      this.versionAccessData = versionAccessData;
      this.filteredVersionAccessData = [...this.versionAccessData];
    });

    this.planVersions$ = this.pdfPlanVersionDataService.findByPdfPlan$(this.pdfPlan.id).pipe(
      map(planVersions => planVersions.reverse())
    );
    this.posthogService.captureEvent('[Project-Room][Pdf-Plans] History modal opened', {});
  }

  private earliestCreatedAtInArray<T extends {seenAt?: Date|string}>(arr: T[]): Date {
    return new Date(_.min(arr.map(({seenAt}) => new Date(seenAt).getTime())));
  }

  searchTextChanged() {
    if (_.isEmpty(this.searchText)) {
      this.filteredVersionAccessData = [...this.versionAccessData];
      return;
    }
    this.filteredVersionAccessData = this.versionAccessData.filter((versionAccess) => this.searchableColumnsIncludeSearchText(versionAccess));
  }

  changeSortOrder(sortType: SortType) {
    if (this.sortType === sortType) {
      this.sortMode = this.sortMode === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortMode = 'asc';
    }
    this.sortType = sortType;
    this.filteredVersionAccessData = _.orderBy(this.filteredVersionAccessData, [sortType], [this.sortMode]);
  }

  getWeekRangeFormatted(distAccess: PdfPlanVersionDistributionAccess) {
    const weekRange = getWeekRange(new Date(distAccess.createdAt));
    let formattedWeekRange = '';
    try {
      formattedWeekRange = moment(weekRange[0]).locale(this.language).format('DD. MMMM') + ' - ' + moment(weekRange[1]).locale(this.language).format('DD. MMMM');
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error formatting week range "${convertErrorToMessage(error)}"`);
    }

    return formattedWeekRange;
  }

  private searchableColumnsIncludeSearchText(versionAccessHistory: VersionAccessHistory): boolean {
    return versionAccessHistory.companyName.toLowerCase().includes(this.searchText.toLowerCase()) ||
           versionAccessHistory.personName.toLowerCase().includes(this.searchText.toLowerCase());
  }

  onVersionChange(event) {
    this.selectedVersionSubject.next(event.value);
    this.selectedVersionName = event.value?.name ?? '';
    this.sortType = 'companyName';
    this.sortMode = 'asc';
  }

  async exportDataAsCsv() {
    await this.csvExportService.exportToCsv(this.selectedVersionName.replace('.pdf', '') + '.csv', this.filteredVersionAccessData.map(({connectedUser, ...versionAccess}) => versionAccess));
  }

  async openEmailDetails(distAccess: PdfPlanVersionDistributionAccess) {
    const modal = await this.modalController.create({
      component: PdfPlanHistoryEmailComponent,
      cssClass: 'omg-modal omg-boundary',
      componentProps: {
        distributionId: distAccess.distributionId
      }
    });
    return await modal.present();
  }

  ngOnDestroy() {
    this.combineSubscription?.unsubscribe();
    this.combineSubscription = undefined;
  }

  async dismiss() {
    await this.modal.dismiss();
  }

}
