<ng-container [formGroup]="protocolForm">
  <ion-grid class="grid-gap-4 omg-form">
    <ion-row>
      <ion-col size="12" *ngIf="showInfo">
        <div class="disclaimer" *ngIf="getFromAPIBoolean()">
          {{ "reports.weatherDisclaimer" | translate }}
        </div>
        <ion-col size="12">
          <ion-button *ngIf="!getFromAPIBoolean()" class="omg-btn-secondary weather-api-button" (click)="callWeatherFunc()">
            <fa-icon slot="start" [icon]="['fal', 'sun-cloud']"></fa-icon>
            <span>{{
              "reports.loadData" | translate
            }}</span>
          </ion-button>
          <ion-button *ngIf="getFromAPIBoolean()" class="omg-btn-secondary weather-api-button" (click)="resetWeather()">
            <fa-icon slot="start" [icon]="['fal', 'sun-cloud']"></fa-icon>
            <span>{{
              "reports.resetData" | translate
            }}</span>
          </ion-button>
        </ion-col>
        <div class="weather-section">
          <div class="spinner-section" *ngIf="fetchingWeatherData">
            <ion-spinner></ion-spinner>
          </div>
          <div class="padding-bottom-10">
            <ion-item [disabled]="getFromAPIBoolean()">
              <ion-label position="floating">{{
                "reports.overallWeatherCondition" | translate
              }}</ion-label>
              <ionic-selectable
                formControlName="weather"
                [isEnabled]="!getFromAPIBoolean()"
                item-content
                itemTextField="name"
                [items]="weathers"
                itemValueField="id"
                searchPlaceholder="{{ 'search' | translate }}"
                searchFailText="{{ 'noItemsFound' | translate }}"
                closeButtonText="{{ 'cancel' | translate }}"
                [shouldStoreItemValue]="true"
              >
                <ng-template ionicSelectableItemTemplate let-weather="item">
                  <fa-icon
                    class="ion-padding-end"
                    [icon]="['fal', weather.icon]"
                  ></fa-icon>
                  <ion-label>{{ weather.name }}</ion-label>
                </ng-template>
                <ng-template ionicSelectableItemIconTemplate let-selected="isItemSelected">
                  <app-selectable-item-icon [selected]="selected"></app-selectable-item-icon>
                </ng-template>
                <ng-template ionicSelectableValueTemplate let-value="value">
                  <app-selectable-value [value]="value"></app-selectable-value>
                </ng-template>
                <ng-template ionicSelectableHeaderTemplate>
                  <app-selectable-header></app-selectable-header>
                </ng-template>
                <ng-template ionicSelectableFooterTemplate>
                  <app-selectable-footer></app-selectable-footer>
                </ng-template>
              </ionic-selectable>
            </ion-item>
          </div>

          <div class="temperature-container" *ngIf="getFromAPIBoolean()">
            <ion-item>
              <ion-input label="{{('reports.min' | translate) + ' ' + ('reports.degC' | translate)}}"
                         [labelPlacement]="minTempSet() ? 'floating' : 'fixed'"
                         formControlName="temperatureMin" [readonly]="true"></ion-input>
            </ion-item>
            <ion-item>
              <ion-input label="{{('reports.max' | translate) + ' ' + ('reports.degC' | translate)}}"
                         [labelPlacement]="maxTempSet() ? 'floating' : 'fixed'"
                         formControlName="temperatureMax" [readonly]="true"></ion-input>
            </ion-item>
          </div>

          <div class="temperature-container" *ngIf="!getFromAPIBoolean()">
            <ion-item>
              <ion-label position="floating">{{('reports.min' | translate) + ' ' + ('reports.degC' | translate)}}</ion-label>
              <ionic-selectable #temperatureMinSelectable
                                id="temperatureMinSelectable"
                                [isEnabled]="!this.isFromAPI"
                                [items]="minValues"
                                [isMultiple]="false"
                                [isConfirmButtonEnabled]="false"
                                formControlName="temperatureMin"
                                item-content
                                appSelectableCommon
                                [canClear]="true"
                                [canSearch]="true"
                                (onOpen)="onOpen($event, minValues)"
                                (onClose)="onClose($event)"
                                (onSelect)="onTemperatureMinChange($event)"
                                placeholder="{{('reports.min' | translate) + ' ' + ('reports.degC' | translate)}}"
                                modalCssClass="temperature-selectable"
                                #fs="appSelectableCommon"
                                >
                <ng-template ionicSelectableItemIconTemplate>
                  <!-- no icon -->
                </ng-template>
                <ng-template ionicSelectableItemTemplate let-value="item">
                  <span class="temperatureItem" [ngClass]="{'temperatureItemSelected': +protocolForm.controls.temperatureMin.value === +value }">{{value}}</span>
                </ng-template>
                <ng-template ionicSelectableHeaderTemplate>
                  <!-- no header -->
                </ng-template>
                <ng-template ionicSelectableFooterTemplate>
                  <app-selectable-footer [canClear]="true" [autoCloseOnSelect]="false"></app-selectable-footer>
                </ng-template>
              </ionic-selectable>
            </ion-item>
            <ion-item>
              <ion-label position="floating">{{('reports.max' | translate) + ' ' + ('reports.degC' | translate)}}</ion-label>
              <ionic-selectable #temperatureMaxSelectable
                                [isEnabled]="!this.isFromAPI"
                                [items]="maxValues"
                                [isMultiple]="false"
                                [isConfirmButtonEnabled]="false"
                                formControlName="temperatureMax"
                                item-content
                                appSelectableCommon
                                [canClear]="true"
                                [canSearch]="true"
                                (onOpen)="onOpen($event, maxValues)"
                                (onClose)="onClose($event)"
                                (onSelect)="onTemperatureMaxChange($event)"
                                placeholder="{{('reports.max' | translate) + ' ' + ('reports.degC' | translate)}}"
                                modalCssClass="temperature-selectable"
                                #fs="appSelectableCommon">
                <ng-template ionicSelectableItemIconTemplate>
                  <!-- no icon -->
                </ng-template>
                <ng-template ionicSelectableItemTemplate let-value="item">
                  <span class="temperatureItem" [ngClass]="{'temperatureItemSelected': +protocolForm.controls.temperatureMax.value === +value }">{{value}}</span>
                </ng-template>
                <ng-template ionicSelectableHeaderTemplate>
                  <!-- no header -->
                </ng-template>
                <ng-template ionicSelectableFooterTemplate>
                  <app-selectable-footer [canClear]="true" [autoCloseOnSelect]="true"></app-selectable-footer>
                </ng-template>
              </ionic-selectable>
            </ion-item>
          </div>
        </div>

        <div class="humid-wind-container">
          <ion-item [disabled]="readonly">
            <ion-input label="{{('reports.humidity' | translate) + ' ' + ('reports.percent' | translate)}}" label-placement="floating" formControlName="humidity" [readonly]="getFromAPIBoolean()"></ion-input>
          </ion-item>
          <ion-item [disabled]="readonly">
            <ion-input label="{{('reports.windspeed' | translate) + ' ' + ('reports.kph' | translate)}}" label-placement="floating" formControlName="windspeed" [readonly]="getFromAPIBoolean()"></ion-input>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>