<app-modal-header>
    <ion-title> {{ protocol?.name }}  </ion-title>
    <ion-title *ngIf="!enabledForm">
      {{'protocol.closedAt' | translate}} {{ protocol.closedAt | date: 'dd.MM.yyyy - HH:mm' }}
    </ion-title>
</app-modal-header>

<ion-content class="with-padding">
    <app-protocol-form
    *ngIf="protocol"
    [(formDirty)]="protocolFormDirty"
    [(form)]="protocolForm"
    [enabledForm]="enabledForm && (isEditEnabled$ | async)"
    [protocol]="protocol"
    [protocolData]="protocolData"
    (navigateAway)="handleNavigateAway($event)"></app-protocol-form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="start">
    <ion-button class="omg-btn-secondary omg-btn-color-danger" (click)="deleteProtocol()" [class.look-disabled]="!(isDeletableObservable | async)" [class.omg-btn-color-danger]="isDeletableObservable | async">
      {{ 'button.delete' | translate }}
    </ion-button>
  </ion-buttons>
    <ion-buttons slot="end" class="omg-form">
      <ion-button (click)="dismissModal()" class="omg-btn-text">{{ 'cancel' | translate }}</ion-button>
      <ion-button [disabled]="!protocolFormDirty.dirty || !protocolForm.valid" (click)="saveProtocol()" class="omg-btn-primary">{{ 'button.save' | translate }}</ion-button>
    </ion-buttons>
</app-modal-footer>
