import { ColumnConfig } from '../ColumnConfig';


const UniqueEventRecipient = ['notification_event_id', 'profile_id'];

export const NotificationEventRecipientColumnSet: Array<ColumnConfig> = [
  { name: 'id', prop: 'id', cnd: true },
  { name: 'notification_event_id', prop: 'notificationEventId', uniqueWith: [UniqueEventRecipient] },
  { name: 'profile_id', prop: 'profileId', uniqueWith: [UniqueEventRecipient] },
  { name: 'seen_at', prop: 'seenAt', cast: 'Timestamp' },
  { name: 'created_at', prop: 'createdAt', cast: 'Timestamp' },
  { name: 'changed_at', prop: 'changedAt', cast: 'Timestamp' }
];
