<app-modal-header>
  <ion-title>{{'usernameUpdate.title' | translate}}</ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <form class="omg-form" [formGroup]="userForm" action="#">
    <ion-item *ngIf="networkConnected === false" lines="none" class="warning">
      {{'clientNameUpdate.noNetwork' | translate}}
    </ion-item>
    <ion-item *ngIf="userForm.controls.name.errors?.networkError" lines="none" class="error-message">
      {{'errorNetwork' | translate}}
    </ion-item>
    <ion-item>
      <ion-input label="{{ 'account_settings.username' | translate }}" label-placement="floating"
                 color="text-primary" formControlName="name" maxlength="150" type="text" required #username [pattern]="emailRegExpPattern" class="omg-required"></ion-input>
    </ion-item>
    <span *ngIf="userForm.controls.name.errors?.required && userForm.controls.name.dirty" class="error-message">
      {{ 'formValidation.emptyField' | translate:{field: 'account_settings.username' | translate} }}
    </span>
    <span *ngIf="userForm.controls.name.errors?.pattern && userForm.dirty" class="error-message">
      {{ 'formValidation.invalidEmail' | translate }}
    </span>
    <span *ngIf="userForm.controls.name.errors?.nameExists && userForm.controls.name.dirty" class="error-message">
      {{ 'usernameUpdate.errorNameExists' | translate }}
    </span>
  </form>
</ion-content>

<app-modal-footer>
  <ion-buttons slot="end">
    <ion-button class="omg-btn-text" (click)="cancel()">{{'cancel' | translate}}</ion-button>
    <ion-button class="omg-btn-primary" (click)="save()" [disabled]="saving || !savePossibleAndNecessary()">
      <ion-spinner slot="start" class="spinner-center-button" *ngIf="saving"></ion-spinner>
      <span>{{ 'button.save' | translate }}</span>
    </ion-button>
  </ion-buttons>
</app-modal-footer>
