<form class="omg-form" [formGroup]="contactEmployeeForm" action="#">
  <ion-grid class="grid-gap-4">
    <ion-row>
      <ion-col size="12">
        <div class="omg-modal-header">
          {{ "contactForm.personalData" | translate }}
        </div>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-input label="{{ 'contactForm.firstName' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="firstName" maxlength="50" type="text" required class="omg-required"></ion-input>
        </ion-item>
        <span *ngIf="contactEmployeeForm.controls.firstName.errors && contactEmployeeForm.controls.firstName.dirty"
              class="error-message">
          {{ 'formValidation.emptyField' | translate:{field: 'contactForm.firstName' | translate} }}
        </span>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-input label="{{ 'contactForm.lastName' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="lastName" maxlength="50" type="text" required class="omg-required"></ion-input>
        </ion-item>
        <span *ngIf="contactEmployeeForm.controls.lastName.errors && contactEmployeeForm.controls.lastName.dirty"
              class="error-message">
          {{ 'formValidation.emptyField' | translate:{field: 'contactForm.lastName' | translate} }}
        </span>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isCurrentUser">
      <ion-col size="12" size-sm="6">
        <ion-item (click)="changeUsername()" class="cursor-pointer">
          <ion-input label="{{ 'account_settings.username' | translate }}" label-placement="floating" readonly [value]="username" class="cursor-pointer username-input omg-required"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <div class="omg-modal-header">
          {{ "contactForm.company" | translate }}
        </div>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-label position="floating">{{ 'contactForm.companyName' | translate }}</ion-label>
          <app-selectable
              id="companyIonicSelectable"
              formControlName="companyName"
              [items]="projectCompanies | async"
              itemValueField="id"
              itemTextField="name"
              (onChange)="companyChanged($event)"
              [allowEmptyValue]="false"
              [isEnabled]="(addressId | isEmpty) && !readonly"
              [allowCreateNew]="true"
              [createNewFunction]="createNewCompanyFunction"
              [assignToProjectFunction]="assignCompanyToProjectFunction"
              [itemTemplate]="companyItemTemplate"
          >
            <ng-template #companyItemTemplate let-company="item">
              <fa-icon class="ion-padding-end" [icon]="['fal','bullhorn']" *ngIf="company.id === null"></fa-icon>
              <ion-label [class.bold]="company.id === null">{{company.name}}</ion-label>
            </ng-template>
          </app-selectable>
        </ion-item>
        <span *ngIf="contactEmployeeForm.controls.companyName.errors && contactEmployeeForm.controls.companyName.dirty"
              class="error-message">
          {{ 'formValidation.emptyField' | translate:{field: 'contactForm.companyName' | translate} }}
        </span>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-input label="{{ 'contactForm.position' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="role" type="text"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-label position="floating">{{ 'contactForm.companyCraft' | translate }}</ion-label>
          <app-selectable
              id="craftIonicSelectable"
              formControlName="crafts"
              [items]="craftsWithinCompany$ | async"
              itemValueField="id"
              itemTextField="name"
              [isMultiple]="true"
              (onChange)="craftsOnChange($event)"
              [isEnabled]="!readonly"
              [assignToProjectFunction]="assignCraftToProjectFunction"
              [itemTemplate]="companyItemTemplate"
          >
            <ng-template #companyItemTemplate let-company="item">
              <fa-icon class="ion-padding-end" [icon]="['fal','bullhorn']" *ngIf="company.id === null"></fa-icon>
              <ion-label [class.bold]="company.id === null">{{company.name}}</ion-label>
            </ng-template>
          </app-selectable>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <div class="omg-modal-header">
          {{ "contactForm.contactData" | translate }}
        </div>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-input label="{{ 'contactForm.mobileNumber' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="mobile" maxlength="25" [pattern]="phoneNumberPattern"
                     type="tel"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-input label="{{ 'contactForm.companyNumber' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="phone" maxlength="25" [pattern]="phoneNumberPattern"
                     type="tel"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-input label="{{ 'contactForm.email' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="email" maxlength="60" type="email" [pattern]="emailRegExpPattern"></ion-input>
        </ion-item>
        <span *ngIf="contactEmployeeForm.controls.email.errors && contactEmployeeForm.dirty" class="error-message">
          {{ 'formValidation.invalidEmail' | translate }}
        </span>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item [disabled]="readonly">
          <ion-input label="{{ 'contactForm.fax' | translate }}" label-placement="floating" [readonly]="readonly" formControlName="fax" maxlength="30" [pattern]="phoneNumberPattern"
                     type="tel"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-item [disabled]="readonly">
          <ion-textarea label="{{ 'contactForm.notes' | translate }}" label-placement="floating" [readonly]="readonly" auto-grow="true" formControlName="note" rows="1"
                        type="text"></ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <div class="omg-modal-header">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col>
                <ion-label class="ion-no-margin">{{ "contactForm.public_data" | translate }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-note>{{ "contactForm.public_data_description" | translate }}</ion-note>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item class="omg omg-form-checkbox" [disabled]="readonly">
         <ion-checkbox label-placement="end" justify="start" [disabled]="readonly" formControlName="dsgvoShowEmail">
           {{ 'contactForm.email' | translate }}
         </ion-checkbox>
        </ion-item>
      </ion-col>
      <ion-col size="12" size-sm="6">
        <ion-item class="omg omg-form-checkbox" [disabled]="readonly">
         <ion-checkbox label-placement="end" justify="start" [disabled]="readonly" formControlName="dsgvoShowTel">
           {{ 'contactForm.mobileNumber' | translate }}
         </ion-checkbox>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
