<app-modal-header *ngIf="!expressView" (close)="capturePosthogEvent('taskAmount')">
  <ion-title *ngIf="!parentEntryId && !isTask">{{ 'newEntry' | translate }}</ion-title>
  <ion-title *ngIf="parentEntryId && !isTask">{{ 'newSubentry' | translate }}</ion-title>
  <ion-title *ngIf="!parentEntryId && isTask">{{ 'tasks.newtask' | translate }}</ion-title>
  <ion-title *ngIf="parentEntryId && isTask">{{ 'tasks.newsubtask' | translate }}</ion-title>
</app-modal-header>

<app-modal-header *ngIf="expressView" (close)="capturePosthogEvent('taskAmount')">
  <ion-title>
    <ion-button (click)="showFullScreen()" class="show-full-screen-btn omg-btn-text">
      <span>
        <fa-icon icon="chevron-down"></fa-icon> <br/>
        <span *ngIf="!parentEntryId && !isTask">{{ 'newEntry' | translate }} > {{ 'details' | translate }}</span>
        <span *ngIf="parentEntryId && !isTask">{{ 'newSubentry' | translate }} > {{ 'details' | translate }}</span>
        <span *ngIf="!parentEntryId && isTask">{{ 'tasks.newtask' | translate }} > {{ 'details' | translate }}</span>
        <span *ngIf="parentEntryId && isTask">{{ 'tasks.newsubtask' | translate }} > {{ 'details' | translate }}</span>
      </span>
    </ion-button>
  </ion-title>
</app-modal-header>

<ion-content class="with-padding">
  <ion-grid class="omg-form grid-gap-4">
    <ion-row>
      <ion-col>
        <app-protocol-entry-form #protocolEntryFormComponent
        [onlyActionableEntryTypes]="onlyActionableEntryTypes"
        [defaultEntryType]="defaultEntryType"
        [typeRequired]="typeRequired"
        [protocolId]="protocolId"
        [expressView]="expressView"
        [(formDirty)]="protocolEntryFormDirty"
        (formChange)="protocolEntryForm = $event"
        (formDataChange)="checkCreationFormMet($event)"
        (additionalFieldsChange)="onAdditionalFieldsChange($event)"
        [acrossProjects]="false"
        [isChild]="!!parentEntryId"
        [shouldFocusOnTitleInput]="!isInStartWithWorkflow"
        ></app-protocol-entry-form>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!expressView">
      <ion-col size="12">
        <ion-item lines="none">
          <div class="omg-modal-section-header">{{'attachments' | translate}}</div>
          <ion-badge>{{attachments.length}}</ion-badge>
        </ion-item>
        <ion-col>
          <app-attachment-slider (markingsChanged)="onMarkingsChanged($event.attachment, $event.markings)" [allowEditMarkings]="true" [attachmentsObservable]="attachmentsObservable" [attachments]="attachments"
          allowDeleting="true" (attachmentDeleted)="onAttachmentDeleted($event.attachment)"></app-attachment-slider>
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<app-modal-footer>
    <ion-buttons slot="start">
      <ion-button (click)="addPdfPlanMarker()" *ngIf="!pdfPlanMarkers?.length && !modifiedMarkings?.new?.length && !modifiedSketching" class="omg-btn-secondary ion-hide-sm-down">
        <fa-icon slot="start" class="icon-size" [icon]="['fal','map-marker-alt']"></fa-icon>
        <span class="ion-hide-xl-down">{{'PlanMarker' | translate}}</span>
      </ion-button>
      <ion-button (click)="addPdfPlanMarker()" *ngIf="pdfPlanMarkers?.length || modifiedMarkings?.new?.length || modifiedSketching" class="omg-btn-secondary ion-hide-sm-down">
        <fa-icon slot="start" class="icon-size" [icon]="['fas','map-marker-alt']"></fa-icon>
        <span class="ion-hide-xl-down">{{'PlanMarker' | translate}}</span>
      </ion-button>

      <ion-button (click)="addBimMarker()" class="omg-btn-secondary ion-hide-sm-down">
        <div>
          <span class="bim-icon" [class.marker-applied]="isBimMarkerApplied">BIM</span>
        </div>
        <span class="ion-hide-xl-down bim-marker-button">{{'marker' | translate}}</span>
      </ion-button>

      <ion-button class="omg-btn-secondary ion-hide-sm-up" (click)="openPopoverMenu($event)">
        <ion-badge class="count-badge" *ngIf="projectRoomAttachments.length + blankPictureAttachments.length + audioAttachments.length > 0">
          {{projectRoomAttachments.length + blankPictureAttachments.length + audioAttachments.length}}
        </ion-badge>
        <fa-icon slot="start" class="icon-size" [icon]="['fal','ellipsis-h']"></fa-icon>
      </ion-button>

      <ion-button class="omg-btn-secondary ion-hide-sm-down" (click)="fileUpload.click()">
        <ion-badge class="count-badge" *ngIf="uploadedAttachments.length > 0">{{ uploadedAttachments.length }}</ion-badge>
        <fa-icon slot="start" class="icon-size" [icon]="['fal','paperclip']"></fa-icon>
        <span class="ion-hide-xl-down">{{'file' | translate}}</span>
      </ion-button>
      <input type="file" [accept]="acceptedMimeTypesForUpload" (change)="uploadFileEvent($event)" multiple #fileUpload class="ion-hide">

      <ion-button class="omg-btn-secondary ion-hide-sm-down" (click)="projectRoomAttachmentsSelector()">
        <ion-badge class="count-badge" *ngIf="projectRoomAttachments.length > 0">{{ projectRoomAttachments.length }}</ion-badge>
        <fa-icon slot="start" class="icon-size" [icon]="['fal','cube']"></fa-icon>
        <span class="ion-hide-xl-down">{{'Attachments' | translate}}</span>
      </ion-button>

      <ion-button class="omg-btn-secondary ion-hide-sm-down" (click)="openSketchingTool()">
        <ion-badge class="count-badge" *ngIf="blankPictureAttachments.length > 0">{{ blankPictureAttachments.length }}</ion-badge>
        <fa-icon slot="start" class="icon-size" [icon]="['fal','signature']"></fa-icon>
        <span class="ion-hide-xl-down">{{'drawing' | translate}}</span>
      </ion-button>

      <ion-button class="omg-btn-secondary" (click)="takePicture()" *ngIf="takingPhotosSupported">
        <ion-badge class="count-badge" *ngIf="pictureAttachments.length > 0">{{ pictureAttachments.length }}</ion-badge>
        <fa-icon slot="start" class="icon-size" [icon]="['fal','camera']"></fa-icon>
      </ion-button>

      <ion-button class="omg-btn-secondary" (click)="takePictures()" *ngIf="takingPhotosSupported">
        <ion-badge class="count-badge" *ngIf="pictureAttachments.length > 0">{{ pictureAttachments.length }}</ion-badge>
        <fa-icon slot="start" class="icon-size" [icon]="['bau','foto-series']"></fa-icon>
      </ion-button>

      <ion-button (click)="startAudioRecording()" class="omg-btn-secondary ion-hide-sm-down" *ngIf="audioRecordingSupported">
        <ion-badge class="count-badge" *ngIf="audioAttachments.length > 0">{{ this.audioAttachments.length }}</ion-badge>
        <fa-icon [icon]="['fal','microphone']" class="icon-size"></fa-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" class="omg-form no-padding-if-button-cut-off">
      <ion-item class="omg omg-form-checkbox" (click)="copyData = !copyData">
       <ion-checkbox label-placement="end" justify="start" [ngModel]="copyData">
         <span class="ion-hide-md-down">{{ 'series' | translate }}</span>
         <span class="ion-hide-md-up"><fa-icon [icon]="['fal', 'layer-plus']"></fa-icon></span>
       </ion-checkbox>
      </ion-item>

      <ion-button class="omg-btn-primary" [disabled]="!protocolEntryFormDirty || !isRequiredValueMet || loading || (typeRequired && protocolEntryForm?.controls.type.invalid)" (click)="saveDismiss()">
        <ion-spinner slot="start" class="spinner-center-button" *ngIf="loading"></ion-spinner>
        <span class="">{{ 'button.create' | translate }}</span>
      </ion-button>
    </ion-buttons>
</app-modal-footer>
