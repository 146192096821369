<ion-item ph-custom-id="entry-form-additional-field">
  <ion-label position="floating">{{ (clientData | async)?.nameableDropdownName }}</ion-label>
  <app-selectable #additionalField
                  [recentlyUsedKey]="acrossProjects ? undefined : 'additionalField'"
                  [items]="dropdownList"
                  [disabledItems]="disabledDropdownList"
                  [allItems]="dropdownListAll"
                  [isEnabled]="!disabled"
                  [(ngModel)]="selectedItem"
                  (onChange)="onAdditionalFieldsChange($event)"
                  itemValueField="id"
                  itemTextField="name"
                  [allowCreateNew]="allowCreatedProjectSettings$ | async"
                  [createNewFunction]="createNewAdditionalFieldFunction"
                  [assignToProjectFunction]="assignAdditionalFieldToProjectFunction"
                  [messageString]="'selectable.custom' | translate"
  >
  </app-selectable>
</ion-item>
