<form class="omg-form" [formGroup]="protocolEntryForm" action="#">
  <ng-template #reminderAtTemplate>
    <ion-item (click)="notImplemented()">
      <ion-label position="floating"><fa-icon [fixedWidth]="true" [icon]="['fal', 'alarm-clock']" ></fa-icon> {{ 'Reminder' | translate }}</ion-label>
      <ion-datetime
        [cancelText]="'cancel' | translate"
        [doneText]="'done' | translate"
        [readonly]="true" class="danger"
        displayFormat="DD.MM.YYYY | HH:mm"
        formControlName="reminderAt"></ion-datetime>
    </ion-item>
  </ng-template>

  <ng-template #titleTextareaTemplate>
    <ion-item lines="none" ph-custom-id="entry-form-title">
      <ion-textarea [label]="!protocolEntryForm.get('title')?.value ? ('protocolEntry.title' | translate) : ''" label-placement="floating"
                    [readonly]="readonly || closeForm" #titleInput auto-grow formControlName="title" rows="1" type="text" spellcheck="true" autocomplete="on" [class.omg-required]="!protocolEntryForm.get('title')?.value"> </ion-textarea>
    </ion-item>
  </ng-template>

  <ng-template #titleInputTemplate>
    <ion-item ph-custom-id="entry-form-title">
      <ion-input [label]=" !protocolEntryForm.get('title')?.value ? ('protocolEntry.title' | translate) : ''" label-placement="floating"
                 (keydown.enter)="$event.preventDefault()" [readonly]="readonly || closeForm" #titleInput auto-grow rows="1" formControlName="title" maxLength="65" type="text"
                 [class.omg-required]="!protocolEntryForm.get('title')?.value" [ngClass]="{'heading-primary size-l': !expressView}" spellcheck="true" autocomplete="on"></ion-input>
    </ion-item>
  </ng-template>

  <ng-template #priorityButtonTemplate>
    <ion-item lines="none" class="ion-float-end">
      <ion-buttons>
        <ion-button [class]="'app-default-priority app-priority-' + selectedPriorityId" (click)="showPriorityPopOver($event)">
          <fa-icon [icon]="['bau', 'flag']"></fa-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ng-template>

  <ng-template #internalAssignmentIdTemplate>
    <ion-item ph-custom-id="entry-form-internalAssign">
      <ion-label position="floating">
        <fa-icon [fixedWidth]="true" [icon]="['fal','user']"></fa-icon> {{ 'internalAssignment' | translate }}
      </ion-label>
      <app-selectable
          formControlName="internalAssignmentId"
          [recentlyUsedKey]="acrossProjects ? undefined : 'user'"
          [items]="companyAddresses | orderBy:['isActive', 'name']:['desc', 'asc']"
          [allItems]="companyAddressesAll"
          [disabledItems]="disabledCompanyAddresses"
          itemTextField="name"
          itemValueField="id"
          [isEnabled]="!readonly && protocolEntryForm.get('company').value?.id !== null"
          [allowCreateNew]="allowCreatedProjectSettings$ | async"
          [createNewFunction]="createNewProfileFunction"
          [assignToProjectFunction]="assignProfileToProjectFunction"
          [messageString]="'selectable.internal' | translate"
      >
      </app-selectable>
    </ion-item>
  </ng-template>

  <ng-template #typeTemplate>
    <ion-item ph-custom-id="entry-form-type">
      <ion-label position="floating" [class.omg-required]="typeRequired"><fa-icon [fixedWidth]="true" [icon]="['fal', 'tag']"></fa-icon> {{ 'Type' | translate }}</ion-label>
      <app-selectable
          formControlName="type"
          [recentlyUsedKey]="acrossProjects ? undefined : 'protocolEntryType'"
          [items]="protocolEntryTypeData | async"
          [disabledItems]="disabledProtocolEntryTypeData |async"
          [allItems]="protocolEntryTypeDataAll | async"
          itemValueField="id"
          itemTextField="name"
          [isEnabled]="!readonly && !closeForm"
          [allowCreateNew]="allowCreatedProjectSettings$ | async"
          [createNewFunction]="createNewProtocolEntryTypeFunction"
          [assignToProjectFunction]="assignProtocolEntryTypeToProjectFunction"
          [messageString]="'selectable.entryType' | translate"
          [canClear]="!defaultEntryType && !typeRequired"
      >
      </app-selectable>
    </ion-item>
  </ng-template>

  <ng-template #todoUntilTemplate>
    <ion-item ph-custom-id="entry-form-todo">
      <ion-input label-placement="floating"
        #todoUntilDatepicker="mobiscroll"
        [readonly]="readonly"
        appMbscCalendar
        formControlName="todoUntil"
        [dateFormat]="MBSC_DATE_FORMAT"
        [showWeekNumbers]="true"
        (onClose)="closeCalender()"
        [firstSelectDay]="1"
        [firstDay]="1"
        [min]="protocolEntryForm.get('startDate')?.value"
        [setText]="'done' | translate"
        [cancelText]="'cancel' | translate"
        [buttons]="[ 'cancel', {text: 'reset' | translate, handler: resetTodoUntil}, 'set' ]"
        mbsc-datepicker
        [controls]="['calendar']"
        [locale]="mbscLocale$ | async"
        [themeVariant]="mbscThemeVariant$ | async"
        theme="windows"
        [mbscOptions]="{disabled: readonly}">
        <div slot="label">
          <fa-icon [fixedWidth]="true" [icon]="['fal', 'calendar-alt']"></fa-icon> {{ 'protocolEntry.todoUntil' | translate }}
        </div>
      </ion-input>
    </ion-item>
  </ng-template>

  <ng-template #startDateTemplate>
    <ion-item ph-custom-id="entry-form-startDate">
      <ion-input label-placement="floating"
        #startDateDatepicker="mobiscroll"
        [readonly]="readonly"
        (onClose)="closeCalender()"
        appMbscCalendar
        formControlName="startDate"
        [dateFormat]="MBSC_DATE_FORMAT"
        [showWeekNumbers]="true"
        [firstSelectDay]="1"
        [firstDay]="1"
        [max]="protocolEntryForm.get('todoUntil')?.value"
        [setText]="'done' | translate"
        [cancelText]="'cancel' | translate"
        [buttons]="[ 'cancel', {text: 'reset' | translate, handler: resetStartDate}, 'set' ]"
        [locale]="mbscLocale$ | async"
        [themeVariant]="mbscThemeVariant$ | async"
        theme="windows"
        mbsc-datepicker
        [controls]="['calendar']"
        [mbscOptions]="{disabled: readonly}">
        <div slot="label"><fa-icon [fixedWidth]="true" [icon]="['fal', 'calendar-alt']"></fa-icon> {{ 'protocolEntry.startDate' | translate }}</div>
      </ion-input>
    </ion-item>
  </ng-template>

  <ng-template #locationTemplate>
    <ion-item ph-custom-id="entry-form-location">
      <ion-label position="floating"><fa-icon [fixedWidth]="true" [icon]="['fal', 'map']"></fa-icon> {{ 'Location' | translate }}</ion-label>
      <app-selectable
          formControlName="location"
          [recentlyUsedKey]="acrossProjects ? undefined : 'location'"
          [items]="protocolEntryLocationData | async"
          [disabledItems]="disabledProtocolEntryLocationData | async"
          [allItems]="protocolEntryLocationDataAll | async"
          itemValueField="id"
          itemTextField="location"
          [isEnabled]="!readonly && !closeForm"
          [allowCreateNew]="allowCreatedProjectSettings$ | async"
          [createNewFunction]="createNewLocationFunction"
          [assignToProjectFunction]="assignLocationToProjectFunction"
          [messageString]="'selectable.location' | translate"
      >
      </app-selectable>
    </ion-item>
  </ng-template>

  <ng-template #craftTemplate>
    <ion-item ph-custom-id="entry-form-craft">
      <ion-label position="floating"><fa-icon [fixedWidth]="true" [icon]="['fal', 'tools']"></fa-icon> {{ 'Category' | translate }}</ion-label>
      <app-selectable
          formControlName="craft"
          [recentlyUsedKey]="acrossProjects ? undefined : 'craft'"
          [items]="craftData | async"
          [allItems]="craftDataAll | async"
          [disabledItems]="disabledCraftData | async"
          itemValueField="id"
          itemTextField="name"
          [isEnabled]="!readonly && !closeForm"
          [allowCreateNew]="allowCreatedProjectSettings$ | async"
          [createNewFunction]="createNewCraftFunction"
          [assignToProjectFunction]="assignCraftToProjectFunction"
          [messageString]="'selectable.craft' | translate"
      >
      </app-selectable>
    </ion-item>
  </ng-template>

  <ng-template #observerCompaniesIonicSelectableTemplate>
    <ion-item class="protocol-entry-form-observer-companies-field" ph-custom-id="entry-form-observer">
      <ion-label position="floating">
        <fa-icon [fixedWidth]="true" [icon]="['fal','city']"></fa-icon> {{ 'observerCompanies' | translate }}
      </ion-label>
      <app-selectable
          id="observerCompaniesIonicSelectable"
          [recentlyUsedKey]="acrossProjects ? undefined : 'company'"
          formControlName="observerCompanies"
          [items]="observerCompanyData | async"
          [allItems]="observerCompanyDataAll | async"
          [disabledItems]="disabledCompanies"
          itemValueField="id"
          itemTextField="name"
          [shouldStoreItemValue]="true"
          [isEnabled]="!closeForm && !isProtocolLayoutShort && !readonly && !isChildEntry"
          [allowCreateNew]="allowCreatedProjectSettings$ | async"
          [isMultiple]="true"
          [createNewFunction]="createNewCompanyFunction"
          [assignToProjectFunction]="assignCompanyToProjectFunction"
          [valueTemplate]="observableCompanyValueTemplate"
          [itemTemplate]="observableCompanyItemTemplate"
          [messageString]="'selectable.observer' | translate"
      >
        <ng-template #observableCompanyItemTemplate let-company="item">
          <ion-label>{{company | companyNameString:company.isRemoved}}</ion-label>
        </ng-template>
        <ng-template #observableCompanyValueTemplate let-companies="value">
          <ng-container *ngFor="let company of companies; let last = last">
            {{(companyById$|async)?.[company] | companyNameString:(!((isCompanyInProject$|async)?.[company]))}}<ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </ng-template>
      </app-selectable>
    </ion-item>
  </ng-template>

  <ng-template #compnayIonicSelectableTemplate>
    <ion-item ph-custom-id="entry-form-company">
      <ion-label position="floating" [class.required]="clientData?.companyFieldRequired === true">
        <fa-icon [fixedWidth]="true" [icon]="['fal','building']"></fa-icon> {{ 'Company' | translate }}
      </ion-label>
      <app-selectable
          id="companyIonicSelectable"
          [recentlyUsedKey]="acrossProjects ? undefined : 'company'"
          formControlName="company"
          (onChange)="companyChange($event)"
          [items]="companyData | async"
          [allItems]="companyDataAll | async"
          [disabledItems]="disabledCompanies"
          itemValueField="id"
          itemTextField="name"
          [isEnabled]="!closeForm && !isProtocolLayoutShort && !readonly"
          [allowCreateNew]="allowCreatedProjectSettings$ | async"
          [createNewFunction]="createNewCompanyFunction"
          [assignToProjectFunction]="assignCompanyToProjectFunction"
          [itemTemplate]="companyItemTemplate"
          [valueTemplate]="companyValueTemplate"
          [messageString]="'selectable.company' | translate"
      >
        <ng-template #companyItemTemplate let-company="item" let-showProjectItems="showProjectItems">
          <fa-icon class="ion-padding-end" [icon]="['fal','bullhorn']" *ngIf="company.id === null"></fa-icon>
          <ion-label [class.bold]="company.id === null">{{company | companyNameString:showProjectItems && company.isRemoved}}</ion-label>
        </ng-template>
        <ng-template #companyValueTemplate let-company="value" let-showProjectItems="showProjectItems">
          {{company | companyNameString:showProjectItems && company.isRemoved}}
        </ng-template>
      </app-selectable>
    </ion-item>
  </ng-template>

  <ng-template #createdAtTemplate>
    <ion-item>
      <ion-input label-placement="floating"
        [readonly]="readonly || closeForm"
        formControlName="createdAt"
        dateFormat="DD.MM.YYYY"
        [showWeekNumbers]="true"
        (onClose)="closeCalender()"
        [firstSelectDay]="1"
        [firstDay]="1"
        [lang]="translateService.currentLang"
        [required]="!massEditView"
        [buttons]="[ {text: 'cancel' | translate, handler: 'cancel'}, {text: 'done' | translate, handler: 'set'} ]"
        mbsc-datepicker
        [controls]="['calendar']">
        <div slot="label"><fa-icon [fixedWidth]="true" [icon]="['fal', 'calendar-alt']"></fa-icon> {{ 'protocolEntry.createdAt' | translate }}</div>
      </ion-input>
    </ion-item>
  </ng-template>

  <ng-template #costTemplate>
    <ion-item class="protocol-entry-form-cost-field" ph-custom-id="entry-form-cost">
      <ion-input label-placement="floating" (ionBlur)="updateCostField($event.target.value)" [readonly]="readonly || closeForm" formControlName="cost" enterkeyhint="next">
        <div slot="label"><fa-icon [fixedWidth]="true" [icon]="['fal', 'money-bill']"></fa-icon> {{ ((protocol | async)?.includesVat ? 'CostWithVat' : 'CostWithoutVat') | translate:{rate: taxRate$ | async | numberFormat} }}</div>
      </ion-input>
    </ion-item>
    <span *ngIf="protocolEntryForm.controls.cost.errors && protocolEntryForm.controls.cost.dirty" class="error-message">
      {{ 'protocolEntry.errors.incorrectCost' | translate }}
    </span>
  </ng-template>

    <!-- protocol layout 'short' -->
  <ion-grid *ngIf="isProtocolLayoutShort" class="grid-gap-4">
    <ion-row *ngIf="!massEditClosedView">
      <ion-col [size]="expressView ? 12 : 11" *ngIf="!massEditView">
        <ng-container *ngTemplateOutlet="titleTextareaTemplate"></ng-container>
      </ion-col>

      <ion-col *ngIf="statusTemplate" size="6">
        <ng-container *ngTemplateOutlet="statusTemplate">
        </ng-container>
      </ion-col>

      <ion-col *ngIf="(protocolEntryId | isEmpty) && !expressView"  [size]="statusTemplate ? 6 : 1">
        <ng-container *ngTemplateOutlet="priorityButtonTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col *ngIf="!expressView && !massEditView" size="6">
        <ng-container *ngTemplateOutlet="compnayIonicSelectableTemplate"></ng-container>
      </ion-col>
      <ion-col [size]="massEditView ? 12 : 6" *ngIf="!massEditClosedView">
        <ng-container *ngTemplateOutlet="internalAssignmentIdTemplate"></ng-container>
      </ion-col>
      <ion-col *ngIf="expressView" size="6">
        <ng-container *ngTemplateOutlet="todoUntilTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!expressView">
      <ion-col>
        <ng-container *ngTemplateOutlet="startDateTemplate"></ng-container>
      </ion-col>
      <ion-col>
        <ng-container *ngTemplateOutlet="todoUntilTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!expressView && !massEditView && !isProtocolLayoutShort">
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="reminderAtTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="massEditView && !massEditClosedView">
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="createdAtTemplate"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

<!-- other protocol layout and expressView-->
  <ion-grid *ngIf="!isProtocolLayoutShort && expressView" class="grid-gap-4">
    <ion-row>
      <ion-col size="12">
        <ng-container *ngTemplateOutlet="titleInputTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="compnayIonicSelectableTemplate"></ng-container>
      </ion-col>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="locationTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="typeTemplate"></ng-container>
      </ion-col>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="todoUntilTemplate"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>


  <!-- other protocol layout and default view -->
  <ion-grid *ngIf="!isProtocolLayoutShort && !expressView" class="grid-gap-4">
    <ion-row *ngIf="!massEditClosedView">
      <ion-col [size]="protocolEntryId ? 12 : 11" *ngIf="!massEditView">
        <ng-container *ngTemplateOutlet="titleInputTemplate"></ng-container>
      </ion-col>

      <ion-col *ngIf="statusTemplate" size="6">
        <ng-container *ngTemplateOutlet="statusTemplate">
        </ng-container>
      </ion-col>

      <ion-col *ngIf="!protocolEntryId || massEditView" [size]="statusTemplate ? 6 : 1" >
        <ng-container *ngTemplateOutlet="priorityButtonTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!massEditView">
      <ion-col size="12">
        <app-rich-text-editor
          ph-custom-id="entry-form-description"
          [label]="'protocolEntry.text' | translate"
          formControlName="text"
          [disabled]="readonly || closeForm"
        ></app-rich-text-editor>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!massEditClosedView">
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="compnayIonicSelectableTemplate"></ng-container>
      </ion-col>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="internalAssignmentIdTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!massEditClosedView">
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="observerCompaniesIonicSelectableTemplate"></ng-container>
      </ion-col>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="typeTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!massEditClosedView">
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="craftTemplate"></ng-container>
      </ion-col>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="locationTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!massEditClosedView">
      <ion-col size="6">
        <app-protocol-additional-fields [acrossProjects]="acrossProjects" [disabled]="readonly || closeForm || isCarriedEntry" [protocolEntry]="protocolEntry" (additionalFieldsChange)="onAdditionalFieldsChange($event)"></app-protocol-additional-fields>
      </ion-col>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="costTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="startDateTemplate"></ng-container>
      </ion-col>
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="todoUntilTemplate"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="massEditView && !massEditClosedView">
      <ion-col size="6">
        <ng-container *ngTemplateOutlet="createdAtTemplate"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
