export interface CanvasOptions {
  element: HTMLElement;
  size: SizeType;
  width?: number;
  height?: number;
}

export interface CanvasDimension {
  width: number;
  height: number;
  zoomFactor: number;
}

export type SizeType = 'small' | 'large' | 'large-square' | 'screenshot-preview';

export function calculateOptimalDimension(imageWidth, imageHeight, viewportWidth, viewportHeight): CanvasDimension {
  let factor: number;
  let calculatedWidth: number, calculatedHeight: number;

  if (imageWidth > viewportWidth || imageHeight > viewportHeight) {
    if (imageWidth / imageHeight > viewportWidth / viewportHeight) {
      calculatedWidth = viewportWidth;
      calculatedHeight = imageHeight / imageWidth * viewportWidth;
    } else {
      calculatedHeight = viewportHeight;
      calculatedWidth = imageWidth / imageHeight * viewportHeight;
    }
    factor = calculatedHeight / imageHeight;
  } else {
    calculatedWidth = imageWidth;
    calculatedHeight = imageHeight;
    factor = 1;
  }
  return {width: calculatedWidth, height: calculatedHeight, zoomFactor: factor};
}
