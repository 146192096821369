import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Menu, MenuService } from 'src/app/services/common/menu.service';
import { PosthogService } from 'src/app/services/posthog/posthog.service';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent implements OnDestroy {
  public tabMenus: Menu[];
  public selectedMenu: Menu;
  public isMobileDevice = true;

  private destroy$ = new Subject<void>();

  constructor(private menuService: MenuService, public router: Router, private posthogService: PosthogService) {
    this.tabMenus = this.menuService.tabMenus;
    this.menuService.currentMenu$.pipe(takeUntil(this.destroy$))
      .subscribe(currentMenu => {
        this.selectedMenu = currentMenu;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async navigateTo(tabMenu: Menu) {
    this.menuService.setMenu(tabMenu);
    this.router.navigate([tabMenu.url], { replaceUrl: true });
    this.posthogService.captureEvent('Mobile Navigate Footer', {destination: tabMenu.title});
  }
}
