import {Address, Company, Craft, IdType, Profile, ProjectCompany, ProjectProfile, ProtocolEntry, User, UserConnectionInvite, UserInvite} from 'submodules/baumaster-v2-common';

export interface Employee extends Address {
  crafts: Craft[];
  projectProfileId: string;
  projectProfile: ProjectProfile | undefined; // Todo: change the logic to use projectProfiles
  projectProfiles: ProjectProfile[] | undefined;
  hasProtocol: boolean;
  profile: Profile;
  user?: User;
  userInvite?: UserInvite;
  userConnectionInvite?: UserConnectionInvite;
  isCurrentUser?: boolean;
}

export interface CompanySource extends Company {
  groupName: string;
  firstElementInGroup: boolean;
  isUserCompany: boolean;
  crafts: Craft[];
  employees: Employee[];
  projectCompany: ProjectCompany | undefined; // Todo: change the logic to use projectCompanies
  projectCompanies: ProjectCompany[] | undefined;
  hasProtocol: boolean;
  assignedEntries: ProtocolEntry[];
}

export const umlautMap = {
  Ü: 'U',
  Ä: 'A',
  Ö: 'O',
  ü: 'u',
  ä: 'a',
  ö: 'o',
  ß: 's',
};

export interface ProfileCompanyAddress {
  id: IdType;
  profile: Profile;
  company?: Company;
  address?: Address;
  searchText: string;
}

export interface PdfContactExportSettings {
  filteredCrafts?: Craft[];
  globalExport: boolean;
  printOnlyCompanies: boolean;
  customOrder: boolean;
}
