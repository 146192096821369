import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import _ from 'lodash';
import moment from 'moment';
import {BehaviorSubject, Observable, ReplaySubject, Subscription, of} from 'rxjs';
import {distinctUntilChanged, switchMap, take} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolEntryModalService} from 'src/app/services/protocol/protocol-entry-modal.service';
import {ReportService} from 'src/app/services/report/report.service';
import {Attachment, IdType, isAttachmentChat, isAttachmentProtocolEntry, isAttachmentReport, Project} from 'submodules/baumaster-v2-common';
import {AttachmentBlob} from '../../../model/attachments';
import {LoggingService} from '../../../services/common/logging.service';
import {ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {PhotoService} from '../../../services/photo/photo.service';
import {ProjectService} from '../../../services/project/project.service';
import {IMAGE_SIZE_LARGE_SQUARE_HEIGHT, IMAGE_SIZE_LARGE_SQUARE_WIDTH, LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY, MOMENT_DATE_FORMAT} from '../../../shared/constants';
import {combineLatestAsync, observableToPromise} from '../../../utils/async-utils';
import {convertISOStringToDate} from '../../../utils/date-utils';
import {AttachmentFullScreenViewerComponent} from '../../common/attachment-full-screen-viewer/attachment-full-screen-viewer.component';
import {trackById} from 'src/app/utils/track-by-id';
import {RxVirtualScrollViewportComponent} from '@rx-angular/template/experimental/virtual-scrolling';

const LOG_SOURCE = 'ProjectRoomAttachmentsListComponent';

type ProjectRoomListAttachment = {
  createdAt: string;
  firstInGroup: boolean;
  groupId: string;
  attachments: Array<Attachment>;
};

@Component({
  selector: 'app-project-room-attachments-list',
  templateUrl: './project-room-attachments-list.component.html',
  styleUrls: ['./project-room-attachments-list.component.scss'],
})
export class ProjectRoomAttachmentsListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  readonly trackById = trackById;
  readonly trackByCreatedAt = (index: number, item: ProjectRoomListAttachment) => item.createdAt + item.groupId;
  public groupedAttachments: Array<ProjectRoomListAttachment>;
  // rx-Angular VirtualScroll in combination with dynamic height only works properly with Observable (otherwise it calls getItemHeight forever)
  private groupedAttachmentsSubject = new ReplaySubject<Array<ProjectRoomListAttachment>>(1);
  public groupedAttachments$ = this.groupedAttachmentsSubject.asObservable();
  private attachments$: Observable<Array<Attachment>>;
  public attachments: Array<Attachment>;
  private attachmentsSubscription: Subscription | undefined;
  private viewportSubscription: Subscription | undefined;
  private selectedAttachments = new Array<Attachment>();
  private readonly padding = 16;
  private readonly margin = 10;
  private scrollWidthSubject = new BehaviorSubject<number|undefined>(undefined);
  private fullScreenModal: HTMLIonModalElement|undefined;
  private get scrollWidth() { return this.scrollWidthSubject.getValue();}
  private set scrollWidth(width: number|undefined) { this.scrollWidthSubject.next(width);}
  private projectIdSubject = new BehaviorSubject<IdType|null>(null);
  private isAttachmentClicked = false;

  @Input()
  set projectId(projectId: string|null) {
    this.projectIdSubject.next(projectId);
  }
  @Input()
  selectMode = false;
  @Input()
  maxSelectionLimit = LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY;
  @Input()
  project: Project|undefined;
  @Input()
  viewportWidth: number|undefined;

  @Output() selectionChanged = new EventEmitter<Array<Attachment>>();
  @Output() public markingsChanged = new EventEmitter<{ attachment: Attachment | AttachmentBlob, markings: Nullish<string> }>();
  @Output() public attachmentDeleted = new EventEmitter<{ attachment: Attachment | AttachmentBlob }>();

  @ViewChild(RxVirtualScrollViewportComponent, {
    static: false
  })
  viewport: RxVirtualScrollViewportComponent;

  afterViewInit = false;

  constructor(private modalController: ModalController, private loggingService: LoggingService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private projectService: ProjectService, private router: Router,
              private photoService: PhotoService, private protocolEntryModalService: ProtocolEntryModalService, private toastService: ToastService,
              private reportService: ReportService, private projectDataService: ProjectDataService,
              private networkStatusService: NetworkStatusService) {
  }

  private reloadProjectSubscription() {
    if (!this.afterViewInit) {
      return;
    }
    this.attachmentsSubscription?.unsubscribe();
    this.attachments$ = this.projectIdSubject.pipe(
      switchMap((projectId) => projectId
        ? this.projectService.getProjectRoomAttachmentsInProject(projectId)
        : this.projectService.getProjectRoomAttachments()
      )
    );
    this.attachmentsSubscription = combineLatestAsync([
      this.attachments$,
      this.viewport.containerRect$,
      this.scrollWidthSubject.pipe(distinctUntilChanged())
    ]).subscribe(async ([allAttachments, _rect, _scrollWidth]) => {
      this.attachments = allAttachments;
      if (!this.scrollWidth) {
        this.scrollWidth = this.viewport.getScrollElement().offsetWidth - this.viewport.getScrollElement().clientWidth;
      }
      const viewportWidth = this.viewportWidth - this.padding - this.scrollWidth;
      const itemWidth: number = IMAGE_SIZE_LARGE_SQUARE_WIDTH + this.padding + this.margin;
      const numberOfItemsPerRow = Math.floor(viewportWidth / itemWidth);
      this.groupedAttachments = _.chain(this.attachments)
        .groupBy(item => moment(convertISOStringToDate(item.createdAt)).format(MOMENT_DATE_FORMAT))
        .map((value, key) => ({createdAt: key, attachments: _.chunk(value, numberOfItemsPerRow)}))
        .value().reduce((acc, val) => {
          return acc.concat(val.attachments.map((attachments, index) => ({
            createdAt: val.createdAt,
            firstInGroup: index === 0,
            groupId: attachments.map(({id}) => id).join(','),
            attachments,
          })));
        }, [] as ProjectRoomListAttachment[]);
      this.groupedAttachmentsSubject.next(this.groupedAttachments);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project) {
      this.reloadProjectSubscription();
    }
  }

  ngOnInit() {
    this.reloadProjectSubscription();
    if (this.viewportWidth === undefined) {
      this.viewportWidth = window.innerWidth;
    }
  }

  ngAfterViewInit() {
    this.afterViewInit = true;
    this.viewportSubscription = this.viewport.containerRect$.subscribe(rect => {
      if (this.viewportWidth !== rect.width) {
        this.viewportWidth = rect.width;
      }
    });
    this.viewportSubscription.add(this.viewport.elementScrolled$.pipe(take(1)).subscribe(() => {
      this.scrollWidth = this.viewport.getScrollElement().offsetWidth - this.viewport.getScrollElement().clientWidth;
    }));

    this.reloadProjectSubscription();
  }

  ngOnDestroy(): void {
    this.attachmentsSubscription?.unsubscribe();
    this.attachmentsSubscription = undefined;
    this.viewportSubscription?.unsubscribe();
    this.viewportSubscription = undefined;
  }

  async goToEntry(attachmentId: IdType) {
    const selectedAttachment = this.attachments.find((attachment) => attachment.id === attachmentId);
    if (!selectedAttachment) {
      this.loggingService.error(LOG_SOURCE, `attachment with id=${attachmentId} does not found`);
      return;
    }
    if (!isAttachmentProtocolEntry(selectedAttachment) && !isAttachmentChat(selectedAttachment)) {
      this.loggingService.error(LOG_SOURCE, `attachment with id=${attachmentId} does not have the property protocolEntryId`);
      return;
    }
    const protocolEntryId = selectedAttachment.protocolEntryId;
    const protocolEntry = await observableToPromise(this.projectIdSubject.value
      ? this.protocolEntryDataService.getByIdAcrossProjects(protocolEntryId)
      : this.protocolEntryDataService.getById(protocolEntryId)
    );

    await this.closeFullScreenModal();
    await this.protocolEntryModalService.openModal(protocolEntry);
  }

  async goToReport(attachmentId: IdType) {
    const selectedAttachment = this.attachments.find((attachment) => attachment.id === attachmentId);
    if (!selectedAttachment) {
      this.loggingService.error(LOG_SOURCE, `attachment with id=${attachmentId} does not found`);
      return;
    }
    const [reportId, page] = await this.reportService.getReportIdAndTypeByAttachment(selectedAttachment);
    if (!reportId) {
      this.loggingService.error(LOG_SOURCE, `attachment with id=${attachmentId} is not a report attachment`);
      return;
    }
    await this.closeFullScreenModal();
    await this.router.navigate([page, reportId]);
  }

  private async closeFullScreenModal(): Promise<boolean> {
    if (!this.fullScreenModal) {
      return false
    }
    this.fullScreenModal.canDismiss = true;
    return await this.fullScreenModal.dismiss();
  }

  async openFullScreen(selectedAttachment: Attachment) {
    if (this.isAttachmentClicked) {
      return;
    }
    try {
      this.isAttachmentClicked = true;
      const modal = await this.modalController.create({
        component: AttachmentFullScreenViewerComponent,
        backdropDismiss: false,
        componentProps: {
          selectedAttachment,
          index: 0,
          attachmentsObservable: of([selectedAttachment]),
          navigateToEntry: (attachmentId: IdType) => this.goToEntry(attachmentId),
          navigateToReport: (attachmentId: IdType) => this.goToReport(attachmentId),
          onMarkingsChanged: async (attachment: Attachment | AttachmentBlob, markings: Nullish<string>) => {
            this.onMarkingsChanged(attachment, markings);
            await modal.dismiss();
          },
          onAttachmentDeleted: (attachment: Attachment | AttachmentBlob) => this.onAttachmentDeleted(attachment)
        }
      });
      modal.onDidDismiss().then(() => this.fullScreenModal = undefined);
      await modal.present();
      this.fullScreenModal = modal;
    } finally {
      this.isAttachmentClicked = false;
    }
    return;
  }

  private onMarkingsChanged(attachment: Attachment | AttachmentBlob, markings: Nullish<string>) {
    this.markingsChanged.emit({attachment, markings});
  }

  public onAttachmentDeleted(attachment: Attachment | AttachmentBlob) {
    this.attachmentDeleted.emit({attachment});
  }

  private async showToastImageNotOffline() {
    await this.toastService.info('attachmentFileProjectRoomPopover.attachmentNotOffline');
  }

  async toggleSelection(selectedAttachment: Attachment): Promise<boolean> {
    if (this.networkStatusService.offline && !(await this.photoService.isAttachmentImageInCache(selectedAttachment))) {
      await this.showToastImageNotOffline();
      return false;
    }
    const attachmentFoundIndex = this.selectedAttachments.findIndex((attachment) => attachment.id === selectedAttachment.id);
    if (attachmentFoundIndex === -1) {
      if (this.selectedAttachments.length >= this.maxSelectionLimit) {
        return false;
      }
      this.selectedAttachments.push(selectedAttachment);
    } else {
      this.selectedAttachments.splice(attachmentFoundIndex, 1);
    }
    this.selectionChanged.emit(this.selectedAttachments);
    return true;
  }

  isAttachmentSelected(attachmentToFind: Attachment): boolean {
    return !!this.selectedAttachments.find((attachment) => attachment.id === attachmentToFind.id);
  }

  getItemHeight = (item: ProjectRoomListAttachment): number => {
    const groupHeaderHeight = item.firstInGroup ? 41 : 0;
    const itemHeight: number = IMAGE_SIZE_LARGE_SQUARE_HEIGHT + this.margin;
    return itemHeight + groupHeaderHeight;
  };

  isEntryAttachment(attachment: Attachment): boolean {
    return (isAttachmentProtocolEntry(attachment) || isAttachmentChat(attachment));
  }

  isReportAttachment(attachment: Attachment): boolean {
    return (isAttachmentReport(attachment));
  }
}
