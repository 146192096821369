import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';
import {FileEntry} from '@awesome-cordova-plugins/file/ngx';
import {AlertController, IonRouterOutlet, LoadingController, ModalController, Platform, PopoverController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {combineLatest, interval, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, debounce, distinctUntilChanged, filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {BimPlanTreeViewComponent} from 'src/app/components/autodesk/bim-plan-tree-view/bim-plan-tree-view.component';
import {AttachmentBlob} from 'src/app/model/attachments';
import {ProjectWithOffline} from 'src/app/model/project-with-offline';
import {ProtocolEntryOrOpen} from 'src/app/model/protocol';
import {AttachmentService} from 'src/app/services/attachment/attachment.service';
import {AuthenticationService} from 'src/app/services/auth/authentication.service';
import {LoggingService} from 'src/app/services/common/logging.service';
import {AttachmentChatDataService} from 'src/app/services/data/attachment-chat-data.service';
import {AttachmentEntryDataService} from 'src/app/services/data/attachment-entry-data.service';
import {PdfPlanPageDataService} from 'src/app/services/data/pdf-plan-page-data.service';
import {ProtocolDataService} from 'src/app/services/data/protocol-data.service';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {AbstractEntryListService} from 'src/app/services/entry/abstract-entry-list.service';
import {LogActionInstance, SystemEventService} from 'src/app/services/event/system-event.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {MediaService} from 'src/app/services/media/media.service';
import {PhotoService} from 'src/app/services/photo/photo.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {BimViewerModalService} from 'src/app/services/project-room/bim-viewer-modal.service';
import {ProjectService} from 'src/app/services/project/project.service';
import {ProtocolNavigationService} from 'src/app/services/protocol-navigation.service';
import {ProtocolEntryCompanyService} from 'src/app/services/protocol/protocol-entry-company.service';
import {ProtocolEntryFilterService} from 'src/app/services/protocol/protocol-entry-filter.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {TriggerEntrySaveFunction, TriggerEntrySaveService} from 'src/app/services/protocol/trigger-entry-save.service';
import {Breakpoints, DeviceService, DISPLAY_SIZE} from 'src/app/services/ui/device.service';
import {PopoverService} from 'src/app/services/ui/popover.service';
import {AUTOSAVE_DELAY_IN_MS, IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES, LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY, PROTOCOL_LAYOUT_NAME_SHORT, ToastDurationInMs} from 'src/app/shared/constants';
import {convertErrorToMessage, ErrorWithUserMessage} from 'src/app/shared/errors';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {convertFileViaBinaryStringToFile, ensureMimeTypeSet, isImage, isImageBlob, isQuotaExceededError} from 'src/app/utils/attachment-utils';
import {dismissOverlayOnBackButtonOrNavigation} from 'src/app/utils/overlay-utils';
import {
  Attachment,
  AttachmentChat,
  AttachmentProtocolEntry,
  BimVersion,
  IdType,
  LicenseType,
  MIME_TYPE_EXTENSION_WHITELIST,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage,
  PdfPlanPageMarking,
  PdfPlanVersion,
  Project,
  Protocol, ProtocolEntry,
  ProtocolEntryLocation,
  ProtocolEntryPriorityLevel,
  ProtocolEntryType,
  ProtocolLayout, PROTOCOL_LAYOUT_NAME_CONTINUOUS
} from 'submodules/baumaster-v2-common';
import {v4 as uuidv4} from 'uuid';
import {BimViewerComponentReturnType} from '../../../model/bim-plan-with-deletable';
import {Nullish} from '../../../model/nullish';
import {ToastService} from '../../../services/common/toast.service';
import {PdfPlanVersionDataService} from '../../../services/data/pdf-plan-version-data.service';
import {PdfPlanService} from '../../../services/pdf/pdf-plan.service';
import {BimPlanService} from '../../../services/project-room/bim-plan.service';
import {FormControlStatus, getFormControlStatus, restoreFormControlStatus} from '../../../utils/form-utils';
import {AudioRecordingComponent} from '../../common/audio-recording/audio-recording.component';
import {PdfPlanMarkerComponent} from '../../common/pdf-plan-marker/pdf-plan-marker.component';
import {PdfPlanTreeViewComponent} from '../../common/pdf-plan-tree-view/pdf-plan-tree-view.component';
import {SketchComponent} from '../../common/sketch/sketch.component';
import {CopyProtocolEntryComponent} from '../../copy/copy-protocol-entry/copy-protocol-entry.component';
import {ProtocolEntryCreateComponent} from '../protocol-entry-create/protocol-entry-create.component';
import {FormDirty, ProtocolEntryFormComponent} from '../protocol-entry-form/protocol-entry-form.component';
import {ProtocolEntryPriorityPopoverComponent} from '../protocol-entry-priority-popover/protocol-entry-priority-popover.component';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {MobiscrollService} from 'src/app/services/common/mobiscroll.service';

const LOG_SOURCE = 'ProtocolEntryEditComponent';

const FORM_FIELDS_SAVE_WITHOUT_DELAY = new Set<string>(['type', 'company', 'observerCompanies', 'craft', 'location', 'startDate', 'todoUntil']);
const IMMEDIATE_SAVE_DELAY_IN_MS = 10;
const WIDTH_ENOUGH_SPACE_ALL_BUTTONS = 1760;
const WIDTH_NOT_ENOUGH_SPACE_ALL_BUTTONS = DISPLAY_SIZE.xxl;
const WIDTH_ENOUGH_SPACE_ALL_BUTTONS_TASKS = 1346;
const WIDTH_TOO_SMALL_ALL_BUTTONS = 500;

@Component({
  selector: 'app-protocol-entry-edit',
  templateUrl: './protocol-entry-edit.component.html',
  styleUrls: ['./protocol-entry-edit.component.scss'],
})
export class ProtocolEntryEditComponent implements OnInit, OnDestroy, OnChanges {
  private static nextInstanceNumber = 1;
  private static numberOfInstances = 0;

  private readonly convertForExternalSourcesNeeded = this.platform.is('android');
  public showFullSizeCreateButton$ = this.deviceService.isAboveBreakpoint(Breakpoints.lg);

  public keyboardIsShown$: Observable<boolean>;

  public protocolEntryLocationData: Observable<Array<ProtocolEntryLocation>>;
  public priorityLevel = ProtocolEntryPriorityLevel;
  private formDirtySubject = new Subject<FormDirty>();
  public audioRecording: boolean | undefined;

  public _protocolEntryFormDirty: FormDirty = {dirty: false};
  public statusFieldActive = true;
  public protocolEntryForm: UntypedFormGroup;
  public showSubEntries = false;
  public isMarkerApplied = false;
  public isPdfPlanPageMarkingsApplied = false;
  public isSubEntry: boolean | undefined;
  public isClosed: boolean | undefined;
  public numberOfFilesAllowed: number;
  private formDirtySubscription: Subscription | undefined;
  private formDirtyWithDelaySubscription: Subscription | undefined;

  public previousProtocolEntry: ProtocolEntry | null = null;
  public nextProtocolEntry: ProtocolEntry | null = null;

  public protocolEntry: ProtocolEntry|undefined|null;
  public protocolData: Observable<Protocol | null>;
  public isBeforeCreatedInProtocol$: Observable<boolean|undefined>;
  public createdInProtocolShortName$: Observable<string|undefined>;
  private project$: Observable<ProjectWithOffline | undefined>;
  private isProjectInConnectedClient$: Observable<boolean>;
  public selectedProject: Project|undefined;
  public selectedAdditionalFieldId: IdType|null;

  public mbscThemeVariant$ = this.mobiscrollService.themeVariant$;
  public mbscLocale$ = this.mobiscrollService.locale$;
  public MBSC_DATE_FORMAT = this.mobiscrollService.DATE_FORMAT;

  @Input()
  public protocolId: IdType;
  @Input()
  public protocolEntryId: IdType;
  @Input()
  public acrossProjects = true;
  @Input()
  onlyActionableEntryTypes = false;
  @Input()
  navigateOnSuccessfulSubEntryAdd = true;
  @Input()
  defaultEntryType: Nullish<ProtocolEntryType>;
  @Input()
  typeRequired = false;
  @Input()
  public context: string;
  public protocolEntriesForPager: Observable<Array<ProtocolEntry>>;
  public protocolSubEntries: Observable<Array<ProtocolEntry>>;
  private authenticatedUserId: IdType | undefined;
  private pdfPlanMarkerSubscription: Subscription|undefined;
  private protocolEntryDataSubscription: Subscription|undefined;
  private protocolEntriesNavigationSubscription: Subscription|undefined;
  private currentProjectSubscription: Subscription|undefined;
  private routeSubscription: Subscription;
  private watchEntryDeletedSubscription?: Subscription;
  public readonly acceptedMimeTypesForUpload: string;
  public mediaCaptureSupported: boolean;
  public audioRecordingSupported: boolean;
  private markedPdfPlanVersion: PdfPlanVersion;

  public protocolLayoutObservable: Observable<ProtocolLayout> | undefined;
  public isProtocolLayoutShort: boolean|undefined;
  public isProtocolLayoutContinuous: boolean|undefined;
  private currentProtocolLayout: ProtocolLayout | undefined;
  public isCarriedEntry: boolean;
  private protocolLayoutSubscription: Subscription | undefined;

  private attachments: Array<AttachmentProtocolEntry | AttachmentChat> | undefined;
  private attachmentsSubscription: Subscription | undefined;
  private authSubscription: Subscription | undefined;
  protected readonly instanceNumber: number;

  private bimVersionsSubscription: Subscription | undefined;
  private selectedBimVersion: BimVersion;
  public isBimMarkerApplied = false;
  public isFourColumnWithNotEnoughSpace: Observable<boolean>;

  @Input()
  public isEditEnabled: boolean;
  @Input()
  public isNavigationVisible = true;
  @Input()
  public isNavigationButtonsVisible = true;
  @Input()
  public canAddSubentries = true;
  @Input()
  public isNavigationEnabled = true;
  @Input()
  isTask?: boolean = false;

  @Output()
  nextEntryRequest = new EventEmitter<ProtocolEntry|undefined>();

  @Output()
  currentEntryDeleted = new EventEmitter<void>();

  private saveInProgress = false;
  private triggerEntrySaveFunction: TriggerEntrySaveFunction;

  @ViewChild(ProtocolEntryFormComponent, {static: false})
  protocolEntryFormComponent: ProtocolEntryFormComponent;

  constructor(public translateService: TranslateService,
              private toastService: ToastService,
              private protocolEntryDataService: ProtocolEntryDataService,
              private router: Router,
              private protocolService: ProtocolService,
              private protocolDataService: ProtocolDataService,
              private projectService: ProjectService,
              private attachmentEntryDataService: AttachmentEntryDataService,
              private attachmentChatDataService: AttachmentChatDataService,
              private photoService: PhotoService,
              private attachmentService: AttachmentService,
              private mediaService: MediaService,
              public platform: Platform,
              public popoverCtr: PopoverController,
              private modalController: ModalController,
              private loggingService: LoggingService,
              private pdfPlanVersionDataService: PdfPlanVersionDataService,
              private pdfPlanPageDataService: PdfPlanPageDataService,
              private protocolEntryService: ProtocolEntryService,
              private pdfPlanService: PdfPlanService,
              private alertController: AlertController,
              private protocolEntryFilterService: ProtocolEntryFilterService,
              private systemEventService: SystemEventService,
              private loadingController: LoadingController,
              private authenticationService: AuthenticationService,
              private featureEnabledService: FeatureEnabledService,
              private deviceService: DeviceService,
              private protocolNavigationService: ProtocolNavigationService,
              private protocolEntryCompanyService: ProtocolEntryCompanyService,
              private posthogService: PosthogService,
              private triggerEntrySaveService: TriggerEntrySaveService,
              private mobiscrollService: MobiscrollService,
              @Optional() private ionRouterOutlet: IonRouterOutlet|null,
              private bimViewerModalService: BimViewerModalService,
              private bimPlanService: BimPlanService,
              private popoverService: PopoverService,
              private networkStatusService: NetworkStatusService,
              @Optional() private entryListService: AbstractEntryListService|null
              ) {
    this.audioRecordingSupported = this.mediaService.isRecordingSupported();
    this.mediaCaptureSupported = this.photoService.isCameraSupported();
    this.instanceNumber = ProtocolEntryEditComponent.nextInstanceNumber++;
    ProtocolEntryEditComponent.numberOfInstances++;
    this.loggingService.debug(LOG_SOURCE, `constructor - instanceNumber=${this.instanceNumber}, numberOfInstances=${ProtocolEntryEditComponent.numberOfInstances}`);
    this.acceptedMimeTypesForUpload = MIME_TYPE_EXTENSION_WHITELIST.join(',');
    this.keyboardIsShown$ = this.deviceService.keyboardIsShown$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.protocolId && !changes.protocolId.firstChange) || (changes.protocolEntryId && !changes.protocolEntryId.firstChange)) {
      this.handleProtocolEntryIdsChange({
        protocolId: this.protocolId,
        protocolEntryId: this.protocolEntryId,
      });
    }
  }

  handleCreatedAtChanged(event: Nullish<Date>) {
    if (!event) {
      this.loggingService.warn(LOG_SOURCE, `Attempted to change createdAt to null/undefined; ignoring event...`);
      return;
    }
    if (!this.protocolEntry) {
      this.loggingService.warn(LOG_SOURCE, `Attempted to change createdAt, but protocol entry is null/undefined; ignoring event...`);
      return;
    }
    this.protocolEntry = {
      ...this.protocolEntry,
      createdAt: event,
    };
    this.protocolEntryFormDirty = {dirty: true};
  }

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, `ngOnInit - instanceNumber=${this.instanceNumber}, numberOfInstances=${ProtocolEntryEditComponent.numberOfInstances}`);
    this.authSubscription = this.authenticationService.authenticatedUserId$.subscribe((authenticatedUserId) => {
      this.authenticatedUserId = authenticatedUserId;
    });

    this.handleProtocolEntryIdsChange({
      protocolId: this.protocolId,
      protocolEntryId: this.protocolEntryId,
    });

    this.triggerEntrySaveFunction = () => this.forceSaveIfDirty();
    this.triggerEntrySaveService.registerSaveFunction(this.triggerEntrySaveFunction);
    this.isFourColumnWithNotEnoughSpace = this.isTask
      ? this.deviceService.matchesMediaQuery(`(min-width: ${DISPLAY_SIZE.xl}px) and (max-width: ${WIDTH_ENOUGH_SPACE_ALL_BUTTONS_TASKS}px)`)
      : this.deviceService.matchesMediaQuery(`(min-width: ${WIDTH_NOT_ENOUGH_SPACE_ALL_BUTTONS}px) and (max-width: ${WIDTH_ENOUGH_SPACE_ALL_BUTTONS}px)`);
  }

  private handleProtocolEntryIdsChange(params: {
    protocolId: IdType,
    protocolEntryId: IdType
  }) {
    this.loggingService.debug(LOG_SOURCE, 'activatedRoute.subscribe called');
    this.unsubscribeAllButRoute();
    this.protocolId = params.protocolId;
    if (params.protocolEntryId === 'first' || params.protocolEntryId === 'last') {
      const protocolId = params.protocolId;
      const firstElement = params.protocolEntryId === 'first';
      if (!protocolId || protocolId === '') {
        throw new Error('Path variable "protocolId" was not provided.');
      }
      const protocolEntryDataSubscription = (this.acrossProjects
        ? this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolIdAcrossProjects(protocolId)
        : this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(protocolId)
      )
        .pipe(map(async (protocolEntries) => {
          if (!protocolEntries || protocolEntries.length === 0) {
            return null;
          }
          const protocolEntryOrOpen = protocolEntries as ProtocolEntryOrOpen[];
          const sortedProtocolEntries = await this.protocolEntryService.sortProtocolEntriesByProtocolId(protocolEntryOrOpen, this.protocolId);
          return sortedProtocolEntries[firstElement ? 0 : protocolEntries.length - 1];
        }),
          mergeMap(async (protocolEntryPromise) => {
            const protocolEntryOrOpen = await protocolEntryPromise;
            return await observableToPromise(this.getProtocolEntryById(protocolEntryOrOpen?.id));
          }))
        .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('handleProtocolEntryIdsChange - protocolEntryDataSubscription - first or last(catchError)', error)));
      this.protocolEntryDataSubscription = protocolEntryDataSubscription.pipe(distinctUntilChanged(_.isEqual)).subscribe((protocolEntry) => {
        try {
          this.initWithProtocolEntry(protocolEntry);
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `handleProtocolEntryIdsChange - protocolEntryDataSubscription. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' handleProtocolEntryIdsChange - protocolEntryDataSubscription.', error);
          this.toastService.error(`handleProtocolEntryIdsChange - protocolEntryDataSubscription. ${convertErrorToMessage(error)}`);
        }
      });
    } else {
      this.protocolEntryId = params.protocolEntryId;
      this.protocolEntryDataSubscription = this.getProtocolEntryById(this.protocolEntryId)
        .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('handleProtocolEntryIdsChange - protocolEntryDataSubscription(catchError)', error)))
        .pipe(distinctUntilChanged(_.isEqual))
        .subscribe(protocolEntry => {
          try {
            this.initWithProtocolEntry(protocolEntry);
          } catch (error) {
            this.loggingService.error(LOG_SOURCE, `handleProtocolEntryIdsChange - protocolEntryDataSubscription. ${convertErrorToMessage(error)}`);
            this.systemEventService.logErrorEvent(LOG_SOURCE + ' handleProtocolEntryIdsChange - protocolEntryDataSubscription.', error);
            this.toastService.error(`handleProtocolEntryIdsChange - protocolEntryDataSubscription. ${convertErrorToMessage(error)}`);
          }
        });
    }

    this.protocolLayoutSubscription = this.protocolService.getProtocolLayoutByProtocolId(this.protocolId, this.acrossProjects)
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('handleProtocolEntryIdsChange - protocolEntryDataSubscription(catchError)', error)))
      .subscribe(protocolLayout => {
      this.currentProtocolLayout = protocolLayout;
      this.isProtocolLayoutShort = protocolLayout?.name === PROTOCOL_LAYOUT_NAME_SHORT;
      this.isProtocolLayoutContinuous = protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
    });
  }

  private subscribeToFormDirty() {
    const filterDirty = (formDirty: FormDirty) => formDirty.dirty;
    const filterDirtyWithoutDelay = (formDirty: FormDirty) => !!(formDirty.dirtyFormFields &&
      formDirty.dirtyFormFields.filter((dirtyFormField) => FORM_FIELDS_SAVE_WITHOUT_DELAY.has(dirtyFormField)))?.length;
    this.formDirtyUnsubscribe();
    this.formDirtySubscription = this.formDirtySubject.asObservable()
      .pipe(filter(filterDirty))
      .pipe(filter(filterDirtyWithoutDelay))
      .pipe(debounce(() => interval(IMMEDIATE_SAVE_DELAY_IN_MS)))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('subscribeToFormDirty - formDirtySubscription(catchError)', error)))
      .subscribe(async (formDirty) => {
        try {
          await this.saveIfDirty();
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `subscribeToFormDirty - formDirtySubscription. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' subscribeToFormDirty - formDirtySubscription.', error);
          this.toastService.error(`subscribeToFormDirty - formDirtySubscription. ${convertErrorToMessage(error)}`);
        }
      }
    );

    this.formDirtyWithDelayUnsubscribe();
    this.formDirtyWithDelaySubscription = this.formDirtySubject.asObservable()
      .pipe(filter(filterDirty))
      .pipe(debounce(() => interval(AUTOSAVE_DELAY_IN_MS)))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('subscribeToFormDirty - formDirtyWithDelaySubscription(catchError)', error)))
      .subscribe(async (formDirty) => {
        try {
          await this.saveIfDirty();
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `subscribeToFormDirty - formDirtyWithDelaySubscription. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' subscribeToFormDirty - formDirtyWithDelaySubscription.', error);
          this.toastService.error(`subscribeToFormDirty - formDirtyWithDelaySubscription. ${convertErrorToMessage(error)}`);
        }
      });
  }

  get protocolEntryFormDirty(): FormDirty {
    return this._protocolEntryFormDirty;
  }

  set protocolEntryFormDirty(newValue: FormDirty) {
    this.loggingService.debug(LOG_SOURCE, 'protocolEntryFormDirty changed to ' + newValue.dirty);
    this._protocolEntryFormDirty = newValue;
    this.loggingService.debug(LOG_SOURCE, `formDirty=${newValue} at ${new Date().toLocaleTimeString()}`);
    this.formDirtySubject.next(newValue);
  }

  getProtocolEntryById(protocolEntryId: IdType): Observable<ProtocolEntry> {
    return this.acrossProjects
      ? this.protocolEntryDataService.getByIdAcrossProjects(protocolEntryId)
      : this.protocolEntryDataService.getById(protocolEntryId).pipe(filter((entry) => !!entry));
  }

  ionViewWillEnter() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewWillEnter called. protocolEntryFormDirty=' + this.protocolEntryFormDirty.dirty);
    this.protocolEntryFormDirty = {dirty: false};
    this.loggingService.debug(LOG_SOURCE, 'ionViewWillEnter after. protocolEntryFormDirty=' + this.protocolEntryFormDirty.dirty);
  }

  ionViewDidEnter() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidEnter called.');
    this.protocolEntryFormDirty = {dirty: false};
  }

  ionViewWillLeave() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewWillLeave called.');
    this.protocolEntryFormDirty = {dirty: false};
    this.unsubscribeAllButRoute();
  }

  ionViewDidLeave() {
    this.loggingService.debug(LOG_SOURCE, 'ionViewDidLeave called.');
  }

  async ngOnDestroy() {
    ProtocolEntryEditComponent.numberOfInstances--;
    this.loggingService.debug(LOG_SOURCE, `ngOnDestroy - instanceNumber=${this.instanceNumber}, numberOfInstances=${ProtocolEntryEditComponent.numberOfInstances}`);
    this.triggerEntrySaveService.unregisterSaveFunction(this.triggerEntrySaveFunction);
    this.unsubscribeAll();
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.removeAllListeners();
    }
  }

  async addSubEntry(whichButton: string) {
    if (!(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER], null, null, this.isProjectInConnectedClient$))) {
      return;
    }
    await this.addNewProtocolEntry(this.protocolEntry.id);
    if (!this.isTask) {
      this.posthogService.captureEvent('[Protocols][Entry] Add Subentry', {
        button: whichButton
      });
    }
  }

  initWithProtocolEntry(protocolEntry: ProtocolEntry|null|undefined): void {
    this.loggingService.debug(LOG_SOURCE, `initWithProtocolEntry called - protocolEntry is "${protocolEntry?.title}", (${protocolEntry?.id}), protocolId = ${this.protocolId}`);
    this.protocolData = this.acrossProjects
      ? this.protocolDataService.getByIdAcrossProjects(this.protocolId)
      : this.protocolDataService.getById(this.protocolId).pipe(filter((protocol) => !!protocol));
    this.isBeforeCreatedInProtocol$ = this.protocolService.isBeforeCreatedInProtocol$(protocolEntry?.createdInProtocolId, this.protocolData, this.acrossProjects);
    this.createdInProtocolShortName$ = (this.acrossProjects
      ? this.protocolDataService.getByIdAcrossProjects(protocolEntry?.createdInProtocolId)
      : this.protocolDataService.getById(protocolEntry?.createdInProtocolId)).pipe(
        switchMap((createdInProtocol) => this.protocolService.getProtocolShortName$(createdInProtocol, this.acrossProjects))
      );
    this.project$ = this.protocolData.pipe(switchMap((protocol) => !protocol ? undefined : this.projectService.getById(protocol.projectId)))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('initWithProtocolEntry - project$(catchError)', error)));
    this.isProjectInConnectedClient$ = this.project$.pipe(switchMap((project) => this.projectService.isProjectInConnectedClient$(project)))
      .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('initWithProtocolEntry - isProjectInConnectedClient$(catchError)', error)));
    this.currentProjectSubscription = this.project$.subscribe((selectedProject) => this.selectedProject = selectedProject);

    if (!protocolEntry) {
      if (!this.protocolEntry) {
        this.watchEntryDeletedSubscription?.unsubscribe();
      }
      this.protocolEntry =  null;
      this.protocolEntryId = null;
      this.selectedAdditionalFieldId = null;
      return;
    }
    this.protocolEntry = protocolEntry;
    this.selectedAdditionalFieldId = this.protocolEntry.nameableDropdownId;
    this.protocolEntryId = protocolEntry.id;
    this.isSubEntry = !!protocolEntry.parentId;
    this.isCarriedEntry = this.protocolEntry.protocolId !== this.protocolId && this.protocolEntry.createdInProtocolId !== this.protocolId;

    this.watchEntryDeleted();
    this.setProtocolEntriesPager();
    this.setNextAndPreviousButtons();
    this.subscribeToFormDirty();
    this.checkIsMarkerApplied();
    this.unsubscribeAttachment();
    this.unsubscribeBimVersion();
    this.attachmentsSubscription = (this.acrossProjects
      ? this.protocolEntryService.getEntryAndChatAttachmentsAcrossProjects(protocolEntry.id)
      : this.protocolEntryService.getEntryAndChatAttachments(protocolEntry.id)
    ).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('initWithProtocolEntry - attachmentsSubscription(catchError)', error)))
      .subscribe(allAttachments => {
      this.attachments = allAttachments;
      this.numberOfFilesAllowed = LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY < this.attachments?.length ? 0 : LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments?.length;
    });
    this.bimVersionsSubscription = this.bimPlanService.getLatestMarkersWithVersion$(protocolEntry.id).subscribe((markersWithVersion) => {
      this.selectedBimVersion = markersWithVersion.bimVersion;
      this.isBimMarkerApplied = Boolean(markersWithVersion.bimMarkers.length);
    });
  }

  private watchEntryDeleted() {
    const {protocolEntry, protocolEntryId, previousProtocolEntry, nextProtocolEntry} = this;
    if (!protocolEntryId) {
      return;
    }

    this.watchEntryDeletedSubscription?.unsubscribe();
    this.watchEntryDeletedSubscription = this.protocolNavigationService.watchProtocolEntryDeleted(
      protocolEntryId,
      this.ionRouterOutlet
    ).subscribe(() => {
      try {
        this.showDeletedProtocolEntryFromServer(protocolEntry, previousProtocolEntry ?? nextProtocolEntry);
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `watchEntryDeleted - watchEntryDeletedSubscription. ${convertErrorToMessage(error)}`);
        this.systemEventService.logErrorEvent(LOG_SOURCE + ' watchEntryDeleted - watchEntryDeletedSubscription.', error);
        this.toastService.error(`watchEntryDeleted - watchEntryDeletedSubscription. ${convertErrorToMessage(error)}`);
      }
    });
  }

  private async showDeletedProtocolEntryFromServer(deletedProtocolEntry: ProtocolEntry, nextProtocolEntry: ProtocolEntry) {
    let message;
    const entryId = await this.protocolEntryService.getShortId(deletedProtocolEntry);
    if (!_.isEmpty(deletedProtocolEntry?.parentId)) {
      message = this.translateService.instant('sync.deletion.subProtocolEntry', {entryId});
    } else {
      message = this.translateService.instant('sync.deletion.protocolEntry', {entryId});
    }
    const alert = await this.alertController.create({
      message,
      buttons: [
        {
          text: this.translateService.instant('okay'),
          handler: async () => {
            this.currentEntryDeleted.emit();
            this.nextEntryRequest.emit(nextProtocolEntry);
          }
        }
      ]
    });
    alert.present();
  }

  private checkIsMarkerApplied() {
    this.pdfPlanMarkerSubscription = combineLatestAsync([
        (this.acrossProjects
          ? this.pdfPlanPageDataService.dataAcrossProjects$
          : this.pdfPlanPageDataService.data
        ),
      (this.acrossProjects
          ? this.pdfPlanVersionDataService.dataLatestVersionAcrossProjects$
          : this.pdfPlanVersionDataService.dataLatestVersion$
      ),
        this.pdfPlanService.getLatestMarkers([this.protocolEntry?.id], this.acrossProjects)
      ]).pipe(catchError((error) => this.observableCatchRethrowErrorHandler('checkIsMarkerApplied - pdfPlanMarkerSubscription(catchError)', error)))
      .subscribe(([pdfPlanPages, latestPdfPlanVersions, {pdfPlanMarkerProtocolEntries: markers, pdfPlanPageMarkings}]) => {
        try {
          const latestPdfPlanVersionIds = latestPdfPlanVersions.map((value) => value.id);
          const latestPdfPlanPages = pdfPlanPages.filter((pdfPlanPage) => latestPdfPlanVersionIds.includes(pdfPlanPage.pdfPlanVersionId));
          const latestPdfPLanPageIds = latestPdfPlanPages.map((value) => value.id);
          const latestMarkers = markers.filter((value) => latestPdfPLanPageIds.includes(value.pdfPlanPageId));
          const latestPdfPlanPageMarkings = pdfPlanPageMarkings.filter((value) => latestPdfPLanPageIds.includes(value.pdfPlanPageId));
          const marker: PdfPlanMarkerProtocolEntry | undefined = _.head(latestMarkers);
          const pdfPlanPageMarking: PdfPlanPageMarking | undefined = _.head(latestPdfPlanPageMarkings);
          const markedPdfPlanPage: PdfPlanPage = latestPdfPlanPages
            .find((pdfPlanPage: PdfPlanPage) => pdfPlanPage?.id === marker?.pdfPlanPageId || pdfPlanPage?.id === pdfPlanPageMarking?.pdfPlanPageId);
          this.markedPdfPlanVersion = latestPdfPlanVersions.find((pdfPlanVersion) => pdfPlanVersion.id === markedPdfPlanPage?.pdfPlanVersionId);
          this.isMarkerApplied = latestMarkers?.length > 0;
          this.isPdfPlanPageMarkingsApplied = !!latestPdfPlanPageMarkings?.length;
        } catch (error) {
          this.loggingService.error(LOG_SOURCE, `checkIsMarkerApplied - pdfPlanMarkerSubscription. ${convertErrorToMessage(error)}`);
          this.systemEventService.logErrorEvent(LOG_SOURCE + ' checkIsMarkerApplied - pdfPlanMarkerSubscription.', error);
          this.toastService.error(`checkIsMarkerApplied - pdfPlanMarkerSubscription. ${convertErrorToMessage(error)}`);
        }
      });
  }

  private setNextAndPreviousButtons() {
    this.protocolEntriesNavigationUnsubscribe();
    this.protocolEntriesNavigationSubscription =  combineLatest([this.protocolEntriesForPager, this.protocolData])
    .subscribe(async ([protocolEntries, protocol]) => {
      try {
        const protocolEntryOrOpen = protocolEntries as ProtocolEntryOrOpen[];
        const sortedProtocolEntries = await this.protocolEntryService.sortProtocolEntriesByProtocolSortEntry(protocolEntryOrOpen, protocol);
        const currentIndex = _.parseInt(_.head(_.keys(_.pickBy(sortedProtocolEntries, {id: this.protocolEntryId}))));
        this.nextProtocolEntry = this.getNextProtocolEntry(sortedProtocolEntries, currentIndex);
        this.previousProtocolEntry = this.getPreviousProtocolEntry(sortedProtocolEntries, currentIndex);
      } catch (error) {
        this.loggingService.error(LOG_SOURCE, `setNextAndPreviousButtons - protocolEntriesNavigationSubscription. ${convertErrorToMessage(error)}`);
        this.systemEventService.logErrorEvent(LOG_SOURCE + ' setNextAndPreviousButtons - protocolEntriesNavigationSubscription.', error);
        this.toastService.error(`setNextAndPreviousButtons - protocolEntriesNavigationSubscription. ${convertErrorToMessage(error)}`);
      }
    });
  }

  setProtocolEntriesPager() {
    if (this.acrossProjects || !this.entryListService) {
      const supportTable: string[] = [];
      if (this.acrossProjects) {
        supportTable.push('"acrossProjects = true"');
      }
      if (!this.entryListService) {
        supportTable.push('"entryListService not provided"');
      }
      this.loggingService.warn(LOG_SOURCE, `Protocol entries pager is not supported for: ${supportTable.join(', ')}`);
      this.protocolEntriesForPager = of([]);
      if (_.isEmpty(this.protocolEntry.parentId)) {
        this.protocolSubEntries = this.protocolEntryDataService.getSubEntriesByParentEntryId(this.protocolEntry?.id, this.acrossProjects)
          .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('setProtocolEntriesPager - protocolSubEntries(catchError)', error)));
      } else {
        this.protocolEntriesForPager = this.protocolEntryDataService.getSubEntriesByParentEntryId(this.protocolEntry?.parentId, this.acrossProjects)
          .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('setProtocolEntriesPager - protocolEntries(catchError)', error)));
        this.showSubEntries = true;
      }
      return;
    }
    if (_.isEmpty(this.protocolEntry.parentId)) {
      this.protocolEntriesForPager = this.entryListService.entriesFiltered$.pipe(
        map((entries) => entries.filter(({isSubtask}) => !isSubtask)),
        switchMap((entries) => this.protocolEntryDataService.dataGroupedById.pipe(
          map((entryById) => entries.map((entry) => entryById[entry.id]).filter((v) => !!v))
        ))
      );
      this.protocolSubEntries = this.protocolEntryDataService.getSubEntriesByParentEntryId(this.protocolEntry?.id, this.acrossProjects)
        .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('setProtocolEntriesPager - protocolSubEntries(catchError)', error)));
    } else {
      this.protocolEntriesForPager = this.protocolEntryDataService.getSubEntriesByParentEntryId(this.protocolEntry?.parentId, this.acrossProjects)
        .pipe(catchError((error) => this.observableCatchRethrowErrorHandler('setProtocolEntriesPager - protocolEntries(catchError)', error)));
      this.showSubEntries = true;
    }
  }

  private getPreviousProtocolEntry(protocolEntries: Array<ProtocolEntry>, currentIndex: number): ProtocolEntry | null {
    if (currentIndex > 0) {
      return protocolEntries[currentIndex - 1];
    }
    return null;
  }

  private getNextProtocolEntry(protocolEntries: Array<ProtocolEntry>, currentIndex: number): ProtocolEntry | null {
    if ((currentIndex + 1) <= (protocolEntries.length - 1)) {
      return protocolEntries[currentIndex + 1];
    }
    return null;
  }

  async previousEntry() {
    await this.forceSaveIfDirty();
    this.nextEntryRequest.emit(this.previousProtocolEntry);
  }

  async nextEntry() {
    await this.forceSaveIfDirty();
    this.nextEntryRequest.emit(this.nextProtocolEntry);
  }

  async forceSaveIfDirty() {
    if (this.protocolEntryFormDirty.dirty) {
      this.formDirtyUnsubscribe();
      this.formDirtyWithDelayUnsubscribe();
      await this.save();
      this.subscribeToFormDirty();
    }
  }

  private async saveIfDirty() {
    if (this.protocolEntryFormDirty.dirty) {
      await this.save();
      this.protocolEntryFormDirty = {dirty: false};
    }
  }

  async takePicture() {
    this.posthogService.captureEvent('[Entry] Photo button', {});
    await this.photoService.getAttachmentFromCamera(
      'protocol entry edit toolbar',
      0,
      this.numberOfFilesAllowed,
      (...args) => this.saveAsAttachmentProtocolEntry(...args),
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
    this.posthogService.captureEvent(this.isTask ? '[Tasks][Task][edit] Photo added' : '[Protocols][Entry][Edit] Photo added', {});
  }

  async takePictures() {
    const attachmentsBefore = this.attachments?.length ?? 0;
    this.posthogService.captureEvent('[Entry] Photos button', {});
    await this.photoService.getAttachmentsFromCamera(
      'protocol entry edit toolbar',
      0,
      this.numberOfFilesAllowed,
      (...args) => this.saveAsAttachmentProtocolEntry(...args),
      (attachment) => this.onTakenPhotoMarkingsChanged(attachment, attachment.markings)
    );
    this.posthogService.captureEvent(this.isTask ? '[Tasks][Task][edit] Photos added' : '[Protocols][Entry][Edit] Photos added', {
      amount: ((this.attachments?.length ?? 0) - attachmentsBefore)
    });
  }

  uploadFileEvent = async (event: any) => {
    const files: FileList = event.target.files;
    const blobFileNames = new Array<{ blob: Blob, fileName: string }>();
    let compressingLoading: HTMLIonLoadingElement|undefined;
    this.posthogService.captureEvent('[Entry] File button', {});
    try {
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i);
        if (this.convertForExternalSourcesNeeded) {
          file = await convertFileViaBinaryStringToFile(file);
        }
        if (!(await this.photoService.ensureContentIsJpegOrPng(file, 'entry'))) {
          break;
        }
        if (file.size > IMAGE_SIZE_COMPRESSION_LIMIT_IN_BYTES && isImageBlob(file)) {
          if (!compressingLoading) {
            compressingLoading = await this.loadingController.create({
              message: this.translateService.instant('imageCompressionInProgress')
            });
            await compressingLoading.present();
          }
          const blob = await this.photoService.scaleImage(file);
          blobFileNames.push({blob, fileName: file.name});
        } else {
          blobFileNames.push({blob: file, fileName: file.name});
        }
      }
      const addedAttachments = await this.addAttachments(blobFileNames);
      this.posthogService.captureEvent(this.isTask ? '[Tasks][Task][edit] Files from System added' : '[Protocols][Entry][Edit] Files from System added', {
        amountSystemFiles: addedAttachments?.length ?? 0
      });
      if (addedAttachments && addedAttachments.length === 1 && isImage(addedAttachments[0])) {
        await this.photoService.showToastMessageImageTakenEditMarkings(addedAttachments[0], this.onTakenPhotoMarkingsChanged, blobFileNames[0].blob);
      }
    } finally {
      if (compressingLoading) {
        await compressingLoading.dismiss();
        compressingLoading = undefined;
      }
    }
    // reset the file upload, otherwise you are not able to upload the same file again
    event.target.value = '';
  };

  startAudioRecording = async () => {
    this.posthogService.captureEvent('[Entry] Audio button', {});
    const modal = await this.modalController.create({
      component: AudioRecordingComponent,
      keyboardClose: false,
      backdropDismiss: true
    });
    modal.onDidDismiss().then(async (result) => {
      if (result.data) {
        const resultAfterRecording: { blobObject: Blob, fileEntry: FileEntry } = result.data;
        const addedAttachments = await this.addAttachments([{
          blob: resultAfterRecording.blobObject,
          fileName: resultAfterRecording.fileEntry.name
        }]);
        if (addedAttachments && addedAttachments.length >= 1) {
          this.posthogService.captureEvent(this.isTask ? '[Tasks][Task][edit] Audio added' : '[Protocols][Entry][Edit] Audio added', {
            amount: addedAttachments.length
          });
          await this.toastService.info('attachment.audioRecordingSuccess');
        }
      }
    });
    return await modal.present();
  };

  async takeVideo() {
    const res = await this.mediaService.takeVideo();
    const videoBlob = await this.mediaService.getBlob(res.fullPath);
    // this.addAttachment(); TODO: add as an attachment
  }

  private onTakenPhotoMarkingsChanged = async (newAttachment: AttachmentBlob|Attachment, markings: Nullish<string>) => {
    if (!this.attachments) {
      return;
    }
    const editedAttachment: AttachmentProtocolEntry = this.attachments.find((attachment) => attachment.id === newAttachment.id);
    if (editedAttachment) {
      editedAttachment.markings = markings;
      await this.attachmentEntryDataService.update(editedAttachment as AttachmentProtocolEntry, this.selectedProject.id);
    }
  };

  private async showAlertForFileUpload() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('alert.reachedAttachmentMaxNumberLimit.header'),
      message: this.translateService.instant('alert.reachedAttachmentMaxNumberLimit.message_entry', {limit: LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY}),
      buttons: [this.translateService.instant('okay')]
    });
    await alert.present();
  }

  private async tryOrLog<T>(functionSource: string, fn: () => Promise<T>, {
    rethrow = false,
    logInstance,
  }: { rethrow?: boolean; logInstance?: LogActionInstance } = {}): Promise<T> {
    try {
      const result = await fn();
      logInstance?.success();
      return result;
    } catch (error) {
      logInstance?.failure(error);
      let message: string|undefined;
      if (error instanceof ErrorWithUserMessage) {
        const errorWithUserMessage = error as ErrorWithUserMessage;
        message = errorWithUserMessage.userMessage;
      } else {
        message = error.message;
      }
      this.loggingService.error(LOG_SOURCE, `Error in ${functionSource}. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ` ${functionSource}`, error);
      if (isQuotaExceededError(message)) {
        await this.attachmentService.showToastQuotaExceeded();
      } else {
        await this.toastService.savingError();
      }
      if (rethrow) {
        throw error;
      }
    }
  }

  private async saveAsAttachmentProtocolEntry(attachmentBlob: AttachmentBlob): Promise<AttachmentProtocolEntry> {
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Adding entry attachment from blob [probably camera] (protocolEntryId=${
      this.protocolEntry.id
    }, id=${attachmentBlob.id}`);

    return this.tryOrLog('saveAsAttachmentProtocolEntry', async () => {
      const attachment: AttachmentProtocolEntry = {
        ..._.omit(attachmentBlob, 'blob'),
        projectId: this.selectedProject.id,
        protocolEntryId: this.protocolEntry.id,
      };

      await this.attachmentEntryDataService.insert(attachment, this.selectedProject.id, {}, attachmentBlob.blob);

      logInstance.success();
      return attachment;
    }, { rethrow: true, logInstance });
  }

  private async addAttachments(values: Array<{ blob: Blob, fileName: string, markings?: string|null }>): Promise<Array<AttachmentProtocolEntry>> {
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Adding entry attachments (protocolEntryId=${
      this.protocolEntry.id
    }, count=${values.length})`);

    return this.tryOrLog('addAttachments', async () => {
      if (values.length > this.numberOfFilesAllowed) {
        await this.showAlertForFileUpload();
        logInstance.success(() => `would exceed number of files allowed [${this.numberOfFilesAllowed}]`);
        return;
      }
      const blobs = new Array<Blob>();
      const attachments = new Array<AttachmentProtocolEntry>();
      for (const value of values) {
        const fileName = value.fileName;
        const blob = ensureMimeTypeSet(value.blob, fileName);
        const fileExt = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
        const attachment: AttachmentProtocolEntry = {
          id: uuidv4(),
          hash: uuidv4(),
          projectId: this.selectedProject.id,
          protocolEntryId: this.protocolEntry.id,
          mimeType: blob.type,
          fileName,
          fileExt,
          markings: value.markings,
          changedAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          createdById: this.authenticatedUserId
        };

        blobs.push(blob);
        attachments.push(attachment);
      }

      await this.attachmentEntryDataService.insert(attachments, this.selectedProject.id, {}, blobs);

      logInstance.success(() => `ids=${attachments.map((att) => att.id)}`);

      return attachments;
    }, { logInstance });
  }

  private async copyAndAddAttachments(attachmentsToCopy: Array<Attachment>) {
    const loading = await this.loadingController.create({
      message: this.translateService.instant('attachment.addingInProgress')
    });
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Copying entry attachments (protocolEntryId=${
      this.protocolEntry.id
    }, toCopyIds=${attachmentsToCopy.map(({ id }) => id)})`);
    try {
      await loading.present();

      const blobs = new Array<{ blob: Blob, fileName: string, markings?: string|null }>();
      for (const attachmentToCopy of attachmentsToCopy) {
        if (!attachmentToCopy.filePath) {
          throw new Error(`Unable to copy attachment with id ${attachmentToCopy.id} as we do not have the attachment information.`);
        }
        const blob = await this.photoService.downloadAttachment(attachmentToCopy, 'image');
        if (!blob) {
          throw new Error(`Unable to copy attachment with id ${attachmentToCopy.id} as we do not have the blob.`);
        }
        blobs.push({blob, fileName: attachmentToCopy.fileName, markings: attachmentToCopy.markings});
      }

      await this.addAttachments(blobs);
      logInstance.success();
    } catch (e) {
      logInstance.failure(e);
      throw e;
    } finally {
      await loading.dismiss();
    }
  }

  async save(): Promise<void> {
    if (!this.protocolEntryForm.valid) {
      await this.toastService.errorWithMessageAndHeader('form_error_validation_header', this.translateService.instant('form_error_validation_message'));
      return;
    }

    if (this.saveInProgress) {
      return;
    }

    this.saveInProgress = true;

    const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Update protocol entry (id=${this.protocolEntry.id}, type=${
      this.protocolEntry?.typeId || '_empty_'
    })`);
    try {
      const { protocolEntryForm, selectedAdditionalFieldId, isProjectInConnectedClient$, selectedProject } = this;
      let { protocolEntry } = this;

      if (!protocolEntry?.id) {
        const msg = 'save - protocolEntry to update is not set';
        this.loggingService.warn(LOG_SOURCE, msg);
        logInstance.failure(msg);
        return;
      }

      if (!this.protocolEntryFormComponent || !this.protocolEntryFormComponent.initializedWithProtocolEntryId(protocolEntry.id)) {
        const msg = `ProtocolEntryForm is initialized inconsistently, or with different or no protocolEntryId (${this.protocolEntryFormComponent?.protocolEntryId})` +
        'than ProtocolEntryEdit; dropped save event';
        this.loggingService.warn(LOG_SOURCE, msg);
        logInstance.failure(msg);
        return;
      }

      const observerCompanies = protocolEntryForm.getRawValue().observerCompanies;

      // Exclude createdAt, priority and status from form update
      const { createdAt, priority, status, ...protocolsInput} = this.protocolEntryService.getPartialProtocolEntryFromFormGroup(protocolEntryForm, selectedAdditionalFieldId);
      const updatedProtocolEntry = {...this.protocolEntry, ...protocolsInput};
      const stillSameEntrySelected = () => this.protocolEntry.id === protocolEntry.id;
      if (await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER], null, null, isProjectInConnectedClient$)) {
        protocolEntry = updatedProtocolEntry;
        if (stillSameEntrySelected()) {
          this.protocolEntry = protocolEntry;
        }
      }
      if (stillSameEntrySelected()) {
        this.selectedAdditionalFieldId = this.protocolEntry.nameableDropdownId;
      }
      let newEntries: Array<ProtocolEntry>|undefined;
      let formControlStates: Record<any, FormControlStatus>|undefined;
      try {
        formControlStates = getFormControlStatus(this.protocolEntryForm);
        this.protocolEntryForm.markAsPristine();
        newEntries = await this.protocolEntryDataService.update(protocolEntry, selectedProject.id);
        await this.protocolEntryCompanyService.saveObserverCompanies(protocolEntry.id, observerCompanies, this.acrossProjects);
      } catch (error) {
        if (formControlStates) {
          restoreFormControlStatus(this.protocolEntryForm, formControlStates);
        }
        throw error;
      }

      if (newEntries?.length) {
        const shortId = this.acrossProjects ? await this.protocolEntryService.getShortIdAcrossProjects(protocolEntry) : await this.protocolEntryService.getShortId(protocolEntry);
        if (shortId) {
          await this.toastService.toastWithTranslateParams('saving_with_number_success', {shortId}, ToastDurationInMs.INFO);
        } else {
          await this.toastService.savingSuccess();
        }
      }
      logInstance.success();
      if (this.isTask) {
        this.posthogService.captureEvent('Task edited', {});
      }
      if (this.context === 'dashboard') {
        this.posthogService.captureEvent('[Dashboard] Entry or task edited via Modal in Dashboard', {});
      }
    } catch (e) {
      logInstance.failure(e);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(e));
    } finally {
      this.saveInProgress = false;
    }
  }

  async removeEntry() {
    const { protocolEntry, selectedProject, attachments } = this;

    const alert = await this.alertController.create({
      header: protocolEntry.parentId ? this.translateService.instant('alert.deleteProtocolSubEntry.header') : this.translateService.instant('alert.deleteProtocolEntry.header'),
      message: protocolEntry.parentId ? this.translateService.instant('alert.deleteProtocolSubEntry.message') : this.translateService.instant('alert.deleteProtocolEntry.message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Remove protocol entry (id=${protocolEntry?.id}, type=${
              protocolEntry?.typeId || '_empty_'
            }, attachmentCount=${attachments?.length})`);
            try {
              this.loggingService.debug(LOG_SOURCE, 'Removing protocolEntry');
              const previousProtocolEntry = await this.getNextNavigationEntry();
              this.nextEntryRequest.emit(previousProtocolEntry);
              this.watchEntryDeletedSubscription?.unsubscribe();
              await this.protocolEntryService.deleteProtocolEntry(protocolEntry, selectedProject.id);
              this.loggingService.debug(LOG_SOURCE, 'Removing of protocolEntry was successful');
              logInstance.success();
              try {
                const isNetworkConnected = this.networkStatusService.onlineOrUnknown;
                this.posthogService.captureEvent('[Protocols][Entry] Delete', {
                  type: this.isTask ? 'task' : 'entry',
                  editedOffline: !isNetworkConnected
                });
              } catch (error) {
                this.loggingService.error(LOG_SOURCE, `Error capturing posthog event "${error?.userMessage}" - "${error?.message}"`);
              }
            } catch (e) {
              logInstance.failure(e);
              await this.toastService.errorWithMessageAndHeader('error_deleting_message', convertErrorToMessage(e));
            }
          }
        }
      ]
    });
    dismissOverlayOnBackButtonOrNavigation(alert, this.router, this.platform);
    await alert.present();
  }

  async getNextNavigationEntry(): Promise<ProtocolEntry|null> {
    if (!_.isEmpty(this.previousProtocolEntry)) {
      return this.previousProtocolEntry;
    } else if (!_.isEmpty(this.nextProtocolEntry)) {
      return this.nextProtocolEntry;
    }

    if (!_.isEmpty(this.protocolEntry.parentId)) {
      return await observableToPromise(this.protocolEntryDataService.getById(this.protocolEntry.parentId));
    }

    return null;
  }

  async addNewProtocolEntry(parentId?: IdType) {
    if (!(await this.featureEnabledService.isFeatureEnabled(false, true, [LicenseType.VIEWER], null, null, this.isProjectInConnectedClient$))) {
      return;
    }
    const modal = await this.modalController.create({
      component: ProtocolEntryCreateComponent,
      keyboardClose: false,
      backdropDismiss: false,
      componentProps: {
        protocolId: this.isCarriedEntry ? this.protocolEntry.protocolId : this.protocolId,
        createdInProtocolId: this.isCarriedEntry ? this.protocolId : undefined,
        expressView: true,
        parentEntryId: parentId,
        navigateOnSuccess: this.navigateOnSuccessfulSubEntryAdd,
        onlyActionableEntryTypes: this.onlyActionableEntryTypes,
        defaultEntryType: this.defaultEntryType,
        typeRequired: this.typeRequired,
        isTask: this.isTask,
        parentIsCarriedOver: this.isCarriedEntry,
      }
    });
    await modal.present();
  }

  private unsubscribeAllButRoute() {
    this.protocolEntryDataUnsubscribe();
    this.protocolEntriesNavigationUnsubscribe();
    this.formDirtyUnsubscribe();
    this.formDirtyWithDelayUnsubscribe();
    this.protocolLayoutUnsubscribe();
  }

  private unsubscribeAll() {
    this.routeUnsubscribe();
    this.unsubscribeAllButRoute();
    this.watchEntryDeletedSubscription?.unsubscribe();
    this.pdfPlanMarkerUnsubscribe();
    this.unsubscribeAttachment();
    this.unsubscribeBimVersion();
    this.unsubscribeAuthentication();
    this.currentProjectSubscription?.unsubscribe();
  }

  private unsubscribeAuthentication() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
      this.authSubscription = undefined;
    }
  }
  private unsubscribeAttachment() {
    if (this.attachmentsSubscription) {
      this.attachmentsSubscription.unsubscribe();
      this.attachmentsSubscription = undefined;
    }
  }

  private unsubscribeBimVersion() {
    if (this.bimVersionsSubscription) {
      this.bimVersionsSubscription.unsubscribe();
      this.bimVersionsSubscription = undefined;
    }
  }

  private pdfPlanMarkerUnsubscribe() {
    if (this.pdfPlanMarkerSubscription) {
      this.pdfPlanMarkerSubscription.unsubscribe();
      this.pdfPlanMarkerSubscription = undefined;
    }
  }

  private protocolEntryDataUnsubscribe() {
    if (this.protocolEntryDataSubscription) {
      this.loggingService.debug(LOG_SOURCE, 'unsubscribing from protocolEntryDataSubscription');
      this.protocolEntryDataSubscription.unsubscribe();
      this.protocolEntryDataSubscription = undefined;
    }
  }

  private formDirtyUnsubscribe() {
    if (this.formDirtySubscription) {
      this.formDirtySubscription.unsubscribe();
      this.formDirtySubscription = undefined;
    }
  }

  private formDirtyWithDelayUnsubscribe() {
    if (this.formDirtyWithDelaySubscription) {
      this.formDirtyWithDelaySubscription.unsubscribe();
      this.formDirtyWithDelaySubscription = undefined;
    }
  }

  private routeUnsubscribe() {
    if (this.routeSubscription) {
      this.loggingService.debug(LOG_SOURCE, 'unsubscribing from routeSubscription');
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
  }

  private protocolEntriesNavigationUnsubscribe() {
    if (this.protocolEntriesNavigationSubscription) {
      this.loggingService.debug(LOG_SOURCE, 'unsubscribing from protocolEntriesNavigationSubscription');
      this.protocolEntriesNavigationSubscription.unsubscribe();
      this.protocolEntriesNavigationSubscription = undefined;
    }
  }

  private protocolLayoutUnsubscribe() {
    if (this.protocolLayoutSubscription) {
      this.protocolLayoutSubscription.unsubscribe();
      this.protocolLayoutSubscription = undefined;
    }
  }

  async showPriorityPopOver(event) {
    const popover = await this.popoverCtr.create({
      component: ProtocolEntryPriorityPopoverComponent,
      event,
      translucent: true
    });

    popover.onDidDismiss()
      .then(async (response: any) => {
        if (typeof response.data !== 'undefined') {
          this.protocolEntry = {
            ...this.protocolEntry,
            priority: response.data?.newPriority
          };
          await this.save();
        }
      });
    return await popover.present();
  }

  async onChangeProtocolEntryStatus(status) {
    this.protocolEntry = {
      ...this.protocolEntry,
      status
    };
    await this.save();
  }

  async onIsContinuousInfoChange(isContinuousInfo: boolean) {
    this.protocolEntry = {
      ...this.protocolEntry,
      isContinuousInfo
    };
    await this.save();
  }

  toggleEntries() {
    this.showSubEntries = !this.showSubEntries;
  }

  onAdditionalFieldsChange(additionalFieldId) {
    this.selectedAdditionalFieldId = additionalFieldId;
    this.protocolEntryFormDirty = {dirty: true};
    setTimeout(() => {
      this.saveIfDirty();
    }, IMMEDIATE_SAVE_DELAY_IN_MS);
  }

  public async onMarkingsChanged(attachment: AttachmentProtocolEntry | AttachmentChat, markings: Nullish<string>) {
    const attachmentId = attachment.id;
    if ('chatId' in attachment) {
      const attachmentChat = attachment as AttachmentChat;
      attachmentChat.markings = markings;
      const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Markings changed in chat attachment (id=${
        attachmentChat.id
      }, chatId=${attachmentChat.chatId}), protocolEntryId=${attachmentChat.protocolEntryId}`);
      try {
        await this.attachmentChatDataService.update(attachmentChat, this.selectedProject.id);
        logInstance.success();
      } catch (e) {
        logInstance.failure(e);
        throw e;
      }
    } else if ('protocolEntryId' in attachment) {
      const attachmentProtocolEntry = attachment as AttachmentProtocolEntry;
      attachmentProtocolEntry.markings = markings;
      const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Markings changed in entry attachment (id=${
        attachmentProtocolEntry.id
      }, protocolEntryId=${attachmentProtocolEntry.protocolEntryId}`);
      try {
        await this.attachmentEntryDataService.update(attachmentProtocolEntry, this.selectedProject.id);
        logInstance.success();
      } catch (e) {
        logInstance.failure(e);
        throw e;
      }
    } else {
      throw new Error(`Attachment with id ${attachmentId} not supported`);
    }
  }

  public async onAttachmentDeleted(attachment: AttachmentProtocolEntry | AttachmentChat) {
    const attachmentId = attachment.id;
    if ('chatId' in attachment) {
      const attachmentChat = attachment as AttachmentChat;
      const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Deleted chat attachment (id=${
        attachmentChat.id
      }, chatId=${attachmentChat.chatId}), protocolEntryId=${attachmentChat.protocolEntryId}`);
      try {
        await this.attachmentChatDataService.delete(attachmentChat, this.selectedProject.id);
        logInstance.success();
      } catch (e) {
        logInstance.failure(e);
        throw e;
      }
    } else if ('protocolEntryId' in attachment) {
      const attachmentProtocolEntry = attachment as AttachmentProtocolEntry;
      const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Deleted entry attachment (id=${
        attachmentProtocolEntry.id
      }, protocolEntryId=${attachmentProtocolEntry.protocolEntryId}`);
      try {
        await this.attachmentEntryDataService.delete(attachmentProtocolEntry, this.selectedProject.id);
        logInstance.success();
      } catch (e) {
        logInstance.failure(e);
        throw e;
      }
    } else {
      throw new Error(`Attachment with id ${attachmentId} not supported`);
    }
    if ('protocolEntryId' in attachment) {
      this.posthogService.captureEvent(this.isTask ? '[Tasks][Task] Attachment deleted' : '[Protocols][Entry] Attachment deleted', {
        type: attachment.fileExt
      });
    }
  }

  async openPDFFolderMarker() {
    this.posthogService.captureEvent('[Entry] Marker button', {});
    if (this.isMarkerApplied || this.isPdfPlanPageMarkingsApplied) {
      const modal = await this.modalController.create({
        component: PdfPlanMarkerComponent,
        cssClass: 'full-screen-modal-xxl',
        componentProps: {
          pdfPlanVersion: this.markedPdfPlanVersion,
          selectedProtocolEntry: this.protocolEntry,
          acrossProjects: this.acrossProjects,
          featureEnabledOverride: !this.isEditEnabled ? false : undefined
        }
      });
      return await modal.present();
    } else if (this.isEditEnabled) {
      const modal = await this.modalController.create({
        component: PdfPlanTreeViewComponent,
        cssClass: 'omg-modal omg-boundary omg-in-modal-list',
        componentProps: {
          protocolEntry: this.protocolEntry,
          acrossProjects: this.acrossProjects
        }
      });
      return await modal.present();
    }
  }

  async openAutodeskViewer() {
    this.posthogService.captureEvent('[Entry] BIM-Marker button', {});

    if (this.isBimMarkerApplied && this.selectedBimVersion) {
      await this.bimViewerModalService.openModal({
        returnType: BimViewerComponentReturnType.PERSIST_CHANGES,
        selectedBimVersion: this.selectedBimVersion,
        selectedProtocolEntry: this.protocolEntry,
        acrossProjects: this.acrossProjects
      });
      return;
    } else {
      if (!(await this.featureEnabledService.isFeatureEnabled(true, false, [LicenseType.PROFESSIONAL]))) {
        this.toastService.infoWithMessageAndHeader('toast.licenseDisabled.header', 'toast.licenseDisabled.message');
        return;
      }
      const modal = await this.modalController.create({
        component: BimPlanTreeViewComponent,
        cssClass: 'omg-modal omg-boundary omg-in-modal-list',
        componentProps: {
          selectedProtocolEntry: this.protocolEntry,
          acrossProjects: this.acrossProjects
        }
      });
      return await modal.present();
    }
  }

  public async openImageInSketchTool() {
    const pdfPlanPages = await observableToPromise(this.pdfPlanPageDataService.data);
    if (!pdfPlanPages.length) {
      return;
    }
    const pdfPlanPage = pdfPlanPages[0];
    let attachmentUrl: string|undefined;
    try {
      const blob = await this.photoService.downloadAttachment(pdfPlanPage, 'image');
      if (!blob) {
        await this.toastService.error('pdfPlanMarker.plan_not_loaded');
        return;
      }
      attachmentUrl = URL.createObjectURL(blob);
      const modal = await this.modalController.create({
        component: SketchComponent,
        cssClass: 'full-screen-sketch',
        backdropDismiss: false,
        componentProps: {
          attachmentUrl,
          revokeObjectUrlOnDestroy: true,
          onMarkingsChanged: (updatedMarkings: Nullish<string>) => {
          }
        }
      });
      await modal.present();
      await modal.onDidDismiss();
    } catch (error) {
      const errorMessage = convertErrorToMessage(error);
      if (errorMessage === 'overlay does not exist') {
        return; // this error can be ignored
      }
      await this.toastService.error('pdfPlanMarker.error_opening_sketching');
      this.loggingService.error(LOG_SOURCE, `Error in openImageInSketchTool - ${errorMessage}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - openImageInSketchTool', error);
    }
  }

  async copy() {
    const modal = await this.modalController.create({
      component: CopyProtocolEntryComponent,
      keyboardClose: false,
      backdropDismiss: true,
      componentProps: {
        protocolId: this.protocolId,
        protocolEntry: this.protocolEntry,
        currentProtocolLayout: this.currentProtocolLayout
      }
    });
    await modal.present();
  }

   projectRoomAttachmentsSelector = async () => {
    this.posthogService.captureEvent('[Entry] Project-Room Attachments button', {});
    if (LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments.length <= 0) {
      await this.showAlertForFileUpload();
      return;
    }

    const project = await observableToPromise(this.project$);
    if (await this.attachmentService.showAlertIfOnlineOnlyProjectAndNoNetwork(project)) {
      return;
    }

    const selectedAttachments = await this.attachmentService.showProjectRoomAttachmentsSelector(
      LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments.length,
      this.acrossProjects ? project.id : null
    );
    if (selectedAttachments?.length) {
      await this.copyAndAddAttachments(selectedAttachments);
      this.posthogService.captureEvent(this.isTask ? '[Tasks][Task][edit] Project-Room attachments added' : '[Protocols][Entry][Edit] Project-Room attachments added', {
        amountProjectRoom: selectedAttachments.length
      });
    }
  };

  openSketchingTool = async () => {
    this.posthogService.captureEvent('[Entry] Sketching button', {});
    if (LIMIT_ATTACHMENTS_NUMBER_PROTOCOL_ENTRY - this.attachments.length <= 0) {
      await this.showAlertForFileUpload();
      return;
    }
    const blob = this.photoService.createEmptyImage();
    const modal = await this.modalController.create({
      component: SketchComponent,
      cssClass: 'full-screen-sketch',
      backdropDismiss: false,
      componentProps: {
        attachmentUrl: URL.createObjectURL(blob),
        onMarkingsChanged: async (markings: Nullish<string>) => {
          const addedAttachments = await this.addAttachments([{blob, fileName: uuidv4() + '.jpg'}]);
          this.posthogService.captureEvent(this.isTask ? '[Tasks][Task][edit] Drawing added' : '[Protocols][Entry][Edit] Drawing added', {});
          if (addedAttachments && addedAttachments.length === 1) {
            await this.onMarkingsChanged(addedAttachments[0], markings);
          }
        }
      }
    });
    await modal.present();
  };

  async openPopoverMenu(event) {
    const isDisabled = this.isCarriedEntry || !_.isEmpty((await observableToPromise(this.protocolData))?.closedAt);
    const result = await this.popoverService.openActions(event, [
      {
        label: 'file',
        role: 'file',
        icon: ['fal', 'paperclip'],
        disabled: isDisabled,
        uploadFileHandler: this.uploadFileEvent
      },
      {
        label: 'attachmentFileProjectRoomPopover.popover',
        role: 'attach',
        icon: ['fal', 'cube'],
        disabled: isDisabled
      },
      {
        label: 'drawing',
        role: 'drawing',
        icon: ['fal', 'signature'],
        disabled: isDisabled
      },
      {
        label: 'Audio',
        role: 'audio',
        icon: ['fal', 'microphone'],
        disabled: isDisabled || !this.audioRecordingSupported
      }
    ]);

    if (result !== 'backdrop') {
      switch (result) {
        case 'drawing':
          this.openSketchingTool();
          break;
        case 'audio':
          this.startAudioRecording();
          break;
        case 'attach':
          this.projectRoomAttachmentsSelector();
          break;
        default:
          throw new Error('Unsupported action: ' + result);
      }
    }
  }

  async openActions() {
    const isDisabled = this.isCarriedEntry || !_.isEmpty((await observableToPromise(this.protocolData))?.closedAt);
    const result = await this.popoverService.openActions(event, [
      {
        label: 'drawing',
        role: 'drawing',
        icon: ['fal', 'signature'],
        disabled: isDisabled
      },
      {
        label: 'Audio',
        role: 'audio',
        icon: ['fal', 'microphone'],
        disabled: isDisabled || !this.audioRecordingSupported
      },
      {
        label: 'Photo',
        role: 'photo',
        icon: ['fal', 'camera'],
        disabled: isDisabled || !this.mediaCaptureSupported
      },
      {
        label: 'Photos',
        role: 'photos',
        icon: ['bau', 'foto-series'],
        disabled: isDisabled || !this.mediaCaptureSupported
      }
    ]);

    if (result !== 'backdrop') {
      switch (result) {
        case 'drawing':
          this.openSketchingTool();
          break;
        case 'audio':
          this.startAudioRecording();
          break;
        case 'photo':
          this.takePicture();
          break;
        case 'photos':
          this.takePictures();
          break;
        default:
          throw new Error('Unsupported action: ' + result);
      }
    }
  }

  protocolEntryFormChanged($event: UntypedFormGroup) {
    this.protocolEntryForm = $event;
  }

  private observableCatchRethrowErrorHandler(message: string, error: any): Observable<never> {
    this.loggingService.error(LOG_SOURCE, `${message} - ${convertErrorToMessage(error)}`);
    this.systemEventService.logErrorEvent(LOG_SOURCE + ' ' + message, error);
    this.toastService.error(`${message} - ${convertErrorToMessage(error)}`);
    return throwError(error);
  }
}
