import {Content, TDocumentDefinitions} from 'pdfmake/interfaces';
import {PdfPreview} from '../../models';
import {PdfProtocolSendReq} from '../../requestResponse';
import {ProjectContent} from './content/pdfWorkflow/project.content';
import {ProtocolEntriesContent} from './content/pdfWorkflow/protocolEntries.content';
import {HeaderFooterData, PdfProtocolGenerateData} from './pdfProtocol.model';
import {AbstractPdfCommon} from './abstractPdfCommon.service';
import {PdfHelperFunctions} from '../common-report-utils';
import {ProtocolSignaturesContent} from './content/pdfWorkflow/protocolSignatures.content';
import {PDF_NODE_ID_DESC_SEARCH_STRING, PDF_NODE_ID_HEADER_SEARCH_STRING} from '../../constants';


export abstract class AbstractPdfProtocolCommonService extends AbstractPdfCommon {

  getDocDefinition(config: PdfProtocolSendReq, data: PdfProtocolGenerateData, pdfHelperFunctions: PdfHelperFunctions, bannerDimensions?: number[]|undefined,
                   indexNumber?: number, pdfPreview?: PdfPreview): TDocumentDefinitions {
    try {
      const content: Content = [];
      const projectContent = new ProjectContent(config, data, pdfHelperFunctions, pdfPreview);
      const protocolEntriesContent = new ProtocolEntriesContent(config, data, pdfHelperFunctions, pdfPreview);

      projectContent.writeProject(content, indexNumber);
      protocolEntriesContent.writeProtocolEntries(content);
      projectContent.writeAfterProtocolDetails(content);
      if (data.attachmentProtocolSignatures?.length) {
        const protocolSignaturesContent = new ProtocolSignaturesContent(config, data, pdfHelperFunctions, pdfPreview);
        protocolSignaturesContent.writeSignatures(content);
      }
      content.push({ text: '.', style: ['lastPage'], fontSize: 1}); // padding for pdf footer image

      return this.getGeneratedDocDefinitions(data.project.language, content, config, {
        project: data.project,
        protocol: data.protocol,
        attachmentClients: data.attachmentClients ? data.attachmentClients : new Map(),
        attachmentProjectImage: data.attachmentProjectImage,
        pdfProjectBanners: data.pdfProjectBanners,
        lookup: {
          protocolTypes: data.lookup.protocolTypes
        }
      }, bannerDimensions);

    } catch (err) {
      throw new Error(`Error generating pdf. ${err}`);
    }
  }

  protected getGeneratedDocDefinitions(language: string, content: Content[], config: PdfProtocolSendReq, data: HeaderFooterData, bannerDimensions?: number[]|undefined): TDocumentDefinitions {
    const noSplitEntryInfo = config.pdfProtocolSetting?.noSplitEntryInfo ?? false;
    const docDefinition: TDocumentDefinitions = {
      pageSize: 'A4',
      content,
      styles: this.getPdfStyle(config, bannerDimensions),
      images: this.getPdfImage(data),
      header: (currentPage: number, pageCount: number, pageSize) => {
        if (currentPage === 1) {
          return;
        }
        return this.getPdfHeader(data);
      },
      footer: (currentPage: number, pageCount: number) => {
        return this.getPdfFooter(currentPage, pageCount, language, config);
      },
      defaultStyle: {
        font: 'Inter'
      },
      pageMargins: [ 40, 50, 40, 40 ],
      pageBreakBefore: (currentNode, followingNodes) => {
        if (currentNode.id?.includes(PDF_NODE_ID_HEADER_SEARCH_STRING) && noSplitEntryInfo) {
          const descNode = followingNodes.find(node => node.id?.includes(PDF_NODE_ID_DESC_SEARCH_STRING));
          if ((descNode && descNode.pageNumbers.length > 1) || (currentNode.pageNumbers.length && descNode?.pageNumbers?.length && currentNode.pageNumbers[0] !== descNode?.pageNumbers[0])) {
            return true;
          }
        }
        return currentNode.id === 'imageRow' && currentNode.pageNumbers.length > 1;
      }
    };
    return docDefinition;
  }

}
