import {Injectable, OnDestroy} from '@angular/core';
import {ReplaySubject, Subscription} from 'rxjs';
import {IdType} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import {AuthenticationService} from '../auth/authentication.service';
import {LoggingService} from '../common/logging.service';
import _ from 'lodash';
import {StorageService} from '../storage.service';

export interface ProjectMetaInfo {
  projectId?: IdType;
  dataSizeFromServer: number;
  attachmentSizeFromServer?: number;
  attachmentSizeOfflineFromServer?: number;
  attachmentSizeLocal?: number;
}

const LOG_SOURCE = 'ProjectMetaInfoService';
const STORAGE_KEY = StorageKeyEnum.PROJECT_META_INFO;

@Injectable({
  providedIn: 'root'
})
export class ProjectMetaInfoService implements OnDestroy {
  private projectMetaInfosSubject = new ReplaySubject<Map<IdType, ProjectMetaInfo>>(1);
  public readonly projectMetaInfosObservable = this.projectMetaInfosSubject.asObservable();
  private authenticationSubscription: Subscription;
  protected isAuthenticated: boolean;

  constructor(private storage: StorageService, private authenticationService: AuthenticationService, private loggingService: LoggingService) {
    this.authenticationSubscription = this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.loggingService.debug(LOG_SOURCE, 'authenticationService subscribed.');
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated) {
        await this.clearStorageData();
      }
    });
    this.getDataByProjectId().then((valuesByProjectId) => this.projectMetaInfosSubject.next(valuesByProjectId));
  }

  public async getProjectMetaInfo(projectId?: string): Promise<ProjectMetaInfo|undefined> {
    const dataByProjectId = await this.getDataByProjectId();
    return dataByProjectId.get(projectId);
  }

  public async setProjectMetaInfo(projectMetaInfo: ProjectMetaInfo): Promise<Array<ProjectMetaInfo>> {
    const dataByProjectId = await this.getDataByProjectId();
    const projectMetaInfoInStore = dataByProjectId.get(projectMetaInfo.projectId);
    if (projectMetaInfoInStore && _.isEqual(projectMetaInfo, projectMetaInfoInStore)) {
      return Array.from(dataByProjectId.values());
    }
    dataByProjectId.set(projectMetaInfo.projectId, projectMetaInfo);
    this.assertAuthenticated();
    const projectMetaInfos = Array.from(dataByProjectId.values());
    await this.storage.set(STORAGE_KEY, projectMetaInfos);
    this.projectMetaInfosSubject.next(dataByProjectId);
    return projectMetaInfos;
  }

  private async getDataFromStorage(): Promise<Array<ProjectMetaInfo>> {
    return (await this.storage.get(STORAGE_KEY)) || [];
  }

  public async getDataByProjectId(): Promise<Map<IdType|undefined, ProjectMetaInfo>> {
    return this.keyByProjectId(await this.getDataFromStorage() ?? []);
  }

  private keyByProjectId(values: Array<ProjectMetaInfo>): Map<IdType|undefined, ProjectMetaInfo> {
    return new Map(values.map(value => [value.projectId, value]));
  }

  protected assertAuthenticated() {
    if (!this.isAuthenticated) {
      throw new Error('User not authenticated (auth is null or undefined)');
    }
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  private async clearStorageData() {
    await this.storage.remove(STORAGE_KEY);
  }
}
