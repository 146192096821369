import { StorageMigration } from './storage-migration';
import { bucketsPerKeyMigration } from './buckets-per-key-migration';

/**
 * List of all migrations.
 * Keep this list in order from the oldest migration to the newest migration!
 */
export const allMigrations: StorageMigration[] = [
  bucketsPerKeyMigration,
];
