import {Injectable} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {StorageService} from '../storage.service';
import {LoggingService} from '../common/logging.service';
import {IdType, UsageStatistic} from 'submodules/baumaster-v2-common';
import {v4 as uuid4} from 'uuid';
import {Platform} from '@ionic/angular';
import {Device} from '@capacitor/device';
import {environment} from '../../../environments/environment';
import {AttachmentSettingService} from '../attachment/attachmentSetting.service';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {AbstractFileAccessUtil, createRequestFromUrl} from '../../utils/attachment-utils';
import {fetchWithTimeout} from '../../utils/fetch-utils';
import {NetworkStatusService} from '../common/network-status.service';
import {convertErrorToMessage} from '../../shared/errors';
import {TokenManagerService} from './token-manager.service';
import {replyOnUnauthorized} from 'src/app/utils/unauthorized-reply-utils';

const LOG_SOURCE = 'UsageStatisticsService';
const LOCAL_STORAGE_KEY_USAGE_STATISTICS = 'USAGE_STATISTIC';

@Injectable({
  providedIn: 'root'
})
export class UsageStatisticsService {
  private readonly serverStatisticsUrl = environment.serverUrl + 'auth/usageStatistics';
  private authenticatedUserId: IdType|undefined;
  private fileAccessUtil: AbstractFileAccessUtil|undefined;

  constructor(private authenticationService: AuthenticationService, private storageService: StorageService, private loggingService: LoggingService, private platform: Platform,
              private attachmentSettingService: AttachmentSettingService, private networkStatusService: NetworkStatusService, private tokenManagerService: TokenManagerService) {
    combineLatestAsync([this.authenticationService.authenticatedUserId$, this.attachmentSettingService.fileAccessUtil$])
      .subscribe(async ([authenticatedUserId, fileAccessUtil]) => {
        this.authenticatedUserId = authenticatedUserId;
        this.fileAccessUtil = fileAccessUtil;
        if (authenticatedUserId) {
          await this.logAuthenticate(authenticatedUserId, fileAccessUtil);
        }
      });
    this.networkStatusService.onlineOrUnknown$.subscribe(async (online) => {
      if (online) {
        await this.sendUnsentStatisticsToServer();
      }
    });
  }

  public async sendNewStatisticsToServer(): Promise<boolean> {
    this.loggingService.debug(LOG_SOURCE, 'sendNewStatisticsToServer called');
    const authenticatedUserId = this.authenticatedUserId ?? await observableToPromise(this.authenticationService.authenticatedUserId$);
    const fileAccessUtil = this.fileAccessUtil ?? await observableToPromise(this.attachmentSettingService.fileAccessUtil$);
    if (authenticatedUserId && fileAccessUtil) {
      await this.logAuthenticate(authenticatedUserId, fileAccessUtil);
      return true;
    }
    return false;
  }

  public async sendUnsentStatisticsToServer(): Promise<boolean> {
    this.loggingService.debug(LOG_SOURCE, 'sendUnsentStatisticsToServer called');
    return await this.sendStatisticsToServer();
  }

  private async logAuthenticate(authenticatedUserId: IdType|undefined, fileAccessUtil: AbstractFileAccessUtil) {
    this.loggingService.debug(LOG_SOURCE, 'logAuthenticate called');
    const usageStatistic = await this.createUsageStatistic(authenticatedUserId, fileAccessUtil);
    await this.sendStatisticsToServer(usageStatistic);
  }

  private async sendStatisticsToServer(usageStatistic?: UsageStatistic): Promise<boolean> {
    this.loggingService.debug(LOG_SOURCE, 'sendStatisticsToServer called.');
    const usageStatistics = await this.pullFromStorage();
    if (usageStatistic) {
      usageStatistics.push(usageStatistic);
    }
    if (!usageStatistics.length) {
      this.loggingService.info(LOG_SOURCE, 'sendStatisticsToServer - No statistics to send to server.');
      return false;
    }
    this.loggingService.info(LOG_SOURCE, `sendStatisticsToServer - sending ${usageStatistics.length} usageStatistics to the server.`);
    const deviceId = await Device.getId();
    try {
      const res = await this.tokenManagerService.runWithTokenGetter(async (tokenGetter) => {
        return replyOnUnauthorized(async () => {
          return await tokenGetter.runAndInvalidateOnError(async (token) => {
            const request = createRequestFromUrl(this.serverStatisticsUrl, token, deviceId.identifier, 'POST', JSON.stringify(usageStatistics), 'application/json');
            return await fetchWithTimeout(request);
          });
        });
      });
      if (!res.ok) {
        throw new Error(`fetchWithTimeout to url ${this.serverStatisticsUrl} failed with statusCode ${res.status} and errorText "${res.statusText}".`);
      }
      if (res.status !== 504) {
        this.networkStatusService.markSuccessfulServerRequest();
      }

      this.loggingService.info(LOG_SOURCE, `sendStatisticsToServer - successfully sent ${usageStatistics.length} usageStatistics to the server.`);
      return true;
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error sending usageStatistics. ` + convertErrorToMessage(error));
      await this.setToStorage(usageStatistics);
      return false;
    }
  }

  private async createUsageStatistic(authenticatedUserId: IdType|undefined, fileAccessUtil: AbstractFileAccessUtil): Promise<UsageStatistic> {
    const deviceInfo = await Device.getInfo();
    const deviceId = await Device.getId();
    return {
      id: uuid4(),
      userId: authenticatedUserId,
      deviceUuid: deviceId.identifier,
      deviceManufacturer: deviceInfo.manufacturer?.substring(0, 150),
      deviceName: deviceInfo.name?.substring(0, 150),
      deviceModel: deviceInfo.model?.substring(0, 150),
      appVersion: environment.version?.substring(0, 100),
      storageDriver: this.storageService.driver,
      mediaStorage: fileAccessUtil.className,
      platform: deviceInfo.platform,
      operatingSystem: deviceInfo.operatingSystem,
      osVersion: deviceInfo.osVersion?.substring(0, 100),
      platforms: this.platform.platforms()?.map((platform) => platform?.substring(0, 100)),
      userAgent: navigator?.userAgent ?? '',
      changedAt: new Date().toISOString(),
    };
  }

  private async pullFromStorage(): Promise<UsageStatistic[]> {
    this.loggingService.debug(LOG_SOURCE, `pullFromStorage called.`);
    const stringValue = localStorage.getItem(LOCAL_STORAGE_KEY_USAGE_STATISTICS);
    if (stringValue) {
      const value: Array<UsageStatistic> = JSON.parse(stringValue);
      localStorage.removeItem(LOCAL_STORAGE_KEY_USAGE_STATISTICS);
      this.loggingService.debug(LOG_SOURCE, `pullFromStorage - ${value.length} elements found in storage.`);
      return value;
    }
    this.loggingService.debug(LOG_SOURCE, 'pullFromStorage - nothing found in storage.');
    return [];
  }

  private async setToStorage(usageStatistics: UsageStatistic[]) {
    this.loggingService.debug(LOG_SOURCE, `setToStorage (length=${usageStatistics.length}) called.`);
    localStorage.setItem(LOCAL_STORAGE_KEY_USAGE_STATISTICS, JSON.stringify(usageStatistics));
  }
}
