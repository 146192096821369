<ion-content class="content-project-room-attachments">
  <rx-virtual-scroll-viewport [dynamic]="getItemHeight">
    <div *rxVirtualFor="let attachmentGroup of groupedAttachments$; trackBy: trackByCreatedAt;" rxVirtualForStrategy="normal">
      <div *ngIf="attachmentGroup.firstInGroup" class="ion-padding-top padding-bottom-5 ion-padding-start">{{attachmentGroup.createdAt}}</div>
      <div class="d-flex ion-padding-start ion-wrap item-container">
        <div *ngFor="let attachment of attachmentGroup.attachments; trackBy: trackById;" class="ion-padding-start item attachment-item-container-large-square-size">
          <div *ngIf="isEntryAttachment(attachment)" class="entry-icon-container"><fa-icon [icon]="['fal', 'clipboard']" class="entry-icon"></fa-icon></div>
          <div *ngIf="isReportAttachment(attachment)" class="entry-icon-container"><fa-icon [icon]="['fal', 'file-alt']" class="entry-icon"></fa-icon></div>
          <app-attachment-viewer (click)="selectMode ? toggleSelection(attachment) : openFullScreen(attachment)" [showSelectedImageIcon]="selectMode && isAttachmentSelected(attachment)" [attachment]="attachment" [showChatImageIcon]="false" size="large-square" class="text-primary">
          </app-attachment-viewer>
        </div>
      </div>
    </div>
  </rx-virtual-scroll-viewport>
</ion-content>
