import {Component} from '@angular/core';
import {IonMenuService} from 'src/app/services/ui/ion-menu.service';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent {

  constructor(private ionMenuService: IonMenuService) { }

  openMenu() {
    this.ionMenuService.ionMenu?.open();
  }

}
