import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ProtocolEntryDataService} from 'src/app/services/data/protocol-entry-data.service';
import {ProtocolLayoutDataService} from 'src/app/services/data/protocol-layout-data.service';
import {ProtocolTypeDataService} from 'src/app/services/data/protocol-type-data.service';
import {AnalyticsService} from 'src/app/services/firebase/analytics.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {LicenseType, Protocol, ProtocolLayout, ProtocolType} from 'submodules/baumaster-v2-common';
import {ProtocolEntryOrOpen} from '../../../model/protocol';
import {ProtocolService} from '../../../services/protocol/protocol.service';
import {ParticipantDataService} from 'src/app/services/data/participant-data.service';
import {PopoverService} from 'src/app/services/ui/popover.service';
import _ from 'lodash';
import {PdfWorkflowComponent} from '../../pdf/pdf-workflow/pdf-workflow.component';
import {observableToPromise} from 'src/app/utils/async-utils';
import {CopyProtocolComponent} from '../../copy/copy-protocol/copy-protocol.component';
import {TranslateService} from '@ngx-translate/core';
import {NetworkStatusService} from 'src/app/services/common/network-status.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {AlertService} from 'src/app/services/ui/alert.service';
import {PdfProtocolService} from 'src/app/services/pdf/pdf-protocol.service';
import {LoadingService} from 'src/app/services/common/loading.service';
import {TriggerEntrySaveService} from 'src/app/services/protocol/trigger-entry-save.service';
import {FeatureEnabledService} from '../../../services/feature/feature-enabled.service';
import {ProtocolDetailEditComponent} from '../protocol-detail-edit/protocol-detail-edit.component';
import { ProtocolDeletionService } from 'src/app/services/protocol/protocol-deletion.service';
import { ProjectDataService } from 'src/app/services/data/project-data.service';
import { SystemEventService } from 'src/app/services/event/system-event.service';
import { OmgToastService } from 'src/app/services/ui/omg-toast.service';
import { OfflineInfoService } from 'src/app/services/common/offline-info.service';

const LOG_SOURCE = 'ProtocolDetailComponent';

@Component({
  selector: 'app-protocol-detail',
  templateUrl: './protocol-detail.component.html',
  styleUrls: ['./protocol-detail.component.scss'],
})
export class ProtocolDetailComponent implements OnInit, OnChanges, OnDestroy {

  @Input() routerLink: string[];
  @Input() protocol: Protocol;
  @Input() active = false;

  public shouldMarkActive$ = this.deviceService.isAboveBreakpoint(Breakpoints.xxl);

  public protocolEntriesData: Observable<Array<ProtocolEntryOrOpen>>;
  public protocolEntriesNotDone: Observable<Array<ProtocolEntryOrOpen>>;
  public wasSentByMail$?: Observable<boolean>;

  public protocolType: Observable<ProtocolType|null>;
  public protocolLayouts: Record<string, ProtocolLayout>|undefined;
  private protocolLayoutsSubscription: Subscription;
  private hasPermissionToCloseProtocol$ = this.featureEnabledService.isFeatureEnabled$(true, false, [LicenseType.BASIC, LicenseType.PROFESSIONAL, LicenseType.LIGHT], true);

  constructor(private protocolEntryDataService: ProtocolEntryDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private router: Router,
              private protocolLayoutDataService: ProtocolLayoutDataService,
              private deviceService: DeviceService,
              private analyticsService: AnalyticsService,
              private protocolService: ProtocolService,
              private participantDataService: ParticipantDataService,
              private popoverService: PopoverService,
              private modalController: ModalController,
              private translateService: TranslateService,
              private networkStatusService: NetworkStatusService,
              private toastService: ToastService,
              private alertService: AlertService,
              private pdfProtocolService: PdfProtocolService,
              private loadingService: LoadingService,
              private triggerEntrySaveService: TriggerEntrySaveService,
              private featureEnabledService: FeatureEnabledService,
              private protocolDeletionService: ProtocolDeletionService,
              private alertCtrl: AlertController,
              private projectDataService: ProjectDataService,
              private systemEventService: SystemEventService,
              private omgToastService: OmgToastService,
              private offlineInfoService: OfflineInfoService) {
  }

  ngOnInit() {
    this.protocolLayoutsSubscription = this.protocolLayoutDataService.dataGroupedById.subscribe((protocolLayouts) => this.protocolLayouts = protocolLayouts);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.protocolEntriesData = this.protocolEntryDataService.getProtocolEntryOrOpenByProtocolId(this.protocol.id);
    this.protocolEntriesNotDone = this.protocolEntryDataService.getNotDoneEntriesOrOpenByProtocolId(this.protocol.id, this.protocolService.getIsProtocolLayoutShort$(this.protocol.id));
    this.protocolType = this.protocolTypeDataService.getById(this.protocol.typeId);
    this.wasSentByMail$ = this.participantDataService.getAllByProtocolId(this.protocol.id)
      .pipe(map((participants) => participants.some((participant) => participant.mailingList && (participant.pdfpreviewId || (this.protocol.closedAt && !participant.pdfpreviewId)))));
  }

  ngOnDestroy(): void {
    this.protocolLayoutsSubscription?.unsubscribe();
  }

  async onClickEllipsis(event) {
    event.stopPropagation();
    const role = (_.isEmpty(this.protocol?.closedAt)) ? 'edit' : 'details';
    const label = (_.isEmpty(this.protocol?.closedAt)) ? 'edit' : 'details';
    const result = await this.popoverService.openActions(event, [
      {
        role,
        label,
        icon: ['far', 'pencil'],
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER]
        }
      },
      {
        role: 'copy',
        label: 'copy',
        icon: ['far', 'copy'],
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER]
        }
      },
      ...(!this.protocol.closedAt && await this.pdfProtocolService.canCloseProtocol(this.protocol.id) ? [{
        role: 'close' as const,
        label: 'protocol.popover.close',
        icon: ['far', 'lock-alt'] as [string, string],
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER]
        },
        disabled: false
      }] : []),
      ...(this.protocol.closedAt && await this.pdfProtocolService.canOpenProtocol(this.protocol.id) && await this.hasPermissionToOpenProtocol() ? [{
        role: 'open' as const,
        label: 'protocol.popover.open',
        icon: ['far', 'lock-open-alt'] as [string, string],
        disabled: false
      }] : []),
      {
        role: 'sendPdf',
        label: 'sendPdf',
        icon: ['far', 'paper-plane'],
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER]
        }
      },
      {
        role: 'delete',
        label: 'delete',
        icon: ['far', 'trash-alt'],
        permissions: {
          featureEnabled: false,
          forConnected: true,
          forLicenses: [LicenseType.VIEWER]
        },
        lookDisabled: !(await this.protocolDeletionService.isDeletable(this.protocol))
      }
    ]);

    if (result !== 'backdrop' && result !== 'feature-disabled-connected' && result !== 'feature-disabled-license') {
      switch (result) {
        case 'edit':
          await this.editProtocol();
          break;
        case 'details':
          await this.editProtocol();
          break;
        case 'copy':
          await this.copyProtocol();
          break;
        case 'close':
          await this.closeProtocol();
          break;
        case 'open':
          await this.openProtocol();
          break;
        case 'sendPdf':
          await this.sendPdf();
          break;
        case 'delete':
          await this.deleteProtocol();
          break;
        default:
          throw new Error('Unsupported action: ' + result);
      }
    }
  }

  goToProtocolEntries() {
    this.router.navigate(this.routerLink, {
      state: {
        suppressAutoScroll: true,
      }
    });
  }

  logEvent(event: PointerEvent, type: 'lock' | 'envelope') {
    const isMouse = event.pointerType !== 'touch';
    this.analyticsService.logEvent(LOG_SOURCE, `Icon ${type} clicked (via ${isMouse ? 'mouse' : 'touch'})`);
  }

  async editProtocol() {
    const modal = await this.modalController.create({
      component: ProtocolDetailEditComponent,
      backdropDismiss: true,
      componentProps: {
        protocolId: this.protocol.id
      },
      cssClass: 'protocol-entry-modal'
    });
    await modal.present();
  }

  async copyProtocol() {
    const modal = await this.modalController.create({
      component: CopyProtocolComponent,
      keyboardClose: false,
      backdropDismiss: true,
      componentProps: {
        protocolId: this.protocol.id,
      }
    });
    await modal.present();
  }

  async deleteProtocol() {
    const isDeletable = await this.protocolDeletionService.isDeletable(this.protocol);

    if (!isDeletable && this.protocol.closedAt !== null) {
      await this.alertService.ok({
        header: 'protocol.notDeleteable.headerClosed',
        message: 'protocol.notDeleteable.messageClosed',
        confirmLabel: 'close'
      });
      return;
    }

    if (!isDeletable && this.protocol.closedAt === null) {
      await this.alertService.ok({
        header: 'protocol.notDeleteable.headerEntries',
        message: 'protocol.notDeleteable.messageEntries',
        confirmLabel: 'close'
      });
      return;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolDeletion.confirm_header'),
      message: this.translateService.instant('protocolDeletion.confirm_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            const logInstance = this.systemEventService.logAction(LOG_SOURCE, () => `Delete protocol (id=${this.protocol.id}, number=${this.protocol.number}, typeId=${this.protocol.typeId})`);
            try {
              const currentProject = await this.projectDataService.getCurrentProject();
              await this.protocolDeletionService.deleteProtocolOnServer(this.protocol, currentProject);
              await this.router.navigate(['/protocols'], {replaceUrl: true});
              logInstance.success();
            } catch (e) {
              logInstance.failure(e);
              throw e;
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async sendPdf() {
    await this.triggerEntrySaveService.triggerSave();
    const protocolValidation = await observableToPromise(this.protocolService.isValidForSendPdfProtocol(this.protocol.id));
    if (!protocolValidation.valid) {
      const message = !protocolValidation.message ? `${this.translateService.instant('pdfProtocol.errorMissingInformation')}` : protocolValidation.message;
      await this.alertService.ok({message});
      return;
    }
    const modal = await this.modalController.create({
      component: PdfWorkflowComponent,
      keyboardClose: false,
      backdropDismiss: false,
      cssClass: 'pdf-workflow-modal',
      componentProps: {
        protocol: this.protocol
      }
    });
    await modal.present();
  }

  async closeProtocol() {
    if (!(await observableToPromise(this.networkStatusService.online$))) {
      if (await this.offlineInfoService.showOfflineAlert(this.translateService.instant('offlineInfo.description3'))) {
        await this.networkStatusService.isOnline();
        this.closeProtocol();
      }
      return;
    }
    const protocolValidation = await observableToPromise(this.protocolService.isValidForSendPdfProtocol(this.protocol.id));
    if (!protocolValidation.valid) {
      const validationMessage = !protocolValidation.message ? `${this.translateService.instant('pdfProtocol.errorMissingInformationClose')}` : protocolValidation.message;
      await this.alertService.ok({message: validationMessage});
      return;
    }
    const message = 'protocol.popover.confirmClose';
    const confirm = await this.alertService.confirm({
      message,
      confirmLabel: 'sendProtocol.closeProtocol',
      confirmButton: {
        fill: 'solid'
      }
    });
    if (confirm) {
      await this.loadingService.withLoading(async () => {
        const closeFinished = await this.pdfProtocolService.closeProtocol(this.protocol.id);
        if (closeFinished) {
          await this.toastService.info('protocol.toast.closeSuccess');
        }
      }, {message: this.translateService.instant('protocol.popover.closing')});
    }
  }

  async openProtocol() {
    if (this.networkStatusService.offline) {
      await this.toastService.info('protocol.toast.openOfflineNotPossible');
      return;
    }
    const message = 'protocol.popover.confirmOpen';
    const confirm = await this.alertService.confirm({
      message,
      confirmLabel: 'protocol.openProtocol',
      confirmButton: {
        fill: 'solid'
      }
    });
    if (confirm) {
      await this.loadingService.withLoading(async () => {
        const openFinished = await this.pdfProtocolService.openProtocol(this.protocol.id);
        if (openFinished) {
          await this.toastService.info('protocol.toast.openSuccess');
        }
      }, {message: this.translateService.instant('protocol.popover.opening')});
    }
  }

  private async hasPermissionToOpenProtocol(): Promise<boolean> {
    return await observableToPromise(this.hasPermissionToCloseProtocol$);
  }
}
