import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {filter, map, startWith} from 'rxjs/operators';
import {InitialSyncPlaceholderComponent} from 'src/app/components/placeholders/initial-sync-placeholder/initial-sync-placeholder.component';
import {NoProjectsPlaceholderComponent} from 'src/app/components/placeholders/no-projects-placeholder/no-projects-placeholder.component';
import {MainHeaderModule} from 'src/app/modules/main-header/main-header.module';
import {AuthenticationService} from 'src/app/services/auth/authentication.service';
import {AppDataPageService} from 'src/app/services/data/app-data-page.service';
import {UserService} from 'src/app/services/user/user.service';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {LogoutService} from 'src/app/services/logout.service';
import {RxLet} from '@rx-angular/template/let';

@Component({
  selector: 'app-app-data',
  templateUrl: './app-data.page.html',
  styleUrls: ['./app-data.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    InitialSyncPlaceholderComponent,
    NoProjectsPlaceholderComponent,
    MainHeaderModule,
    RxLet
  ]
})
export class AppDataPage implements OnInit {

  currentRoute$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map((event) => event.url),
    startWith(this.router.url)
  );
  data$ = combineLatestAsync([
    this.appDataPageService.allowInteraction$.pipe(startWith(false)),
    this.appDataPageService.initialSync$.pipe(startWith(false)),
    this.appDataPageService.hasProjects$.pipe(startWith(false)),
    this.currentRoute$,
    this.userService.isCurrentUserAdmin$,
    this.authService.isAuthenticated$
  ]).pipe(
    map(([allowInteraction, initialSync, hasProjects, currentRoute, isCurrentUserAdmin, isAuthenticated]) => ({
      allowInteraction, initialSync, hasProjects, isCurrentUserAdmin, isAuthenticated,
      allowedWhenNoProjects: currentRoute.startsWith('/the-settings') || (isCurrentUserAdmin && currentRoute.startsWith('/projects'))
    }))
  );

  constructor(
    private appDataPageService: AppDataPageService,
    private router: Router,
    private userService: UserService,
    private authService: AuthenticationService,
    private logoutService: LogoutService
  ) {}

  ngOnInit() {}

  logout() {
    this.logoutService.logout();
  }

}
