import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {observableToPromise} from '../utils/async-utils';
import {AuthenticationService} from './auth/authentication.service';
import {ToastService} from './common/toast.service';
import {SyncStatusService} from './sync/sync-status.service';
import {UserDeviceService} from './auth/user-device.service';
import {LoggingService} from './common/logging.service';
import {convertErrorToMessage} from '../shared/errors';
import posthog from 'posthog-js';
import {SyncStrategy} from './sync/sync-utils';
import {SyncService} from './sync/sync.service';
import {PosthogService} from './posthog/posthog.service';
import {ToastDurationInMs} from '../shared/constants';

const LOG_SOURCE = 'LogoutService';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(private loadingController: LoadingController,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private toastService: ToastService,
              private syncStatusService: SyncStatusService,
              private userDeviceService: UserDeviceService,
              private alertController: AlertController,
              private loggingService: LoggingService,
              private posthogService: PosthogService,
              private injector: Injector) { }

  private async performLogout(hadLocalChanges: boolean, hadNotUploadedAttachments: boolean, hadAttachmentsInErrorQueue: boolean) {
    this.posthogService.captureEvent('[Logout] User logged out', {
      hadLocalChanges,
      hadNotUploadedAttachments,
      hadAttachmentsInErrorQueue 
    });
    const loading = await this.loadingController.create({
      message: this.translateService.instant('LOGOUT.logging_out'),
    });

    await loading.present();
    try {
      await this.userDeviceService.deactivateUserDevice(undefined, 10000);
    } catch (error) {
      this.loggingService.warn(LOG_SOURCE, `Error performLogout when calling deactivateUserDevice. Continue with logout. ${convertErrorToMessage(error)}`);
    }
    posthog.reset();
    await this.authenticationService.logout();
    await loading.dismiss();

    await this.router.navigate(['/login'], { replaceUrl: true });

    await this.toastService.info('LOGOUT.successful_logout');
  }

  async logout(): Promise<boolean> {
    const hasLocalChanges = (await observableToPromise(this.syncStatusService.clientOrProjectsWithLocalChanges$)).size > 0;

    const numberOfAttachmentsNotUploaded = await observableToPromise(this.syncStatusService.numberOfAttachmentsInUploadQueueObservable);
    const numberOfAttachmentsInErrorQueue = await observableToPromise(this.syncStatusService.numberOfAttachmentsInErrorUploadQueueObservable);

    let message: string;

    const buttons = [];
    let logoutButtonText = this.translateService.instant('LOGOUT.deleteAndLogout');
    let headerMessage = this.translateService.instant('LOGOUT.header_confirmation');
    let logoutButtonCss = 'ion-color-danger alert-button-solid';

    if (hasLocalChanges) {
      message = this.translateService.instant('LOGOUT.confirmation_message_local_changes');
      headerMessage = this.translateService.instant('LOGOUT.header_confirmation_data_loss');
      buttons.push({
        text: this.translateService.instant('LOGOUT.synchronize'),
        cssClass: 'ion-color-text-primary alert-button-outline',
        role: 'sync',
        handler: () => {
          this.syncDataActive();
        }
      });
    } else if (numberOfAttachmentsNotUploaded > 0) {
      message = this.translateService.instant('LOGOUT.confirmation_message_attachments_not_uploaded');
      headerMessage = this.translateService.instant('LOGOUT.header_confirmation_attachment_loss');
      buttons.push({
        text: this.translateService.instant('LOGOUT.synchronize'),
        cssClass: 'ion-color-text-primary alert-button-outline',
        role: 'sync',
        handler: () => {
          this.syncDataActive();
        }
      });
    } else if (numberOfAttachmentsInErrorQueue > 0) {
      message = this.translateService.instant('LOGOUT.confirmation_message_attachments_not_uploaded_error_queue');
      headerMessage = this.translateService.instant('LOGOUT.header_confirmation_attachment_error');
      buttons.push({
        text: this.translateService.instant('LOGOUT.synchronize'),
        cssClass: 'ion-color-text-primary alert-button-outline',
        role: 'sync',
        handler: () => {
          this.uploadFilesFromErrorQueue();
        }
      });
    } else {
      logoutButtonText = this.translateService.instant('MENU.logout');
      message = this.translateService.instant('LOGOUT.confirmation_message');
      logoutButtonCss = 'ion-color-danger alert-button-outline';
      buttons.push({
        text: this.translateService.instant('cancel'),
        cssClass: 'ion-color-alternative alert-button',
        role: 'cancel'
      });
    }

    buttons.push({
      text: logoutButtonText,
      cssClass: logoutButtonCss,
      role: 'logout',
      handler: () => {
        this.performLogout(hasLocalChanges, numberOfAttachmentsNotUploaded > 0, numberOfAttachmentsInErrorQueue > 0)
      }
    },
    {
      text: '×',
      cssClass: 'alert-button-x-to-close ion-color-alternative',
      role: 'cancel',
    });

    const alert = await this.alertController.create({
      header: headerMessage,
      message,
      buttons
    });

    await alert.present();
    const alertResult  = await alert.onDidDismiss();
    return alertResult?.role === 'logout';
  }

  async syncDataActive() {
    const syncService = this.injector.get<SyncService>(SyncService);
    await syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES, {showInfoToastMessages: true});
  }

  async uploadFilesFromErrorQueue() {
    const syncService = this.injector.get<SyncService>(SyncService);
    const filesScheduled = await syncService.uploadFilesFromErrorQueue();
    if (filesScheduled === 0) {
      await this.toastService.infoWithMessage('sync_no_attachments_in_error_queue');
    } else {
      await this.toastService.toastWithTranslateParams('sync_attachments_moved_from_error_to_upload_queue', {count: filesScheduled}, ToastDurationInMs.INFO_WITH_MESSAGE);
    }
  }
}
