import {
  Address,
  Attachment,
  AttachmentBim,
  AttachmentBimMarkerScreenshot,
  AttachmentChat,
  AttachmentClient,
  AttachmentProjectBanner,
  AttachmentProjectBannerType,
  AttachmentProjectImage,
  AttachmentProtocolEntry,
  AttachmentProtocolSignature,
  AttachmentUserEmailSignature,
  BimMarker,
  BimVersion,
  Client,
  Company,
  Craft,
  IdType,
  NameableDropdown,
  NameableDropdownItem,
  Participant,
  PdfPlanAttachment,
  PdfPlanMarkerProtocolEntry,
  PdfPlanPage, PdfPlanPageMarking,
  PdfPlanVersion,
  Profile,
  ProfileCraft,
  Project,
  ProjectCompany,
  ProjectProfile,
  Protocol,
  ProtocolEntry,
  ProtocolEntryChat,
  ProtocolEntryCompany,
  ProtocolEntryLocation,
  ProtocolEntryType,
  ProtocolLayout,
  ProtocolOpenEntry,
  ProtocolType,
  UserPublic
} from '../../models';

export type ClientAttachmentType = 'logo' | 'pdfStartBanner' | 'pdfEndBanner';

export enum PdfColor {
  BLUE = '#2691C8',
  YELLOW = '#FFDB00',
  RED = '#AD003E',
  GREEN = '#78C832',
  DARK_GRAY = '#5D5D5D',
  LIGH_GRAY = '#B3B3B3'
}

export interface AttachmentWithContent<T extends Attachment> {
  attachment: T;
  contentBase64?: string;
  publicLink?: string;
}

export interface PdfPlanAttachmentWithContent extends AttachmentWithContent<PdfPlanPage> {
  secondContentBase64?: string;
  secondPublicLink?: string;
  protocolEntryId: IdType;
  pdfPlanMarkerProtocolEntryId?: IdType;
  pdfPlanPageMarkingId?: IdType;
  markings?: Array<string>;
}

export interface HeaderFooterData {
  pdfProjectBanners?: Map<AttachmentProjectBannerType, AttachmentWithContent<AttachmentProjectBanner>>;
  attachmentClients: Map<ClientAttachmentType, AttachmentWithContent<AttachmentClient>>;
  project?: Project;
  protocol?: Protocol;
  attachmentProjectImage?: AttachmentWithContent<AttachmentProjectImage>;
  lookup: {
    protocolTypes?: Map<IdType, ProtocolType>;
  };
}

export interface PdfLookupData {
    protocols: Map<IdType, Protocol>;
    protocolTypes: Map<IdType, ProtocolType>;
    protocolLayouts: Map<IdType, ProtocolLayout>;
    protocolEntryTypes: Map<IdType, ProtocolEntryType>;
    companies: Map<IdType, Company>;
    projectCompanies: Map<IdType, ProjectCompany>;
    projectProfiles: Array<ProjectProfile>;
    profileCrafts: Array<ProfileCraft>;
    crafts: Map<IdType, Craft>;
    userPublicData: Map<IdType, UserPublic>;
    protocolEntryLocations: Map<IdType, ProtocolEntryLocation>;
    nameableDropdowns: Map<IdType, NameableDropdown>;
    nameableDropdownItems: Map<IdType, NameableDropdownItem>;
    profiles: Map<IdType, Profile>;
    addresses: Map<IdType, Address>;
    clients: Map<IdType, Client>;
    profilesByAttachedToUserId: Map<IdType, Profile>;
}

export interface PdfProtocolGenerateData {
  userId: IdType;
  client: Client;
  project: Project;
  projectAddress: Address|undefined;
  protocol: Protocol;
  protocolEntries: Array<ProtocolEntry>;
  protocolEntryChats: Array<ProtocolEntryChat>;
  protocolEntryCompanies: Array<ProtocolEntryCompany>;
  protocolOpenEntries: Array<ProtocolOpenEntry>;
  pdfPlanMarkerProtocolEntries: Array<PdfPlanMarkerProtocolEntry>;
  pdfPlanPageMarkings: Array<PdfPlanPageMarking>;
  participants: Array<Participant>;
  pdfProjectBanners?: Map<AttachmentProjectBannerType, AttachmentWithContent<AttachmentProjectBanner>>;
  attachmentProtocolEntries?: Array<AttachmentWithContent<AttachmentProtocolEntry>>;
  attachmentChats?: Array<AttachmentWithContent<AttachmentChat>>;
  attachmentClients?: Map<ClientAttachmentType, AttachmentWithContent<AttachmentClient>>;
  pdfPlanPages?: Array<PdfPlanAttachmentWithContent>;
  pdfPlanVersions?: Array<PdfPlanVersion>;
  pdfPlanVersionsWithContent?: Array<AttachmentWithContent<PdfPlanAttachment>>;
  attachmentProjectImage?: AttachmentWithContent<AttachmentProjectImage>;
  attachmentProtocolSignatures?: Array<AttachmentWithContent<AttachmentProtocolSignature>>;
  filteredProtocolEntries?: Array<ProtocolEntry>;
  attachmentUserEmailSignature?: AttachmentWithContent<AttachmentUserEmailSignature>;
  attachmentBims?: Array<AttachmentBim>;
  bimMarkers?: Array<BimMarker>;
  bimVersions?: Array<BimVersion>;
  attachmentBimMarkerScreenshots?: Map<IdType, AttachmentWithContent<AttachmentBimMarkerScreenshot>>;
  attachmentsMissingContent: Array<Attachment>;
  lookup: PdfLookupData;
}

export interface GlobalSearchFilter {
  key: string;
  values: string[];
}

export interface GlobalSearchPdfProtocolGenerateData  {
  pdfTitle: string;
  language: string;
  client: Client;
  participants: Array<Participant>;
  searchFilter?: GlobalSearchFilter[];
  protocolDataList: PdfProtocolGenerateData[];
  pdfProjectBanners?: Map<AttachmentProjectBannerType, AttachmentWithContent<AttachmentProjectBanner>>;
  attachmentClients?: Map<ClientAttachmentType, AttachmentWithContent<AttachmentClient>>;
  attachmentUserEmailSignature?: AttachmentWithContent<AttachmentUserEmailSignature>;
  attachmentsMissingContent: Array<Attachment>;
  lookup: {
    companies: Map<IdType, Company>;
    profiles: Map<IdType, Profile>;
    addresses: Map<IdType, Address>;
    clients: Map<IdType, Client>;
    profileCrafts: Array<ProfileCraft>;
    projectProfiles: Array<ProjectProfile>;
    projectCompanies: Map<IdType, ProjectCompany>;
    crafts: Map<IdType, Craft>;
  };
}
