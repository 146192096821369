import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PdfMailingListCompany, PdfMailingListEmployee} from '../../../model/send-protocol';

@Component({
  selector: 'app-pdf-mailing-list-table',
  templateUrl: './pdf-mailing-list-table.component.html',
  styleUrls: ['./pdf-mailing-list-table.component.scss']
})

export class PdfMailingListTableComponent {

  @Input() company: PdfMailingListCompany;
  @Input() employees: Array<PdfMailingListEmployee>;
  @Input() isSearchMode: boolean;
  @Input() showEmptyResult: boolean;
  @Output() recipientChanged = new EventEmitter<{ employee: PdfMailingListEmployee }>();
  @Output() presentChanged = new EventEmitter<{ employee: PdfMailingListEmployee }>();
  @Input() showSeen = false;
  @Input() readOnly = false;
  @Input() showPresent = true;
  @Input() showMailingList = true;
  @Input() showCompanyPin = true;

  getCraftNames(companyContact: PdfMailingListCompany): string | undefined {
    return companyContact.crafts?.map(craft => craft.name).join(', ');
  }

  updatePresent(employee: PdfMailingListEmployee) {
    employee.participant.present = !employee.participant.present;
    this.presentChanged.emit({employee});
  }

  updateRecipient(employee: PdfMailingListEmployee) {
    employee.participant.mailingList = !employee.participant.mailingList;
    this.recipientChanged.emit({employee});
  }

  isCompanyPinned() {
    return typeof this.company?.projectCompany?.sortOrder === 'number';
  }
}
