import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IonInput} from '@ionic/angular';
import {Subject} from 'rxjs';
import {ErrorCodeAuthenticationType, User} from 'submodules/baumaster-v2-common';
import {SyncService} from '../../../services/sync/sync.service';
import {NetworkStatusService} from '../../../services/common/network-status.service';
import {ToastService} from '../../../services/common/toast.service';
import {takeUntil} from 'rxjs/operators';
import {Capacitor} from '@capacitor/core';
import {Keyboard} from '@capacitor/keyboard';
import {SyncStrategy} from '../../../services/sync/sync-utils';
import {HttpErrorResponse} from '@angular/common/http';
import {convertErrorToMessage, NetworkError, TimeoutError} from '../../../shared/errors';
import {UserService} from '../../../services/user/user.service';
import {EMAIL_REG_EXP_PATTERN} from '../../../shared/constants';

@Component({
  selector: 'app-username-update',
  templateUrl: './username-update.component.html',
  styleUrls: ['./username-update.component.scss'],
})
export class UsernameUpdateComponent implements OnInit, OnDestroy {
  private modal: HTMLIonModalElement;
  public userForm: UntypedFormGroup;
  @ViewChild('username', {static: false}) username: IonInput;
  private destroy$ = new Subject<void>();
  public user: User|undefined;
  public networkConnected: boolean|undefined;
  public saving = false;
  private abortController: AbortController|undefined;
  public readonly emailRegExpPattern = EMAIL_REG_EXP_PATTERN;

  constructor(private fb: UntypedFormBuilder,
              private userService: UserService, private syncService: SyncService, private networkStatusService: NetworkStatusService,
              private toastService: ToastService) {
    this.userForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(150)]]
    });
  }

  ngOnInit() {
    this.userService.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.userForm.reset({name: user.username});
    });
    this.networkStatusService.online$.pipe(takeUntil(this.destroy$)).subscribe((networkConnected) => this.networkConnected = networkConnected);
  }

  ionViewDidEnter() {
    setTimeout(async () => {
      await this.username.setFocus();
      if (Capacitor.isPluginAvailable('Keyboard')) {
        await Keyboard.show();
      }
    }, 500);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async save() {
    if (!this.savePossibleAndNecessary()) {
      return;
    }
    try {
      this.saving = true;
      this.abortController = new AbortController();
      const abortSignal = this.abortController.signal;
      await this.userService.updateUsername(this.user, this.userForm.controls.name.value, abortSignal);
      if (abortSignal.aborted) {
        return;
      }
      await this.syncService.startSync(SyncStrategy.CURRENT_PROJECT_AND_PROJECT_WITH_CHANGES);
      if (abortSignal.aborted) {
        return;
      }
      await this.toastService.info('saving_success');

      await this.modal.dismiss({role: 'save'});
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 400) {
        const httpError = error as HttpErrorResponse;
        const errorCode = httpError.error.errorCode as ErrorCodeAuthenticationType;
        if (errorCode === 'NAME_EXISTS') {
          this.userForm.controls.name.setErrors({nameExists: true});
          return;
        }
      } else if (error instanceof NetworkError || error instanceof TimeoutError || error?.name === 'TimeoutError') {
        this.userForm.controls.name.setErrors({networkError: true});
        return;
      }
      await this.toastService.error(`updateUsername.error`, {message: convertErrorToMessage(error)});
    } finally {
      this.saving = false;
      this.abortController = undefined;
    }
  }

  public async cancel() {
    this.abortController?.abort();
    await this.modal.dismiss({role: 'cancel'});
  }

  public savePossibleAndNecessary(): boolean {
    return this.userForm.valid && this.userForm.dirty && this.userForm.controls.name.value !== this.user?.username && this.networkConnected !== false;
  }
}
