import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NavController, ViewDidEnter} from '@ionic/angular';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, shareReplay, startWith, switchMap, take} from 'rxjs/operators';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolEntryTypeDataService} from 'src/app/services/data/protocol-entry-type-data.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ProjectService} from 'src/app/services/project/project.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {observableToPromise, switchMapOrDefault} from 'src/app/utils/async-utils';
import {getProtocolEntryPagePath, getTaskPagePath} from 'src/app/utils/router-utils';
import {IdType, LicenseType, ProtocolEntryType} from 'submodules/baumaster-v2-common';
import {ProtocolEntryEditComponent} from '../protocol-entry-edit/protocol-entry-edit.component';

@Component({
  selector: 'app-protocol-entry-modal',
  templateUrl: './protocol-entry-modal.component.html',
  styleUrls: ['./protocol-entry-modal.component.scss'],
  providers: [PageDidEnterLifecycleService],
})
export class ProtocolEntryModalComponent implements OnInit, ViewDidEnter {

  @ViewChild(ProtocolEntryEditComponent, {
    static: false
  })
  entryComponent: ProtocolEntryEditComponent;

  private readonly protocolIdSubject = new BehaviorSubject<IdType|null>(null);
  private modal: HTMLIonModalElement;

  @Input()
  protocolEntryId: IdType;
  @Input()
  context: string;
  @Input()
  set protocolId(protocolId: IdType|null) {
    this.protocolIdSubject.next(protocolId);
  }
  get protocolId() { return this.protocolIdSubject.value; }

  animationFinished = false;

  private readonly protocolProject$ = this.protocolIdSubject.pipe(
    filter((v) => !!v),
    switchMap((protocolId) => this.protocolService.getProjectByProtocolId(protocolId))
  );

  readonly isProtocolInConnectedClient$ = this.protocolProject$.pipe(
    switchMapOrDefault((project) => this.projectService.isProjectInConnectedClient$(project), true)
  );

  readonly isTaskProtocol$ = this.protocolIdSubject.pipe(
    filter((v) => !!v),
    switchMap((protocolId) => this.protocolService.isTaskProtocolAcrossProjects(protocolId)),
    startWith(false),
    shareReplay(1)
  );

  private readonly isEditEnabledProtocolEntry$ = this.featureEnabledService.isFeatureEnabled$(
    false, true, [LicenseType.VIEWER],
    null, null, this.isProtocolInConnectedClient$
  );
  private readonly isEditEnabledTask$ = this.featureEnabledService.isFeatureEnabled$(
    false, true, [LicenseType.VIEWER, LicenseType.LIGHT],
    null, null, this.isProtocolInConnectedClient$
  );

  readonly isEditEnabled$ = this.isTaskProtocol$.pipe(switchMap((isTask) => isTask ? this.isEditEnabledTask$ : this.isEditEnabledProtocolEntry$));

  readonly taskEntryTypeOrUndefined$: Observable<ProtocolEntryType|undefined> = this.isTaskProtocol$.pipe(
    switchMap((isTaskProtocol) => isTaskProtocol ? this.protocolEntryTypeDataService.taskEntryType$ : of(undefined))
  );

  constructor(
    private featureEnabledService: FeatureEnabledService,
    private protocolService: ProtocolService,
    private projectService: ProjectService,
    private projectDataService: ProjectDataService,
    private navController: NavController,
    private pageDidEnterLifecycleService: PageDidEnterLifecycleService,
    private protocolEntryTypeDataService: ProtocolEntryTypeDataService
  ) {}

  ngOnInit() {
    setTimeout(() => this.animationFinished = true, 500);
  }

  ionViewDidEnter() {
    this.pageDidEnterLifecycleService.pageDidEnter();
  }

  async close(role?: string) {
    await this.entryComponent?.forceSaveIfDirty();
    await this.modal.dismiss(undefined, role);
  }

  async goToEntry() {
    await this.close('goToEntry');
    const protocolProject = await observableToPromise(this.protocolProject$);
    const isTaskProtocol = await observableToPromise(this.isTaskProtocol$);
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    if (currentProject.id !== protocolProject?.id) {
      if (!(await this.projectService.setCurrentProject(protocolProject, {temporarily: true}))) {
        return;
      }
      await observableToPromise(this.projectDataService.currentProjectObservable.pipe(filter((project) => project?.id === protocolProject.id), take(1)));
    }

    const path = isTaskProtocol ? getTaskPagePath(this.protocolId, this.protocolEntryId) : getProtocolEntryPagePath(this.protocolId, this.protocolEntryId);

    await this.navController.navigateRoot(
      path,
      {
        replaceUrl: true,
        state: { protocolListShowActive: true },
      }
    );
  }

}
