import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {LoggingService} from 'src/app/services/common/logging.service';
import {AddressDataService} from 'src/app/services/data/address-data.service';
import {ProfileCraftDataService} from 'src/app/services/data/profile-craft-data.service';
import {ProfileDataService} from 'src/app/services/data/profile-data.service';
import {Address, Client, Company, Craft, IdType, Profile, ProfileCraft, ProjectProfile} from 'submodules/baumaster-v2-common';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';
import {Router} from '@angular/router';
import {ProjectProfileDataService} from 'src/app/services/data/project-profile-data.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {CompanyDataService} from '../../../services/data/company-data.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {observableToPromise} from 'src/app/utils/async-utils';
import {SystemEventService} from '../../../services/event/system-event.service';
import {ClientService} from '../../../services/client/client.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {ToastService} from 'src/app/services/common/toast.service';
import {convertErrorToMessage} from 'src/app/shared/errors';

const LOG_SOURCE = 'ContactEmployeCreateComponent';

@Component({
  selector: 'app-contact-employe-create',
  templateUrl: './contact-employe-create.component.html',
  styleUrls: ['./contact-employe-create.component.scss'],
})
export class ContactEmployeCreateComponent implements OnInit, OnDestroy {

  public contactEmployeeForm: UntypedFormGroup;
  public loading = false;
  public addToProject = true;
  private resetSearchFilter?: () => void;
  @Input()
  public companyId?: IdType;
  public company: Company | undefined;
  @Input() projectId: IdType | undefined;
  @Input() prePopulateMainFieldWithText?: string;
  @Input() createForSelectable = false;

  private destroy$ = new Subject<void>();

  private clientData: Client;
  private modal: HTMLIonModalElement;

  public notConnected$ = this.featureEnabledService.isFeatureEnabled$(false, true, null);

  constructor(private profileDataService: ProfileDataService,
              private addressDataService: AddressDataService,
              private profileCraftDataService: ProfileCraftDataService,
              private companyDataService: CompanyDataService,
              private projectCompanyDataService: ProjectCompanyDataService,
              private clientService: ClientService,
              private projectProfileDataService: ProjectProfileDataService,
              private translateService: TranslateService,
              private loggingService: LoggingService,
              private systemEventService: SystemEventService,
              private toastService: ToastService,
              private modalController: ModalController,
              private projectDataService: ProjectDataService,
              private alertCtrl: AlertController,
              private navParams: NavParams,
              private router: Router,
              private featureEnabledService: FeatureEnabledService) { }

  async ngOnInit() {
    this.clientService.ownClient$.pipe(takeUntil(this.destroy$)).subscribe(clientData => {
      this.clientData = clientData;
    });
    if (this.companyId) {
      this.companyDataService
        .getByIdForOwnClient(this.companyId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((company) => this.company = company);

      this.projectCompanyDataService
        .isCompanyInTheProject(this.companyId)
        .pipe(
          takeUntil(this.destroy$),
          map((isCompanyInTheProject, index) => [isCompanyInTheProject, index === 0])
        )
        .subscribe(([isCompanyInTheProject, isFirstTimeValue]) => {
          if (isFirstTimeValue) {
            this.addToProject = isCompanyInTheProject;
          }
        });
    }
    this.resetSearchFilter = this.navParams.data.resetSearchFilter;
    this.setCanDismiss();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async createNewContact() {
    const logInstance = this.systemEventService.logAction(LOG_SOURCE, `Create contact`);
    try {
      this.loading = true;

      const rawData = this.contactEmployeeForm.getRawValue();

      const isInProject = rawData.companyName?.id && await observableToPromise(this.projectCompanyDataService.isCompanyInTheProject(rawData.companyName.id).pipe(take(1)));

      if (this.addToProject && !isInProject) {
        const alert = await this.alertCtrl.create({
          message: this.translateService.instant('contactCreate.will_add_company_to_project', {
            name: rawData.companyName?.name
          }),
          buttons: [{
            text: this.translateService.instant('cancel'),
            role: 'cancel',
          }, {
            text: this.translateService.instant('okay'),
            role: 'accept',
          }]
        });

        await alert.present();

        const result = await alert.onDidDismiss();

        if (result.role !== 'accept') {
          this.loading = false;
          logInstance.success('cancelled');
          return;
        }
      }

      const createdAddress = _.head(await this.createAddress(rawData));
      logInstance.logCheckpoint(() => `address created (id=${createdAddress.id})`);
      const createdProfile = _.head(await this.createProfile(rawData, createdAddress.id));
      logInstance.logCheckpoint(() => `profile created (id=${createdProfile.id})`);
      await this.createProfileCrafts(rawData, createdProfile.id);
      logInstance.logCheckpoint(() => `profile crafts created (craftIds=${rawData.crafts?.map((craft) => craft?.id).join(',')})`);
      if (this.addToProject && await observableToPromise(this.notConnected$)) {
        await this.addProfileToProject(createdProfile);
        logInstance.logCheckpoint(`profile added to the project`);
        if (!isInProject) {
          await this.projectCompanyDataService.insertToProject(rawData.companyName.id);
          logInstance.logCheckpoint(() => `company (id=${rawData.companyName.id}) added to the project`);
        }
      }
      this.contactEmployeeForm.markAsPristine();
      this.dismissModal('ok', createdProfile);
      if (!this.createForSelectable) {
        await this.redirectToContactDetails(createdAddress.id);
      }
      if (this.resetSearchFilter) {
        this.resetSearchFilter();
      }
      logInstance.success();
    } catch (error) {
      logInstance.failure(error);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - save contact employee ', error?.userMessage + '-' + error?.message);
      this.loggingService.error(LOG_SOURCE, `Error save contact employee. "${error?.userMessage}" - "${error?.message}"`);
      await this.toastService.errorWithMessageAndHeader('error_saving_message', convertErrorToMessage(error));
      throw error;
    } finally {
      this.loading = false;
    }

  }

  private async redirectToContactDetails(addressId: IdType) {
    await this.router.navigate(['/contact-detail/employee/' + addressId]);
  }

  private async addProfileToProject(profile: Profile) {
    const projectId = this.projectId ?? (await this.projectDataService.getCurrentProject()).id;
    const projectProfile: ProjectProfile = {
      id: projectId + profile.id,
      projectId,
      profileId: profile.id,
      changedAt: new Date().toISOString()
    };
    await this.projectProfileDataService.insert(projectProfile, projectId);
  }

  private async createProfile(rawData: any, addressId: IdType): Promise<Profile[]> {
    const profile: Profile = {
      id: uuidv4(),
      note: rawData.note,
      profession: rawData.role,
      clientId: this.clientData.id,
      addressId,
      companyId: rawData.companyName?.id,
      dsgvoShowEmail: rawData.dsgvoShowEmail,
      dsgvoShowTel: rawData.dsgvoShowTel,
      changedAt: new Date().toISOString(),
      isActive: true
    };
    return await this.profileDataService.insert(profile, (await this.clientService.getOwnClientMandatory()).id);
  }

  private async createAddress(rawData): Promise<Address[]> {
    const address: Address = {
      id: uuidv4(),
      firstName: rawData.firstName,
      lastName: rawData.lastName,
      city: null,
      country: null,
      email: rawData.email,
      phone: rawData.phone,
      mobile: rawData.mobile,
      messenger: null,
      fax: rawData.fax,
      salutation: null,
      street1: null,
      street2: null,
      zipCode: null,
      changedAt: new Date().toISOString(),
      clientId: this.clientData.id,
    };
    return await this.addressDataService.insert(address, (await this.clientService.getOwnClientMandatory()).id);
  }

  private async createProfileCrafts(rawData: any, profileId: IdType) {
    if (!_.isEmpty(rawData.crafts)) {
      const profileCrafts = this.createObjectsProfileCrafts(rawData.crafts, profileId);
      await this.profileCraftDataService.insert(profileCrafts, (await this.clientService.getOwnClientMandatory()).id);
    }
  }


  private createObjectsProfileCrafts(crafts: Array<Craft>, profileId: IdType): Array<ProfileCraft> {
    return crafts.map(craft => {
      return {
        id: profileId + craft.id,
        craftId: craft.id,
        profileId,
        changedAt: new Date()
      } as ProfileCraft;
    });
  }

  dismissModal(role?: 'ok' | 'cancel', data?: Profile) {
    return this.modal.dismiss(data, role);
  }

  private canDismiss = async () => {
    if (!this.contactEmployeeForm?.dirty) {
      return true;
    }

    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolCreation.data_loss_header'),
      message: this.translateService.instant('protocolCreation.data_loss_message'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          role: 'cancel'
        },
        {
          text: this.translateService.instant('yes'),
          role: 'dismiss'
        }
      ]
    });
    await alert.present();
    return (await alert.onWillDismiss()).role === 'dismiss';
  };

  private setCanDismiss() {
    this.modal.canDismiss = this.canDismiss;
  }

  toggleAddToProject() {
    this.addToProject = !this.addToProject;
  }

}
