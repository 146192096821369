import { Component, Input, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NavController} from '@ionic/angular';
import {Observable} from 'rxjs';
import {ClientAdminGuard} from 'src/app/guards/client-admin.guard';
import {LogoutService} from 'src/app/services/logout.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {UserProfileService} from 'src/app/services/user/user-profile.service';
import {UserService} from 'src/app/services/user/user.service';

@Component({
  selector: 'app-main-header-settings-popover',
  templateUrl: './main-header-settings-popover.component.html',
  styleUrls: ['./main-header-settings-popover.component.scss'],
})
export class MainHeaderSettingsPopoverComponent implements OnInit {

  popover: HTMLIonPopoverElement;
  address$ = this.userProfileService.currentUserOwnAddress$;
  user$ = this.userService.currentUser$;

  @Input()
  takingPhotosSupported: boolean;
  @Input()
  isTakingPictureEnabled$: Observable<boolean>;
  @Input()
  onTakePicture: () => void;
  @Input()
  onTakePictures: () => void;

  constructor(
    public clientAdminGuard: ClientAdminGuard,
    private logoutService: LogoutService,
    private userProfileService: UserProfileService,
    private userService: UserService,
    private deviceService: DeviceService,
    private router: Router,
    private navController: NavController
  ) { }

  ngOnInit() {}

  async logout() {
    await this.logoutService.logout();
  }

  dismiss() {
    this.popover.dismiss();
  }

  takePicture() {
    this.onTakePicture();
    this.dismiss();
  }

  takePictures() {
    this.onTakePictures();
    this.dismiss();
  }

  async navigateToSettings(url: string) {
    if (!(this.deviceService.isAboveBreakpointSync(Breakpoints.lg))) {
      await this.navController.navigateRoot('/the-settings');
    }

    await this.router.navigateByUrl(url);
  }

}
