import {PdfProtocolSendReq} from '../../../../requestResponse';
import {PdfPreview} from '../../../../models';
import {PdfProtocolGenerateData} from '../../pdfProtocol.model';
import {AbstractProjectContent} from '../abstractProject.content';
import {Content} from 'pdfmake/interfaces';
import {PdfHelperFunctions} from '../../../common-report-utils';
import _ from 'lodash';

export class ProjectContent extends AbstractProjectContent {

  constructor(config: PdfProtocolSendReq, data: PdfProtocolGenerateData, pdfHelperFunctions: PdfHelperFunctions, pdfPreview?: PdfPreview) {
    super(data.project.language, config, data, pdfHelperFunctions, pdfPreview);
  }

  writeLetterhead(content: Content[]) {
    if (this.config.pdfProtocolSetting && !_.isEmpty(this.config.pdfProtocolSetting.letterheadText)) {
      content.push({
        text: this.config.pdfProtocolSetting.letterheadText ? this.config.pdfProtocolSetting.letterheadText : '',
        style: ['font12', 'letterHead'],
      });
    }
  }

  writeProject(content: Content[], indexNumber: number|undefined)  {
    this.writeProjectPhoto(content);
    this.writeCompanyInfo(content);
    if (this.config.pdfProtocolSetting?.showLetterhead) {
      this.writeLetterhead(content);
    }
    this.writeProjectSection(content, indexNumber);
    if (this.config.pdfProtocolSetting?.showWeather) {
      this.writeWeatherHeading(content);
      this.writeWeather(content);
    }
    this.writeNextMeeting(content);
    if (this.data.participants.length && (this.data.participants.some(participant => participant.mailingList || participant.present) || this.config.pdfProtocolSetting?.showAllContactsInProject)) {
      this.writeParticipants(content);
    }
    this.writeStartText(content);
  }

  writeAfterProtocolDetails(content: Content[]) {
    this.writeIndividualNextMeetings(content);
  }

  protected writeParticipants(content: Content[]) {
    if (!this.isHasProtocolEntries(this.data.protocolEntries) && this.config.participant !== undefined) {
      this.writeNoProtocolEntries(content);
    } else {
      this.writeParticipantsHeader(content);
      if (this.config.pdfProtocolSetting?.compactContactList) {
        this.writeCompactParticipantList(content, this.config.pdfProtocolSetting?.showParticipantCol);
      } else {
        this.writeParticipantList(content, this.config.pdfProtocolSetting?.showParticipantCol);
      }
    }
  }

}
