import {ColumnConfig} from '../ColumnConfig';

export const NotificationEventColumnSet: Array<ColumnConfig> = [
  { name: 'id', prop: 'id', cnd: true },
  { name: 'notification_config_id', prop: 'notificationConfigId' },
  { name: 'notification_event_type', prop: 'notificationEventType'},
  { name: 'protocol_entry_id', prop: 'protocolEntryId', def: null },
  { name: 'pdf_plan_version_id', prop: 'pdfPlanVersionId', def: null },
  { name: 'bim_version_id', prop: 'bimVersionId', def: null },
  { name: 'sent_at', prop: 'sentAt', cast: 'Timestamp', def: null },
  { name: 'notification_text', prop: 'notificationText' },
  { name: 'referencing_object_changed_at', prop: 'referencingObjectChangedAt', cast: 'Timestamp' },
  { name: 'created_at', prop: 'createdAt', cast: 'Timestamp' },
  { name: 'changed_at', prop: 'changedAt', cast: 'Timestamp' },
  { name: 'created_by_id', prop: 'createdById' },
];