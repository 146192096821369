import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadDirective } from '../../../directives/download.directive';



@NgModule({
  declarations: [DownloadDirective],
  imports: [
    CommonModule
  ],
  exports: [DownloadDirective]
})
export class DownloadModule { }
