import {Injectable} from '@angular/core';
import {ProtocolEntryDataService} from '../data/protocol-entry-data.service';
import {AttachmentEntryDataService} from '../data/attachment-entry-data.service';
import {
  Address,
  Attachment,
  AttachmentProtocolEntry,
  Client,
  CommonEntryMailService,
  Company,
  Craft,
  EntryMailData,
  EntryMailReq,
  IdAware,
  IdType,
  NameableDropdown,
  Profile,
  Project,
  Protocol,
  ProtocolEntry,
  ProtocolEntryCompany,
  ProtocolEntryLocation,
  ProtocolEntryType,
  ProtocolType
} from 'submodules/baumaster-v2-common';
import {combineLatestAsync, observableToPromise} from '../../utils/async-utils';
import {LoggingService} from '../common/logging.service';
import {PhotoService} from '../photo/photo.service';
import {ProjectDataService} from '../data/project-data.service';
import {ProtocolEntryCompanyDataService} from '../data/protocol-entry-company-data.service';
import {Observable} from 'rxjs';
import {CompanyDataService} from '../data/company-data.service';
import {CraftDataService} from '../data/craft-data.service';
import {AddressDataService} from '../data/address-data.service';
import {ProfileDataService} from '../data/profile-data.service';
import {ProtocolEntryTypeDataService} from '../data/protocol-entry-type-data.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {switchMap} from 'rxjs/operators';
import _ from 'lodash';
import {ProtocolDataService} from '../data/protocol-data.service';
import {ProtocolTypeDataService} from '../data/protocol-type-data.service';
import {ClientService} from '../client/client.service';
import {NameableDropdownDataService} from '../data/nameable-dropdown-data.service';
import {ProtocolEntryLocationDataService} from '../data/protocol-entry-location-data.service';

const LOG_SOURCE = 'EntryMailService';

@Injectable({
  providedIn: 'root'
})
export class EntryMailService {
  private commonEntryMailService = new CommonEntryMailService();

  constructor(private protocolEntryDataService: ProtocolEntryDataService,
              private attachmentEntryDataService: AttachmentEntryDataService,
              private photoService: PhotoService,
              private projectDataService: ProjectDataService,
              private protocolDataService: ProtocolDataService,
              private protocolEntryCompanyDataService: ProtocolEntryCompanyDataService,
              private companyDataService: CompanyDataService,
              private craftDataService: CraftDataService,
              private addressDataService: AddressDataService,
              private profileDataService: ProfileDataService,
              private protocolTypeDataService: ProtocolTypeDataService,
              private protocolEntryTypeDataService: ProtocolEntryTypeDataService,
              private loggingService: LoggingService,
              private http: HttpClient,
              private clientService: ClientService,
              private nameableDropdownDataService: NameableDropdownDataService,
              private protocolEntryLocationDataService: ProtocolEntryLocationDataService) {
  }

  public generateHtmlContent$(entryIds: Array<IdType>): Observable<{html: string, attachmentsMissingContent: Array<Attachment>}> {
    this.loggingService.debug(LOG_SOURCE, `generateHtmlContent$ called with ${entryIds?.length} entryIds.`);
    return combineLatestAsync([
      combineLatestAsync([this.projectDataService.currentProjectObservable,
        this.protocolDataService.data,
        this.protocolEntryDataService.getByIds(entryIds),
        this.attachmentEntryDataService.getByProtocolEntries(entryIds),
        this.protocolEntryCompanyDataService.data,
        this.clientService.currentClient$]),
      combineLatestAsync([this.companyDataService.dataAcrossClients$,
        this.craftDataService.dataAcrossClients$,
        this.addressDataService.dataAcrossClients$,
        this.profileDataService.dataAcrossClients$,
        this.protocolTypeDataService.dataAcrossClients$,
        this.protocolEntryTypeDataService.dataAcrossClients$,
        this.nameableDropdownDataService.dataAcrossClients$,
        this.protocolEntryLocationDataService.data])
    ])
      .pipe(switchMap(([
        [currentProject, protocols, entries, attachments, observerCompanies, client],
                        [companies, crafts, addresses, profiles, protocolTypes, protocolEntryTypes, nameableDropdowns, locations]]) => {
        const protocolIds = _.compact(_.uniq(entries.map((entry) => entry.protocolId)));
        const usedProtocols = protocols.filter((protocol) => protocolIds.includes(protocol.id));
        return this.generateHtmlContentInternal(entryIds, currentProject, client, usedProtocols, entries, attachments, observerCompanies,
          companies, crafts, addresses, profiles, protocolTypes, protocolEntryTypes, nameableDropdowns, locations);
      }));
  }

  private async generateHtmlContentInternal(entryIds: Array<IdType>, project: Project, client: Client, protocols: Array<Protocol>, entries: Array<ProtocolEntry>,
                                            attachments: Array<AttachmentProtocolEntry>, observerCompanies: Array<ProtocolEntryCompany>, companies: Array<Company>,
                                            crafts: Array<Craft>, addresses: Array<Address>, profiles: Array<Profile>, protocolTypes: Array<ProtocolType>,
                                            protocolEntryTypes: Array<ProtocolEntryType>, nameableDropdowns: Array<NameableDropdown>, locations: Array<ProtocolEntryLocation>
                                            ): Promise<{html: string, attachmentsMissingContent: Array<Attachment>}> {
    const attachmentsMissingContent = new Array<Attachment>();
    const attachmentsWithContent = await this.photoService.toAttachmentWithContent(attachments, 'thumbnail', attachmentsMissingContent, {orderByDate: true});

    const req: EntryMailReq = {
      entryIds,
      recipientProfileIds: []
    };

    const data: EntryMailData = {
      project,
      protocols,
      entries,
      attachmentsWithContent,
      observerCompanies,
      nameableDropdownName: client.nameableDropdownName,
      lookup: {
        companies: this.toMap(companies),
        crafts: this.toMap(crafts),
        addresses: this.toMap(addresses),
        profiles: this.toMap(profiles),
        protocolTypes: this.toMap(protocolTypes),
        protocolEntryTypes: this.toMap(protocolEntryTypes),
        nameableDropdowns: this.toMap(nameableDropdowns),
        locations: this.toMap(locations)
      }
    };
    const html = await this.commonEntryMailService.generateHtml(data, req);
    return {html, attachmentsMissingContent};
  }

  public toMap<T extends IdAware>(values: Array<T>): Map<IdType, T> {
    const valueMap = new Map<IdType, T>();
    for (const value of values) {
      valueMap.set(value.id, value);
    }
    return valueMap;
  }

  public async sendMail(req: EntryMailReq): Promise<void> {
    const url = await this.getSendPdfUrl();
    await observableToPromise(this.http.post<void>(url, req));
    return;
  }

  private async getSendPdfUrl(): Promise<string> {
    const currentProject = await this.projectDataService.getMandatoryCurrentProject();
    return environment.serverUrl + `api/entryMail/send?projectId=${currentProject.id}`;
  }
}
