import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';
import { scan } from 'rxjs/operators';
import { STORAGE_KEY_PROJECT_SEPARATOR } from 'src/app/shared/constants';
import { StorageMigration, StorageMigrationProgressEvent } from './storage-migration';

const createProgressEvent: (step: number, translationContext: any) => StorageMigrationProgressEvent = (step, translationContext) => {
  const event: StorageMigrationProgressEvent = {
    allSteps: 3,
    currentStep: step,
    stepTranslation: '',
    translationContext: {
      step,
      allSteps: 3,
      loadedObjects: translationContext.loadedObjects,
      totalObjects: translationContext.totalObjects,
    }
  };

  switch (step) {
    case 2:
      event.stepTranslation = 'storageMigration.bucketPerKeyMigration.step2';
      break;
    case 3:
      event.stepTranslation = 'storageMigration.bucketPerKeyMigration.step3';
      break;
    case 1:
    default:
      event.stepTranslation = 'storageMigration.bucketPerKeyMigration.step1';
      break;
  }

  return event;
};

export const STORAGE_PREFIX = 'DB';

export const getBucketName = (prefix: string, key: string): string => {
  if (!key.includes(STORAGE_KEY_PROJECT_SEPARATOR)) {
    return prefix;
  }
  const [bucketName] = key.split(STORAGE_KEY_PROJECT_SEPARATOR).reverse();
  return `${prefix}_${bucketName}`;
};

const runMigration = async (storage: Storage, progressSubject: Subject<StorageMigrationProgressEvent>, keys: string[]): Promise<void> => {
  const keysFromPreviousVersion = keys.filter((key) => !key.startsWith(STORAGE_PREFIX));
  const existingKeys = keys.filter((key) => key.startsWith(STORAGE_PREFIX));

  const step1Subject = new Subject<void>();
  const step2Subject = new Subject<void>();
  const step3Subject = new Subject<void>();

  const startStep1Sub = () => {
    step1Subject.pipe(
      scan((acc) => acc + 1, 0)
    ).subscribe((loadedObjects) => {
      progressSubject.next(createProgressEvent(1, {
        loadedObjects,
        totalObjects: keys.length,
      }));
    });
  };

  const startStep2Sub = (totalObjects: number) => {
    step2Subject.pipe(
      scan((acc) => acc + 1, 0)
    ).subscribe((loadedObjects) => {
      progressSubject.next(createProgressEvent(2, {
        loadedObjects,
        totalObjects,
      }));
    });
  };

  const startStep3Sub = () => {
    step3Subject.pipe(
      scan((acc) => acc + 1, 0)
    ).subscribe((loadedObjects) => {
      progressSubject.next(createProgressEvent(3, {
        loadedObjects,
        totalObjects: keysFromPreviousVersion.length,
      }));
    });
  };

  startStep1Sub();
  startStep3Sub();

  const newState = {};

  const setBucket = (bucketName: string, value: any) => {
    newState[bucketName] = value ?? {};
  };

  const setValue = (key: string, value: any) => {
    const bucketName = getBucketName(STORAGE_PREFIX, key);
    if (!newState[bucketName]) {
      newState[bucketName] = {};
    }

    if (newState[bucketName][key]) {
      console.warn(`Migration: Overriding '${key}'!`);
    }

    newState[bucketName][key] = value;
  };

  if (existingKeys.length > 0) {
    console.warn(
      `Migration: Keys with migration prefix '${STORAGE_PREFIX}' already exists! (${existingKeys.join(',')})
Migration will attempt to merge overlapping keys, but will **override** conflicts!`);

    await Promise.all(existingKeys.map(async (bucketName) => {
      const value = await storage.get(bucketName);

      setBucket(bucketName, value);

      step1Subject.next();
    }));
  }

  await Promise.all(keysFromPreviousVersion.map(async (key) => {
    const value = await storage.get(key);

    setValue(key, value);

    step1Subject.next();
  }));

  step1Subject.complete();

  const entries = Object.entries(newState);

  startStep2Sub(entries.length);

  await Promise.all(entries.map(async ([bucketName, value]) => {
    await storage.set(bucketName, value);

    step2Subject.next();
  }));

  step2Subject.complete();

  await Promise.all(keysFromPreviousVersion.map(async (key) => {
    await storage.remove(key);

    step3Subject.next();
  }));

  step3Subject.complete();
};

const bucketsPerKeyMigration: StorageMigration = async (storage, progressSubject) => {
  // Check if it's a subject of migration
  const keys = await storage.keys();

  if (keys.length === 0 || keys.every((key) => key.startsWith(STORAGE_PREFIX))) {
    // Database is empty of all of the keys are a part of the storage
    return;
  }

  try {
    console.log(`[${new Date().toISOString()}] Running migration 0010 (buckets per key)...`);

    await runMigration(storage, progressSubject, keys);

    console.log(`[${new Date().toISOString()}] Migration 0010 (buckets per key) finished successfully!`);
    progressSubject.complete();
  } catch (e) {
    console.error(`[${new Date().toISOString()}] Failed to migrate 0010 (buckets per key). Reason: ${e?.message ?? e}`);
    progressSubject.complete();

    throw e;
  }
};
bucketsPerKeyMigration.migrationDetails = {
  name: 'buckets per key',
  number: '0010',
};

export { bucketsPerKeyMigration };
