import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {fabric} from 'fabric';
import _ from 'lodash';
import * as Hammer from 'hammerjs';
import {IdType, PdfPlanPageMarking, PdfPlanPageMarkingBase, ProtocolEntryIconStatus} from 'submodules/baumaster-v2-common';
import {LoadingController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LoggingService} from '../../../services/common/logging.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {FabricPdfPlanMarker, MarkerData, MarkerType} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/fabricPdfPlanMarker';
import {
  loadImageEnsureWidthHeight,
  loadMarkerImages,
  loadPdfPlanPageMarkings,
  loadPdfPlanPageMarkingsGrouped,
  zoomToCenter
} from '../../../../../submodules/baumaster-v2-common/dist/planMarker/planMarkerCanvasUtils';
import {convertErrorToMessage} from '../../../shared/errors';
import {MoveChoiceType} from '../../../model/pdf-plan-marker-migration';
import {IEvent} from 'fabric/fabric-impl';

const LOG_SOURCE = 'ImageCanvasComponent';
const ZOOMSPEED = 1.12, MIN_SCALE = 1, MAX_SCALE = 24;
const CANVAS_MARGIN_MARKER = 5;

interface PositionAxis {
  x: number;
  y: number;
}

@Component({
  selector: 'app-image-canvas',
  templateUrl: './image-canvas.component.html',
  styleUrls: ['./image-canvas.component.scss'],
})

export class ImageCanvasComponent implements OnInit, OnChanges, OnDestroy {

  @Input() src: string;
  @Input() srcWidth?: number|null;
  @Input() srcHeight?: number|null;
  /** Needs to be provided if adding new markers should be possible. This component will clone this object when creating new markers. */
  @Input() createNewMarkerFn?: () => Promise<MarkerData|undefined>;
  @Input() oldMarkers: MarkerData[];
  @Input() readonly = false;
  @Input() pdfPlanPageMarkings?: Array<PdfPlanPageMarking | PdfPlanPageMarkingBase>;
  @Input() groupPdfPlanPageMarkings = false;
  @Input() cursor = 'default';
  @Input() editableMarkerId: IdType|Array<IdType>|undefined;
  @Input() allEditable = false;
  @Output() loadedImage = new EventEmitter<any>();
  @Input() moveChoice?: MoveChoiceType;
  @Output() moveChoiceChange = new EventEmitter<MoveChoiceType>();
  @Input() width?: number;
  @Input() height?: number;

  @Input() moveEntriesWhenOutsideVisibleArea = false;
  @Output() entriesMovedFromOutsideVisibleArea = new EventEmitter<boolean>();

  @Output() newMarkersAdded = new EventEmitter<MarkerData[]>();
  @Output() markersDeleted = new EventEmitter<MarkerData[]>();
  @Input() selectedMarkers? = new Array<MarkerData>();
  @Output() selectedMarkersChange = new EventEmitter<Array<MarkerData>>();

  @Output() pdfPlanPageMarkingsChange = new EventEmitter<Array<PdfPlanPageMarking>>();
  @Output() olderMarkersChange = new EventEmitter<Array<MarkerData>>();
  @Output() oldMarkerTap = new EventEmitter<MarkerData>();

  public canvasWidth: number;
  public canvasHeight: number;
  public showErrorMessage = false;

  private markers = new Array<FabricPdfPlanMarker>();
  private canvas: fabric.Canvas;
  private factor = 0;
  private loading: HTMLIonLoadingElement|null = null;
  private onSelectionCleared = true;
  private pinchCenter: PositionAxis|null = null;
  private scale = 1;
  private lastScale = 1;
  private currentX = 0;
  private currentY = 0;
  private currentZX = 0;
  private currentZY = 0;
  private lastX = 0;
  private lastY = 0;
  public imageLoaded = false;
  private image: fabric.Image|undefined;
  private imageWidth: number|undefined;
  private imageHeight: number|undefined;
  /*
  If true, Image could not have been rendered in full size (so for only known on some Android devices) and needed to be scaled.
   */
  public imageScaled: boolean|undefined;

  private loadedSvgMarkers = new Map<ProtocolEntryIconStatus, CanvasImageSource>();
  private loadedSvgSelectedMarkers = new Map<ProtocolEntryIconStatus, CanvasImageSource>();

  private canvasHammer: any;
  private discardActiveObjectInProgress: boolean|undefined;
  private refreshCanvasInProgress = false;

  @ViewChild('canvasContainer', {static: false}) canvasContainer: ElementRef;
  @ViewChild('imageCanvas', {static: false}) canvasElement: ElementRef;

  constructor(private loadingCtr: LoadingController,
              private translateService: TranslateService,
              private loggingService: LoggingService,
              private systemEventService: SystemEventService) { }

  private async loadMarkerImagesIfNotLoaded() {
    if (!this.loadedSvgMarkers?.size) {
      this.loadedSvgMarkers = await loadMarkerImages();
    }
    if (!this.loadedSvgSelectedMarkers?.size) {
      this.loadedSvgSelectedMarkers = await loadMarkerImages(undefined, true);
    }
  }

  async ngOnInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called');
    this.imageLoaded = false;
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.loggingService.debug(LOG_SOURCE, `ngOnChanges called. (${Object.keys(changes).join(',')})`);
    await this.loadMarkerImagesIfNotLoaded();
    if (this.imageLoaded && changes.oldMarkers) {
      this.loadOldMarkers();
    }

    if (this.imageLoaded && changes.pdfPlanPageMarkings) {
      await this.renderCanvasMarkingsAndMarkers();
    }

    if (changes.src?.currentValue) {
      this.loggingService.debug(LOG_SOURCE, 'ngOnChanges - src changed.');
      await this.renderCanvas();
    } else if (this.src && this.canvas && (changes.pdfPlanPageMarkings || changes.oldMarkers || changes.selectedMarkers)) {
      await this.refreshCanvas();
      this.selectObjectsByMoveChoice();
    } else if (this.src && this.canvas) {
      if (changes.editableMarkerId) {
        this.makeAllMarkersEditable(false);
        if (this.getEditableMarkerIds()?.length) {
          this.getEditableMarkerIds().forEach((editableMarkerId) => this.makeEditableMarker(editableMarkerId));
        }
      }
    }

    if (changes.cursor?.currentValue && this.canvas) {
      this.canvas.defaultCursor = this.cursor;
      this.canvas.hoverCursor = this.cursor;
    }

    if (changes.moveChoice && changes.moveChoice.currentValue) {
      this.selectObjectsByMoveChoice();
    }
  }

  private clearCanvasSelection() {
    try {
      this.discardActiveObjectInProgress = true;
      this.canvas.discardActiveObject();
      this.canvas.renderAll();
    } finally {
      this.discardActiveObjectInProgress = false;
    }
  }

  private selectObjectsByMoveChoice() {
    if (!this.moveChoice) {
      return;
    }
    switch (this.moveChoice) {
      case 'single':
        this.clearCanvasSelection();
        break;
      case 'all':
        this.selectCanvasObjects(this.canvas.getObjects());
        break;
      case 'allMarkers':
        this.selectCanvasObjects(this.getCanvasMarkerObjects());
        break;
      case 'allSketches':
        this.selectCanvasObjects(this.getCanvasPdfPlanPageMarkings());
        break;
      default:
        throw new Error(`Unsupported moveChoice "${this.moveChoice}"`);
    }
  }

  private selectCanvasObjects(objects: Array<any>) {
    try {
      this.discardActiveObjectInProgress = true;
      this.canvas.discardActiveObject();
      if (objects?.length) {
        const selection = new fabric.ActiveSelection(objects, {canvas: this.canvas});
        this.canvas.setActiveObject(selection);
      }
      this.canvas.renderAll();
    } finally {
      this.discardActiveObjectInProgress = false;
    }
  }

  async ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called');
    if (this.canvas) {
      this.canvas.setDimensions({width: 0, height: 0});
      this.canvas.dispose();
    }
  }

  private async showLoading(key = 'modal.image_loading') {
    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
    this.loading = await this.loadingCtr.create({
      message: this.translateService.instant(key),
      duration: 10000
    });
    await this.loading.present();
    this.loading.onDidDismiss().then(({role, data}) => {
      this.showErrorMessage = typeof data === 'undefined';
    });
  }

  async hideLoading() {
    this.loggingService.debug(LOG_SOURCE, 'hideLoading called');
    if (this.loading) {
      await this.loading.dismiss({
        imageLoaded: true
      });
      this.loading = null;
    }
  }

  async renderCanvas() {
    this.loggingService.debug(LOG_SOURCE, `renderCanvas called - src="${this.src}"`);
    try {
      await this.showLoading();
      const {image, imageScaled} = await loadImageEnsureWidthHeight(this.src, this.srcWidth, this.srcHeight);
      this.image = image;
      this.imageScaled = imageScaled;
      if (imageScaled) {
        this.loggingService.warn(LOG_SOURCE, `renderCanvas. Device was rendering image smaller scaled width/height (${image.width}/${image.height}).`);
      } else {
        this.loggingService.debug(LOG_SOURCE, `renderCanvas. Image was rendered in full width/height (${this.image.width}/${this.image.height}) imageScaled=${imageScaled}`);
      }
      if (!_.isEmpty(this.image)) {
        // this.image.width or this.image.height is being changed (compromised) as soon as this.canvas.setBackgroundImage(this.image...) is being called. So keep the correct values here.
        this.imageWidth = this.image.width;
        this.imageHeight = this.image.height;
        const {width: viewWidth, height: viewHeight} = this.width !== undefined && this.height !== undefined ?
          {width: this.width, height: this.height} : this.canvasContainer.nativeElement.getBoundingClientRect();
        if (this.canvas) {
          this.loggingService.info(LOG_SOURCE, `renderCanvas - canvas already set.dispose it.`);
          this.canvas.dispose();
        }

        this.canvas = new fabric.Canvas(this.canvasElement.nativeElement, {width: viewWidth, height: viewHeight});
        this.canvas.defaultCursor = this.cursor;
        this.canvas.hoverCursor = this.cursor;
        this.canvas.selection = false;
        if (!this.readonly) {
          this.bindECanvasEvents();
        }
        this.bindTouchEvents();
        this.bindMouseWheel();
        this.bindECanvasMouseDownEvent();

        this.calculateFactor(this.imageWidth, this.imageHeight, viewWidth, viewHeight);
        this.canvas.setZoom(this.factor);
        await this.renderCanvasMarkingsAndMarkers();
        if (this.allEditable) {
          this.makeAllMarkersEditable();
        }
        this.makeImageCenter(this.imageWidth, this.imageHeight, viewWidth, viewHeight);
        await this.hideLoading();
        this.imageLoaded = true;
        this.loadedImage.emit();
      } else {
        this.loggingService.warn(LOG_SOURCE, 'renderCanvas - Canvas image was not loaded.');
      }
    } catch (error) {
      await this.hideLoading();
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - renderCanvas', error?.userMessage + '-' + error?.message);
    }
  }

  private async renderCanvasMarkingsAndMarkers() {
    this.loggingService.debug(LOG_SOURCE, 'renderCanvasMarkingsAndMarkers called.');
    const canvasNewMarkerObjects = this.getCanvasNewMarkerObjects();
    this.clearPdfPlanPageMarkings();
    this.loggingService.debug(LOG_SOURCE, 'renderCanvasMarkingsAndMarkers - canvas.setBackgroundImage loaded');
    if (this.groupPdfPlanPageMarkings) {
      loadPdfPlanPageMarkingsGrouped(this.canvas, this.pdfPlanPageMarkings, !this.readonly);
    } else {
      loadPdfPlanPageMarkings(this.canvas, this.pdfPlanPageMarkings);
    }
    if (!this.allEditable) {
      this.preventCanvasObjectsFromBeingSelectedAndMovedAround();
    }
    await this.setCanvasBackgroundImage();
    this.canvas.renderAll();
    this.addCanvasMarkerObjectsIfNotExisting(canvasNewMarkerObjects);
    this.loadOldMarkers();
    if (this.moveEntriesWhenOutsideVisibleArea) {
      this.ensureMarkersAndPlanPageMarkingsWithinVisibleArea();
    }
  }

  private async setCanvasBackgroundImage() {
    this.loggingService.debug(LOG_SOURCE, 'setCanvasBackgroundImage - calling canvas.setBackgroundImage');
    const backgroundImagePromise = new Promise<void>((resolve, reject) => {
      this.canvas.setBackgroundImage(this.image, (arg) => {
        this.loggingService.debug(LOG_SOURCE, 'setCanvasBackgroundImage - canvas.setBackgroundImage resolved');
        resolve();
      }, {width: (this.canvasWidth / this.factor), height: (this.canvasHeight / this.factor)});
    });
    await backgroundImagePromise;
    this.loggingService.debug(LOG_SOURCE, 'setCanvasBackgroundImage - canvas.setBackgroundImage awaited');
  }

  private preventCanvasObjectsFromBeingSelectedAndMovedAround() {
    this.canvas.forEachObject((element) => {
      element.selectable = false;
      element.evented = false;
    });
  }

  private async refreshCanvas() {
    this.loggingService.debug(LOG_SOURCE, `refreshCanvas called - src="${this.src}"`);
    try {
      this.refreshCanvasInProgress = true;
      const canvasMarkerObjects = this.getCanvasNewMarkerObjects();
      this.canvas?.clear();
      await this.renderCanvasMarkingsAndMarkers();
      this.addCanvasMarkerObjectsIfNotExisting(canvasMarkerObjects);
      if (this.allEditable) {
        this.makeAllMarkersEditable();
      } else if (this.getEditableMarkerIds()?.length) {
        this.getEditableMarkerIds().forEach((editableMarkerId) => this.makeEditableMarker(editableMarkerId));
      }
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error in refreshCanvas - ${convertErrorToMessage(error)}`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - refreshCanvas', convertErrorToMessage(error));
    } finally {
      this.refreshCanvasInProgress = false;
    }
  }

  private getEditableMarkerIds(): Array<IdType> | undefined {
    if (!this.editableMarkerId) {
      return undefined;
    }
    return _.isArray(this.editableMarkerId) ? this.editableMarkerId : [this.editableMarkerId];
  }

  private convertEventTargetToJsonStringIgnoreErrors(event: IEvent, maxLength = 40): string {
    try {
      if (!event?.target) {
        return 'undefined';
      }
      return JSON.stringify(event.target).substring(0, maxLength);
    } catch (error) {
      return 'error converting';
    }
  }

  bindECanvasMouseDownEvent() {
    this.canvas.on('mouse:down', (event) => {
      try {
        this.loggingService.debug(LOG_SOURCE, `mouse:down - (${this.convertEventTargetToJsonStringIgnoreErrors(event)})`);
        let selectedMarkerData: MarkerData | null = null;
        if (event.target && this.isMarkerObject(event.target)) {
          selectedMarkerData = (event.target as FabricPdfPlanMarker).markerData;
          const newSelectedMarkers = [selectedMarkerData];
          const hasChanged = !!_.xorBy(this.selectedMarkers, newSelectedMarkers, 'id').length;
          if (hasChanged) {
            this.selectedMarkers = newSelectedMarkers; // change if you want to select more than one.
            this.selectedMarkersChange.emit(this.selectedMarkers);
          }
          this.oldMarkerTap.emit(selectedMarkerData);
        } else {
          if (this.selectedMarkers?.length) {
            this.selectedMarkers = []; // change if you want to select more than one.
            this.selectedMarkersChange.emit(this.selectedMarkers);
          }
        }
      } catch (error) {
        this.systemEventService.logErrorEvent(LOG_SOURCE, error);
        this.loggingService.error(LOG_SOURCE, `mouse:down - error - (${this.convertEventTargetToJsonStringIgnoreErrors(event)})`, error);
      }
    });
  }

  bindECanvasEvents() {
    this.canvas.on('selection:created', (event) => {
      this.loggingService.debug(LOG_SOURCE, `selection:created - (${this.convertEventTargetToJsonStringIgnoreErrors(event)})`);
      this.onSelectionCleared = false;
    });

    this.canvas.on('selection:cleared', (event) => {
      this.loggingService.debug(LOG_SOURCE, `selection:cleared - (${this.convertEventTargetToJsonStringIgnoreErrors(event)})`);
      if (this.refreshCanvasInProgress) {
        return;
      }
      this.onSelectionCleared = true;
      if (!this.discardActiveObjectInProgress && this.moveChoice && this.moveChoice !== 'single') {
        this.moveChoice = 'single';
        this.moveChoiceChange.emit(this.moveChoice);
      }
    });

    this.canvas.on('object:moving', (event) => {
      this.ensureMovingObjectsAreWithinCanvas(event);

      if (_.get(event.target, 'markerData')) {
        _.set(event.target, 'markerData.x', event.target.left);
        _.set(event.target, 'markerData.y', event.target.top);
      } else if (event.target instanceof fabric.Group) {
        const group = event.target as fabric.Group;
        for (const groupObject of group.getObjects()) {
          if (_.get(groupObject, 'markerData')) {
            const {x, y} = this.getPositionOfGroupObject(group, groupObject);
            _.set(groupObject, 'markerData.x', x);
            _.set(groupObject, 'markerData.y', y);
          }
        }
      }
    });

    this.canvas.on('object:modified', (event) => {
      this.loggingService.debug(LOG_SOURCE, `object:modified - ${event.target}`);
      this.ensureMovingObjectsAreWithinCanvas(event);
      const target: FabricPdfPlanMarker & fabric.Object | fabric.Object = event.target;
      if ('markerData' in target) {
        const fabricPdfPlanMarker = target as FabricPdfPlanMarker;
        this.olderMarkersChange.emit([fabricPdfPlanMarker.markerData]);
      } else {
        const pdfPlanPageMarkings = this.convertFabricObjectsToPdfPlanPageMarkings([target]);
        this.pdfPlanPageMarkingsChange.emit(pdfPlanPageMarkings);
      }
    });
  }

  private ensureMovingObjectsAreWithinCanvas(event: IEvent) {
    const oldTop = event.target.top, oldLeft = event.target.left;
    const targetWidth: number = event.target.width;
    const targetHeight: number = event.target.height;
    const borderTop = this.imageHeight, borderLeft = this.imageWidth; // do not move marking outside the actual image (the canvas is bigger than the image)
    if (oldTop < CANVAS_MARGIN_MARKER) {
      event.target.top = CANVAS_MARGIN_MARKER;
    } else if (oldTop > (borderTop - CANVAS_MARGIN_MARKER)) {
      event.target.top = (borderTop - CANVAS_MARGIN_MARKER);
    } else if (oldTop > (borderTop - targetHeight)) {
      event.target.top = (borderTop - targetHeight);
    }

    if (oldLeft < CANVAS_MARGIN_MARKER) {
      event.target.left = CANVAS_MARGIN_MARKER;
    } else if (oldLeft > (borderLeft - CANVAS_MARGIN_MARKER)) {
      event.target.left =  Math.floor(borderLeft - CANVAS_MARGIN_MARKER);
    } else if (oldLeft > (borderLeft - targetWidth)) {
      event.target.left = (borderLeft - targetWidth);
    }

    event.target.left = Math.floor(event.target.left);
    event.target.top = Math.floor(event.target.top);
  }

  bindTouchEvents() {
    const upperElement = _.get(this.canvas, 'upperCanvasEl');
    this.canvasHammer = new Hammer.Manager(upperElement);
    this.canvasHammer.add([new Hammer.Pinch(), new Hammer.Tap(), new Hammer.Pan()]);

    this.canvasHammer.on('pinch', (event) => {
      this.loggingService.debug(LOG_SOURCE, 'canvasHammer.on.pinch');
      if (this.pinchCenter === null) {
        this.pinchCenter = this.rawCenter(event);
        this.currentZX = (this.pinchCenter.x + this.currentX) / this.scale;
        this.currentZY = (this.pinchCenter.y + this.currentY) / this.scale;
      }
      this.zoomAround(event.scale, this.pinchCenter.x, this.pinchCenter.y);
    });

    this.canvasHammer.on('pinchend', (event) => {
      this.loggingService.debug(LOG_SOURCE, 'canvasHammer.on.pinchend');
      this.updateLastScale();
      this.updateLastPos();
      this.pinchCenter = null;
    });

    this.canvasHammer.on('tap', async (event) => {
      this.loggingService.debug(LOG_SOURCE, 'canvasHammer.on.tap');
      if (this.createNewMarkerFn) {
        const coords = this.getCorrCoords(event);
        const markerData = await this.createNewMarkerFn();
        markerData.x = Math.floor(Math.min(coords.x, this.imageWidth)); // Math.min with imageWidth makes sure, that the marker is not placed outside the image
        markerData.y = Math.floor(Math.min(coords.y, this.imageHeight)); // Math.min with imageHeight makes sure, that the marker is not placed outside the image
        this.newMarkersAdded?.emit([markerData]);
        this.selectedMarkers = [markerData];
      }
    });

    this.canvasHammer.on('pan', (event) => {
      if (this.onSelectionCleared) {
        this.pointToCanvas(this.lastX - event.deltaX, this.lastY - event.deltaY);
      }
    });

    this.canvasHammer.on('panend', () => {
      this.updateLastPos();
    });

  }

  bindMouseWheel() {
    this.canvas.on('mouse:wheel', (event) => {
      const coord = this.getCoords(event);
      const delta = _.get(event, 'e.deltaY', 0);

      this.currentZX = (coord.x + this.currentX) / this.scale;
      this.currentZY = (coord.y + this.currentY) / this.scale;
      this.zoomAround((delta < 0 ? ZOOMSPEED : 1 / ZOOMSPEED), coord.x, coord.y);
      this.updateLastScale();
      this.updateLastPos();
    });
  }

  calculateFactor(imageWidth: number, imageHeight: number, viewWidth: number, viewHeight: number) {
    this.canvasWidth = viewWidth;
    this.canvasHeight = viewHeight;
    this.factor = Math.min(this.canvasWidth / imageWidth, this.canvasHeight / imageHeight);
  }

  makeImageCenter(imageWidth: number, imageHeight: number, viewWidth: number, viewHeight: number) {
    zoomToCenter(this.canvas, this.factor, this.factor);
  }

  addCanvasMarker(markerImg: Map<ProtocolEntryIconStatus, CanvasImageSource>, markerData: MarkerData) {
    const marker = new FabricPdfPlanMarker(markerImg, markerData);
    marker.setSize(this.scale * this.factor * 2);
    this.markers.push(marker);
    this.canvas.add(marker);
  }

  rawCenter(event): PositionAxis {
    const centerX = _.get(event, 'center.x', 0), centerY = _.get(event, 'center.y', 0);
    const scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
    const scrollTop = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
    return {x: (centerX + scrollLeft), y: (centerY + scrollTop)};

  }

  getCoords(event): PositionAxis {
    const pos = this.canvasContainer.nativeElement.getBoundingClientRect();
    const scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
    const scrollTop = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;
    let x, y;

    if (_.get(event, 'e.clientX', false)) {
      x = _.get(event, 'e.clientX', 0);
      y = _.get(event, 'e.clientY', 0);
    } else if (_.get(event, 'center', false)) {
      x = _.get(event, 'center.x', 0);
      y = _.get(event, 'center.y', 0);
    } else if ((_.get(event, 'e.touches', [])).length > 0) {
      x = _.get(event, 'touches[0].clientX', 0);
      y = _.get(event, 'touches[0].clientY', 0);
    } else {
      return;
    }

    return {x: (x - pos.left + scrollLeft), y: (y - pos.top + scrollTop)};
  }

  zoomAround(scaleBy: number, rawZoomX: number, rawZoomY: number) {
    this.scale = this.restrictScale(this.lastScale * scaleBy);
    this.canvas.setZoom(this.factor * this.scale);
    this.pointToCanvas(((this.currentZX * this.scale) - rawZoomX), ((this.currentZY * this.scale) - rawZoomY));
    this.updateMarkerSize();
  }

  restrictScale(scale: number): number {
    return this.clamp(scale, MIN_SCALE, MAX_SCALE);
  }

  clamp(num: number, min: number, max: number): number {
    return Math.max(min, Math.min(num, max));
  }

  pointToCanvas(tx, ty) {
    this.currentX = this.clamp(tx, 0, this.canvasWidth * this.scale - this.canvasWidth);
    this.currentY = this.clamp(ty, 0, this.canvasHeight * this.scale - this.canvasHeight);
    this.canvas.absolutePan(new fabric.Point(this.currentX, this.currentY));
  }

  updateLastPos() {
    this.lastX = this.currentX;
    this.lastY = this.currentY;
  }

  updateLastScale() {
    this.lastScale = this.scale;
  }

  getCorrCoords(event): PositionAxis {
    const coords = this.getCoords(event);

    if (!coords) {
      return;
    }
    const factor = this.factor,
      cw = this.canvasWidth, ch = this.canvasHeight
      , scaleOffset = {x: this.currentX, y: this.currentY}
      , scale = this.scale;

    return {
      x: (this.clamp(coords.x, 0, cw) + scaleOffset.x) / factor / scale,
      y: (this.clamp(coords.y, 0, ch) + scaleOffset.y) / factor / scale,
    };
  }

  updateMarkerSize() {
    this.markers.forEach((marker: FabricPdfPlanMarker) => {
      marker.setSize(this.scale * this.factor * 2);
    });
  }

  loadOldMarkers() {
    if (this.oldMarkers?.length > 0) {
      this.clearOldMarkers();
      this.oldMarkers.forEach((oldMarker: MarkerData) => {
        const isSelected = this.selectedMarkers?.length && this.selectedMarkers.some((selectedMarker) => selectedMarker.id === oldMarker.id);
        this.addCanvasMarker(isSelected ? this.loadedSvgSelectedMarkers : this.loadedSvgMarkers, oldMarker);
      });
      if (this.allEditable) {
        this.makeAllMarkersEditable();
      } else if (this.getEditableMarkerIds()?.length) {
        this.getEditableMarkerIds().forEach((editableMarkerId) => this.makeEditableMarker(editableMarkerId));
      }
      if (this.moveEntriesWhenOutsideVisibleArea) {
        this.ensureMarkersAndPlanPageMarkingsWithinVisibleArea();
      }
    }
  }

  private clearOldMarkers() {
    this.getCanvasMarkerObjects().forEach(planMarkerObject => {
      this.canvas.remove(planMarkerObject);
    });
  }

  private clearPdfPlanPageMarkings() {
    const pdfPlanPageMarkings = this.getCanvasPdfPlanPageMarkings();
    pdfPlanPageMarkings.forEach((pdfPlanPageMarking) => {
      this.canvas.remove(pdfPlanPageMarking);
    });
  }

  makeEditableMarker(markerDataId: IdType, editable = true) {
    const fabricPdfPlanMarkers = this.getCanvasMarkerObjects();
    fabricPdfPlanMarkers.forEach((fabricPdfPlanMarker: FabricPdfPlanMarker) => {
      const markerData: MarkerData = fabricPdfPlanMarker.markerData;
      if (markerData.id === markerDataId) {
        fabricPdfPlanMarker.selectable = editable;
        fabricPdfPlanMarker.lockMovementX = !editable;
        fabricPdfPlanMarker.lockMovementY = !editable;
      }
    });
  }

  makeAllMarkersEditable(editable = true) {
    const fabricPdfPlanMarkers = this.getCanvasMarkerObjects();
    fabricPdfPlanMarkers.forEach((fabricPdfPlanMarker: FabricPdfPlanMarker) => {
      fabricPdfPlanMarker.selectable = editable;
      fabricPdfPlanMarker.lockMovementX = !editable;
      fabricPdfPlanMarker.lockMovementY = !editable;
    });
  }

  deleteMarker(markerDataId: IdType) {
    try {
      const fabricPdfPlanMarker = this.getCanvasMarkerObjects().find((marker: FabricPdfPlanMarker) => marker.markerData?.id === markerDataId);
      this.canvas.remove(fabricPdfPlanMarker);
      this.markersDeleted?.emit([fabricPdfPlanMarker.markerData]);
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - deleteMarker', error?.userMessage + '-' + error?.message);
    }
  }

  private getCanvasMarkerObjects(): Array<FabricPdfPlanMarker & fabric.Object> {
    return this.canvas.getObjects().filter((object: any): object is FabricPdfPlanMarker => this.isMarkerObject(object)); // filter out the pdfPlanPageMarking objets
  }

  private getCanvasNewMarkerObjects(): Array<FabricPdfPlanMarker & fabric.Object> {
    return this.getCanvasMarkerObjects().filter((canvasMarker: FabricPdfPlanMarker) => canvasMarker.markerData?.markerType === MarkerType.NEW);
  }

  private addCanvasMarkerObjectsIfNotExisting(markers: Array<FabricPdfPlanMarker>) {
    const existingMarkerObjects = this.getCanvasMarkerObjects();
    const markersToAdd = markers.filter((marker) => !existingMarkerObjects.find((existingMarkerObject) => existingMarkerObject.markerData?.id === marker.markerData?.id));
    markersToAdd.forEach((markerToAdd) => this.canvas.add(markerToAdd));
  }

  private getCanvasPdfPlanPageMarkings(): fabric.Object[] {
    return this.canvas.getObjects().filter((object: any) => !this.isMarkerObject(object)); // filter the pdfPlanPageMarking objets
  }

  private convertFabricObjectsToPdfPlanPageMarkings(fabricObjects: fabric.Object[]): PdfPlanPageMarking[] {
    if (!fabricObjects.length) {
      return [];
    }
    if (!this.groupPdfPlanPageMarkings) {
      throw new Error('convertFabricObjectsToPdfPlanPageMarkings only supported when [groupPdfPlanPageMarkings]=true');
    }
    fabricObjects.forEach(fabricO => {
      if (fabricO instanceof fabric.Group) {
        const tempfabs = fabricO.getObjects();
        for (const tempfab of tempfabs) {
          if (tempfab.name && tempfab instanceof fabric.Group) {
            fabricObjects.push(tempfab);
          }
        }
      }
    });
    const fabricGroups = fabricObjects as fabric.Group[];
    return _.compact(fabricGroups.map((fabricGroup) => this.convertFabricObjectToPdfPlanPageMarking(fabricGroup)));
  }

  private convertFabricObjectToPdfPlanPageMarking(fabricGroup: fabric.Group): PdfPlanPageMarking|undefined {
    const pdfPlanPageMarkingId = fabricGroup.name;
    if (!pdfPlanPageMarkingId) {
      return undefined;
    }
    const existingPdfPlanPageMarking = this.pdfPlanPageMarkings
      .find((pdfPlanPageMarking) => 'id' in pdfPlanPageMarking && pdfPlanPageMarking.id === pdfPlanPageMarkingId) as PdfPlanPageMarking|undefined;
    if (!existingPdfPlanPageMarking) {
      return undefined;
    }
    const isExistingPlanPageMarkingString = typeof existingPdfPlanPageMarking.markings === 'string';
    let markings = isExistingPlanPageMarkingString ? JSON.parse(existingPdfPlanPageMarking.markings) : _.cloneDeep(existingPdfPlanPageMarking.markings);
    const isExistingPlanPageMarkingDataString = typeof markings === 'string';
    if (isExistingPlanPageMarkingDataString) {
      // markings are being stored as string in the database, even though is a jsonb datatype. This allows to work with both types of data.
      markings = JSON.parse(markings);
    }
    markings.last_modified = Date.now();
    const groupObjects: fabric.Object[] = fabricGroup.getObjects();
    for (let i = 0; i < markings.fabricData.objects.length; i++) {
      const markingObject = markings.fabricData.objects[i];
      const groupObject = groupObjects[i];
      const {x, y} = this.getPositionOfGroupObject(fabricGroup, groupObject);
      markingObject.left = x;
      markingObject.top = y;
    }

    const ret: PdfPlanPageMarking = {
      ...existingPdfPlanPageMarking,
      markings: isExistingPlanPageMarkingDataString ?
        JSON.stringify(isExistingPlanPageMarkingString ? JSON.stringify(markings) : markings)
        : (isExistingPlanPageMarkingString ? JSON.stringify(markings) : markings)
    };
    return ret;
  }

  private getPositionOfGroupObject(group: fabric.Group, groupObject: fabric.Object): {x: number; y: number} {
    const matrix = group.calcTransformMatrix();
    const top: number = _.get(groupObject, 'top');
    const left: number = _.get(groupObject, 'left');
    const newTopLeft = fabric.util.transformPoint(new fabric.Point(left, top), matrix);
    return {x: newTopLeft.x, y: newTopLeft.y};
  }

  private isMarkerObject(object: any): boolean {
    return !!object.markerData;
  }

  private ensureMarkersAndPlanPageMarkingsWithinVisibleArea(triggerEmit = true): boolean {
    if (!this.imageWidth || !this.imageHeight) {
      return false;
    }
    let changed = false;
    for (const markerObject of this.canvas.getObjects()) {
      let objectChanged = false;
      if (markerObject.left > this.imageWidth - CANVAS_MARGIN_MARKER) {
        const newLeft = this.imageWidth - CANVAS_MARGIN_MARKER;
        markerObject.left = newLeft;
        if ('markerData' in markerObject) {
          (markerObject as FabricPdfPlanMarker).markerData.x = newLeft;
        }
        objectChanged = true;
      }
      if (markerObject.top > this.imageHeight - CANVAS_MARGIN_MARKER) {
        const newTop = this.imageHeight - CANVAS_MARGIN_MARKER;
        markerObject.top = newTop;
        if ('markerData' in markerObject) {
          (markerObject as FabricPdfPlanMarker).markerData.y = newTop;
        }
        objectChanged = true;
      }
      if (objectChanged) {
        markerObject.setCoords();
        changed = true;
      }
    }

    if (triggerEmit && changed) {
      if (this.groupPdfPlanPageMarkings) {
        this.olderMarkersChange.emit(this.getCanvasMarkerObjects().map((markerObject) => markerObject.markerData));
        this.pdfPlanPageMarkingsChange.emit(this.convertFabricObjectsToPdfPlanPageMarkings(this.getCanvasPdfPlanPageMarkings()));
      }
      this.entriesMovedFromOutsideVisibleArea.emit(true);
    }
    return changed;
  }

}
