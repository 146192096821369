import {Injectable} from '@angular/core';
import { AlertService } from '../ui/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Address, WeatherEnum, WeatherForLocationReq, WeatherForLocationRes } from 'submodules/baumaster-v2-common';
import { ClientService } from '../client/client.service';
import { observableToPromise } from 'src/app/utils/async-utils';
import { ProtocolLocationEnum } from 'src/app/components/protocol/protocol-form/protocol-form.component';
import { CountryDataService } from '../data/country-data.service';
import { ProjectDataService } from '../data/project-data.service';
import { AddressDataService } from '../data/address-data.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from './logging.service';
import { SystemEventService } from '../event/system-event.service';

const LOG_SOURCE = 'CommonWeatherService';

@Injectable({
  providedIn: 'root'
})
export class CommonWeatherService {

  constructor(private alertService: AlertService, private translateService: TranslateService, private clientService: ClientService, private countryDataService: CountryDataService,
              private projectDataService: ProjectDataService, private addressDataService: AddressDataService, private http: HttpClient, private loggingService: LoggingService,
              private systemEventService: SystemEventService) { }

  public async alertWentWrong() {
    return await this.alertService.confirm({
      header: 'reports.wentWrong.header',
      message: 'reports.wentWrong.message',
      cancelLabel: 'button.cancel',
      confirmLabel: 'reports.wentWrong.button',
      confirmButton: {
        color: 'text-primary',
        fill: 'solid',
      }
    });
  }

  public async alertOtherAddress() {
    return await this.alertService.ok({
      header: 'protocol.otherAddress.header',
      message: 'protocol.otherAddress.message',
      confirmLabel: 'close',
      confirmButton: {
        color: 'text-primary',
        fill: 'solid',
      }
    });
  }

  public async alertInvalidAddress(location: string) {
    return await this.alertService.confirm({
      header: 'reports.invalidAddress.header',
      message: this.translateService.instant('reports.invalidAddress.message', {address: location}),
      cancelLabel: 'close',
      confirmLabel: 'reports.invalidAddress.button',
      confirmButton: {
        color: 'text-primary',
        fill: 'solid',
      }
    });
  }

  public async alertMissingProjectAddress() {
    return await this.alertService.confirm({
      header: 'reports.missingAddress.header',
      message: 'reports.missingAddress.message',
      cancelLabel: 'close',
      confirmLabel: 'reports.missingAddress.button',
      confirmButton: {
        color: 'text-primary',
        fill: 'solid',
      }
    });
  }

  public async alertMissingOfficeAddress() {
    return await this.alertService.confirm({
      header: 'protocol.missingAddress.header',
      message: 'protocol.missingAddress.message',
      cancelLabel: 'close',
      confirmLabel: 'protocol.missingAddress.button',
      confirmButton: {
        color: 'text-primary',
        fill: 'solid',
      }
    });
  }

  public async alertInvalidDate() {
    return await this.alertService.confirm({
      header: 'reports.invalidDate.header',
      message: 'reports.invalidDate.message',
      cancelLabel: 'close',
      confirmLabel: 'reports.invalidDate.button',
      confirmButton: {
        color: 'text-primary',
        fill: 'solid',
      }
    });
  }

  public async getCurrentAddressStringProtocol(selectedLocation: number): Promise<{location: string; address: Address|null, country: string, whichAddress: ProtocolLocationEnum}> {
    let addressId;
    let isOfficeAddress = false;
    let country = '';
    let whichAddress: ProtocolLocationEnum;
    if (selectedLocation === ProtocolLocationEnum.OFFICE_ADDRESS) {
      isOfficeAddress = true;
      whichAddress = ProtocolLocationEnum.OFFICE_ADDRESS;
      const client = this.clientService.getCurrentClient();
      addressId = client.addressId ?? undefined;
      country = (await observableToPromise(this.countryDataService.getById(client?.countryId)))?.name;
    } else if (selectedLocation === ProtocolLocationEnum.NEW_ADDRESS) {
      const project = await this.projectDataService.getCurrentProject();
      addressId = project.addressId;
      whichAddress = ProtocolLocationEnum.NEW_ADDRESS;
    } else if (selectedLocation === ProtocolLocationEnum.PROJECT_ADDRESS) {
      whichAddress = ProtocolLocationEnum.PROJECT_ADDRESS;
    } else {
      whichAddress = ProtocolLocationEnum.OTHER;
    }

    if (!addressId) {
      return {location: '', address: null, country: '', whichAddress};
    }
    const address = await observableToPromise(this.addressDataService.getById(addressId));
    const location = isOfficeAddress ? ((address?.street1 ? address.street1 + ' ' : '') +
                            (address?.street2 ? address.street2 + ' ' : '') +
                            (address?.zipCode ? address.zipCode + ' ' : '') +
                            (address?.city ? address.city + ' ' : '') +
                            (country ? country + ' ' : '')).trim() :

                            ((address?.street1 ? address.street1 + ' ' : '') +
                            (address?.street2 ? address.street2 + ' ' : '') +
                            (address?.zipCode ? address.zipCode + ' ' : '') +
                            (address?.city ? address.city + ' ' : '') +
                            (address?.country ? address.country + ' ' : '')).trim();
    return {location, address, country, whichAddress};
  }

  public async checkDateAndAddressProtocol(date: Date, selectedLocation: number, getAddressStringResult: {location: string; address: Address|null, country: string, whichAddress: ProtocolLocationEnum}): Promise<{valid: false; status: 'OTHER_ADDRESS_UNSUPPORTED' | 'OFFICE_ADDRESS_MISSING' | 'PROJECT_ADDRESS_MISSING' | 'DATE_NOT_VALID' | 'PROJECT_ADDRESS_UNSUPPORTED'} | {valid: true; status: 'ADDRESS_VALID'}>{
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate()+1);
    nextDay.setHours(0,0,0,0);

    if (!date || date > nextDay) {
      return {valid: false, status: 'DATE_NOT_VALID'};
    }

    const {location, address, country, whichAddress} = getAddressStringResult;

    if (whichAddress === ProtocolLocationEnum.OTHER) {
      return {valid: false, status: 'OTHER_ADDRESS_UNSUPPORTED'};
    }
    if (whichAddress === ProtocolLocationEnum.PROJECT_ADDRESS) {
      return {valid: false, status: 'PROJECT_ADDRESS_UNSUPPORTED'};
    }
    if (location !== '' && address?.zipCode?.trim() && address?.city?.trim() && (address?.country?.trim() || country !== '')) {
      return {valid: true, status: 'ADDRESS_VALID'};
    } else {
      return {valid: false, status: selectedLocation === ProtocolLocationEnum.OFFICE_ADDRESS ? 'OFFICE_ADDRESS_MISSING' : 'PROJECT_ADDRESS_MISSING'};
    }
  }


  public async checkDateAndAddressReport(date: Date): Promise<{valid: boolean, status: string}>{
    let isValid = false;
    let status = '';
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate()+1);
    nextDay.setHours(0,0,0,0);
    const {location, address} = await this.getCurrentAddressStringReport();
    if (location !== '' && address?.zipCode?.trim() && address?.city?.trim() && address?.country?.trim()) {
      isValid = true;
      status = 'ADDRESS_VALID';
    } else {
      isValid = false;
      status = 'ADDRESS_MISSING';
    }
    if (!date || date > nextDay) {
      isValid = false;
      status = 'DATE_NOT_VALID';
    }
    return {valid: isValid, status};
  }

  public async getCurrentAddressStringReport(): Promise<{location: string; address: Address|null}>{
    const project = await this.projectDataService.getCurrentProject();
    const addressId = project.addressId;
    if (!addressId) {
      return {location: '', address: null};
    }
    const address = await observableToPromise(this.addressDataService.getById(addressId));
    const location = ((address.street1 ? address.street1 + ' ' : '') +
                            (address.street2 ? address.street2 + ' ' : '') +
                            (address.zipCode ? address.zipCode + ' ' : '') +
                            (address.city ? address.city + ' ' : '') +
                            (address.country ? address.country + ' ' : '')).trim();
    return {location, address};
  }

  public async getWeatherAPI(date: string, location: string): Promise<WeatherForLocationRes> {
    const projectId = (await this.projectDataService.getCurrentProject()).id;
    try {
      const requestBody: WeatherForLocationReq = {
        startTime: date,
        endTime: date,
        location,
      };
      return await observableToPromise(this.http.post<WeatherForLocationRes>(`${environment.serverUrl}api/weather/forLocation/?projectId=${projectId}`, requestBody));
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `Error getting weather API data. "${error?.userMessage}" - "${error?.message}"`);
      await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - getWeatherAPI', error?.userMessage + '-' + error?.message);
      throw error;
    }
  }

  public getWeathers(): Array<{ id: WeatherEnum, name: string, icon: string }> {
    const weatherKeys: string[] = Object.keys(WeatherEnum).filter(x => isNaN(Number(x)));
    return weatherKeys.map((key) => {
      return {
        id: WeatherEnum[key],
        name: this.translateService.instant('reports.weatherOption.' + key),
        icon: this.getIcon(WeatherEnum[key])
      };
    });
  }

  private getIcon(weather: WeatherEnum): string {
    switch (weather) {
      case WeatherEnum.SUNNY: return 'sun';
      case WeatherEnum.CLOUDY: return 'clouds';
      case WeatherEnum.RAINY: return 'cloud-showers';
      case WeatherEnum.FOGGY: return 'fog';
      case WeatherEnum.STORMY: return 'thunderstorm';
      case WeatherEnum.SNOWY: return 'snowflake';
      case WeatherEnum.SNOWRAIN: return 'cloud-sleet';
      case WeatherEnum.RAINSTORM: return 'cloud-showers-heavy';
      case WeatherEnum.SNOWSTORM: return 'snow-blowing';
      default: throw new Error('weather enum does not support: ' + weather);
    }
  }

  public getDateForAPI(date: Date) {
    date.setHours(7, 0, 0, 0);
    return date;
  }
}
