import { Injectable } from '@angular/core';
import {LoadingController} from '@ionic/angular';
import {LoadingOptions} from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(
    private loadingController: LoadingController,
  ) { }

  async withLoading<T>(fn: () => Promise<T>, loadingOptions?: LoadingOptions): Promise<T> {
    const loading = await this.loadingController.create(loadingOptions);

    await loading.present();
    try {
      return await fn();
    } catch (e) {
      throw e;
    } finally {
      await loading.dismiss();
    }
  }
}
