import {Component, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ModalController, NavController, ViewDidEnter, ViewWillEnter, ViewWillLeave} from '@ionic/angular';
import {of, Subscription} from 'rxjs';
import {debounceTime, filter, map, startWith, switchMap, tap} from 'rxjs/operators';
import {ProtocolCreateComponent} from 'src/app/components/protocol/protocol-create/protocol-create.component';
import {ProtocolListComponent} from 'src/app/components/protocol/protocol-list/protocol-list.component';
import {PageDidEnterLifecycleService} from 'src/app/services/common/page-did-enter-lifecycle.service';
import {ProjectDataService} from 'src/app/services/data/project-data.service';
import {ProtocolFilterService} from 'src/app/services/protocol/protocol-filter.service';
import {ProtocolListService} from 'src/app/services/protocol/protocol-list.service';
import {SelectedProtocolService} from 'src/app/services/protocol/selected-protocol.service';
import {Breakpoints, DeviceService, QUERY} from 'src/app/services/ui/device.service';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {getProtocolEntryPagePath} from 'src/app/utils/router-utils';
import {IdType, Project} from 'submodules/baumaster-v2-common';

@Component({
  selector: 'app-protocols',
  templateUrl: './protocols.page.html',
  styleUrls: ['./protocols.page.scss'],
  providers: [PageDidEnterLifecycleService],
})
export class ProtocolsPage implements ViewWillEnter, ViewWillLeave, ViewDidEnter, OnInit {
  @ViewChild(ProtocolListComponent, {
    static: false
  }) protocolList: ProtocolListComponent;

  // To programmatically turn off 4 column layout, use value '(max-width: 0px)'
  readonly fourColumnLayoutBreakpoint = QUERY[Breakpoints.xxl];

  private project: Project | null = null;

  private currentUrl$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    startWith(this.router.url),
    map(() => this.router.url)
  );

  isInEntriesView$ = this.currentUrl$.pipe(map((url) => url.includes('/view')));
  private isFourColumn$ = this.deviceService.isAboveBreakpoint(Breakpoints.xxl);
  showBackButton$ = combineLatestAsync([this.isInEntriesView$, this.isFourColumn$]).pipe(map(([isInEntriesView, isFourColumn]) => isInEntriesView && !isFourColumn));
  showRouterOutletFab$ = combineLatestAsync([
    this.isInEntriesView$,
    this.isFourColumn$
  ]).pipe(map(([isInEntryView, isFourColumn]) => !isInEntryView && !isFourColumn));

  showFullSizeCreateButton$ = this.deviceService.isAboveBreakpoint(Breakpoints.lg);
  backButtonPage$ = this.deviceService.isAboveBreakpoint(Breakpoints.lg).pipe(
    switchMap((hasSplitScreenInEntryView) => {
      if (hasSplitScreenInEntryView) {
        return of('/protocols');
      }

      return this.currentUrl$.pipe(
        map((url) => url.includes('/entry') ? url.substr(0, url.indexOf('/entry')) : '/protocols')
      );
    })
  );

  private redirectToFirstProtocolSubscription?: Subscription;

  constructor(
    public modalController: ModalController,
    private router: Router,
    private deviceService: DeviceService,
    private protocolFilterService: ProtocolFilterService,
    private protocolListService: ProtocolListService,
    private projectDataService: ProjectDataService,
    private selectedProtocolService: SelectedProtocolService,
    private navController: NavController,
    private pageDidEnterLifecycleService: PageDidEnterLifecycleService
  ) {}

  ngOnInit() {
    this.protocolFilterService.selectedProtocolType = null;
  }

  private subscribeToRedirectToFirstProtocol() {
    this.unsubscribeFromRedirectToFirstProtocol();
    this.redirectToFirstProtocolSubscription = combineLatestAsync([
      this.selectedProtocolService.getCurrentProtocol(),
      this.protocolListService.filteredProtocols$,
      this.deviceService.isAboveBreakpoint(Breakpoints.xxl),
      this.projectDataService.currentProjectObservable.pipe(
        tap((project) => {
          if (!this.project) {
            this.project = project;
          }
        })
      ),
    ])
      .pipe(
        filter(([activeProtocol, protocols, isFourColumn, currentProject]) => {
          // Automatically redirect to the first entry only in four column layout
          if (!isFourColumn) {
            return false;
          }

          // Redirect to the first entry when navigation from empty protocol type
          // or/and from empty project
          if (!this.router.url.includes('/entry') && protocols.length > 0) {
            return true;
          }

          // Assuming no action is needed if active protocol is null
          if (!activeProtocol) {
            return false;
          }

          if (this.project && this.project.id !== currentProject.id) {
            this.project = currentProject;
            // We're in the middle of current project change;
            // go to first protocol, if active protocol is not a part of new project
            return !protocols.some(({ id }) => activeProtocol.id === id);
          }

          // No protocols found in the selected protocol type; redirect to empty page
          if (protocols.length === 0) {
            return true;
          }

          // Redirect to the first entry only if currently selected entry is not
          // present in current protocols list
          return !protocols.some(({ id }) => activeProtocol.id === id);
        })
      )
      .subscribe(() => this.navigateToFirstProtocol());
  }

  private unsubscribeFromRedirectToFirstProtocol() {
    this.redirectToFirstProtocolSubscription?.unsubscribe();
  }

  ionViewWillEnter() {
    this.subscribeToRedirectToFirstProtocol();
  }

  ionViewDidEnter() {
    this.pageDidEnterLifecycleService.pageDidEnter();
    this.protocolList?.scrollOnEnter();
  }

  ionViewWillLeave() {
    this.unsubscribeFromRedirectToFirstProtocol();
  }

  async addNewProtocol() {
    const modal = await this.modalController.create({
      component: ProtocolCreateComponent,
      keyboardClose: false,
      cssClass: 'protocol-entry-modal',
      componentProps: {
        protocolType: this.protocolFilterService.selectedProtocolType
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    await this.gotToProtocolEntryList(data?.id);
  }

  async gotToProtocolEntryList(protocolId: IdType|undefined) {
    if (typeof(protocolId) !== 'undefined') {
      await this.router.navigate(getProtocolEntryPagePath(protocolId, 'first'));
    }
  }

  async navigateToFirstProtocol() {
    // debounceTime(0) to make sure `combineLatestAsync` completes after we try to get first protocol
    const protocol = await observableToPromise(this.protocolListService.firstProtocol$.pipe(debounceTime(0)));
    // navigateBack makes sure that we are not stacking pages of the same type. Such behavior leads to race conditions.
    if (protocol) {
      await this.navController.navigateBack(getProtocolEntryPagePath(protocol.id, 'first'));
    } else {
      await this.navController.navigateBack(['/protocols']);
    }
  }

  async onSplitPaneVisible(event: CustomEvent<{ visible: boolean; }>) {
    if (event.detail.visible && !this.router.url.includes('/entry')) {
      await this.navigateToFirstProtocol();
    }
  }
}
