import {UntypedFormGroup} from '@angular/forms';

export interface FormControlStatus {
  dirty: boolean;
  touched: boolean;
}

export function getFormControlStatus(form: UntypedFormGroup): Record<any, FormControlStatus> {
  const formControlStates: Record<any, FormControlStatus> = {};
  for (const formControlKey of Object.keys(form.controls)) {
    formControlStates[formControlKey] = {
      dirty: form.controls[formControlKey].dirty,
      touched: form.controls[formControlKey].touched
    };
  }
  return formControlStates;
}

export function restoreFormControlStatus(form: UntypedFormGroup, formControlStates: Record<any, FormControlStatus>) {
  for (const formControlKey of Object.keys(formControlStates)) {
    const formControl = form.controls[formControlKey];
    const formControlState = formControlStates[formControlKey];
    if (!formControl.dirty && formControl.dirty !== formControlState.dirty) {
      formControl.markAsDirty();
    }
    if (!formControl.touched && formControl.touched !== formControlState.touched) {
      formControl.markAsTouched();
    }
  }
}
