import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {pairwise} from 'rxjs/operators';
import {LoggingService} from 'src/app/services/common/logging.service';
import {SystemEventService} from 'src/app/services/event/system-event.service';
import {OmgToastService} from 'src/app/services/ui/omg-toast.service';
import {TEMPERATURE_MAX_VALUE, TEMPERATURE_MIN_VALUE, ToastDurationInMs} from 'src/app/shared/constants';
import {ErrorCodeAuthenticationType, WeatherForLocationRes} from 'submodules/baumaster-v2-common';
import {CommonWeatherService} from 'src/app/services/common/common-weather.service';
import {IonicSelectableComponent} from 'ionic-selectable';
import {convertErrorToMessage} from '../../../../shared/errors';
import {KeyboardResizeOptions} from '@capacitor/keyboard';
import {SelectableUtilService} from '../../../../services/common/selectable-util.service';

const LOG_SOURCE = 'ProtocolFormWeatherComponent';
const TEMPERATURE_ITEM_HEIGHT = 48;
const TEMPERATURE_ITEMS_PER_PAGE = 7;
const DEFAULT_TEMPERATURE = 15;

@Component({
  selector: 'app-protocol-form-weather',
  templateUrl: './protocol-form-weather.component.html',
  styleUrls: ['./protocol-form-weather.component.scss'],
})
export class ProtocolFormWeatherComponent implements OnInit, OnDestroy {
  private dateFormSubscription: Subscription;

  @Input()
  readonly: boolean;

  @Input()
  protocolForm: UntypedFormGroup;

  @Output()
  invalidProjectAddress = new EventEmitter<void>();

  @Output()
  invalidOfficeAddress = new EventEmitter<void>();

  weathers = this.commonWeatherService.getWeathers();

  fetchingWeatherData = false;
  isFromAPI: boolean;
  showInfo = true;
  status = '';
  minValues = this.temperatureValues();
  maxValues = this.temperatureValues();

  @ViewChild('temperatureMinSelectable', {static: false}) temperatureMinSelectable: IonicSelectableComponent;
  @ViewChild('temperatureMaxSelectable', {static: false}) temperatureMaxSelectable: IonicSelectableComponent;

  private resizeModeBeforeOpen: KeyboardResizeOptions | undefined;

  constructor(private loggingService: LoggingService, private systemEventService: SystemEventService, private toastService: OmgToastService, private commonWeatherService: CommonWeatherService,
              private selectableUtilService: SelectableUtilService) { }

  ngOnInit() {
    this.dateFormSubscription = this.protocolForm.get('date').valueChanges.pipe(
      pairwise()
    ).subscribe(([prev, curr]) => {
      const prevDate = prev ? new Date(prev) : null;
      const currDate = curr ? new Date(curr) : null;

      if (prevDate && currDate) {
        if (prevDate.getTime() !== currDate.getTime()) {
          this.resetWeather();
        }
      } else if (prevDate || currDate) {
        this.resetWeather();
      }
    });
  }

  minTempSet() {
    return this.protocolForm.get('temperatureMin').value !== undefined && this.protocolForm.get('temperatureMin').value !== null;
  }

  maxTempSet() {
    return this.protocolForm.get('temperatureMax').value !== undefined && this.protocolForm.get('temperatureMax').value !== null;
  }

  getFromAPIBoolean() {
    return this.protocolForm.get('weatherFromApi').value;
  }

  async callWeatherFunc() {
    const date = this.commonWeatherService.getDateForAPI(new Date(this.protocolForm.get('date').value));
    const getAddressStringResult = await this.commonWeatherService.getCurrentAddressStringProtocol(this.protocolForm.get('selectedLocation').getRawValue().uniqueId);
    const isValid = await this.commonWeatherService.checkDateAndAddressProtocol(date, this.protocolForm.get('selectedLocation').getRawValue().uniqueId, getAddressStringResult);
    let result: WeatherForLocationRes;
    if (isValid.valid === false && isValid.status === 'OTHER_ADDRESS_UNSUPPORTED') {
      this.resetWeather();
      await this.commonWeatherService.alertOtherAddress();
      return;
    }
    if (isValid.valid === false && isValid.status === 'PROJECT_ADDRESS_UNSUPPORTED') {
      const confirm = await this.commonWeatherService.alertMissingProjectAddress();
      if (confirm) {
        this.invalidProjectAddress.emit();
      }
      return;
    }
    if (isValid.valid === false && isValid.status === 'PROJECT_ADDRESS_MISSING') {
      const confirm = await this.commonWeatherService.alertMissingProjectAddress();
      if (confirm) {
        this.invalidProjectAddress.emit();
      }
      return;
    } 
    if (isValid.valid === false && isValid.status === 'OFFICE_ADDRESS_MISSING') {
      const confirm = await this.commonWeatherService.alertMissingOfficeAddress();
      if (confirm) {
        this.invalidOfficeAddress.emit();
      }
      return;
    } 
    if (isValid.valid === false && isValid.status === 'DATE_NOT_VALID') {
      this.resetWeather();
      await this.commonWeatherService.alertInvalidDate();
      return;
    } 
    try {
      this.fetchingWeatherData = true;
      const response = await this.commonWeatherService.getWeatherAPI(date.toISOString(), getAddressStringResult.location);
      result = response;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 400) {
        const errorCode = error.error.errorCode as ErrorCodeAuthenticationType;
        if (errorCode === 'INVALID_LOCATION_FOR_WEATHER_API') {
          this.loggingService.error(LOG_SOURCE + ' - error in getWeatherAPI', errorCode);
          const confirm = await this.commonWeatherService.alertInvalidAddress(getAddressStringResult.location);
          if (confirm) {
            this.invalidProjectAddress.emit();
          }
          return;
        }
      } else {
        this.loggingService.error(LOG_SOURCE, `Error loading weather API data. "${error?.userMessage}" - "${error?.message}"`);
        await this.systemEventService.logErrorEvent(LOG_SOURCE + ' - callWeatherFunc', error?.userMessage + '-' + error?.message);
        const confirm = await this.commonWeatherService.alertWentWrong();
        if (confirm) {
          this.resetWeather();
          this.callWeatherFunc();
        }
        throw error;
      }
    } finally {
      this.fetchingWeatherData = false;
    }

    this.changeWeatherFromApiValue(true);

    if (result.weather || result.humidity || result.maxTemp || result.minTemp || result.windspeed) {
      this.protocolForm.markAsDirty();
      this.protocolForm.get('weather').setValue(result.weather);
      this.protocolForm.get('temperatureMax').setValue(result.maxTemp);
      this.protocolForm.get('temperatureMin').setValue(result.minTemp);
      this.protocolForm.get('humidity').setValue(result.humidity);
      this.protocolForm.get('windspeed').setValue(result.windspeed);
      this.toastService.toastWithTranslateParams('reports.weatherApi.successfulImportForAddress', {address: result.address}, ToastDurationInMs.INFO_WITH_MESSAGE);
    } else {
      this.resetWeather();
      const confirm = await this.commonWeatherService.alertWentWrong();
      if (confirm) {
        this.resetWeather();
        this.callWeatherFunc();
      }
    }
  }

  toggleSection(): void {
    this.showInfo = !this.showInfo;
  }

  resetWeather() {
    this.protocolForm.get('weather').reset();
    this.protocolForm.get('temperatureMin').reset();
    this.protocolForm.get('temperatureMax').reset();
    this.protocolForm.get('humidity').reset();
    this.protocolForm.get('windspeed').reset();
    this.protocolForm.markAsDirty();
    this.changeWeatherFromApiValue(false);
  }

  private changeWeatherFromApiValue(newWeatherFromApi: boolean): boolean {
    const currentWeatherFromApi = this.protocolForm.get('weatherFromApi').value;
    if (newWeatherFromApi === currentWeatherFromApi) {
      return false;
    }
    this.protocolForm.get('weatherFromApi').setValue(newWeatherFromApi);
    this.isFromAPI = this.protocolForm.get('weatherFromApi').value;
  }

  ngOnDestroy() {
    this.dateFormSubscription?.unsubscribe();
  }

  temperatureValues(min = TEMPERATURE_MIN_VALUE, max = TEMPERATURE_MAX_VALUE): Array<number> {
    if (min > max) {
      return [0];
    }
    return Array.from(Array(max - min + 1), (_, i) => i + min).sort((a, b) => +b - +a);
  }

  scrollToDefaultTemperature(event: {component: IonicSelectableComponent}, values: Array<number>) {
    try {
      const tempMin = document.getElementsByClassName('temperature-selectable');
      const contentElements = tempMin[0].getElementsByTagName('ion-content');
      const temperatureScrollTo: number|null|undefined = event.component.value !== null && event.component.value !== undefined ? +event.component.value : DEFAULT_TEMPERATURE;
      let temperatureScrollToIndex = values.indexOf(temperatureScrollTo);
      if (temperatureScrollToIndex !== -1) {
        temperatureScrollToIndex = Math.max(temperatureScrollToIndex - Math.floor(TEMPERATURE_ITEMS_PER_PAGE / 2), 0);
        contentElements[0].scrollToPoint(undefined, temperatureScrollToIndex * TEMPERATURE_ITEM_HEIGHT);
      }
    } catch (error) {
      this.loggingService.error(LOG_SOURCE, `scrollToDefaultTemperature: ${convertErrorToMessage(error)}`);
    }
  }

  async onTemperatureMinChange(event: {component: IonicSelectableComponent, item: string|number, isSelected: boolean}) {
    this.protocolForm.get('temperatureMin').setValue(event.item)
    const minValue = +event.item;
    const maxValue = this.protocolForm.get('temperatureMax').value ? +this.protocolForm.get('temperatureMax').value : undefined;
    if (maxValue !== undefined && maxValue !== null && maxValue < minValue) {
      this.protocolForm.get('temperatureMax').setValue(event.item)
    }
    await event.component.close();
  }

  async onTemperatureMaxChange(event: {component: IonicSelectableComponent, item: string|number, isSelected: boolean}) {
    this.protocolForm.get('temperatureMax').setValue(event.item)
    const minValue = this.protocolForm.get('temperatureMin').value ? +this.protocolForm.get('temperatureMin').value : undefined;
    const maxValue = +event.item;
    if (minValue !== undefined && minValue !== null && minValue > maxValue) {
      this.protocolForm.get('temperatureMin').setValue(event.item)
    }
    await event.component.close();
  }

  async onOpen($event: { component: IonicSelectableComponent }, values: number[]) {
    this.resizeModeBeforeOpen = await this.selectableUtilService.setKeyboardResizeModeOnOpen();
    this.scrollToDefaultTemperature($event, values)
  }

  async onClose($event: { component: IonicSelectableComponent }) {
    await this.selectableUtilService.setKeyboardResizeModeOnClose($event, this.resizeModeBeforeOpen);
  }
}
