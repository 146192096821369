import {Injectable, OnDestroy} from '@angular/core';
import {SyncHistory, SyncToServerHistory} from '../../model/sync-models';
import {AuthenticationService} from '../auth/authentication.service';
import {ReplaySubject, Subscription} from 'rxjs';
import {LoggingService} from '../common/logging.service';
import {IdType} from 'submodules/baumaster-v2-common';
import {StorageKeyEnum} from '../../shared/constants';
import async, {AsyncResultCallback, QueueObject} from 'async';
import {StorageService} from '../storage.service';

const STORAGE_KEY = StorageKeyEnum.SYNC_HISTORY;
const STORAGE_KEY_TO_SERVER = StorageKeyEnum.SYNC_TO_SERVER_HISTORY;
const LOG_SOURCE = 'SyncHistoryService';

@Injectable({
  providedIn: 'root'
})
export class SyncHistoryService implements OnDestroy {
  private static readonly synchronizedSyncQueue: QueueObject<() => Promise<any>> = async.queue<any>(async (task: () => Promise<any>, callback: AsyncResultCallback<any>) => {
    try {
      const result = await task();
      callback(undefined, result);
    } catch (error) {
      callback(error);
    }
  }, 1);
  private syncHistorySubject = new ReplaySubject<Map<IdType, SyncHistory>>(1);
  public readonly syncHistory$ = this.syncHistorySubject.asObservable();
  private syncToServerHistorySubject = new ReplaySubject<Map<IdType, SyncToServerHistory>>(1);
  public readonly syncToServerHistory$ = this.syncToServerHistorySubject.asObservable();
  private authenticationSubscription: Subscription;
  protected isAuthenticated: boolean;

  private static async runSynchronized<R>(functionToCall: () => Promise<R>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      SyncHistoryService.synchronizedSyncQueue.push<R>(functionToCall, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  }

  constructor(private storage: StorageService, private authenticationService: AuthenticationService, private loggingService: LoggingService) {
    this.authenticationSubscription = this.authenticationService.isAuthenticated$.subscribe(async (isAuthenticated) => {
      this.loggingService.debug(LOG_SOURCE, 'authenticationService subscribed.');
      this.isAuthenticated = isAuthenticated;
      if (!isAuthenticated) {
        await this.clearStorageData();
      }
    });
    this.getSyncHistoriesByClientOrProjectId().then((syncHistoryByClientOrProjectId) => this.syncHistorySubject.next(syncHistoryByClientOrProjectId));
  }

  ngOnDestroy(): void {
    this.authenticationSubscription.unsubscribe();
  }

  public async getSyncHistories(): Promise<Array<SyncHistory>> {
    const syncHistories: Array<SyncHistory>|null = await this.storage.get(STORAGE_KEY);
    return syncHistories || [];
  }

  public async getSyncToServerHistories(): Promise<Array<SyncToServerHistory>> {
    const syncHistories: Array<SyncToServerHistory>|null = await this.storage.get(STORAGE_KEY_TO_SERVER);
    return syncHistories || [];
  }

  private keyByClientOrProjectId<T extends SyncHistory|SyncToServerHistory>(syncHistories: Array<T>): Map<IdType, T> {
    return new Map(syncHistories.map(syncHistory => [syncHistory.projectId || syncHistory.clientId, syncHistory]));
  }

  public async getSyncHistoriesByClientOrProjectId(): Promise<Map<IdType, SyncHistory>> {
    return this.keyByClientOrProjectId(await this.getSyncHistories());
  }

  public async getSyncHistory(clientOrProjectId?: string): Promise<SyncHistory|undefined> {
    const syncHistoriesByClientOrProjectId = await this.getSyncHistoriesByClientOrProjectId();
    return syncHistoriesByClientOrProjectId.get(clientOrProjectId);
  }

  public async setSyncHistory(syncHistory: SyncHistory): Promise<Array<SyncHistory>> {
    return await SyncHistoryService.runSynchronized<Array<SyncHistory>>(async () => {
      const syncHistoriesByClientOrProjectId = await this.getSyncHistoriesByClientOrProjectId();
      syncHistoriesByClientOrProjectId.set(syncHistory.projectId || syncHistory.clientId, syncHistory);
      const syncHistories = Array.from(syncHistoriesByClientOrProjectId.values());
      this.assertAuthenticated();
      await this.storage.set(STORAGE_KEY, syncHistories);
      this.syncHistorySubject.next(syncHistoriesByClientOrProjectId);
      return syncHistories;
    });
  }

  public async getSyncToServerHistoriesByClientOrProjectId(): Promise<Map<IdType, SyncToServerHistory>> {
    return this.keyByClientOrProjectId(await this.getSyncToServerHistories());
  }

  public async getSyncToServerHistory(clientOrProjectId?: string): Promise<SyncToServerHistory|undefined> {
    const syncHistoriesByClientOrProjectId = await this.getSyncToServerHistoriesByClientOrProjectId();
    return syncHistoriesByClientOrProjectId.get(clientOrProjectId);
  }

  public async setSyncToServerHistory(syncToServerHistory: SyncToServerHistory): Promise<Array<SyncToServerHistory>> {
    return await SyncHistoryService.runSynchronized<Array<SyncToServerHistory>>(async () => {
      const syncHistoriesByClientOrProjectId = await this.getSyncToServerHistoriesByClientOrProjectId();
      syncHistoriesByClientOrProjectId.set(syncToServerHistory.projectId || syncToServerHistory.clientId, syncToServerHistory);
      const syncHistories = Array.from(syncHistoriesByClientOrProjectId.values());
      this.assertAuthenticated();
      await this.storage.set(STORAGE_KEY_TO_SERVER, syncHistories);
      this.syncToServerHistorySubject.next(syncHistoriesByClientOrProjectId);
      return syncHistories;
    });
  }

  public async removeAllButForProjects(keepProjectIds: Array<string>): Promise<number> {
    return await SyncHistoryService.runSynchronized<number>(async () => {
      const countToServer = await this.removeSyncToServerHistories((syncHistories) =>
        syncHistories.filter((syncHistory) => syncHistory.projectId === undefined
          || keepProjectIds.find((keepProjectId) => keepProjectId === syncHistory.projectId)));

      const count = await this.removeSyncHistories((syncHistories) =>
        syncHistories.filter((syncHistory) => syncHistory.projectId === undefined
          || keepProjectIds.find((keepProjectId) => keepProjectId === syncHistory.projectId)));
      return countToServer + count;
    });
  }

  public async removeForProjects(removeProjectId: IdType): Promise<number> {
    return await SyncHistoryService.runSynchronized<number>(async () => {
      const countToServer = await this.removeSyncToServerHistories((syncHistories) =>
        syncHistories.filter((syncHistory) => syncHistory.projectId === undefined || syncHistory.projectId !== removeProjectId));

      const count = await this.removeSyncHistories((syncHistories) =>
        syncHistories.filter((syncHistory) => syncHistory.projectId === undefined || syncHistory.projectId !== removeProjectId));
      return countToServer + count;
    });
  }

  public async removeAllButForClients(keepProjectIds: Array<string>): Promise<number> {
    return await SyncHistoryService.runSynchronized<number>(async () => {
      const countToServer = await this.removeSyncToServerHistories((syncHistories) =>
        syncHistories.filter((syncHistory) => syncHistory.projectId === undefined
          || keepProjectIds.find((keepProjectId) => keepProjectId === syncHistory.projectId)));

      const count = await this.removeSyncHistories((syncHistories) =>
        syncHistories.filter((syncHistory) => syncHistory.projectId === undefined
          || keepProjectIds.find((keepProjectId) => keepProjectId === syncHistory.projectId)));
      return countToServer + count;
    });
  }

  private async removeSyncHistories(keepFunction: (syncHistories: Array<SyncHistory>) => Array<SyncHistory>): Promise<number> {
    const syncHistories = await this.getSyncHistories();
    const syncHistoriesToKeep = keepFunction(syncHistories);
    this.assertAuthenticated();
    await this.storage.set(STORAGE_KEY, syncHistoriesToKeep);
    return syncHistories.length - syncHistoriesToKeep.length;
  }

  private async removeSyncToServerHistories(keepFunction: (syncHistories: Array<SyncToServerHistory>) => Array<SyncToServerHistory>): Promise<number> {
    const syncHistories = await this.getSyncToServerHistories();
    const syncHistoriesToKeep = keepFunction(syncHistories);
    this.assertAuthenticated();
    await this.storage.set(STORAGE_KEY_TO_SERVER, syncHistoriesToKeep);
    return syncHistories.length - syncHistoriesToKeep.length;
  }

  private async clearStorageData() {
    await this.storage.remove(STORAGE_KEY);
    await this.storage.remove(STORAGE_KEY_TO_SERVER);
  }

  protected assertAuthenticated() {
    if (!this.isAuthenticated) {
      throw new Error('User not authenticated (auth is null or undefined)');
    }
  }
}
