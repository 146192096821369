import {CommonModule} from '@angular/common';
import {Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {RxFor} from '@rx-angular/template/for';
import _ from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {Nullish} from 'src/app/model/nullish';
import {ProtocolWithTypeAndLayout} from 'src/app/model/protocol';
import {SelectableRecentlyUsedItems} from 'src/app/model/selectable';
import {RecentlyUsedSelectableService} from 'src/app/services/common/recently-used-selectable.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {UiModule} from 'src/app/shared/module/ui/ui.module';
import {combineLatestAsync} from 'src/app/utils/async-utils';
import {memoizedFormatNumber} from 'src/app/utils/number-utils';
import {IdType} from 'submodules/baumaster-v2-common';
import {ProtocolInList} from '../../../model/protocol-in-list';

const SEARCH_INPUT_DEBOUNCE_TIME_MS = 250;

@Component({
  selector: 'app-select-target-protocol-modal',
  templateUrl: './select-target-protocol-modal.component.html',
  styleUrls: ['./select-target-protocol-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    UiModule,
    TranslateModule,
    RxFor,
    FormsModule
  ]
})
export class SelectTargetProtocolModalComponent implements OnChanges, OnInit {

  private readonly modal: HTMLIonModalElement;
  readonly searchSubject = new BehaviorSubject('');
  @Input()
  mapProtocols?: (protocols: ProtocolInList[]) => ProtocolInList[];
  @Input()
  boldTitle?: string;
  @Input()
  title?: string;
  @Input()
  selectedCountTranslationKey?: string;
  @Input()
  allSubEntriesWillMoveTranslationKey?: string;
  @Input()
  withoutParentWillSkipTranslationKey?: string;
  @Input()
  protocolsWithTypeAndLayout$?: Nullish<Observable<ProtocolWithTypeAndLayout[]>>;
  @Input()
  hasAllChildren: boolean;
  @Input()
  hasAllParents: boolean;
  @Input()
  entryIds: IdType[];
  @Input()
  entryIdsToMove: IdType[];

  protocols$: Observable<ProtocolInList[]>;

  targetProtocolId: IdType | null = null;

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private recentlyUsedSelectableService: RecentlyUsedSelectableService,
    private protocolService: ProtocolService
  ) {}

  ngOnInit() {
    this.initProtocolsObservable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.protocolsWithTypeAndLayout$) {
      this.initProtocolsObservable();
    }
  }

  private initProtocolsObservable() {
    this.protocols$ = combineLatestAsync([
      this.protocolsWithTypeAndLayout$ ?? this.protocolService.openProtocolsWithTypeAndLayout$,
      this.recentlyUsedSelectableService.getRecentlyUsedItemsOfCurrentProject$('protocol').pipe(
        distinctUntilChanged<SelectableRecentlyUsedItems>(_.isEqual)
      )
    ]).pipe(
      map(([protocols, recentlyUsed]) => {
        const grouped = _.keyBy(recentlyUsed ?? [], 'id');

        return _.orderBy(protocols, [(protocol) => {
          if ('inGroup' in protocol) {
            return -1;
          }
          const index = (recentlyUsed ?? []).findIndex(v => v.id === protocol.id);
          if (index === -1) {
            return Infinity;
          }

          return index;
        }, 'typeId', 'number']).map<ProtocolInList>((protocol) => ({
          ...protocol,
          inGroup: (protocol as any).inGroup ?? (Boolean(grouped[protocol.id]) ? 'recently_used' : 'other'),
          firstInGroup: false,
        }));
      }),
      switchMap((protocols) => this.searchSubject.pipe(
        map((searchText) => searchText.trim().toLocaleLowerCase()),
        debounceTime(SEARCH_INPUT_DEBOUNCE_TIME_MS),
        map((searchText) => !searchText ? protocols : protocols.filter((protocol) => (protocol.name?.toLocaleLowerCase() ?? '').includes(searchText)
          || (`${protocol.protocolType.code}-${memoizedFormatNumber(protocol.number, this.locale, '2.0')}`.toLocaleLowerCase()).includes(searchText)
          || protocol.protocolType.name.toLocaleLowerCase().includes(searchText)
        )),
        distinctUntilChanged<ProtocolInList[]>(_.isEqual)
      )),
      map((protocols) => this.mapProtocols ? this.mapProtocols(protocols) : protocols),
      map((protocols) => protocols.reduce<ProtocolInList[]>((acc, protocol) => {
        if (acc.length === 0) {
          acc.push({...protocol, firstInGroup: true});
          return acc;
        }
        if (acc[acc.length - 1].inGroup !== protocol.inGroup) {
          acc.push({...protocol, firstInGroup: true});
        } else {
          acc.push(protocol);
        }
        return acc;
      }, []))
    );
  }

  close() {
    this.modal.dismiss();
  }

  confirmTarget() {
    this.recentlyUsedSelectableService.markRecentlyUsedForCurrentProject('protocol', this.targetProtocolId);
    this.modal.dismiss(this.targetProtocolId, 'confirm-target');
  }

}
