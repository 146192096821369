<ion-content>
  <app-protocol-list
    class="ion-hide-xxl-up"
  ></app-protocol-list>
  <ng-template #noRowsInProtocolType>
    <!-- Empty for now maybe displays a graphic in the future -->
    <h1 class="no-data ion-hide-lg-up ion-hide-xxl-down">
    </h1>
    <h1 class="no-data ion-hide-lg-down ion-hide-xxl-down">
    </h1>
  </ng-template>
  <h1 class="no-data ion-hide-xxl-down" *ngIf="!(protocolTypeFilterObservable | async)?.name; else noRowsInProtocolType">
  </h1>
</ion-content>
