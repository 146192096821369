import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, IonSearchbar, LoadingController, ModalController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Observable, ReplaySubject, Subject, Subscription, animationFrameScheduler} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, pairwise, startWith, subscribeOn, switchMap, take, takeUntil} from 'rxjs/operators';
import {EntryCardListModel, EntryCardModel} from 'src/app/model/entry-card-model';
import {SearchFilterField} from 'src/app/model/protocol-entry-search-filter';
import {CompanyDataService} from 'src/app/services/data/company-data.service';
import {ProjectCompanyDataService} from 'src/app/services/data/project-company-data.service';
import {MoveEntryService} from 'src/app/services/entry/move-entry.service';
import {FeatureEnabledService} from 'src/app/services/feature/feature-enabled.service';
import {PosthogService} from 'src/app/services/posthog/posthog.service';
import {ProtocolNavigationService} from 'src/app/services/protocol-navigation.service';
import {ProtocolEntryParameterFilter} from 'src/app/services/protocol/protocol-entry-filter.service';
import {ProtocolEntryListService} from 'src/app/services/protocol/protocol-entry-list.service';
import {ProtocolEntrySelectionService} from 'src/app/services/protocol/protocol-entry-selection.service';
import {ProtocolEntryService} from 'src/app/services/protocol/protocol-entry.service';
import {ProtocolService} from 'src/app/services/protocol/protocol.service';
import {TriggerEntrySaveService} from 'src/app/services/protocol/trigger-entry-save.service';
import {ProtocolEntrySearchFilterService} from 'src/app/services/search/protocol-entry-search-filter.service';
import {Breakpoints, DeviceService} from 'src/app/services/ui/device.service';
import {PROTOCOL_LAYOUT_NAME_SHORT, ToastDurationInMs} from 'src/app/shared/constants';
import {convertErrorToMessage} from 'src/app/shared/errors';
import {combineLatestAsync, observableToPromise} from 'src/app/utils/async-utils';
import {dismissOverlayOnBackButtonOrNavigation} from 'src/app/utils/overlay-utils';
import {getProtocolEntryPagePath} from 'src/app/utils/router-utils';
import {
  Company,
  IdType,
  isTaskProtocol,
  LicenseType,
  ProjectCompany,
  Protocol,
  ProtocolEntry,
  ProtocolEntryIconStatus,
  ProtocolLayout,
  PROTOCOL_LAYOUT_NAME_CONTINUOUS
} from 'submodules/baumaster-v2-common';
import {ProtocolEntryOrOpen} from '../../../model/protocol';
import {LoggingService} from '../../../services/common/logging.service';
import {ToastService} from '../../../services/common/toast.service';
import {ActiveProtocolEntry, ProtocolEntryDataService} from '../../../services/data/protocol-entry-data.service';
import {SystemEventService} from '../../../services/event/system-event.service';
import {CopyProtocolEntryComponent} from '../../copy/copy-protocol-entry/copy-protocol-entry.component';
import {EntryCardListComponent} from '../../entry/entry-card-list/entry-card-list.component';
import {EntryCardSettingsComponent} from '../../entry/entry-card-settings/entry-card-settings.component';
import {EntryFilterModalComponent} from '../../entry/entry-filter-modal/entry-filter-modal.component';
import {PdfWorkflowComponent} from '../../pdf/pdf-workflow/pdf-workflow.component';
import {ProtocolEntryMassEditComponent} from '../protocol-entry-mass-edit/protocol-entry-mass-edit.component';
import { ProtocolEntryCompanyService } from 'src/app/services/protocol/protocol-entry-company.service';


const LOG_SOURCE = 'ProtocolEntriesComponent';

type ProtocolEntryGroupBundle = {
  protocolEntry: ProtocolEntry,
  firstInGroup: boolean;
};

@Component({
  selector: 'app-protocol-entries',
  templateUrl: './protocol-entries.component.html',
  styleUrls: ['./protocol-entries.component.scss'],
})
export class ProtocolEntriesComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @ViewChild('autofocus', { static: false }) searchbar: IonSearchbar;
  @ViewChild(EntryCardListComponent, {static: false}) entryCardListComponent: EntryCardListComponent|undefined;

  @Input() protocol: Protocol;
  @Input() showFilter = true;

  get textSearch() { return this.protocolEntryListService.search; }
  set textSearch(search: string) { this.protocolEntryListService.search = search; }

  private layoutSubscription: Subscription|undefined;
  private textSearchSubscription: Subscription|undefined;
  public companyIdFilter: SearchFilterField<IdType>|undefined;
  public observerCompaniesFilter: SearchFilterField<IdType>|undefined;
  public protocolVirtualScrollHeights = new Map<IdType, number>();
  public allSubEntriesExpanded = false;
  public disableSubentryButton = false;

  private protocolLayout: ProtocolLayout|undefined;
  private pendingMultipleDeletion = false;
  public loading = false;
  private isProtocolLayoutShort = false;

  protected contentRendered$ = new ReplaySubject(1);

  public isCompanyInProject$: Observable<Record<IdType, ProjectCompany>> = this.projectCompanyDataService.dataByCompanyId$;
  companyById$: Observable<Record<IdType, Company>> = this.companyDataService.dataGroupedById.pipe(startWith({}));

  hasCarriedOverOrClosedEntriesSelected$ = this.protocolEntrySelectionService.selectedEntries$.pipe(
    map((entries) => entries.some((entry) =>
      this.protocol.closedAt ||
      entry.layout === 'CONTINUOUS' ? entry.isCarriedOver : entry.protocolId !== this.protocol.id
    ))
  );
  isMoveToProtocolDisabled$ = combineLatestAsync([
    this.protocolEntrySelectionService.selectedEntries$,
    this.hasCarriedOverOrClosedEntriesSelected$
  ]).pipe(
    map(([entries, hasCarriedOverOrClosedEntriesSelected]) => hasCarriedOverOrClosedEntriesSelected || entries.every((entry) => entry.parentId))
  );

  currentEntryId$ = this.protocolEntryListService.currentEntryId$;
  hasEntriesStatus$ = this.protocolEntryListService.hasEntriesStatus$;
  hasFilters$ = this.protocolEntryListService.hasFilters$;
  sortOrderAsc$ = this.protocolEntryListService.sortOrderAsc$;
  withSubEntries$ = this.protocolEntryListService.withSubEntries$;
  canMultiselect$ = this.featureEnabledService.isFeatureEnabled$(false, true, [LicenseType.VIEWER]);

  constructor(private protocolEntryDataService: ProtocolEntryDataService,
              private protocolEntryService: ProtocolEntryService,
              private protocolService: ProtocolService,
              private modalController: ModalController,
              private systemEventService: SystemEventService,
              private loggingService: LoggingService,
              private alertCtrl: AlertController,
              private translateService: TranslateService,
              public protocolEntrySelectionService: ProtocolEntrySelectionService<EntryCardModel>,
              private loadingCtrl: LoadingController,
              private toastService: ToastService,
              private router: Router,
              private protocolNavigationService: ProtocolNavigationService,
              private platform: Platform,
              private projectCompanyDataService: ProjectCompanyDataService,
              private triggerEntrySaveService: TriggerEntrySaveService,
              private moveEntryService: MoveEntryService,
              private posthogService: PosthogService,
              private protocolEntryListService: ProtocolEntryListService,
              private deviceService: DeviceService,
              private protocolEntrySearchFilterService: ProtocolEntrySearchFilterService,
              private companyDataService: CompanyDataService,
              private featureEnabledService: FeatureEnabledService,
              private protocolEntryCompanyService: ProtocolEntryCompanyService) {
  }

  ngOnInit(): void {
    this.watchTaskSort();
    this.loggingService.debug(LOG_SOURCE, 'ngOnInit called.');
    this.protocolEntrySearchFilterService.filters$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((filters) => {
      this.companyIdFilter = filters.entry.companyId.in?.length > 0 ? filters.entry.companyId : undefined;
      this.observerCompaniesFilter = filters.entry.observerCompanies.in?.length > 0 ? filters.entry.observerCompanies : undefined;
    });
  }

  private watchTaskSort() {
    combineLatestAsync([
      this.protocolEntryListService.currentEntryId$,
      this.protocolEntryListService.sortOrderAsc$.pipe(pairwise())
    ]).pipe(takeUntil(this.destroy$)).subscribe(([entryId, [orderBefore, orderAfter]]) => {
      if (orderBefore !== orderAfter) {
        this.entryCardListComponent?.scrollToEntry(entryId, {scheduleAtNextRender: true});
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    this.loggingService.debug(LOG_SOURCE, 'ngOnChanges called.');
    try {
      if (changes.protocol) {
        this.layoutUnsubscribe();
        if (this.protocol?.id) {
          this.loading = true;
          this.layoutSubscription = this.protocolService.getProtocolLayoutByProtocolId(this.protocol.id, false).subscribe((protocolLayout) => {
            this.protocolLayout = protocolLayout;
            this.isProtocolLayoutShort = protocolLayout?.name === PROTOCOL_LAYOUT_NAME_SHORT;

            this.disableSubentryButton = this.isProtocolLayoutShort;
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }
    } catch (error) {
      this.systemEventService.logErrorEvent(LOG_SOURCE + ' - ngOnChanges', error?.userMessage + '-' + error?.message);
    }
  }

  groupAdditionalHeightFn = (entry: EntryCardListModel) => entry?.firstInGroup ? 30 : 0;

  ngAfterViewInit() {
    this.loggingService.debug(LOG_SOURCE, 'ngAfterViewInit called.');
    const { url } = this.router;
    if (this.entryCardListComponent && url.startsWith('/protocols') && url.includes('/entry')) {
      const id = url.slice(url.indexOf('/entry') + 44);
      this.entryCardListComponent.scrollToEntry(id);
    }
    this.protocolEntryListService.currentProtocolId$.pipe(
      distinctUntilChanged(),
      switchMap(() => this.contentRendered$.pipe(take(1))),
      switchMap(() => this.currentEntryId$.pipe(take(1))),
      debounceTime(0, animationFrameScheduler),
      takeUntil(this.destroy$)
    ).subscribe((entryId) => {
      this.entryCardListComponent?.scrollToEntry(entryId);
    });
  }

  ngOnDestroy() {
    this.loggingService.debug(LOG_SOURCE, 'ngOnDestroy called.');
    this.destroy$.next();
    this.destroy$.complete();
    this.layoutUnsubscribe();
    this.textSearchSubscription?.unsubscribe();
    if (this.showFilter) {
      this.protocolEntrySearchFilterService.clearAllFilters();
    }
  }

  layoutUnsubscribe() {
    if (this.layoutSubscription) {
      this.loggingService.debug(LOG_SOURCE, 'unsubscribe called.');
      this.layoutSubscription.unsubscribe();
      this.layoutSubscription = undefined;
    }
  }

  async openEntryFilter() {
    const modal = await this.modalController.create({
      component: EntryFilterModalComponent,
      cssClass: 'omg-modal'
    });

    await modal.present();
    this.posthogService.captureEvent('Protocol Filter Button clicked', {});
  }

  async openSendPdfDialog() {
    await this.triggerEntrySaveService.triggerSave();
    let filteredProtocolEntries: ProtocolEntry[] = [];
    if (this.protocolEntrySelectionService.isSelectMode) {
      filteredProtocolEntries = await this.retrieveSelectedEntries();
    } else if (await observableToPromise(this.hasFilters$)) {
      filteredProtocolEntries = await this.retrieveAllEntries();
    }

    const protocolValidation = await observableToPromise(this.protocolService.isValidForSendPdfProtocol(this.protocol.id, filteredProtocolEntries));
    if (!protocolValidation.valid) {
      const message = !protocolValidation.message ? `${this.translateService.instant('pdfProtocol.errorMissingInformation')}` : protocolValidation.message;
      const alert = await this.alertCtrl.create({
        message,
        buttons: [
          {
            text: 'OK',
            handler: async () => {
              await alert.dismiss();
            }
          }
        ]
      });
      await alert.present();
      return;
    }
    const modal = await this.modalController.create({
      component: PdfWorkflowComponent,
      keyboardClose: false,
      backdropDismiss: false,
      cssClass: 'pdf-workflow-modal',
      componentProps: {
        protocol: this.protocol,
        filteredProtocolEntries
      }
    });
    await modal.present();
  }

  async removeSelectedEntries() {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant('protocolEntry.deleteMany.confirmHeader'),
      message: this.translateService.instant('protocolEntry.deleteMany.confirm'),
      buttons: [
        {
          text: this.translateService.instant('no'),
          handler: async () => {
            await alert.dismiss();
          },
        },
        {
          text: this.translateService.instant('yes'),
          handler: async () => {
            await this.performRemovalOfSelectedEntries();
          },
        }
      ]
    });
    dismissOverlayOnBackButtonOrNavigation(alert, this.router, this.platform);
    await alert.present();
  }

  async moveSelectedEntries() {
    const selectedEntries = await this.retrieveSelectedEntries();

    const currentEntry = await observableToPromise(this.protocolEntryDataService.currentProtocolEntryObservable);
    const isCurrentEntryInSelected = this.isCurrentEntryInSelected(currentEntry, selectedEntries);
    const redirectPath = this.getRedirectionPath(selectedEntries, isCurrentEntryInSelected);

    const moved = await this.moveEntryService.moveProtocolEntries(
      selectedEntries.map((entry) => entry.id),
      this.protocol
    );

    if (moved) {
      this.protocolEntrySelectionService.leaveSelectMode();

      if (redirectPath) {
        await this.router.navigate(redirectPath, {
          state: {
            protocolListShowActive: true,
          },
        });
      }
    }
  }

  async copySelectedEntries() {
    const selectedEntries = await this.retrieveSelectedEntries();
    const modal = await this.modalController.create({
      component: CopyProtocolEntryComponent,
      keyboardClose: false,
      backdropDismiss: true,
      componentProps: {
        protocolId: this.protocol.id,
        protocolEntries: selectedEntries,
        currentProtocolLayout: this.protocolLayout
      }
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    if (result.data?.success) {
      this.protocolEntrySelectionService.leaveSelectMode();
    }
  }

  private inSplitScreen(): Promise<boolean> {
    return observableToPromise(this.deviceService.isAboveBreakpoint(Breakpoints.lg));
  }

  async navigateToEntry(entry: EntryCardModel) {
    const path = getProtocolEntryPagePath(this.protocol?.id ?? entry.protocolId, entry.id);
    const isInSplitScreen = await this.inSplitScreen();
    await this.router.navigate(path, {replaceUrl: isInSplitScreen});
  }

  private isCurrentEntryInSelected(currentEntry: ActiveProtocolEntry, selectedEntries: ProtocolEntry[]) {
    return selectedEntries.some((entry) => currentEntry?.protocolEntry?.id === entry.id);
  }

  private async performRemovalOfSelectedEntries() {
    const loading = await this.loadingCtrl.create({
      message: this.translateService.instant('protocolEntry.deleteMany.loading'),
    });
    this.pendingMultipleDeletion = true;
    await loading.present();
    try {
      const selectedEntries = await this.retrieveSelectedEntries();

      const currentEntry = await observableToPromise(this.protocolEntryDataService.currentProtocolEntryObservable);
      const isCurrentEntryInSelected = this.isCurrentEntryInSelected(currentEntry, selectedEntries);
      const redirectPath = this.getRedirectionPath(selectedEntries, isCurrentEntryInSelected);

      if (isCurrentEntryInSelected) {
        this.protocolNavigationService.cancelWatchProtocolEntryDeleted(currentEntry?.protocolEntry.id);
      }

      await this.protocolEntryService.deleteProtocolEntries(selectedEntries, this.protocol.projectId);

      if (redirectPath) {
        this.router.navigate(redirectPath, {
          state: {
            protocolListShowActive: true,
          },
        });
      }

      this.protocolEntrySelectionService.leaveSelectMode();
      this.pendingMultipleDeletion = false;
      await loading.dismiss();
    } catch (error) {
      this.pendingMultipleDeletion = false;
      await loading.dismiss();
      throw error;
    }
  }

  private getRedirectionPath(selectedEntries: ProtocolEntry[], containsActive: boolean): string[]|null {
    const firstEntryPath = getProtocolEntryPagePath(this.protocol.id, 'first');

    if (this.protocolEntrySelectionService.hasAllSelected) {
      return firstEntryPath;
    }

    if (containsActive) {
      return firstEntryPath;
    }

    // Stay on the same page
    return null;
  }

  async retrieveSelectedEntries() {
    const selectedSimplifiedEntries = this.protocolEntrySelectionService.selectedEntries;
    return await observableToPromise(this.protocolEntryDataService.getByIds(selectedSimplifiedEntries.map(({id}) => id)));
  }

  async retrieveAllEntries() {
    const allSimplifiedEntries = this.protocolEntrySelectionService.allEntries;
    return await observableToPromise(this.protocolEntryDataService.getByIds(allSimplifiedEntries.map(({id}) => id)));
  }

  async massEdit() {
    const selectedEntries = await this.retrieveSelectedEntries();
    const isShortProtocolLayout = this.protocolLayout?.name === PROTOCOL_LAYOUT_NAME_SHORT;
    const isContinuousProtocolLayout= this.protocolLayout?.name === PROTOCOL_LAYOUT_NAME_CONTINUOUS;
    let hasNonActionableItems = false;
    let hasActionableItems = false;
    for (const entry of selectedEntries) {
      const status = await this.protocolEntryService.getProtocolEntryIconStatusByEntryId(entry.id);
      if (status === ProtocolEntryIconStatus.INFO) {
        hasNonActionableItems = true;
      } else {
        hasActionableItems = true;
      }
      if (hasActionableItems && hasNonActionableItems) {
        break;
      }
    }
    if (!this.protocol) {
      throw new Error(`massEdit - no protocol selected.`);
    }

    const modal = await this.modalController.create({
      component: ProtocolEntryMassEditComponent,
      componentProps: {
        protocolId: this.protocol.id,
        isShortProtocolLayout,
        isContinuousProtocolLayout,
        isTaskProtocol: isTaskProtocol(this.protocol),
        hasChildEntries: selectedEntries.some((entry) => !!entry.parentId),
        isClosedOrHasCarriedOverEntries: this.protocol.closedAt || selectedEntries.some((entry) =>
          this.protocol.closedAt ||
          (entry.hasOwnProperty('isOpenEntry') ? (entry as ProtocolEntryOrOpen).isOpenEntry : entry.protocolId !== this.protocol.id)),
        entriesCount: selectedEntries.length,
        hasNonActionableItems,
        hasActionableItems,
      },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (!data) {
      return;
    }

    const protocolEntryInput = this.protocolEntryService.getPartialProtocolEntryFromRawData(data);

    const loading = await this.loadingCtrl.create({
      message: this.translateService.instant('protocolEntry.massEdit.loading'),
    });

    await loading.present();
    try {
      if (data.observerCompanies?.length) {
        for (const entry of selectedEntries) {
          await this.protocolEntryCompanyService.saveObserverCompanies(entry.id, data.observerCompanies);
        }
      }
      await this.protocolEntryService.editProtocolEntries(selectedEntries, protocolEntryInput, this.protocol.projectId);
      await this.toastService.toastWithTranslateParams('protocolEntry.massEdit.success', {count: selectedEntries.length}, ToastDurationInMs.INFO);
      this.protocolEntrySelectionService.leaveSelectMode();

    } catch (e) {
      await this.toastService.toastWithTranslateParams('protocolEntry.massEdit.failure', {message:  convertErrorToMessage(e)} , ToastDurationInMs.ERROR);

    } finally {
      await loading.dismiss();
    }
  }

  async openEntryCardSettings() {
    const modal = await this.modalController.create({
      component: EntryCardSettingsComponent,
      cssClass: 'omg-modal',
      backdropDismiss: true,
      componentProps: {
        useProtocolEntryNumber: true
      }
    });

    await modal.present();
  }

  toggleSortDirection() {
    this.protocolEntryListService.sortOrderAsc = !this.protocolEntryListService.sortOrderAsc;
  }

  toggleSubentriesVisibility() {
    this.protocolEntryListService.withSubEntries = !this.protocolEntryListService.withSubEntries;
  }

}
